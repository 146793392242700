import React, { Fragment, useContext } from "react";
import { AppContext } from "../context";
import { userLogs } from "../context/utils";
import Button from "./Button";

const TimeOut = () => {
  const { config } = useContext(AppContext);

  const refreshLink = () =>{
    window.location.reload()
  }
 
  return (
    <div className="timeout">
      <h1>{config["TimeOut"]["header-text"]}</h1>
      <p>{config["TimeOut"]["body-text"]}</p>
      <Button
          wide={false}
          onclick={refreshLink}
          text={"Refresh Link"}
        />
    </div>
  );
};

export default TimeOut;
