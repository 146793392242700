import * as utilFuncs from "../utils_new/UtilFuncs";
import * as constants from "../utils_new/Constants";

export class AngleGyroEstimator {
  constructor() {
    this.betaStartVal = constants.START_FLAG;
    this.gammaStartVal = constants.START_FLAG;
    this.compass_starting_offset = constants.START_FLAG;
    this.betaThresh = 90;
    this.gammaThresh = 20;
    this.angleStep = 5;
    this.deviceOrientationVector = null;

    // Add the DeviceOrientation Event for Angle calculation
    if (window.DeviceOrientationEvent) {
      window.addEventListener("deviceorientation", (event) => {
        // console.log("event", event.alpha, event.beta, event.gamma)
        this.deviceOrientationVector = event;
      });
    } else {
      console.log(
        "DeviceOrientationEvent (gyro) not supported in this browser."
      );
    }
  }

  // Function to process and trim the Angle value
  trimAngle(angle) {
    // Remove the decimals
    angle = Math.floor(angle);

    // Set the angle to nearest multiple of angleStep
    angle = utilFuncs.roundToNearestX(angle, this.angleStep);

    // Bring the angle to (0,360)
    if (angle < 0) angle += 360;

    if (angle >= 360) angle -= 360;

    return angle;
  }

  getAngleOfFrame(aggregatedAngle, aggregatedOrientation) {
    // Angle components in Degrees
    let alphaDeg = this.deviceOrientationVector.alpha;
    let betaDeg = this.deviceOrientationVector.beta;
    let gammaDeg = this.deviceOrientationVector.gamma;
    // console.log(alphaDeg, betaDeg, gammaDeg)

    //Keep the initial Beta and Gamma values
    if (this.betaStartVal === constants.START_FLAG) this.betaStartVal = betaDeg;

    if (this.gammaStartVal === constants.START_FLAG)
      this.gammaStartVal = gammaDeg;

    /* Deals with the edge cases when the user points the camera towards the sky or the ground.
            Do not update Angle when Gamma is between (-this.gammaThresh, this.gammaThresh). */
    if (Math.abs(gammaDeg) < this.gammaThresh) return -1;

    /* Handles edge case when user flips the phone, for eg, from pointing the camera at the car to pointing it at the user himself.
            Do not update Angle when current Gamma vector is in the opposite direction as the starting Gamma vector
            and when the difference between the current and starting Beta vectors is less than a threshold. */

    if (gammaDeg / this.gammaStartVal < 0)
      if (Math.abs(betaDeg - this.betaStartVal) < this.betaThresh) return -1;

    /* Handles a continuation of the previous edge case, where the user continues rotating the phone in the same direction, 
            for eg, from pointing it at the user to pointing it at the car back.
            Do not update Angle when current Gamma vector is in the same direction compared as the starting Gamma vector
            and when the difference between the current and starting Beta vectors is greater than a threshold. */
    if (gammaDeg / this.gammaStartVal > 0)
      if (Math.abs(betaDeg - this.betaStartVal) > this.betaThresh) return -1;

    // Angle components in Radians
    let alphaRad = alphaDeg * (Math.PI / 180);
    let betaRad = betaDeg * (Math.PI / 180);
    let gammaRad = gammaDeg * (Math.PI / 180);

    // Calculate equation components
    let cA = Math.cos(alphaRad);
    let sA = Math.sin(alphaRad);
    let cB = Math.cos(betaRad);
    let sB = Math.sin(betaRad);
    let cG = Math.cos(gammaRad);
    let sG = Math.sin(gammaRad);

    // Calculate A, B, C rotation components
    let rA = -cA * sG - sA * sB * cG;
    let rB = -sA * sG + cA * sB * cG;
    let rC = -cB * cG;

    // Calculate compass heading
    let compassHeading = Math.atan(rA / rB);

    // Convert from half unit circle to whole unit circle
    if (rB < 0) compassHeading += Math.PI;
    else if (rA < 0) compassHeading += 2 * Math.PI;

    // Convert from radians to degrees
    compassHeading *= 180 / Math.PI;

    // Setting the class Angle variable
    let angle = compassHeading;

    // Return angle as -1 if it isn't a proper value yet
    if (angle === constants.START_FLAG || isNaN(angle) || angle === -1)
      return -1;

    // Determine the Compass Starting Offset at the beginning of the video. ALso update it using the starting angle of the user.
    if (this.compass_starting_offset === constants.START_FLAG) {
      this.compass_starting_offset = angle;
      if (aggregatedOrientation === "Left")
        this.compass_starting_offset -= 360 - aggregatedAngle;
      else this.compass_starting_offset -= aggregatedAngle;
    }

    // Offset the current angle by the Compass Starting Offset
    angle -= this.compass_starting_offset;

    // Process and trim the angle
    angle = this.trimAngle(angle);

    return angle;
  }
}
