import React, { useState, useRef, useEffect, useContext } from "react";
import { makeRequestVideo } from "../context/utils";
import { AppContext } from "../context";
import Button from "./Button";

let timerStart = null;
let seconds = 0;
let secondsRestriction = 0;
let minutes = 0;
let recorder = null;
let formData = null;
let stream = null;

const VideoRecorder = () => {
  const {
    config,
    currentBlock,
    token,
    inspectionId,
    setScreen,
    condition,
    setCondition,
    setMandatory,
  } = useContext(AppContext);
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);
  const backRef = useRef(null);
  const timerRef = useRef(null);
  const videoLimit = useRef(null);
  const poupMssg = useRef(null);
  const cameraIns = useRef(null);

  const startCamera = async () => {
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: Object.keys(config).includes("aspectRatio") ?
        {
          width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
         height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
         facingMode: "environment",
         aspectRatio: 4 / 3,
         zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
         frameRate: { ideal: 25, max: 30 }
       } : {
         width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
         height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
         facingMode: "environment",
         zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
         frameRate: { ideal: 25, max: 30 }
       },
        audio: currentBlock["audioRecording"],
      });
      videoRef.current.srcObject = stream;
      timerRef.current.innerText = "00:00";
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  useEffect(() => {
    startCamera();
    return () => {
      if (videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, []);

  const timerCount = () => {
    timerStart = setInterval(() => {
      seconds++;
      secondsRestriction++;
      if (secondsRestriction === currentBlock["videoTimer"]) {
        poupMssg.current.style.display = "flex";
        stopRecording();
        videoLimit.current.innerText = "Recording Timeout";
      }
      if (seconds === 60) {
        seconds = 0;
        minutes++;
      }
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      timerRef.current.innerText = `${formattedMinutes}:${formattedSeconds}`;
    }, 1000);
  };

  const startRecording = async () => {
    timerCount();
    backRef.current.style.display = "none";
    cameraIns.current.style.display = "none";
    setIsRecording(true);
    recorder = new MediaRecorder(stream);
    const chunks = [];
    recorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    recorder.onstop = async () => {
      const blob = new Blob(chunks, { type: "video/webm" });
      formData = new FormData();
      formData.append("video", blob, `${inspectionId}_video.webm`);
      formData.append("token", token);
      formData.append("inspectionId", inspectionId);
      for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      const region = Object.keys(config["video-page"]).includes("clientRegion")
        ? config["video-page"]["clientRegion"]
        : "eu";
      // console.log(blob);
    };
    recorder.start();
  };

  const stopRecording = () => {
    poupMssg.current.style.display = "flex";
    clearInterval(timerStart);
    videoLimit.current.innerText = "Recording completed";
    // setIsRecording(false);
    if (recorder && recorder.state !== "inactive") {
      recorder.stop();
    }
  };

  const back = () => {
    if (recorder && recorder.state !== "inactive") {
      recorder.stop();
    }
    videoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop();
    });
    setScreen("menu");
  };

  const continueUploading = () => {
    makeRequestVideo(formData);
    videoRef.current.srcObject.getTracks().forEach((track) => {
      track.stop();
    });
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    resumeFlowFinalSubmitHandler("Engine running");
    setScreen("menu");
  };

  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <div>
        <video
        style={
          Object.keys(config).includes("aspectRatio") 
          ? { width: "100vw", height: "100vh", objectFit: "contain", backgroundColor: "black", zIndex: -1 } 
          : { zIndex: -1 }}
          ref={videoRef}
          id="videoWindow"
          autoPlay
          playsInline
          muted
        ></video>
      </div>
      <div ref={backRef} className="back-btn" style={{ color: "white" }}>
        <i className="fas fa-arrow-left fa-2x" onClick={back}></i>
      </div>
      {isRecording ? (
        <div
          onClick={stopRecording}
          style={{ display: "block" }}
          id="btn-android-stop"
        ></div>
      ) : (
        <div
          onClick={startRecording}
          style={{ display: "block" }}
          id="btn-android-start"
        ></div>
      )}
      <div className="timerDiv">
        <p ref={timerRef}></p>
      </div>
      <div ref={poupMssg} className="videoRecoderPopup">
        <div className="videoRecoderPopupdiv">
          <p ref={videoLimit}></p>
          <Button
            disabled={false}
            wide={false}
            onclick={() => {
              continueUploading();
            }}
            text={config["instruction-page"]["btn-text"]}
          />
        </div>
      </div>
      {Object.keys(currentBlock).includes("cameraInstruction") && (
        <div ref={cameraIns} className="camerInst">
          <p>{currentBlock["cameraInstruction"]}</p>
        </div>
      )}
    </div>
  );
};

export default VideoRecorder;
