export class AbruptMovementDetector {
  constructor() {
    this.accelerationThreshold = 5;
    this.rotationRateThreshold = 180;
    this.deviceAccelerationVector = null;
    this.deviceRotationRateVector = null;

    if (window.DeviceMotionEvent) {
      window.addEventListener("devicemotion", (event) => {
        this.deviceAccelerationVector = event.acceleration;
        this.deviceRotationRateVector = event.rotationRate;
      });
    }
  }

  checkAbruptMovement() {
    // Calculating the length of the device Acceleration and RotationRate vectors
    let acc = this.deviceAccelerationVector;
    let rotRate = this.deviceRotationRateVector;

    const accVectorLength = (acc.x ** 2 + acc.y ** 2 + acc.z ** 2) ** 0.5;
    const rotRateVectorLength =
      (rotRate.alpha ** 2 + rotRate.beta ** 2 + rotRate.gamma ** 2) ** 0.5;

    return (
      accVectorLength >= this.accelerationThreshold ||
      rotRateVectorLength >= this.rotationRateThreshold
    );
  }
}
