import * as colorsys from "colorsys";

export class LowLightDetector {
  constructor() {
    this.hsvValueLowerThreshold = 33.33;
    this.hsvValueUpperThreshold = 66.66;
    this.llDarkThreshold = 6000;
    this.llMediumThreshold = 10000;
    this.llLightThreshold = 14000;
    this.singleLumaThreshold = 7000;
    this.llCropDim = 90;
  }

  // Function that checks if a frame has low-light
  checkLowLight(frame) {
    // Removing the Batch axis
    frame = frame.squeeze();

    // Get Center Crop
    const offset = Math.floor((frame.shape[0] - this.llCropDim) / 2);
    const sliced = frame.slice(
      [offset, offset],
      [this.llCropDim, this.llCropDim]
    );

    // Separate the R,G,B channels
    let [red_c, green_c, blue_c] = sliced.split(3, 2);

    // Convert the channel Tensors into Arrays
    const red_c_arr = red_c.arraySync();
    const green_c_arr = green_c.arraySync();
    const blue_c_arr = blue_c.arraySync();

    // Calculate the Average 'Value' in HSV format
    let hsv_value_avg = 0;
    for (let i = 0; i < this.llCropDim; i++) {
      for (let j = 0; j < this.llCropDim; j++) {
        let hsv = colorsys.rgb_to_hsv({
          r: red_c_arr[i][j][0],
          g: green_c_arr[i][j][0],
          b: blue_c_arr[i][j][0],
        });
        hsv_value_avg += hsv.v;
      }
    }
    hsv_value_avg /= this.llCropDim ** 2;

    // Get normalized sum of pixel values of each channel
    const red = red_c.sum().dataSync()[0] / this.llCropDim ** 2;
    const green = green_c.sum().dataSync()[0] / this.llCropDim ** 2;
    const blue = blue_c.sum().dataSync()[0] / this.llCropDim ** 2;

    // Calculate Luma Squared
    const luma_sq = 0.241 * red ** 2 + 0.691 * green ** 2 + 0.068 * blue ** 2;

    // Apply different Thresholds
    if (hsv_value_avg < this.hsvValueLowerThreshold)
      return luma_sq < this.llDarkThreshold;
    else if (hsv_value_avg < this.hsvValueUpperThreshold)
      return luma_sq < this.llMediumThreshold;
    else return luma_sq < this.llLightThreshold;
  }

  /* Function that checks if a frame has low-light. 
       Exactly same as checkLowLight() but faster. Uses TF Buffer instead of Arrays. */
  checkLowLight2(frame) {
    // Removing the Batch axis
    frame = frame.squeeze();

    // Get Center Crop
    const offset = Math.floor((frame.shape[0] - this.llCropDim) / 2);
    const sliced = frame.slice(
      [offset, offset],
      [this.llCropDim, this.llCropDim]
    );

    // Separate the R,G,B channels
    let [red_c, green_c, blue_c] = sliced.split(3, 2);

    red_c = red_c.squeeze();
    green_c = green_c.squeeze();
    blue_c = blue_c.squeeze();

    // Convert the channel Tensors into Arrays
    const red_c_arr = red_c.bufferSync().values;
    const green_c_arr = green_c.bufferSync().values;
    const blue_c_arr = blue_c.bufferSync().values;

    // Calculate the Average 'Value' in HSV format
    let hsv_value_avg = 0;
    for (let i = 0; i < this.llCropDim ** 2; i++)
      hsv_value_avg += colorsys.rgb_to_hsv({
        r: red_c_arr[i],
        g: green_c_arr[i],
        b: blue_c_arr[i],
      }).v;

    hsv_value_avg /= this.llCropDim ** 2;

    // Get normalized sum of pixel values of each channel
    const rgb_sum = sliced
      .sum([0, 1])
      .div(this.llCropDim ** 2)
      .bufferSync().values;

    // Calculate Luma Squared
    const luma_sq =
      0.241 * rgb_sum[0] ** 2 +
      0.691 * rgb_sum[1] ** 2 +
      0.068 * rgb_sum[2] ** 2;

    // Apply different Thresholds
    if (hsv_value_avg < this.hsvValueLowerThreshold)
      return luma_sq < this.llDarkThreshold;
    else if (hsv_value_avg < this.hsvValueUpperThreshold)
      return luma_sq < this.llMediumThreshold;
    else return luma_sq < this.llLightThreshold;
  }

  /* Function that checks if a frame has low-light.
       Trimmed down version of checkLowLight2(). */
  checkLowLight2Trimmed(frame) {
    // Removing the Batch axis
    frame = frame.squeeze();

    // Get normalized sum of pixel values of each channel
    const rgb_sum = frame
      .sum([0, 1])
      .div(frame.shape[0] * frame.shape[1])
      .bufferSync().values;

    // Calculate Luma Squared
    const luma_sq =
      0.241 * rgb_sum[0] ** 2 +
      0.691 * rgb_sum[1] ** 2 +
      0.068 * rgb_sum[2] ** 2;

    // Apply Threshold
    if (luma_sq < this.singleLumaThreshold) return true;
    else return false;
  }
}
