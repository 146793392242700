import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../context";
import { addImageToList } from "../context/utils";
import cameraImg from "../img/camera.png";
import { object } from "underscore";

let captured = 0;
let images = [];
let imagesUploaded = false;

const CustomHiibModule = () => {
  const {
    inspectionId,
    clientId,
    stepCovered,
    setStepCovered,
    condition,
    config,
    currentBlock,
    setCurrentPage,
    setCondition,
    setScreen,
    mandatory,
    setMandatory,
    isLandscape,
    setLandscape,
    hiibCustomValue,
  } = useContext(AppContext);
  const videoRef = useRef();
  const canvasRef = useRef();
  const captureBtn = useRef();
  const screen3 = useRef();
  const reelsRef = useRef();
  const reelMessage = useRef();
  const modalRef = useRef();
  const modalImg = useRef();
  const displayPopUp = useRef();
  const displayPopUpInstruction = useRef();
  const [disabled, setDisabled] = useState(true);
  const constraints = {
    video: Object.keys(config).includes("aspectRatio")
      ? {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : 1920,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : 1080,
        facingMode: "environment",
        aspectRatio: 4 / 3,
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      }
      : {
        width: Object.keys(config).includes("resolution")
          ? config["resolution"]["width"]
          : 1920,
        height: Object.keys(config).includes("resolution")
          ? config["resolution"]["height"]
          : 1080,
        facingMode: "environment",
        zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 },
      },
    audio: false,
  };

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        videoRef.current.srcObject = stream;
        captureBtn.current.style.display = "block";
        if (hiibCustomValue) {
          displayPopUpInstruction.current.style.display = "flex";
        } else {
          displayPopUpInstruction.current.style.display = "none";
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const captureImg = () => {
    setDisabled(false);
    if (images.length < 6) {
      const cnv = canvasRef.current;
      const vw = videoRef.current;
      cnv.width = vw.videoWidth;
      cnv.height = vw.videoHeight;
      cnv.getContext("2d").drawImage(vw, 0, 0);
      const imgData = cnv.toDataURL("image/jpeg");
      images.push(imgData);
      const imgElem = document.createElement("img");
      imgElem.src = imgData;
      imgElem.dataset.index = captured;
      imgElem.onclick = showModal;
      reelsRef.current.appendChild(imgElem);
      captured++;
    } else {
      displayPopUp.current.style.display = "flex";
    }
  };

  const showModal = (e) => {
    console.log(images[e.target.dataset.index]);
    modalImg.current.src = images[e.target.dataset.index];
    modalRef.current.style.display = "block";
  };

  const hideModal = () => {
    modalRef.current.style.display = "none";
  };

  const handleNext = () => {
    window.stream.getTracks().forEach((track) => track.stop());
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    imagesUploaded === false &&
      images.map((data) => {
        addImageToList({
          name: `${currentBlock["tag"]}.jpg`,
          imgData: data,
          tag: currentBlock["tag"],
          web_region: null,
          angle: null,
          region: region,
          videoData: null,
          dialStatus: null,
        });
      });
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };


  useEffect(() => {
    const setDimensions = () => {

      const processElement = document.querySelector(".hiib_size");
      if (processElement) {
        processElement.style.height = `${window.innerHeight}px`;
      }
    };

    // Set dimensions on load
    setDimensions();

    // Update dimensions on window resize
    window.addEventListener('resize', setDimensions);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', setDimensions);
    };
  }, [window.innerHeight]);

  useEffect(() => {
    startCamera();
  }, []);

  return (
    <Fragment>
      {!isLandscape ? (
        <Rotate />
      ) : (
        <div
          className="hiib_size"
          style={{
            position: "relative",
            width: window.innerWidth,
            height: window.innerHeight,
            overflow: "hidden",
          }}
          ref={screen3}
        >
          <canvas
            ref={canvasRef}
            id="ios-canvas"
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : {}
            }
          ></canvas>
          <video
            ref={videoRef}
            id="videoWindow"
            autoPlay
            playsInline
            style={
              Object.keys(config).includes("aspectRatio")
                ? {
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                }
                : { width: window.innerWidth, height: window.innerHeight }
            }
          ></video>
          <div
            ref={captureBtn}
            style={{
              backgroundImage: `url(${cameraImg})`,
              backgroundSize: "37px 37px",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
            onClick={captureImg}
            id="btn-vin-start"
          ></div>
          <div className="reel-container1">
            <div ref={reelsRef} className="reels1"></div>
            <div className="form-btn">
              <button
                disabled={disabled}
                style={{
                  backgroundColor: config["colors"]["btn"],
                  width: "20%",
                  height: "40px",
                  margin: "0px",
                  width: "100%",
                  opacity: disabled? "0.3": ""
                }}
                onClick={handleNext}
              >
                Continue
              </button>
            </div>
          </div>
          <div
            ref={modalRef}
            id="vin-image"
            style={
              Object.keys(config).includes("aspectRatio")
                ? { backgroundColor: "black", objectFit: "contain" }
                : {}
            }
          >
            <div onClick={hideModal} className="close1">
              X
            </div>
            <img
              ref={modalImg}
              style={
                Object.keys(config).includes("aspectRatio")
                  ? { objectFit: "contain" }
                  : {}
              }
              alt=""
            />
          </div>
          <div
            ref={displayPopUp}
            style={{ width: window.innerWidth, height: window.innerHeight }}
            className="customPopup"
          >
            <div
              className="customPopupdiv"
              style={{
                width: window.innerWidth / 2,
                height: window.innerHeight / 2,
              }}
            >
              <p>Six images were captured</p>
              <div className="form-btn" style={{ width: "20%" }}>
                <button
                  style={{
                    backgroundColor: config["colors"]["btn"],
                    width: "20%",
                    height: "40px",
                    margin: "0px",
                    width: "100%",
                  }}
                  onClick={handleNext}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div
            ref={displayPopUpInstruction}
            style={{ width: window.innerWidth, height: window.innerHeight }}
            className="customPopup"
          >
            <div
              className="customPopupdiv"
              style={{
                width: window.innerWidth / 2,
                height: window.innerHeight / 2,
              }}
            >
              <h4>Instructions</h4>
              <p>{hiibCustomValue}</p>
              <div className="form-btn" style={{ width: "20%" }}>
                <button
                  style={{
                    backgroundColor: config["colors"]["btn"],
                    width: "20%",
                    height: "40px",
                    margin: "0px",
                    width: "100%",
                  }}
                  onClick={() => {
                    displayPopUpInstruction.current.style.display = "none";
                  }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CustomHiibModule;
