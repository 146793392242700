import { createContext } from "react";

export const AppContext = createContext({
  token: null,
  retake: null,
  config: {},
  currentBlock: {},
  screen: null,
  condition: {},
  subCondition: {},
  mandatory: {},
  subMandatory: {},
  showOptions: true,
  showPreview: false,
  showDescription: "",
  userEmail: "",
  uploaded: false,
  isLandscape: false,
  count: 0,
  count1: 0,
  imgPosition: null,
  currentDamage: 0,
  fImg: null,
  sImg: null,
  page: "inst",
  showCamera: false,
  completed: false,
  subCompleted: false,
  mandatoryCompleted: false,
  subMandatoryCompleted: false,
  errorMessage: "",
  inspectionId: null,
  location: { latitude: 0, longitude: 0 },
  showZeroCamera: false,
  previewZeroImg: null,
  showQRCamera: false,
  previewQRImg: null,
  scrollX: 0,
  scrolEnd: false,
  ping: null,
  showAlert: null,
});
