import * as tf from "@tensorflow/tfjs";
import { loadGraphModel } from "@tensorflow/tfjs-converter";
import * as constants from "../utils_new/Constants";
import {mlLogs} from "../context/utils"

// Adds the CPU backend to the global backend registry.
import "@tensorflow/tfjs-backend-cpu";

// Adds the WebGL backend to the global backend registry.
import "@tensorflow/tfjs-backend-webgl";

// Adds the WASM backend to the global backend registry.
import "@tensorflow/tfjs-backend-wasm";

// Add the WebGPU backend to the global backend registry.
//import '@tensorflow/tfjs-backend-webgpu';

import { setWasmPaths } from "@tensorflow/tfjs-backend-wasm";
let OnceUploadMLLOg = true
const crypto = require("crypto");

tf.enableProdMode();

//tf.env().set('CANVAS2D_WILL_READ_FREQUENTLY', true)

// Using WASM Backend
setWasmPaths("/");
//tf.setBackend('wasm').then(console.log('Initially, the Backend is', tf.getBackend()))

// Using WebGL Backend
tf.setBackend("webgl")
  .then
  // console.log('Initially, the Backend is', tf.getBackend())
  ();

export class PartDetector {
  constructor() {
    // Setting thresholds for each part to 0.85, except for running_board for which it is 0.5
    this.detectionScoreThresholdForEachPart = {};
    for (let id in constants.CLASS_LABELS)
      this.detectionScoreThresholdForEachPart[constants.CLASS_LABELS[id]] = 0.8;
    this.detectionScoreThresholdForEachPart["running_board"] = 0.3;
    this.detectionScoreThresholdForEachPart["fender"] = 0.4;
    this.detectionScoreThresholdForEachPart["qtr_panel"] = 0.4;
    this.detectionScoreThresholdForEachPart["front_door"] = 0.6;
    this.detectionScoreThresholdForEachPart["window_glass"] = 0.4;

    this.serverURL = "https://wcs.inspektlabs.com/models/guidance_v4_ecp/";
    this.modelFile = "m.txt";
    this.shardFile = "1";
  }

  // Function that parses the url response into a Text/Buffer, performs decryption and returns the result
  /**
   * It fetches a response from a URL, parses the response into text/buffer, and performs decryption.
   * @param url - The URL of the encrypted file
   * @param isWeight - Boolean - Whether the data is a model or not.
   * @returns The decrypted data.
   */
  async decryptFromURL(url, isWeight) {
    // The Decipher Object
    try {
      let decipher = crypto.createDecipheriv(
        process.env.REACT_APP_ALGO,
        process.env.REACT_APP_KEY,
        process.env.REACT_APP_IV
      );

      // Fetches Response from the URL
      const response = await fetch(url);

      // Parses the Reponse into Text/Buffer and performs Decryption
      let decrypted;
      if (isWeight) {
        const arrayBuffer = await response.arrayBuffer();
        const buffer = Buffer.from(arrayBuffer);
        decrypted = decipher.update(buffer);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
      } else {
        const text = await response.text();
        decrypted = decipher.update(
          text,
          process.env.REACT_APP_MODEL_ENCRYPTED_ENCODING,
          process.env.REACT_APP_MODEL_ACTUAL_ENCODING
        );
        decrypted += decipher.final(
          process.env.REACT_APP_MODEL_ACTUAL_ENCODING
        );
      }
      return decrypted;
    } catch (e) {
      // add logs e
      // console.log(e)
      console.log("Model Download not completed");
      if(OnceUploadMLLOg){
        OnceUploadMLLOg = false
        mlLogs(`${e.message} ,Model Download not completed`)
      }
    }
  }

  // Async function to load our model
  /**
   * It decrypts the encrypted Model structure and Weights, creates blobs from the decrypted Weight
   * files, serves the decrypted Weight blobs, updates the Weight paths with the newly generated blob
   * names, creates a blob from the decrypted Model object, serves the decrypted Model blob, and
   * finally loads the Graph Model.
   * @returns The model is being returned.
   */
  async loadModelFromURL() {
    // URLs where the encrypted Model structure and Weights are stored
    const shardURL = this.serverURL + this.shardFile;
    const modelURL = this.serverURL + this.modelFile;

    // Decrypting the encrypted Model structure and Weights
    const decryptedShardBuffer = await this.decryptFromURL(shardURL, true);
    const decryptedModelString = await this.decryptFromURL(modelURL, false);

    // Creating blobs from the decrypted Weight files
    const shardBlob = new Blob([decryptedShardBuffer], {
      type: "application/octet-stream",
    });

    // Serving the decrypted Weight blobs
    const shardBlobURL = URL.createObjectURL(shardBlob);

    // Parsing the blob names
    const shardBlobName = shardBlobURL.split("/").pop();

    // Update the Weight paths with the newly generated blob names
    const decryptedModelObject = JSON.parse(decryptedModelString);
    decryptedModelObject.weightsManifest[0].paths = [shardBlobName];

    // Create blob from the decrypted Model object
    const modelBlob = new Blob([JSON.stringify(decryptedModelObject)], {
      type: "application/json",
    });

    // Serving the decrypted Model blob
    const modelBlobURL = URL.createObjectURL(modelBlob);

    // Finally loading the Graph Model
    const model = await loadGraphModel(modelBlobURL, {
      weightPathPrefix: "blob:" + window.location.origin + "/",
    });

    return model;
  }

  // Function to process a frame from the HTML Video Element. Reads frame in the same backend as already set.
  /**
   * It takes a video frame from the HTML Video Element, resizes it to 128x128, changes the data type
   * to 'Int32' and adds a dimension to represent the batch axis
   * @param video_element - The HTML Video Element from which the frame is to be read.
   * @returns A tensor with shape [1, 128, 128, 3]
   */
  processInput(video_element) {
    const start = performance.now();
    // Reading a frame from the HTML Video Element
    let tfimg = tf.browser.fromPixels(video_element);

    // Resizing the frame to 128x128
    tfimg = tf.image.resizeBilinear(tfimg, [128, 128]);
    //tfimg = tf.image.resizeNearestNeighbor(tfimg, [128,128]);

    // Resizing changes the dtype from 'Int32' to 'Float32'. Change it back to 'Int32'.
    tfimg = tfimg.toInt();

    // Add a dimension to represent the batch axis
    tfimg = tfimg.expandDims();
    
    const end = performance.now();
    console.log(`processInput - Time taken: ${end - start} ms`, tfimg);
    return tfimg;
  }

  // Function to process a frame from the HTML Video Element. Reads frame using WebGL backend, then simply switches to WASM backend.
  /**
   * It takes a video frame from the HTML Video Element, resizes it to 128x128, changes the data type
   * to 'Int32' and adds a dimension to represent the batch axis
   * @param video_element - The HTML Video Element from which the frame is to be read.
   * @returns A tensor with shape [1, 128, 128, 3]
   */
  processInput2(video_element) {
    tf.setBackend("webgl")
      .then
      // console.log('Beginning of ProcessInput2 - The Backend is', tf.getBackend())
      ();

    // Reading a frame from the HTML Video Element
    let tfimg = tf.browser.fromPixels(video_element);

    // Resizing the frame to 128x128
    tfimg = tf.image.resizeBilinear(tfimg, [128, 128]);
    //tfimg = tf.image.resizeNearestNeighbor(tfimg, [128,128]);

    // Resizing changes the dtype from 'Int32' to 'Float32'. Change it back to 'Int32'.
    tfimg = tfimg.toInt();

    // Add a dimension to represent the batch axis
    tfimg = tfimg.expandDims();

    tf.setBackend("wasm")
      .then
      // console.log('End of ProcessInput2 - The Backend is', tf.getBackend())
      ();

    return tfimg;
  }

  // Function to process a frame from the HTML Video Element. Reads frame using WebGL backend, converts the tensor to
  // array/data, switches to WASM backend and re-creates the tensor from the array.
  /**
   * It takes a video frame from the HTML Video Element, resizes it to 128x128, changes the data type
   * to 'Int32' and adds a dimension to represent the batch axis
   * @param video_element - The HTML Video Element from which the frame is to be read.
   * @returns A tensor with shape [1, 128, 128, 3]
   */
  processInput2Recreate(video_element) {
    tf.setBackend("webgl")
      .then
      // console.log('Beginning of ProcessInput2Recreate - The Backend is', tf.getBackend())
      ();

    // Reading a frame from the HTML Video Element
    let tfimg = tf.browser.fromPixels(video_element);

    // Resizing the frame to 128x128
    tfimg = tf.image.resizeBilinear(tfimg, [128, 128]);
    //tfimg = tf.image.resizeNearestNeighbor(tfimg, [128,128]);

    // Resizing changes the dtype from 'Int32' to 'Float32'. Change it back to 'Int32'.
    tfimg = tfimg.toInt();

    // Add a dimension to represent the batch axis
    tfimg = tfimg.expandDims();

    let tf_img_arr = tfimg.arraySync();
    //let tf_img_arr = tfimg.dataSync();

    tf.setBackend("wasm")
      .then
      // console.log('End of ProcessInput2Recreate - The Backend is', tf.getBackend())
      ();

    tfimg = tf.tensor(tf_img_arr, [1, 128, 128, 3]).toInt();

    return tfimg;
  }

  // Function to process a frame from the HTML Video Element. Reads frame in the same backend as already set.
  /**
   * It takes a video frame from the HTML Video Element, resizes it to 128x128, changes the data type
   * to 'Int32' and adds a dimension to represent the batch axis
   * @param video_element - The HTML Video Element from which the frame is to be read.
   * @returns A tensor with shape [1, 128, 128, 3]
   */
  processInputReturnOrig(video_element) {
    // Reading a frame from the HTML Video Element
    let tfimg_orig = tf.browser.fromPixels(video_element);

    // Resizing the frame to 128x128
    let tfimg = tf.image.resizeBilinear(tfimg_orig, [128, 128]);
    //tfimg = tf.image.resizeNearestNeighbor(tfimg, [128,128]);

    // Resizing changes the dtype from 'Int32' to 'Float32'. Change it back to 'Int32'.
    tfimg = tfimg.toInt();

    // Add a dimension to represent the batch axis
    tfimg = tfimg.expandDims();

    return [tfimg, tfimg_orig];
  }

  // Function that returns the max-score and corresponding class-label for the output boxes.
  /**
   * It takes in a tensor of shape #AnchorBox x #Classes and returns two 1D tensors with #AnchorBox elements each.
   * The first tensor contains the highest score for each anchor box and the second tensor
   * contains the class corresponding to that score.
   * @param scores - The output of the model, which is a tensor of shape #AnchorBox x #Classes.
   * @returns a list of two arrays. The first array contains the highest score for each anchor box.
   * The second array contains the class label corresponding to the highest score for each anchor box.
   */
  filterScores(scores) {
    // Removing the Background class scores
    let scores_woBG = scores.slice([0, 1], [-1, -1]);

    // Getting the highest score and the class corresponding to that score (for a particular bbox). Both are 1D tensors with #AnchorBox elements each.
    let max_scores = scores_woBG.max(-1);

    let argmax_scores = scores_woBG.argMax(-1).add(1); // Added 1 to the class labels to account for the Background class

    return [max_scores, argmax_scores];
  }

  /* Function to convert the predictions into DetectionObjects.
       This only retains those detections (out of the #AnchorBox predictions) whose confidence score is greater than the threshold;
                    whilst keeping only a single bbox (with the highest score) for each class */
  /**
   * It takes the output of the model, and returns an array of objects, each object containing the
   * bounding box, class, label and score of the detected object.
   * @param boxes - An array of bounding boxes of the detected objects. It is a 2D array of shape #AnchorBox x 4.
   * @param scores - An array of scores of the detected objects. It is a 1D array with #AnchorBox elements.
   * @param classes - An array of class indices of the detected objects. It is a 1D array with #AnchorBox elements.
   * @returns An array of objects.
   */
  buildDetectedObjectsArraySync(boxes, scores, classes) {
    const detectionObjectsDict = {};

    // Iterate through the scores to keep only a single occurance of each class with the highest score
    scores.forEach((score, i) => {
      if (
        score >
        this.detectionScoreThresholdForEachPart[
          constants.CLASS_LABELS[classes[i]]
        ]
      ) {
        // If the class is already present in the dict, then check if the score is lower than the existing score. If yes, skip.
        if (classes[i] in detectionObjectsDict)
          if (score < detectionObjectsDict[classes[i]][1]) return;

        // If the class is not present in the dict, then add the class and its score to the dict.
        const bbox = [];

        /* 
                The origin is at the top-left corner of the frame.
                    (minX, minY) -> Top-Left  &  (maxX, maxY) -> Bottom-Right
                The values are in [0,1].
                */

        const minY = boxes[i][0];
        const minX = boxes[i][1];
        const maxY = boxes[i][2];
        const maxX = boxes[i][3];

        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;

        detectionObjectsDict[classes[i]] = [bbox, score];
      }
    });

    // Convert the dict into an array of objects
    const detectionObjectsArr = [];

    for (let [classIdx, values] of Object.entries(detectionObjectsDict)) {
      const idx = parseInt(classIdx, 10);
      detectionObjectsArr.push({
        bbox: values[0],
        class: idx,
        label: constants.CLASS_LABELS[idx],
        score: values[1].toFixed(4),
      });
    }
    return detectionObjectsArr;
  }

  /* Function to convert the predictions into DetectionObjects.
       This only retains those detections (out of the #AnchorBox predictions) whose confidence score is greater than the threshold;
                    whilst keeping only a single bbox (with the highest score) for each class */
  /**
   * It takes the output of the model, and returns an array of objects, each object containing the
   * bounding box, class, label and score of the detected object.
   * @param boxes - A TF Buffer of bounding boxes of the detected objects. It is a 2D buffer of shape #AnchorBox x 4.
   * @param scores - A TF Buffer of scores of the detected objects. It is a 1D buffer with #AnchorBox elements.
   * @param classes - A TF Buffer of class indices of the detected objects. It is a 1D buffer with #AnchorBox elements.
   * @returns An array of objects.
   */
  buildDetectedObjectsBufferSync(boxes_b, scores_b, classes_b) {
    const detectionObjectsDict = {};

    const boxes = boxes_b.values;
    const scores = scores_b.values;
    const classes = classes_b.values;

    // Iterate through the scores to keep only a single occurance of each class with the highest score
    for (let [i, score] of Object.entries(scores)) {
      if (
        score >
        this.detectionScoreThresholdForEachPart[
          constants.CLASS_LABELS[classes[i]]
        ]
      ) {
        // If the class is already present in the dict, then check if the score is lower than the existing score. If yes, skip.
        if (classes[i] in detectionObjectsDict)
          if (score < detectionObjectsDict[classes[i]][1]) continue;

        // If the class is not present in the dict, then add the class and its score to the dict.
        const bbox = [];

        /* 
                The origin is at the top-left corner of the frame.
                    (minX, minY) -> Top-Left  &  (maxX, maxY) -> Bottom-Right
                The values are in [0,1].
                */

        const minY = boxes[i * 4 + 0];
        const minX = boxes[i * 4 + 1];
        const maxY = boxes[i * 4 + 2];
        const maxX = boxes[i * 4 + 3];

        bbox[0] = minX;
        bbox[1] = minY;
        bbox[2] = maxX - minX;
        bbox[3] = maxY - minY;

        detectionObjectsDict[classes[i]] = [bbox, score];
      }
    }

    // Convert the dict into an array of objects
    const detectionObjectsArr = [];

    for (let [classIdx, values] of Object.entries(detectionObjectsDict)) {
      const idx = parseInt(classIdx, 10);
      detectionObjectsArr.push({
        bbox: values[0],
        class: idx,
        label: constants.CLASS_LABELS[idx],
        score: values[1].toFixed(4),
      });
    }

    return detectionObjectsArr;
  }

  // Function that takes the raw model predictions and filters them to generate a cleaned array of DetectionObjects.
  /**
   * It takes the predictions from the model and returns an array of objects with the following
   * properties:
   * - **bbox**: The coordinates of the bounding box in the format: min_Y, min_X, max_Y, max_X
   * - **class**: The class index of the prediction
   * - **label**: The label of the class
   * - **score**: The confidence score of the prediction
   * @param predictions - The output of the model.
   * @returns an array of objects. Each object contains the coordinates of the bounding box, the
   * class index, the class label and the score.
   */
  getDetectionObjects(predictions) {
    /*
        Retrieve the boxes and scores from predictions. (#AnchorBox -> Number of Anchor Boxes i.e. 330 in this case)
            boxes -> Tensor of Shape 1 x #AnchorBox x 4. (Coordinates are in the format:  min_Y, min_X, max_Y, max_X) 
            scores -> Tensor of shape 1 x #AnchorBox x 16. (Includes score for Background class)
        */

    const boxes = predictions[0].squeeze();
    const scores = predictions[1].squeeze();

    // Clip the boxes between [0,1]
    let boxes_processed = boxes.clipByValue(0, 1);

    //Filter the scores to get the maximum score and corresponding class index for a particular bbox. Both are 1D Tensors with #AnchorBox elements each.
    const [scores_max, class_indxs] = this.filterScores(scores);

    // Get the Detection objects by passing either the ArraySync variables or TF Buffer variables
    //const detections = this.buildDetectedObjectsArraySync(boxes_processed.arraySync(), scores_max.arraySync(), class_indxs.arraySync());
    const detections = this.buildDetectedObjectsBufferSync(
      boxes_processed.bufferSync(),
      scores_max.bufferSync(),
      class_indxs.bufferSync()
    );

    return detections;
  }
}
