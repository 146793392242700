// Function to return the most frequently occuring value in an array (here, sliding window)
export function getMode(array) {
    let counter = {};
    let mode = [];
    let max = 0;
    array.forEach(val => {
        if (!(val in counter))
            counter[val] = 0;
        counter[val]++;
 
        if (counter[val] === max) 
            mode.push(val);
        else if (counter[val] > max) {
            max = counter[val];
            mode = [val];
        }
    })
    return mode[0]; 
}

export function roundToNearestX(val, x) {
    return Math.ceil( val / x ) * x;
}

export function equidistantIndex(start, end) {
    const arrayOfPoints = []
    for (var index = 0; index < start; index++) {
       var v = (index+1)/ (start+1);
       var v2 = v*end;
       arrayOfPoints.push(Math.round(v2));
    }
    arrayOfPoints.push(end)
    return arrayOfPoints;
  }