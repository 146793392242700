import React, { useState, useContext, useEffect } from "react";
import NavbarP from "./NavBarP";
import { AppContext } from "../../context";
import Rotate from "../Rotate";
import {
  setLog,
  getCountData,
  formUpload,
  userLogs,
  patLogs,

} from "../../context/utils";
import leftArrow from "../../img/left.png";
import rightArrow from "../../img/right.png";

let upload;
let uploaded = true;
let runsOnce = true;
export default function FormModuleWondle() {
  const {
    stepCovered,
    setScreen,
    setLandscape,
    config,
    setCurrentPage,
    inspectionId,
    mandatory,
    setMandatory,
    isLandscape,
    setCondition,
    currentBlock,
    condition,
    clientId,
    setStepCovered,
  } = useContext(AppContext);
  const [inputValidationError, setInputValidationError] = useState([]);
  const [formdata, setFormData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [wrongFile, setWrongFile] = useState(null);
  const [corrupted, setCorrupted] = useState(false);
  const [formDocuments, setFormDocuments] = useState({});
  const [refreshed, setRefreshed] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState(1);
  const [textData, setTextData] = useState({});

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  window.onresize = () => {
    setLandscape(land());
  };

  const handleFileSelect = (e, f) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" || file.type === "application/pdf")
    ) {
      setDisabled(false);
      setWrongFile("");
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFormDocuments({ ...formDocuments, [f]: reader.result });
      };
      reader.onerror = () => {
        setDisabled(true);
        setCorrupted(true);
        setWrongFile(f);
      };
    } else {
      setDisabled(true);
      setWrongFile(f);
    }
  };

  const uploadForm = () => {
    let jsonFileWithDocuments = {};
    let jsonFile = "";
    let impData = [];
    jsonFileWithDocuments = {
      ...formdata,
      documents: formDocuments,
      ...textData,
    };
    jsonFile = formdata;
    if (config["form-page"]["impData"]) {
      impData = [
        config["form-page"]["impData"],
        formdata[config["form-page"]["impData"]]
          ? formdata[config["form-page"]["impData"]]
          : "",
      ];
    } else {
      impData = ["", ""];
    }
    // upload = setInterval(() => {
    // let data = getCountData();
    // if (data && uploaded) {
    // uploaded = false;
    formUpload(
      JSON.stringify(jsonFileWithDocuments),
      JSON.stringify(jsonFile),
      impData
    );

    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };

  window.onresize = () => {
   // userLogs({ position: 11, screen_orientation: "yes", inspectionId });
    setLandscape(land() ? "Landscape Mode" : "Portrait Mode");
  };

  const radioFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a] === e
        ? delete formdata[a]
        : setFormData({ ...formdata, [a]: e })
      : setFormData({ ...formdata, [a]: e });
  };

  const checkBoxFunc = (a, e) => {
    setRefreshed(refreshed ? false : true);
    formdata[a]
      ? formdata[a].includes(e)
        ? formdata[a].length === 1
          ? delete formdata[a]
          : setFormData({
            ...formdata,
            [a]: formdata[a].filter((q) => q !== e),
          })
        : setFormData({
          ...formdata,
          [a]: formdata[a] ? [...formdata[a], e] : [e],
        })
      : setFormData({
        ...formdata,
        [a]: formdata[a] ? [...formdata[a], e] : [e],
      });
  };

  const inputCheck = (f, e, validation) => {
    if (validation[0] === "") {
      setFormData({ ...formdata, [f]: e.target.value });
    } else {
      let regex = new RegExp(validation[0]);
      if (regex.test(e.target.value)) {
        setFormData({ ...formdata, [f]: e.target.value });
        setInputValidationError(inputValidationError.filter((e) => e !== f));
      } else {
        formdata[f] && setFormData({ ...delete formdata[f] });
        setInputValidationError([...inputValidationError, f]);
      }
    }
  };

  const setTextArea = (f, e) => {
    setTextData({ ...textData, [f]: e.target.value });
  };

  const deleteSelectedFile = (f) => {
    setRefreshed(refreshed ? false : true);
    document.getElementById(f).value = "";
    formDocuments[f] && delete formDocuments[f];
    setCorrupted(false);
    setWrongFile(null);
  };

  useEffect(() => {
    setLandscape(land());
    config["form-page"].tag_mandatory.filter(
      (e) => !formdata[e] && !formDocuments[e]
    ).length
      ? setDisabled(true)
      : setDisabled(false);
  }, [formdata, formDocuments, refreshed]);

  useEffect(() => {
    setTotalQuestions(
      Object.keys(config["form-page"].inputTag).length +
      Object.keys(config["form-page"].radio).length +
      Object.keys(config["form-page"].checkbox).length +
      Object.keys(config["form-page"].select).length +
      config["form-page"].documents.length +
      Object.keys(config["form-page"].textAreaTag).length
    );
    // !isLandscape && resetData();
  }, [currentQuestions, setCurrentQuestions, window.onresize]);

  const resetData = () => {
    for (
      let i = 0;
      i <
      Object.keys(config["form-page"].inputTag).length +
      Object.keys(config["form-page"].radio).length +
      Object.keys(config["form-page"].checkbox).length +
      Object.keys(config["form-page"].select).length +
      config["form-page"].documents.length +
      Object.keys(config["form-page"].textAreaTag).length;
      i++
    ) {
      document.getElementById(`${i + 1}`).style.display =
        currentQuestions === i + 1 ? "block" : "none";
    }
  };

  if (runsOnce) {
    let radioData = {};
    let checkboxData = {};
    Object.keys(config["form-page"].radio).map((e) => {
      radioData[e] = config["form-page"].radio[e][0];
    });

    Object.keys(config["form-page"].checkbox).map((e) => {
      checkboxData[e] = config["form-page"].checkbox[e];
    });
    setFormData({
      ...formdata,
      ...radioData,
      ...checkboxData,
    });
    runsOnce = false;
  }

  return (
    <>
      {isLandscape ? (
        <Rotate />
      ) : (
        <div className="process" style={{ position: "relative", justifyContent: "space-between", height: "0px" }}>
          <NavbarP />
          <div
            className="form_size"
            id="container"
            style={{
              height: "100dvh",
              display: "flex",
              flexDirection: "column",
              overflow: "scroll"
            }}
          >
            <div
            id="form-module-inner-div"
            >
              {Object.keys(config["form-page"].inputTag).map((f, i) => (
                <div
                  key={f}
                  id={`${i + 1}`}
                  className="div-input"
                  style={{ display: "none" }}
                >
                  <label className="label">
                    {f}{" "}
                    {config["form-page"].tag_mandatory?.includes(f) && (
                      <span>*</span>
                    )}
                    {inputValidationError.includes(f) && (
                      <span>
                        {"("} Enter Valid {f} {")"}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    placeholder={config["form-page"].inputTag[f][1]}
                    value={formdata.f}
                    onChange={(e) =>
                      inputCheck(f, e, config["form-page"].inputTag[f])
                    }
                  />
                </div>
              ))}
              {Object.keys(config["form-page"].radio).map((a, i) => (
                <div
                  style={{ display: "none" }}
                  id={`${Object.keys(config["form-page"].inputTag).length + i + 1
                    }`}
                >
                  <label className="label">
                    {a}{" "}
                    {config["form-page"].tag_mandatory?.includes(a) && (
                      <span>*</span>
                    )}
                  </label>
                  <div className="div-radio">
                    {Object.values(config["form-page"].radio[a]).map((c) => (
                      <div className="label-div">
                        <input
                          type="radio"
                          value={c}
                          checked={formdata[a] === c}
                          onClick={() => radioFunc(a, c)}
                        />
                        <p>{c}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {Object.keys(config["form-page"].checkbox).map((a, i) => (
                <div
                  style={{ marginTop: "20px" }}
                >
                  <div className="checkboxLevel">
                    <label
                      className="label"
                      style={{ fontSize: "20px", margin: "10px 14px" }}
                    >
                      {a}{" "}
                      {config["form-page"].tag_mandatory?.includes(a) && (
                        <span>*</span>
                      )}
                    </label>
                    <div className="sampleCheckbox">
                      {"( "}
                      <div className="label-div">
                        <input
                          // disabled={true}
                          type="checkbox"
                          checked={true}
                        />
                        <p style={{ fontSize: "15px", margin: "0px -6px" }}>
                          Okay
                        </p>
                      </div>
                      <div className="label-div" style={{ margin: "0px 10px" }}>
                        <input
                          type="checkbox"
                          // disabled={true}
                          checked={false}
                        />
                        <p style={{ fontSize: "15px", margin: "5px -6px" }}>
                          Not Okay{" "}
                        </p>
                      </div>
                      {" )"}
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <p
                        style={{ color: "black" }}
                        className="countingForm"
                      >{`( ${currentQuestions} / ${totalQuestions} )`}</p>
                    </div>
                  </div>
                  <div className="div-radio">
                    {Object.values(config["form-page"].checkbox[a]).map((c) => (
                      <div className="label-div">
                        <input
                          type="checkbox"
                          value={c}
                          checked={formdata[a]?.includes(c)}
                          onClick={() => checkBoxFunc(a, c)}
                        />
                        <p style={{ fontSize: "15px", margin: "5px 0px" }}>
                          {c}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {Object.keys(config["form-page"].select).map((a, i) => (
                <div
                  style={{ display: "none" }}
                  id={`${Object.keys(config["form-page"].inputTag).length +
                    Object.keys(config["form-page"].radio).length +
                    Object.keys(config["form-page"].checkbox).length +
                    i +
                    1
                    }`}
                  className="div-input"
                >
                  <label className="label">
                    {a}{" "}
                    {config["form-page"].tag_mandatory?.includes(a) && (
                      <span>*</span>
                    )}
                  </label>
                  <select
                    className="form-select"
                    value={formdata.cartype}
                    onChange={(e) =>
                      setFormData({ ...formdata, [a]: e.target.value })
                    }
                  >
                    <option
                      className="form-select-option"
                      value=""
                      style={{ display: "none" }}
                    ></option>
                    {Object.values(config["form-page"].select[a]).map((g) => (
                      <option className="form-select-option" value={g}>
                        {g}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
              {config["form-page"].documents.map((f, i) => (
                <div
                  style={{ display: "none" }}
                  id={`${Object.keys(config["form-page"].inputTag).length +
                    Object.keys(config["form-page"].radio).length +
                    Object.keys(config["form-page"].checkbox).length +
                    Object.keys(config["form-page"].select).length +
                    i +
                    1
                    }`}
                  className="documents"
                >
                  <label className="label">
                    {f}{" "}
                    {config["form-page"].tag_mandatory?.includes(f) && (
                      <span>*</span>
                    )}
                  </label>
                  <div className="inputFile">
                    <div className="radiodiv">
                      <input
                        type="file"
                        id={f}
                        accept="image/pdf, image/jpg"
                        onChange={(e) => handleFileSelect(e, f)}
                      />
                      {wrongFile === f && !corrupted && (
                        <p>{config["form-page"]["alertImageType"]}</p>
                      )}
                      {wrongFile === f && corrupted && (
                        <p>{config["form-page"]["alertImageCorrupted"]}</p>
                      )}
                    </div>
                    <div
                      className="imageRemovebutton"
                      onClick={() => deleteSelectedFile(f)}
                    >
                      X
                    </div>
                    <div></div>
                  </div>
                </div>
              ))}

              {Object.keys(config["form-page"]).includes("textAreaTag") &&
                Object.keys(config["form-page"].textAreaTag).map((f, i) => (
                  <div
                    key={f}
                    className="div-input"
                    style={{
                      display: "none",
                      marginTop: "20px",
                      margin: "0px",
                      padding: "0px",
                      width: "79vw",
                    }}
                    id={`${Object.keys(config["form-page"].inputTag).length +
                      Object.keys(config["form-page"].radio).length +
                      Object.keys(config["form-page"].checkbox).length +
                      Object.keys(config["form-page"].select).length +
                      Object.keys(config["form-page"].documents).length +
                      i +
                      1
                      }`}
                  >
                    <div className="checkboxLevel">
                      <label
                        className="label"
                        style={{ fontSize: "20px", margin: "10px 14px" }}
                      >
                        {f}{" "}
                        {config["form-page"].tag_mandatory?.includes(f) && (
                          <span>*</span>
                        )}
                      </label>
                      <div style={{ marginLeft: "10px" }}>
                        <p
                          style={{ color: "black" }}
                          className="countingForm"
                        >{`( ${currentQuestions} / ${totalQuestions} )`}</p>
                      </div>
                    </div>
                    <textarea
                      type="text"
                      value={formdata.f}
                      onChange={(e) => setTextArea(f, e)}
                      rows="8"
                      style={{ width: "99%" }}
                    />
                  </div>
                ))}
            </div>
          </div>
          {currentQuestions === totalQuestions && (
            <div
              className="form-btn"
              style={{
                height: "15vh",
                position: "absolute",
                bottom: "20px",
                right: "20px",
                width: "15%",
              }}
            >
              <button
                disabled={disabled}
                style={{
                  backgroundColor: disabled
                    ? "#bcbcbc"
                    : config["form-page"]["btn"],
                  width: "20%",
                  height: "40px",
                  margin: "0px",
                  width: "100%",
                }}
                onClick={uploadForm}
              >
                {config["form-page"]["btnText"]}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
