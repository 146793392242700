import carsmall from "../img/carsmall.png";
import * as constants from "../utils_new/Constants";

const drawArc = (canvas, arcAngle, arcRegions) => {
  let context;
  try{
    context = canvas.getContext("2d");
  }
  catch(error){
    console.log("error in drac arc: ", error)
  }
  const colors = ["rgba(0,0,0,0.2)", "rgba(255,0,0,0.6)", "rgba(0,255,0,0.6)"];
  const x = canvas.width / 2;
  const y = canvas.height / 2;
  const parts = 8;
  const pieAngle = (2 * Math.PI) / parts;
  const segmentDepth = canvas.width / 2 - 10;
  const pointAngle = 90 - arcAngle;

  context.clearRect(0, 0, canvas.width, canvas.height);

  for (let region in arcRegions) {
    let part = 0;
    switch (region) {
      case constants.REGIONS.FRONT:
        part = 0;
        break;
      case constants.REGIONS.FRONT_ISOMETRIC_RIGHT:
        part = 1;
        break;
      case constants.REGIONS.SIDE_RIGHT:
        part = 2;
        break;
      case constants.REGIONS.REAR_ISOMETRIC_RIGHT:
        part = 3;
        break;
      case constants.REGIONS.REAR:
        part = 4;
        break;
      case constants.REGIONS.REAR_ISOMETRIC_LEFT:
        part = 5;
        break;
      case constants.REGIONS.SIDE_LEFT:
        part = 6;
        break;
      case constants.REGIONS.FRONT_ISOMETRIC_LEFT:
        part = 7;
        break;
      default:
        break;
    }

    const color = colors[arcRegions[region]];
    context.beginPath();
    context.moveTo(x, y);
    context.arc(
      x,
      y,
      segmentDepth,
      (part - 2) * pieAngle - 0.4,
      (part - 1) * pieAngle - 0.4,
      false
    );
    context.lineWidth = segmentDepth;
    context.fillStyle = color;
    context.fill();
    context.lineWidth = 2;
    context.strokeStyle = "#fff";
    context.stroke();
  }

  const img = new Image();
  img.src = carsmall;
  img.onload = () => {
    context.drawImage(img, canvas.width / 2.6, canvas.height / 3.8, x / 2, y);
  };

  const px = x + segmentDepth * Math.cos((-pointAngle * Math.PI) / 180);
  const py = y + segmentDepth * Math.sin((-pointAngle * Math.PI) / 180);
  context.beginPath();
  context.arc(px, py, 8, 0, 2 * Math.PI, false);
  context.fillStyle = "#f00";
  context.fill();
};

export default drawArc;
