import React, { useContext } from "react";
import { AppContext } from "../context";

const Navbar = () => {
  const { config } = useContext(AppContext);

  return (
    <div id="navbar" style={{ backgroundColor: config["colors"]["nav"] }}>
      {config["logo"].length > 0 && <img id="logo" alt="" src={config["logo"]} />}
    </div>
  );
};

export default Navbar;
