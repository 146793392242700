import React from "react";
import ReactDOM from "react-dom";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import "./index.css";
import App from "./App";
// import * as Sentry from "@sentry/react";
// import { CaptureConsole, HttpClient } from "@sentry/integrations";

// Sentry.init({
//   dsn: "https://61c6428924cb17028f7d475f3cebc8b7@o4505929547317248.ingest.sentry.io/4505929550659584",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["localhost", /^\//],
//     }),
//     new Sentry.Replay(),
//     new CaptureConsole(),
//     new HttpClient(),
//   ],
//   sendDefaultPii: true,
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
