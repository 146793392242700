import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from "react";
import takeCamera from "../img/takeCamera.png";
import camera from "../img/camera.png";
import { AppContext } from "../context";
import Rotate from "./Rotate";
import Button from "./Button";
import {
  addImageToList,
  setLog,
  imgCoordinates,
  userLogs,
  patLogs, 
  resumeFlowFinalSubmitHandler,
  getWindshieldModuleCount
} from "../context/utils";
import dragImg from "../img/drag.png";
import topBottom from "../img/top-bottom.png";
import leftRight from "../img/left-right.png";
// import ReactGA from "react-ga4";

const screenfull = require("screenfull");

let cameraStarted = false;
let captured = 0;
let images = [];
let track = null;
let imgData = null;
let logbugs = true;

const WindshieldDamge = () => {
  const {
    stepCovered,
    setStepCovered,
    showAlert,
    setScreen,
    setCurrentPage,
    condition,
    setCondition,
    currentBlock,
    config,
    isLandscape,
    setLandscape,
    currentDamage,
    setCurrentDamage,
    mandatory,
    setMandatory,
    setNeedScroll,
    inspectionId,
  } = useContext(AppContext);
  let webCamPromise = null;
  let model = null;
  let active = true;
  if (logbugs) {
    patLogs("Windshield Module -> At instruction page", inspectionId);
    userLogs({ position: 8, last_page: "windshield module", inspectionId });
    // ReactGA.send({
    //   hitType: "pageview",
    //   page: "Windshield Module",
    //   title: "Windshield Module",
    // });
  }
  logbugs = false;

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const constraints = {
    video: { width: width, height: height, facingMode: "environment", zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 } },
    audio: false,
  };
  const videoRef = useRef();
  const startBtn = useRef();
  const canvasRef = useRef();
  const damageScreen = useRef();
  const modalRef = useRef();
  const modalImg = useRef();
  const canvasRef2 = useRef();
  const canvasRef1 = useRef();
  const [dragX, setDragX] = useState(0);
  const [dragY, setDragY] = useState(0);
  const [isDragging, setIsDragging] = useState(true);
  const [resizeX, setResizeX] = useState(0);
  const [resizeY, setResizeY] = useState(0);
  const [isResizing, setIsResizing] = useState(false);
  const [squareX, setSquareX] = useState(width / 2 - 50);
  const [squareY, setSquareY] = useState(height / 2 - 25);
  const [squareWidth, setSquareWidth] = useState(100);
  const [squareHeight, setSquareHeight] = useState(50);
  const [visible, setVisible] = useState(true);
  const [visibleText, setVisibleText] = useState(false);
  const [visibleTextCamera, setVisibleTextCamera] = useState(false);
  const [count, setCount] = useState(getWindshieldModuleCount());
  const [fImg, setFImg] = useState(null);
  const [sImg, setSImg] = useState(null);
  const [imgPosition, setImgPosition] = useState(null);
  const [page, setPage] = useState("inst");
  const [changeText, setChangeText] = useState(false);
  const [txt, setText] = useState(false);
  const [enableTickbtn, setEnableTickbtn] = useState(false);

  let base_image = new Image();
  base_image.src = dragImg;
  let base_image3 = new Image();
  base_image3.src = leftRight;
  let base_image2 = new Image();
  base_image2.src = topBottom;

  const land = () => window.innerWidth > window.innerHeight * 1.2;

  window.onresize = () => {
    setLandscape(land());
    if (cameraStarted) startCamera();
  };

  const ZoomStart = () => {
    patLogs(
      "Windshield Module -> At Capturing (Zoom out & zoon in) images",
      inspectionId
    );
    setPage("damage");
  };

  const TakeMore = () => {
    cameraStarted = false;
    images = [];
    setSImg(null);
    setFImg(null);
    setCount(count + 1);
    setSquareX(width / 2 - 50);
    setSquareY(height / 2 - 25);
    setSquareHeight(50);
    setSquareWidth(100);
    // patLogs("Take more Damage images", inspectionId);
  };

  const End = () => {
    cameraStarted = false;
    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    //resume flow
    resumeFlowFinalSubmitHandler("WindshieldDamage")
    setScreen("menu");
    patLogs(
      "Damages Module Submited (user clicked to end button)",
      inspectionId
    );
  };

  const ZoomIn = () => {
    // patLogs("Zoom in section clicked to capture image", inspectionId);
    setPage("camera");
    setImgPosition(0);
    startCamera();
    setVisibleTextCamera(false);
    setText(true);
    setTimeout(() => {
      setText(false);
    }, 60000);
  };

  const ZoomOut = () => {
    // patLogs("Zoom out section clicked to capture image", inspectionId);
    setEnableTickbtn(false);
    setPage("camera");
    setImgPosition(1);
    startCamera();
    setText(false);
    setVisibleTextCamera(true);
    setTimeout(() => {
      setVisibleTextCamera(false);
    }, 60000);
  };

  const startCamera = () => {
    if (imgData) {
      modalImg.current.src = imgData;
      modalRef.current.classList.add("active");
    }
    cameraStarted = true;
    webCamPromise = navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        window.stream = stream;
        track = stream.getTracks()[0];
        videoRef.current.srcObject = stream;
        setTimeout(() => {
          startBtn.current.style.display = "block";
        }, 500);
      });
  };

  const captureImg = () => {
   // patLogs("Image is captured", inspectionId);
    if (imgPosition === 1) document.body.style.overflow = "hidden";
    const cnv = canvasRef.current;
    const vw = videoRef.current;
    cnv.width = vw.videoWidth;
    cnv.height = vw.videoHeight;
    cnv.getContext("2d").drawImage(vw, 0, 0);
    imgData = cnv.toDataURL("image/jpeg");
    if (imgPosition === 0) {
      setFImg(cnv.toDataURL("image/jpeg"));
    } else if (imgPosition === 1) {
      setSImg(cnv.toDataURL("image/jpeg"));
    }
    active = false;
    window.stream = null;
    captured++;
    showModal();
    if (imgPosition === 1) {
      setVisibleText(true);
      setTimeout(() => {
        setVisibleText(false);
      }, 25000);
    }
  };

  const showModal = (e) => {
    modalImg.current.src = imgData;
    modalRef.current.classList.add("active");
  };

  const combineImageWithCoordinate = async (fImg, sImg, coordinates) => {
    const image = await loadImage(sImg);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);
    context.strokeStyle = "blue";
    context.lineWidth = 5;
    context.rect(
      coordinates.x,
      coordinates.y,
      coordinates.width,
      coordinates.height
    );
    context.stroke();
    canvas.toDataURL("image/jpeg");
    return combineImage(fImg, canvas.toDataURL("image/jpeg"));
  };
  const combineImage = async (fImg, sImg) => {
    const image1 = await loadImage(fImg);
    const image2 = await loadImage(sImg);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 1280;
    canvas.height = 720;

    context.drawImage(image1, 0, 0, 1280 / 2, 720);
    context.drawImage(image2, 1280 / 2, 0, 1280 / 2, 720);
    context.lineWidth = 15;
    context.strokeStyle = "yellow";
    context.strokeRect(0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL("image/jpeg");
    return dataURL;
  };
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  const handleNext = async () => {
    patLogs("tick button clicked image sent for uploading...", inspectionId);
    startBtn.current.style.display = "none";
    document.body.style.overflow = "scroll";
    setIsDragging(true);
    setIsResizing(false);
    cameraStarted = false;
    window.stream = null;
    track.stop();
    if (fImg) {
      //patLogs("Zoom In image is clicked", inspectionId);
    }
    if (sImg) {
      //patLogs("Zoom Out image is clicked", inspectionId);
    }
    if (fImg && sImg) {
      // patLogs(
      //   `Round ${count + 1} Both images was captured and sent for uploading`,
      //   inspectionId
      // );
      setLog(`${currentBlock["tag"]} was completed`);
      let coordinates = {
        x: squareX,
        y: squareY,
        width: squareWidth,
        height: squareHeight,
        xwindshield: width,
        ywindshield: height,
      };
      setCurrentDamage(currentDamage + 1);
      let region = Object.keys(config["video-page"]).includes("clientRegion")
        ? config["video-page"]["clientRegion"]
        : "eu";
      let drowcord = await combineImageWithCoordinate(fImg, sImg, coordinates);
      images = [
        {
          name: `D_${currentDamage}_${captured}.jpg`,
          imgData: drowcord,
          tag: "WindshieldDamage",
          coordinates,
        },
        {
          angle: null,
          region: region,
          web_region: null,
          videoData: null,
          dialStatus: null,
        },
      ];
      //send images

      addImageToList(images);
    }
    setPage("damage");
    imgData = null;
  };

  const handleRetake = () => {
    // patLogs("retake button clicked to capture image again", inspectionId);
    setEnableTickbtn(false);
    imgData = null;
    document.body.style.overflow = "scroll";
    setIsDragging(true);
    setIsResizing(false);
    setSquareX(width / 2 - 50);
    setSquareY(height / 2 - 25);
    setSquareHeight(50);
    setSquareWidth(100);
    images = [];
    modalRef.current.classList.remove("active");
    captured = 0;
    if (imgPosition === 0) {
      setFImg(null);
    }
    if (imgPosition === 1) {
      setSImg(null);
    }
  };

  const handleMouseDown = (e) => {
    if (isDragging) {
      setDragX(e.changedTouches["0"].clientX - squareX);
      setDragY(e.changedTouches["0"].clientY - squareY);
    }
    if (isResizing) {
      setResizeX(squareWidth - (e.changedTouches["0"].clientX - squareX));
      setResizeY(squareHeight - (e.changedTouches["0"].clientY - squareY));
    }
  };

  const handleMouseMove = (e) => {
    !enableTickbtn && setEnableTickbtn(true);
    if (isDragging) {
      setVisible(false);
      setSquareX(e.changedTouches["0"].clientX - dragX);
      setSquareY(e.changedTouches["0"].clientY - dragY);
    }
    if (isResizing) {
      setVisible(false);
      if (e.changedTouches["0"].clientX - squareX + resizeX > 50) {
        setSquareWidth(e.changedTouches["0"].clientX - squareX + resizeX);
      }
      if (e.changedTouches["0"].clientY - squareY + resizeY > 50) {
        setSquareHeight(e.changedTouches["0"].clientY - squareY + resizeY);
      }
    }
  };

  useEffect(() => {
    const c = document.getElementById("myCanvas");

    if (c) {
      const ctx = c.getContext("2d");
      ctx.canvas.width = window.innerWidth;
      ctx.canvas.height = window.innerHeight;
      ctx.clearRect(0, 0, width, height);
      ctx.moveTo(105, 25);
      ctx.lineTo(605, 25);
      ctx.lineTo(705, 300);
      ctx.lineTo(5, 300);
      ctx.lineTo(105, 25);
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#FFFFFF";
      ctx.stroke();
    }
    setLandscape(land());
    const canvas2 = canvasRef2.current;
    if (canvas2) {
      const ctx2 = canvas2.getContext("2d");
      ctx2.canvas.width = window.innerWidth;
      ctx2.canvas.height = window.innerHeight;
      ctx2.clearRect(0, 0, width, height);
      ctx2.save();
      ctx2.fillStyle = "#ffffffaa";
      ctx2.fillRect(0, 0, width, height);
      ctx2.beginPath();
      visible &&
        isResizing &&
        ctx2.drawImage(
          base_image3,
          squareX + squareWidth / 2,
          squareY + squareHeight,
          40,
          40
        );
      visible &&
        isResizing &&
        ctx2.drawImage(
          base_image2,
          squareX + squareWidth,
          squareY + squareHeight / 2,
          40,
          40
        );
      ctx2.rect(squareX, squareY, squareWidth, squareHeight);
      ctx2.clearRect(squareX, squareY, squareWidth, squareHeight);
      ctx2.restore();

      const canvas1 = canvasRef1.current;
      const ctx1 = canvas1.getContext("2d");
      ctx1.canvas.width = window.innerWidth;
      ctx1.canvas.height = window.innerHeight;
      ctx1.clearRect(0, 0, width, height);
      ctx1.save();
      ctx1.beginPath();
      ctx1.fillStyle = "transparent";
      ctx1.fillRect(0, 0, width, height);
      visible &&
        isDragging &&
        ctx1.drawImage(
          base_image,
          squareX + squareWidth / 2 - 10,
          squareY + squareHeight / 2 - 10,
          20,
          20
        );
      ctx1.restore();
    }
  }, [
    squareX,
    squareY,
    squareWidth,
    squareHeight,
    visible,
    isDragging,
    isDragging,
    captureImg,
  ]);

  const drag = () => {
    setIsResizing(false);
    setIsDragging(true);
  };

  const reSize = () => {
    setIsResizing(true);
    setIsDragging(false);
  };

  const handleMouseUp = (e) => {
    setVisible(true);
  };

  const back = () => {
    // patLogs("back button clicked", inspectionId);
    cameraStarted = false;
    setScreen("menu");
    setPage("inst");
    setSImg(null);
    setFImg(null);
    logbugs = true;
  };

  const goback = () => {
    // patLogs(
    //   "back button clicked and return to Windshield module",
    //   inspectionId
    // );
    cameraStarted = false;
    window.stream = null;
    track.stop();
    setPage("damage");
    logbugs = true;
  };

  return (
    <div>
      {!isLandscape ? (
        <Rotate />
      ) : page === "inst" ? (
        <div className="zoom-gif damage_size">
          <div className="dmgIns">
            <h1>{config["damage-page"]["menu-title"]}</h1>
            <div className="inst">
              <img src={config["windshieldDamage"]["imageOne"]} />
              <div>
                <h3>{config["windshieldDamage"]["instOne"]}</h3>
              </div>
            </div>
            <div className="inst">
              <img src={config["windshieldDamage"]["imageTwo"]} />
              <div>
                <h3>{config["windshieldDamage"]["instTwo"]}</h3>
              </div>
            </div>
            <div className="inst">
              <img src={config["windshieldDamage"]["imageThree"]} />
              <div>
                <h3>{config["windshieldDamage"]["instThree"]}</h3>
              </div>
            </div>
          </div>
          <div className="InternetWarning">
            <p>{showAlert && showAlert}</p>
          </div>
          <div className="sep-btn">
            <Button
              secoundaryBtn="true"
              className="start"
              style={{ marginTop: "0px", padding: "0px" }}
              onclick={back}
              text={config["damage-page"]["back-btn-text"]}
              marginDefault= "0px 0px 0px 0px"
            />
            <Button
              className="start"
              style={{ marginTop: "0px", padding: "0px" }}
              onclick={ZoomStart}
              text={config["damage-page"]["menu-btn-text"]}
              marginDefault= "0px 25px 0px 25px"
            />
          </div>
        </div>
      ) : page === "damage" ? (
        <div className="screen18 damage_size">
          <h1>{config["damage-page"]["select-screen-title"]}</h1>
          <div className="post-sub">
            <div className="post-sub-div">
              <div className="zoom-container">
                <h3>{config["windshieldDamage"]["stepTextOne"]}</h3>
                <div disabled={true} className={sImg ? "timg" : "rimg"}>
                  <img
                    src={sImg ? sImg : takeCamera}
                    onClick={!sImg && ZoomOut}
                  />
                </div>
                <div className="zoom-ALigment">
                  <div className={sImg ? "captured" : "pending"}>
                    {sImg ? "✓" : "!"}
                  </div>
                  {sImg
                    ? config["damage-page"]["captured"]
                    : config["damage-page"]["pending"]}
                </div>
              </div>
              <div className="zoom-container">
                <h3>{config["windshieldDamage"]["stepTextTwo"]}</h3>
                <div
                  disabled={fImg ? true : false}
                  className={fImg ? "timg" : "rimg"}
                >
                  <img
                    src={fImg ? fImg : takeCamera}
                    onClick={!fImg && ZoomIn}
                  />
                </div>
                <div className="zoom-ALigment">
                  <div className={fImg ? "captured" : "pending"}>
                    {fImg ? "✓" : "!"}
                  </div>
                  {fImg
                    ? config["damage-page"]["captured"]
                    : config["damage-page"]["pending"]}
                </div>
              </div>
            </div>
            <div className="InternetWarning">
              <p>{showAlert && showAlert}</p>
            </div>
            <div className="damage-btn">
              {/* /////////////////////////Adjust Image count here ////////////////////////////////// */}
              {count !== currentBlock["count"] && fImg && sImg ? (
                <div
                  className="btn process-btn"
                  style={{
                    margin: "0px 0px 0px 0px",
                    backgroundColor: "white",
                    color: config["colors"]["secoundaryBtn"] ? config["colors"]["secoundaryBtn"] : "black",
                    border: config["colors"]["secoundaryBtn"] ? `2px solid ${config["colors"]["secoundaryBtn"]}` : '2px solid black'
                  }}
                  onClick={TakeMore}
                >
                  {config["damage-page"]["more-btn"]}
                </div>
              ) : null}
              {fImg && sImg ? (
                <div
                  className="btn process-btn"
                  style={{
                    margin: "0px 25px 0px 25px",
                    backgroundColor: config["colors"]["btn"],
                  }}
                  onClick={End}
                >
                  {config["damage-page"]["end-btn"]}
                </div>
              ) : null}
            </div>
            {count === currentBlock["count"] ? null : fImg && sImg ? null : (
              <div className="back-div">
                <button
                  className="start-btn"
                  style={{
                    margin: "5px 0px",
                    backgroundColor: "white",
                    color: config["colors"]["secoundaryBtn"] ? config["colors"]["secoundaryBtn"] : "black",
                    border: config["colors"]["secoundaryBtn"] ? `2px solid ${config["colors"]["secoundaryBtn"]}` : '2px solid black'
                  }}
                  onClick={back}
                >
                  {config["damage-page"]["back-btn-text"]}
                </button>
              </div>
            )}
          </div>
        </div>
      ) : page === "camera" ? (
        <div id="screen3" className="damage_size" ref={damageScreen}>
          <canvas ref={canvasRef} id="ios-canvas"></canvas>
          <video ref={videoRef} id="videoWindow" autoPlay playsInline></video>
          {visibleTextCamera && (
            <div
              className="warning"
              style={{
                position: "fixed",
                bottom: "54px",
                zIndex: "23",
                zIndex: 2,
              }}
            >
              <div style={{ width: "fit-content" }}>
                <p style={{ padding: "5px 20px" }}>
                  {config["windshieldDamage"]["cameraText"]}
                </p>
              </div>
            </div>
          )}
          {txt && (
            <div
              className="warning"
              style={{
                position: "fixed",
                bottom: "54px",
                zIndex: "23",
                zIndex: 2,
              }}
            >
              <div style={{ width: "fit-content" }}>
                <p style={{ padding: "5px 20px" }}>
                  {config["windshieldDamage"]["instThree"]}
                </p>
              </div>
            </div>
          )}
          <div style={{ zIndex: 2 }} className="back-btn">
            <i className="fas fa-arrow-left fa-2x" onClick={goback}></i>
          </div>
          <div id="btn-car-container"></div>
          {imgPosition === 1 && <canvas id="myCanvas"></canvas>}
          <div className="clickBtn" ref={startBtn}>
            <img id="btn-android-start1" onClick={captureImg} src={camera} />
          </div>
          <div ref={modalRef} className="reels-modal">
            {imgPosition === 1 ? (
              <>
                <img
                  ref={modalImg}
                  style={{ width: "100%", height: "100%", zIndex: "21" }}
                />
                <canvas
                  height={height}
                  width={width}
                  ref={canvasRef1}
                  style={{
                    position: "absolute",
                    zIndex: "22",
                    left: "0px",
                    top: "0px",
                  }}
                  onTouchMove={handleMouseMove}
                  onTouchStart={handleMouseDown}
                  onTouchEnd={handleMouseUp}
                />
                <canvas
                  height={height}
                  width={width}
                  ref={canvasRef2}
                  style={{
                    position: "absolute",
                    zIndex: "23",
                    left: "0px",
                    top: "0px",
                  }}
                  onTouchMove={handleMouseMove}
                  onTouchStart={handleMouseDown}
                  onTouchEnd={handleMouseUp}
                />
                <div
                  style={{
                    display: visible ? "block" : "none",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    color: "white",
                    zIndex: "23",
                    margin: "10px",
                  }}
                >
                  <button
                    style={{
                      width: "80px",
                      height: "30px",
                      border: "none",
                      borderRadius: "5px",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      backgroundColor: "white",
                      color: isDragging ? "#007aff" : "black",
                    }}
                    onClick={drag}
                  >
                    Drag
                  </button>
                  {/* <button 
                          style={{
                            width: "80px",
                            height: "30px",
                            border: "none",
                            borderRadius: "5px",
                            margin: "5px",
                            fontSize: "14px",
                            fontWeight: "bold", 
                            backgroundColor: "white",
                            color: isResizing ? "#007aff" : "black",                 
                          }} onClick={reSize}>Resize</button> */}
                </div>
                {/* <div className="reel-container" style={{ display: visible ? "block" : "none",}}> */}
                <i
                  className="fas fa-times fa-2x vin-btn-retake"
                  style={{
                    display: visible ? "block" : "none",
                    position: "fixed",
                    zIndex: "24",
                    bottom: "52px",
                  }}
                  onClick={handleRetake}
                ></i>
                {enableTickbtn && (
                  <i
                    className="fas fa-check fa-2x vin-btn-next"
                    style={{
                      display: visible ? "block" : "none",
                      position: "fixed",
                      zIndex: "24",
                      bottom: "52px",
                    }}
                    onClick={handleNext}
                  ></i>
                )}
                {visibleText && (
                  <div
                    className="warning"
                    style={{ position: "fixed", bottom: "52px", zIndex: "23" }}
                  >
                    <div style={{ width: "fit-content" }}>
                      <p style={{ padding: "5px 20px" }}>
                        {config["windshieldDamage"]["reSizeText"]}
                      </p>
                    </div>
                  </div>
                )}
                {/* </div> */}
              </>
            ) : (
              <>
                <img ref={modalImg} alt="" />
                <div
                  className="reel-container"
                  style={{
                    zIndex: "23",
                    display: visible ? "block" : "none",
                    bottom: "88px",
                  }}
                >
                  <i
                    className="fas fa-times fa-2x vin-btn-retake"
                    onClick={handleRetake}
                  ></i>
                  <i
                    className="fas fa-check fa-2x vin-btn-next"
                    onClick={handleNext}
                  ></i>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WindshieldDamge;
