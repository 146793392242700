export class OrientationEstimator {
  // Function that returns a list of Orientiations, upon which voting is done to determine the actual orientation.
  getOrientationVotingList(sortedParts) {
    let orientationVotingList = [];

    /* In certain cases, a few detected parts and their order can be used to predict the 
            Left/Right Orientation with a high confidence. Hence, the votingList is not necessary. */
    if (sortedParts.includes("wheel")) {
      if (sortedParts.includes("front_bumper")) {
        if (sortedParts.indexOf("wheel") > sortedParts.indexOf("front_bumper"))
          return ["Left", "Left"];
        else return ["Right", "Right"];
      }
      if (sortedParts.includes("back_bumper")) {
        if (sortedParts.indexOf("back_bumper") > sortedParts.indexOf("wheel"))
          return ["Left", "Left"];
        else return ["Right", "Right"];
      }
    }

    if (sortedParts.includes("front_bumper")) {
      if (sortedParts.includes("fender")) {
        if (sortedParts.indexOf("fender") > sortedParts.indexOf("front_bumper"))
          return ["Left", "Left"];
        else return ["Right", "Right"];
      }
    }
    if (sortedParts.includes("back_bumper")) {
      if (sortedParts.includes("qtr_panel")) {
        if (
          sortedParts.indexOf("back_bumper") > sortedParts.indexOf("qtr_panel")
        )
          return ["Left", "Left"];
        else return ["Right", "Right"];
      }
    }

    /* In other cases, the few detected parts and their order are not sufficient to predict the 
            Orientation with confidence. Hence, the votingList is necessary. */
    if (sortedParts.includes("fender")) {
      if (sortedParts.includes("front_bumper")) {
        if (sortedParts.indexOf("fender") > sortedParts.indexOf("front_bumper"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("front_door")) {
        if (sortedParts.indexOf("fender") < sortedParts.indexOf("front_door"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_door")) {
        if (sortedParts.indexOf("fender") < sortedParts.indexOf("back_door"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("qtr_panel")) {
        if (sortedParts.indexOf("fender") < sortedParts.indexOf("qtr_panel"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_bumper")) {
        if (sortedParts.indexOf("fender") < sortedParts.indexOf("back_bumper"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
    }

    if (sortedParts.includes("front_door")) {
      if (sortedParts.includes("front_bumper")) {
        if (
          sortedParts.indexOf("front_door") >
          sortedParts.indexOf("front_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_door")) {
        if (
          sortedParts.indexOf("front_door") < sortedParts.indexOf("back_door")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("qtr_panel")) {
        if (
          sortedParts.indexOf("front_door") < sortedParts.indexOf("qtr_panel")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_bumper")) {
        if (
          sortedParts.indexOf("front_door") < sortedParts.indexOf("back_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
    }

    if (sortedParts.includes("back_door")) {
      if (sortedParts.includes("front_bumper")) {
        if (
          sortedParts.indexOf("back_door") > sortedParts.indexOf("front_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("qtr_panel")) {
        if (sortedParts.indexOf("back_door") < sortedParts.indexOf("qtr_panel"))
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_bumper")) {
        if (
          sortedParts.indexOf("back_door") < sortedParts.indexOf("back_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
    }

    if (sortedParts.includes("qtr_panel")) {
      if (sortedParts.includes("front_bumper")) {
        if (
          sortedParts.indexOf("qtr_panel") > sortedParts.indexOf("front_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
      if (sortedParts.includes("back_bumper")) {
        if (
          sortedParts.indexOf("qtr_panel") < sortedParts.indexOf("back_bumper")
        )
          orientationVotingList.push("Left");
        else orientationVotingList.push("Right");
      }
    }

    return orientationVotingList;
  }

  getCarOrientation(boundingBoxDetections) {
    /* Creating two empty array lists that would store the labels of detected parts and
            their X coordinates (For applying the relative centroid logic) */
    let sortedParts = [];
    let sortedXCoordinates = [];

    /* Iterate over the bounding boxes and fill in our lists in a sorted way such that the 
            X coordinates of the bounding boxes are in asceding order . */
    boundingBoxDetections.forEach((detection) => {
      let label = detection["label"];
      let bbox = detection["bbox"];
      let partXCoordinateCentroid = bbox[0] + bbox[2] / 2;

      let insertionPosition = 0;

      if (sortedXCoordinates.length === 0) {
        sortedXCoordinates.push(-1.0);
        sortedParts.push("PlaceHolder");
      } else {
        while (
          partXCoordinateCentroid > sortedXCoordinates[insertionPosition]
        ) {
          insertionPosition++;
          if (insertionPosition >= sortedXCoordinates.length) break;
        }
        let i = sortedXCoordinates.length - 1;
        sortedXCoordinates.push(-1.0);
        sortedParts.push("PlaceHolder");
        while (i >= insertionPosition) {
          sortedXCoordinates[i + 1] = sortedXCoordinates[i];
          sortedParts[i + 1] = sortedParts[i];
          i--;
        }
      }
      sortedParts[insertionPosition] = label;
      sortedXCoordinates[insertionPosition] = partXCoordinateCentroid;
    });

    /* Now sortedParts and sortedXCoordinates contain parts labels and X_Coord values 
            according to the ascending order of the X_Coord values. */

    // Get the Voting List
    let orientationVotingList = this.getOrientationVotingList(sortedParts);

    if (orientationVotingList.length === 0) return "Unclear";

    let leftCount = 0;
    let rightCount = 0;

    // Count the frequence of each Orientation in the OrientationVotingList
    orientationVotingList.forEach((orientation) => {
      if (orientation === "Left") leftCount += 1;
      else rightCount += 1;
    });

    // Decide the Orientation based on the frequency of each.
    if (leftCount > rightCount) return leftCount > 1 ? "Left" : "Unclear";
    else if (rightCount > leftCount)
      return rightCount > 1 ? "Right" : "Unclear";
    else return "Unclear";
  }
}
