import * as constants from "../utils_new/Constants";

export class PartCoverageEstimator {
  constructor() {
    // Setting parts that have orientation i.e. left and right.
    this.orientatedParts = [
      "fender",
      "qtr_panel",
      "window_glass",
      "front_door",
      "back_door",
      "running_board",
    ];
    this.extraOrientatedParts = [
      "front_bumper_isometric",
      "back_bumper_isometric",
    ];

    /* boxBorderThresholdForEachPart is an object that stores the parts only (not their orientation) as keys, and the top-bottom-left-right border thresholds.
            Each (key, value) pair is {'part_with_orientation': {'top': t_thresh, 'bottom': b_thresh, 'left': l_thresh, 'right': r_thresh} */
    this.boxBorderThresholdForEachPart = {};
    this.defaultBorderThreshold = {
      top: 0.01,
      bottom: 0.99,
      left: 0.01,
      right: 0.99,
    };

    /* partsCoveredAndAnglesMap is an object that stores the parts (along with their orientation) as keys, and the angles of detection of those parts as values.
            Each (key, value) pair is {'part_with_orientation': [angle1, angle2, angle3, ...]} */
    this.partsCoveredAndAnglesMap = {};

    // Initialize boxBorderThresholdForEachPart and partsCoveredAndAnglesMap
    for (let id in constants.CLASS_LABELS) {
      let label = constants.CLASS_LABELS[id];
      this.boxBorderThresholdForEachPart[label] = this.defaultBorderThreshold;

      if (this.orientatedParts.includes(label)) {
        this.partsCoveredAndAnglesMap["left_" + label] = new Set();
        this.partsCoveredAndAnglesMap["right_" + label] = new Set();
      } else {
        this.partsCoveredAndAnglesMap[label] = new Set();
      }
    }
    this.extraOrientatedParts.forEach((label) => {
      this.boxBorderThresholdForEachPart[label] = this.defaultBorderThreshold;

      this.partsCoveredAndAnglesMap["left_" + label] = new Set();
      this.partsCoveredAndAnglesMap["right_" + label] = new Set();
    });
    this.boxBorderThresholdForEachPart["front_glass"] = {
      top: 0.025,
      bottom: 0.975,
      left: 0.025,
      right: 0.975,
    };
    // this.boxBorderThresholdForEachPart['back_glass'] = {'top': 0.025, 'bottom': 0.975, 'left': 0.025, 'right': 0.975};

    /* partsCoveredAndFramesCountMap is a object that stores the parts (along with their orientation) as keys, and stores in how many frames how those parts
            appeared while constrained by the angle requirements. 
            Each (key, value) pair of partsCoveredAndFramesCountMap is {'part_with_orientation': frame_count} */
    this.partsCoveredAndFramesCountMap = {};

    // Initialize partsCoveredAndFramesCountMap
    for (let id in constants.CLASS_LABELS) {
      let label = constants.CLASS_LABELS[id];
      if (this.orientatedParts.includes(label)) {
        this.partsCoveredAndFramesCountMap["left_" + label] = 0;
        this.partsCoveredAndFramesCountMap["right_" + label] = 0;
      } else this.partsCoveredAndFramesCountMap[label] = 0;
    }
    this.extraOrientatedParts.forEach((label) => {
      this.partsCoveredAndFramesCountMap["left_" + label] = 0;
      this.partsCoveredAndFramesCountMap["right_" + label] = 0;
    });
  }

  // Function that returns a list of the detected parts (along with their orientation when applicable) in a frame.
  getPartsCovered(boundingBoxDetections, angle) {
    let partsCoveredInAFrame = [];
    let orientation;

    // Determine Orientation based on the Angle.
    if (angle >= 20 && angle <= 160) orientation = "Right";
    else if (angle >= 190 && angle <= 340) orientation = "Left";
    else orientation = "Unclear";

    // Iterate through each Detection
    boundingBoxDetections.forEach((detection) => {
      let label = detection["label"];
      let x = detection["bbox"][0];
      let y = detection["bbox"][1];
      let width = detection["bbox"][2];
      let height = detection["bbox"][3];

      // Check if the bboxes are not too close to the borders of the image, based on thresholds.
      let boxIsHorizontallyCovered =
        x >= this.boxBorderThresholdForEachPart[label]["left"] &&
        x + width <= this.boxBorderThresholdForEachPart[label]["right"];
      let boxIsVerticallyCovered =
        y >= this.boxBorderThresholdForEachPart[label]["top"] &&
        y + height <= this.boxBorderThresholdForEachPart[label]["bottom"];

      // Add the parts (and the orientation if applicable) to the list.
      if (boxIsHorizontallyCovered && boxIsVerticallyCovered) {
        if (
          this.orientatedParts.includes(label) ||
          this.extraOrientatedParts.includes(label)
        ) {
          if (orientation !== "Unclear")
            partsCoveredInAFrame.push(orientation.toLowerCase() + "_" + label);
        } else partsCoveredInAFrame.push(label);
      }
    });
    return partsCoveredInAFrame;
  }

  // Function that adds the angle of the frame and updates the frame count to (and of) the corresponding detected parts in partsCoveredAndAnglesMap
  setAnglesCoveredAndFramesCountForPart(partsCovered, angle) {
    /* Parts like 'side_view_mirror', 'wheel', 'taillight', 'headlight' are not considered,
            so their corresponding value arrays in the partsCoveredAndAnglesMap dictionary is empty
            and partsCoveredAndFramesCountMap dictionary is zero. */

    partsCovered.forEach((part) => {
      let considerPartFlag = false;

      if (
        part === "front_bumper" &&
        ((angle >= 0 && angle <= 30) || (angle >= 330 && angle <= 360))
      )
        considerPartFlag = true;

      if (
        part === "hood" &&
        ((angle >= 0 && angle <= 30) || (angle >= 330 && angle <= 360))
      )
        considerPartFlag = true;

      if (
        part === "front_glass" &&
        ((angle >= 0 && angle <= 10) || (angle >= 350 && angle <= 360))
      )
        considerPartFlag = true;

      if (part === "right_front_bumper_isometric" && angle >= 25 && angle <= 80)
        considerPartFlag = true;

      if (
        part === "left_front_bumper_isometric" &&
        angle >= 280 &&
        angle <= 335
      )
        considerPartFlag = true;

      if (part === "right_fender" && angle >= 25 && angle <= 80)
        considerPartFlag = true;

      if (part === "left_fender" && angle >= 280 && angle <= 335)
        considerPartFlag = true;

      if (part === "right_front_door" && angle >= 75 && angle <= 100)
        considerPartFlag = true;

      if (part === "left_front_door" && angle >= 260 && angle <= 285)
        considerPartFlag = true;

      if (part === "right_back_door" && angle >= 80 && angle <= 105)
        considerPartFlag = true;

      if (part === "left_back_door" && angle >= 255 && angle <= 280)
        considerPartFlag = true;

      if (part === "right_window_glass" && angle >= 60 && angle <= 150)
        considerPartFlag = true;

      if (part === "left_window_glass" && angle >= 210 && angle <= 300)
        considerPartFlag = true;

      if (part === "right_running_board" && angle >= 75 && angle <= 105)
        considerPartFlag = true;

      if (part === "left_running_board" && angle >= 255 && angle <= 285)
        considerPartFlag = true;

      if (part === "right_qtr_panel" && angle >= 100 && angle <= 165)
        considerPartFlag = true;

      if (part === "left_qtr_panel" && angle >= 195 && angle <= 260)
        considerPartFlag = true;

      if (part === "right_back_bumper_isometric" && angle >= 90 && angle <= 140)
        considerPartFlag = true;

      if (part === "left_back_bumper_isometric" && angle >= 220 && angle <= 270)
        considerPartFlag = true;

      if (part === "back_bumper" && angle >= 150 && angle <= 210)
        considerPartFlag = true;

      if (part === "dicky" && angle >= 150 && angle <= 210)
        considerPartFlag = true;

      if (part === "back_glass" && angle >= 170 && angle <= 190)
        considerPartFlag = true;

      if (considerPartFlag) {
        this.partsCoveredAndAnglesMap[part].add(angle);
        this.partsCoveredAndFramesCountMap[part]++;
      }
    });
  }

  // Function that uses the list of covered parts to determine which of them can be marked as covered with high confidence.
  determinePartsCoveredWithConfidence(partsCovered) {
    let overallPartsCovered = [];

    // Simplified Part-Angle criteria logics
    partsCovered.forEach((part) => {
      // There should be at least one angle of detection for all the parts and at least 2 frames for doors.
      if (this.partsCoveredAndAnglesMap[part].size > 0)
        if (
          part.includes("door") &&
          this.partsCoveredAndFramesCountMap[part] > 1
        )
          overallPartsCovered.push(part);
        else overallPartsCovered.push(part);
    });

    /*
        // Iterate through the parts detected in the frame
        partsCovered.forEach(part => {

            // For 'Front Bumper', there should be at least two angles of detection and one of them should be '0'.
            if (part === "front_bumper") {
                if (this.partsCoveredAndAnglesMap[part].size > 1 && this.partsCoveredAndAnglesMap[part].includes(0))
                    overallPartsCovered.push(part);
            } 
            // For 'Back Bumper', there should be at least two angles of detection and one of them should be '180'.
            else if (part === "back_bumper") {
                if (this.partsCoveredAndAnglesMap[part].size > 1 && this.partsCoveredAndAnglesMap[part].includes(180))
                    overallPartsCovered.push(part);
            }
            // For both orientations of either 'Fender' or 'Qtr Panel', there should be at least two angles of detection.
            else if (["left_fender", "right_fender", "left_qtr_panel", "right_qtr_panel"].includes(part)) {
                if (this.partsCoveredAndAnglesMap[part].size > 1)
                    overallPartsCovered.push(part);
            }
            // For the rest, there should be at least one angles of detection.
            else {
                if (this.partsCoveredAndAnglesMap[part].size > 0)
                    overallPartsCovered.push(part);
            }
        })
        */

    return overallPartsCovered;
  }

  // Function that returns a list of all the parts (in a frame) that can be said to have been covered with a high degree of confidence.
  getPartsCoveredWithConfidence(boundingBoxDetections, angle) {
    /* Get the Parts (as well as their orientation when applicable) detected in a frame.
            Eg, [left_fender, front_glass, front_bumper, left_window_glass] */
    let partsCovered = this.getPartsCovered(boundingBoxDetections, angle);

    // Add the angle of the frame to the corresponding parts in partsCoveredAndAnglesMap
    this.setAnglesCoveredAndFramesCountForPart(partsCovered, angle);

    // Use the list of covered parts to determine parts that can be said to have been covered with a high degree of confidence.
    let partsCoveredWithConfidence =
      this.determinePartsCoveredWithConfidence(partsCovered);

    return partsCoveredWithConfidence;
  }
}
