import { score } from "../models/AngleModel";

// Predicting Angle of the View in the frame from the Bounding Boxes of the Detection Objects
export class AngleRFEstimator {
  constructor() {
    // Target Angle Values
    this.labels = [
      "0",
      "0_Zoom",
      "10",
      "110",
      "120",
      "130",
      "140",
      "150",
      "160",
      "170",
      "180",
      "180_Zoom",
      "20",
      "30",
      "40",
      "50",
      "60",
      "70",
      "90",
      "90_Zoom",
      "Window_Zoom",
    ];

    // Parts that will be used to predict the Angle
    this.featureLabels = [
      "front_bumper",
      "back_bumper",
      "front_door",
      "back_door",
      "dicky",
      "front_glass",
      "back_glass",
      "fender",
      "qtr_panel",
      "hood",
      "window_glass",
      "headlight",
      "taillight",
      "wheel",
    ];
  }

  // Function to generate Features from a single Bounding Box
  // Features for one bbox = [PercentageArea, Centroid_X, Centroid_Y]
  getFeaturesFromBox(bbox) {
    let percentArea = bbox[2] * bbox[3];
    let centX = bbox[0] + bbox[2] / 2;
    let centY = bbox[1] + bbox[3] / 2;
    return [percentArea, centX, centY];
  }

  // Function to generate the featureVector from the Detection Objects
  // featureVector is an array of length 42, where every trio of consecutive elements correspond to each of the 14 featureLabels
  // Each of the 3 elements represent the PercentageArea, Centroid_X, Centroid_Y of the Corresponding Detected Part's bbox.
  getFeatureVector(boundingBoxDetections) {
    // Declare and initialize the featureVector with zeros.
    let featureVector = new Array(this.featureLabels.length * 3);
    for (let i = 0; i < featureVector.length; ++i) featureVector[i] = 0;

    // Loop through the Detection Objects and fill the corresponding values in the featureVector
    boundingBoxDetections.forEach((detection) => {
      let featureClassIndex = this.featureLabels.indexOf(detection["label"]);

      /* featureClassIndex will be -1, if the label is not present in featureLabels. Add to the featureVector only if 
            the label is present in featureLabels. */
      if (featureClassIndex !== -1) {
        // Generate the features corresponding to the individual Bounding Box i.e. [PercentageArea, Centroid_X, Centroid_Y]
        let boxFeature = this.getFeaturesFromBox(detection["bbox"]);

        // Fill up the corresponding values in featureVector
        featureVector[featureClassIndex * 3] = boxFeature[0];
        featureVector[featureClassIndex * 3 + 1] = boxFeature[1];
        featureVector[featureClassIndex * 3 + 2] = boxFeature[2];
      }
    });
    return featureVector;
  }

  // Computes the angle using Random Forest Classifier. It takes in the featureVector and returns the predicted Angle class.
  computeAngle(features) {
    // Calculate the Confidence Scores for each of the Angle class.
    let confidenceScoreArray = score(features);

    // Choose the Angle with the highest confidence value.
    let bestConfidenceIndex = 0;
    for (let i = 1; i < confidenceScoreArray.length; i++)
      if (confidenceScoreArray[i] > confidenceScoreArray[bestConfidenceIndex])
        bestConfidenceIndex = i;

    return this.labels[bestConfidenceIndex];
  }

  /* It validates the angle on the basis of detected parts. This function is used for post processing
           of angle and return the final angle on the basis of some logic. */
  angleValidator(boundingBoxDetections, angle) {
    // Adding all the detected parts to an Array
    let partList = [];
    boundingBoxDetections.forEach((detection) => {
      partList.push(detection["label"]);
    });

    // Logic for Validating the angle

    // For 180 view, any of Back Bumper, Dicky or Back Glass should be present
    if (angle === "180" || angle === "180_Zoom") {
      if (
        partList.includes("back_bumper") ||
        partList.includes("dicky") ||
        partList.includes("back_glass")
      )
        return "180";
      else return "-1";
    }

    // For 0 view, any of Front Bumper, Hood or Front Glass should be present
    if (angle === "0" || angle === "0_Zoom") {
      if (
        partList.includes("front_bumper") ||
        partList.includes("hood") ||
        partList.includes("front_glass")
      )
        return "0";
      else return "-1";
    }

    /* For 90 view, any of Front Door, Back Door or Window Glass should be present and
           Front Glass and Back Glass shouldn't be present. */
    if (angle === "90" || angle === "90_Zoom" || angle === "Window_Zoom") {
      let has90Parts =
        partList.includes("front_door") ||
        partList.includes("back_door") ||
        partList.includes("window_glass");
      let hasFrontBackGlass =
        partList.includes("front_glass") || partList.includes("back_glass");
      if (has90Parts && !hasFrontBackGlass) return "90";
      else return "-1";
    }
    return angle;
  }

  // Returns the Angle of the view. It takes as parameters the Detection Objects.
  getAngleOfFrame(boundingBoxDetections) {
    // if there is no detection, return -1 as the angle.
    if (boundingBoxDetections.length === 0) return -1;

    // Calculate the featureVector from the Detection Objects
    let featureVector = this.getFeatureVector(boundingBoxDetections);

    // Use the featureVector to predict the Angle
    let angle = this.computeAngle(featureVector);

    // Validating the Predicted Angle
    if (
      [
        "0",
        "0_Zoom",
        "180",
        "180_Zoom",
        "90",
        "90_Zoom",
        "Window_Zoom",
      ].includes(angle)
    )
      return parseInt(this.angleValidator(boundingBoxDetections, angle));
    else return parseInt(angle);
  }
}
