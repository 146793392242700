import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { AppContext } from "../../context";
import NavbarP from "./NavBarP";
import {
    addImageToList,
    setLog,
    userLogs,
    patLogs,
    resumeFlowFinalSubmitHandler
} from "../../context/utils";
import cameraImg from "../../img/camera.png";
import cameraGuidanceImg from "../../img/cameraGuidanceImg.jpg";
import Button from "../Button";
import Rotate from "../Rotate";
import white_tick from "../../img/white_tick.png";
import block from "../../img/block.png";
import block_damage from "../../img/block_damage.png";

// import ReactGA from "react-ga4";
let count = 0;
let logBugs = true;
let currentSubBlock = "";
let currentTag = "";
let imageCaptured = false;
let image = {};
let alignment = false;
let mandatoryMissing = [];
let subMissing = [];
let cameraStarted = false;
let track = null;
let reservedData = null;

const MultipleImageSub = () => {
    const {
        stepCovered,
        setStepCovered,
        clientId,
        showAlert,
        condition,
        setCondition,
        setScreen,
        currentBlock,
        showOptions,
        setShowOptions,
        config,
        isLandscape,
        setLandscape,
        mandatory,
        setMandatory,
        subMandatory,
        subMandatorySub,
        setSubMandatory,
        setSubMandatorySub,
        subCompleted,
        setSubCompleted,
        setCurrentPage,
        inspectionId,
        subMandatoryCompleted,
        setSubMandatoryCompleted,
        subCondition,
        setSubCondition,
        setSubConditionSub,
        count1,
        setCount1,
        setCurrentBlock,
        subConditionSub,
        tempStream
    } = useContext(AppContext);

    const land = () => window.innerWidth > window.innerHeight * 1.2;
    const [instructions, setInstructions] = useState(null);
    const [damage, setDamage] = useState(false);
    const [displayBackBtn, setDisplayBackBtn] = useState(true);
    const [colorImg, setColorImg] = useState("");

    window.onresize = () => {
        setLandscape(land());
        if (!land()) {
            if (cameraStarted) startCamera();
        }
    };

    const [reduce, setReduce] = useState(false);
    if (logBugs) {
        patLogs(`Entered to ${currentBlock.tag}`, inspectionId);
        userLogs({
            position: 8,
            last_page: `${currentBlock.tag} module`,
            inspectionId,
        });
    }
    logBugs = false;
    const singleScreen = useRef();
    const videoRef = useRef();
    const canvasRef = useRef();
    const startBtn = useRef();
    const imgRef = useRef();
    const modalRef = useRef();
    const divImgRef = useRef();
    const guidanceRef = useRef();
    const damageRef = useRef();
    const noRef = useRef();
    const yesRef = useRef();
    const blockRef = useRef();

    const startCamera = () => {
        try {
            let interval = setInterval(()=> {
                if(videoRef && videoRef.current)
                {
                    videoRef.current.srcObject = tempStream;
                    clearInterval(interval);
                }
            },1);

            setTimeout(() => {
                guidanceRef.current.style.display = "flex";
            }, 5);
            if (reservedData) {
                divImgRef.current.src = reservedData;
                imgRef.current.style.display = "block";
            }
            cameraStarted = true;
            const elements = document.querySelectorAll('.pingsPort');
            elements.forEach(element => {
                element.style.top = "0px";
                element.style.right = "0px";
                element.style.left = "10px";
            });
        }
        catch (err) {
            console.log(err)
        }
    };

    const captureImg = () => {
        startBtn.current.style.display = "none";
        setDisplayBackBtn(false);
        blockRef.current.style.display = "none"
        //patLogs("capture image button clicked", inspectionId);
        const cnv = canvasRef.current;
        const vw = videoRef.current;
        cnv.width = vw.videoWidth;
        cnv.height = vw.videoHeight;
        cnv.getContext("2d").drawImage(vw, 0, 0);
        const imgData = cnv.toDataURL("image/jpeg");
        divImgRef.current.src = imgData;
        reservedData = imgData;
        let region = Object.keys(config["video-page"]).includes("clientRegion")
            ? config["video-page"]["clientRegion"]
            : "eu";
        image = {
            name: damage ? `${currentTag}-damage.jpg` : `${currentTag}.jpg`,
            imgData: cnv.toDataURL("image/jpeg"),
            tag: damage ? `360 Image: ${currentBlock.tag}: ${currentTag}-damage` : `360 Image: ${currentBlock.tag}: ${currentTag}`,
            web_region: null,
            angle: null,
            region: region,
            videoData: null,
            dialStatus: null,
            currentTag,
        };
        imgRef.current.style.display = "block";
    };

    const imageRetake = () => {
        startBtn.current.style.display = "flex";
        if (!damage) setDisplayBackBtn(true);
        blockRef.current.style.display = "block"
        if (damage) patLogs("retake button clicked to capture damage section image again", inspectionId);
        else patLogs("retake button clicked to capture image again", inspectionId);
        reservedData = null;
        imgRef.current.style.display = "none";
    };

    const goBack = () => {
        if (subCompleted) {
            patLogs("back button clicked", inspectionId);
            modalRef.current.style.display = "flex";
            logBugs = true;
            return;
        }
        cameraStarted = false;
        setSubCompleted(false);
        setSubMandatoryCompleted(false);
        currentSubBlock = "";
        currentTag = "";
        alignment = false;
        // track.stop();
        setCurrentBlock(config["blocks"][0]);
    };

    const removeModal = () => {
        // patLogs(
        //     `user clicked cross button to close warning and move to ${currentBlock.id}`,
        //     inspectionId
        // );
        modalRef.current.style.display = "none";
    };
    const modal = () => {
        if (subCompleted) {
            finsh();
            alignment = false;
            return;
        }
        if (!subMandatoryCompleted || !subCompleted) {
            patLogs(
                `clicked to submit button, Warning given to user,${subMissing.join(
                    ", "
                )} is not completed`,
                inspectionId
            );
            modalRef.current.style.display = "flex";
            return;
        }
    };

    const firstImageNext = () => {

        logBugs = true;
        patLogs(
            `first tick button clicked image sent for uploading... and return to ${currentBlock.name}`,
            inspectionId
        );
        reservedData = null;
        if (imageCaptured) return;
        imgRef.current.style.display = "none";
        let temp2 = subConditionSub;
        temp2[currentBlock.name][currentSubBlock] = "completed";
        setSubConditionSub(temp2);
        currentBlock.values.map((item) => {
            if (item.name === currentSubBlock && item.mandatory) {
                let temp3 = subMandatorySub;
                temp3[currentBlock.name][currentSubBlock] = "completed";
                setSubMandatorySub(temp3);
            }
        });
        setLog(`${currentBlock.tag}: ${currentTag} was captured`);
        addImageToList(image, "No Conversion");
        checkAll();
        setCount1(count1 + 1);
    };

    const imageNext = () => {
        logBugs = true;
        patLogs(
            `tick button clicked image sent for uploading... and return to ${currentBlock.name}`,
            inspectionId
        );
        reservedData = null;
        if (imageCaptured) return;
        cameraStarted = false;
        imgRef.current.style.display = "none";
        imageCaptured = true;
        window.stream = null;
        // track.stop();
        if (!damage) {
            let temp2 = subConditionSub;
            temp2[currentBlock.name][currentSubBlock] = "completed";
            setSubConditionSub(temp2);
            currentBlock.values.map((item) => {
                if (item.name === currentSubBlock && item.mandatory) {
                    let temp3 = subMandatorySub;
                    temp3[currentBlock.name][currentSubBlock] = "completed";
                    setSubMandatorySub(temp3);
                }
            });
        }
        setLog(`${currentBlock.tag}: ${currentTag} damage section was captured`);
        addImageToList(image, "No Conversion");
        checkAll();
        setCount1(count1 + 1);
        const elements = document.querySelectorAll('.pingsPort');

        // Loop through each element and set the styles
        elements.forEach(element => {
            element.style.top = "60px";
            element.style.right = "15px";
            element.style.left = "";
        });
        setShowOptions(true);
        setDamage(false);
        setDisplayBackBtn(true);
        startBtn.current.style.display = "flex";
    };

    const checkAll = () => {
        let tempSubCheck = true;
        let tempMandCheck = true;
        mandatoryMissing = [];
        subMissing = [];
        currentBlock.values.map((item) => {
            if (subConditionSub[currentBlock.name][item.name] === "pending") {
                tempSubCheck = false;
                subMissing.push(item.name);
            }
            if (item.mandatory) {
                if (subMandatorySub[currentBlock.name][item.name] === "pending") {
                    tempMandCheck = false;
                    mandatoryMissing.push(item.name);
                }
            }
        });

        if (tempSubCheck) {
            setSubCompleted(true);
        }
        if (tempMandCheck) {
            setSubMandatoryCompleted(true);
        }
    };

    const back = () => {
        patLogs(
            `back button is clicked and return to ${currentBlock.id}`,
            inspectionId
        );
        currentSubBlock = "";
        currentTag = "";
        imageCaptured = true;
        cameraStarted = false;
        window.stream = null;
        // track.stop();
        const elements = document.querySelectorAll('.pingsPort');
        // Loop through each element and set the styles
        elements.forEach(element => {
            element.style.top = "60px";
            element.style.right = "15px";
            element.style.left = "";
        });
        setShowOptions(true);
    };

    const finsh = () => {
        if (!subMandatoryCompleted) return;
        patLogs(`${currentBlock.name} is completed`, inspectionId);
        cameraStarted = false;
        let temp = subCondition;
        if (currentBlock.mandatory) {
            let temp1 = subMandatory;
            temp1["360 Image"][currentBlock.name] = "completed";
            setSubMandatory(temp1);
        }
        image = {};
        currentSubBlock = "";
        currentTag = "";
        imageCaptured = false;
        temp["360 Image"][currentBlock.name] = "completed";
        setLog(`${currentBlock["tag"]} was finished`);
        setSubCompleted(false);
        setSubMandatoryCompleted(false);
        setSubCondition(temp);
        setCurrentBlock(config["blocks"][0]);
        if (Object.keys(config).includes("resumeFlow")) {
            resumeFlowFinalSubmitHandler("360 Image");
        }
    };

    useEffect(() => {
        setLandscape(land());
        checkAll();
        setCount1(count1 + 1);
        if (currentBlock["values"].length <= 3) {
            alignment = true;
        }
        imageCaptured = true;
        currentBlock["values"].map((e) => {
            if (e["name"].length > 14) {
                setReduce(true);
            }
        });
    }, []);

    // useEffect(() => {
       
    // }, []);

    return (
        <Fragment>
            {isLandscape ? (
                <Rotate />
            ) : showOptions ? (
                <div className="process" style={{ position: "relative", justifyContent: "normal", alignItems: "normal" }}>
                    <NavbarP />
                    <div
                        className="process-steps-wondle"
                        style={{
                            display: "flex",
                            columnGap: window.innerWidth > 419 ? "30px" : "",
                            justifyContent: window.innerWidth <= 389 ? "space-evenly" : window.innerWidth > 389 && window.innerWidth <= 419 ? "space-between" : "",
                        }}
                    >
                        {currentBlock["values"].map((item, index) => {
                            if (
                                subConditionSub[currentBlock.name][item.name] === "completed"
                            ) {
                                return (
                                    <div
                                        className="menu-optionsP"
                                        style={reduce ? { fontSize: "12px" } : null}
                                        key={index}
                                    >
                                        {item["mandatory"] ? (
                                            <h4
                                                style={{
                                                    color: config["colors"]["complete-block"],
                                                    margin: "0px"
                                                }}
                                                key={index}
                                            >
                                                {item["name"]}&#42;
                                            </h4>
                                        ) : (
                                            <h4
                                                style={{
                                                    color: config["colors"]["complete-block"],
                                                    margin: "0px"
                                                }}
                                                key={index}
                                            >
                                                {item["name"]}
                                            </h4>
                                        )}
                                        <div
                                            className="process-vin"
                                            style={{
                                                border: `2px solid ${config["colors"]["complete-block"]}`,
                                                width: "110px"
                                            }}
                                        >
                                            <div className="greenCircleTick">
                                                <img src={white_tick} alt="1232"></img>
                                            </div>
                                            <img src={item["image"]} alt="123"></img>
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className="menu-optionsP"
                                    style={reduce ? { fontSize: "12px" } : null}
                                    key={index}
                                >
                                    {item["mandatory"] ? (
                                        <h4 style={{ margin: "0px" }} key={index}>{item["name"]}&#42;</h4>
                                    ) : (
                                        <h4 style={{ margin: "0px" }} key={index}>{item["name"]}</h4>
                                    )}
                                    <div
                                        className="process-vin"
                                        style={{
                                            border: `1px solid ${config["colors"]["incomplete-block"]}`,
                                            width: "110px"
                                        }}
                                        onClick={async () => {
                                            patLogs(`${currentBlock.tag}: {${item["tag"]} is clicked`, inspectionId);
                                            setColorImg(item["colorImg"]? item["colorImg"] : cameraGuidanceImg)
                                            currentSubBlock = item["name"];
                                            currentTag = item["tag"];
                                            imageCaptured = false;
                                            setShowOptions(false);
                                            startCamera();
                                            Object.keys(item).includes("cameraInstruction") &&
                                                setInstructions(item["cameraInstruction"]);
                                        }}
                                    >
                                        <img src={item["image"]} alt="123"></img>
                                    </div>
                                </div>
                            );
                        })}
                        <div id="menuP-mixed-container">
                            <div style={{ display: "flex", justifyContent: "normal" }} className="InternetWarning">
                                <p>{showAlert && showAlert}</p>
                            </div>
                            <div className="modal-btn-container-multipleImage" style={{ display: "flex", backgroundColor: "#F8F7FC" }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center', width: '100%' }}>
                                    <Button
                                        secoundaryBtn="true"
                                        text={config["multiple-image-page"]["back-btn-text"]}
                                        disabled={subCompleted}
                                        onclick={goBack}
                                        marginDefault="0px 0px 0px 0px"
                                    />
                                    <Button
                                        text={config["multiple-image-page"]["next-btn-text"]}
                                        disabled={!subMandatoryCompleted}
                                        onclick={modal}
                                        marginDefault="0px 25px 0px 25px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        ref={modalRef}
                        className="menu-modal-container"
                        style={{ display: "none" }}
                    >
                        {!subMandatoryCompleted ? (
                            <div className="menu-modal-port">
                                <h3>{config["multiple-image-page"]["mandatory-modal-text"]}</h3>
                                <h4 style={{ color: "red" }}>{mandatoryMissing.join(", ")}</h4>
                                <div id="closeBtn" style={{ marginBottom: "20px" }}>
                                    <Button
                                        text="Close"
                                        onclick={removeModal}
                                        marginDefault="0px 25px 0px 25px"
                                    />
                                </div>
                            </div>
                        ) : subMandatoryCompleted && !subCompleted ? (
                            <div className="menu-modal-port">
                                <h3>{config["multiple-image-page"]["optional-modal-text"]}</h3>
                                <h4>{subMissing.join(", ")}</h4>
                                <div className="modal-btn-container">
                                    <Button
                                        text="Close"
                                        onclick={removeModal}
                                        marginDefault="0px 25px 0px 25px"
                                    />
                                    <Button
                                        text={config["multiple-image-page"]["modal-btn-next"]}
                                        onclick={() => {
                                            setStepCovered([...stepCovered]);
                                            patLogs(
                                                `Warning given to user,${subMissing.join(
                                                    ", "
                                                )} is not completed still user clicked final submit button`,
                                                inspectionId
                                            );
                                            modal();
                                        }}
                                    />
                                </div>
                            </div>
                        ) : subCompleted ? (
                            <div className="menu-modal-port" style={{ height: "20vh" }}>
                                <h3>{config["multiple-image-page"]["back-modal-text"]}</h3>
                                <div id="closeBtn" style={{ marginBottom: "20px" }}>
                                    <Button
                                        text="Close"
                                        onclick={removeModal}
                                        marginDefault="0px 25px 0px 25px"
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : (
                <div id="vin-screen" ref={singleScreen}>
                    <div ref={guidanceRef} id="camera-guidance-modal">
                        <h3>{config["multiple-image-page"]["camera-guidance-text"]}</h3>
                        <img style={{ width: "80vw", height: "20vh" }} src={colorImg}></img>
                        <div className="review-btns" style={{ marginBottom: "10px" }}>
                            <Button
                                text={config["video-page"]["btnText"]}
                                onclick={() => {
                                    guidanceRef.current.style.display = "none"
                                    startBtn.current.style.display = "block";
                                    blockRef.current.style.display = "block"
                                }}
                            ></Button>
                        </div>
                    </div>

                    <div ref={damageRef} id="damage-capture-modal">
                        <h3>{config["multiple-image-page"]["damage-capture-text"]}</h3>
                        <div className="review-btns" style={{ marginBottom: "10px", width: "100%" }}>
                            <Button
                                secoundaryBtn="true"
                                text={config["multiple-image-page"]["damage-modal-yes"]}
                                onclick={() => {
                                    blockRef.current.style.display = "block"
                                    firstImageNext();
                                    damageRef.current.style.display = "none";
                                    imgRef.current.style.display = "none";

                                    noRef.current.style.display = "inline-block";
                                    yesRef.current.style.display = "inline-block";
                                    startBtn.current.style.display = "flex";

                                    setDamage(true);
                                }}
                            ></Button>
                            <Button
                                text={config["multiple-image-page"]["damage-modal-no"]}
                                onclick={() => imageNext()}
                            ></Button>
                        </div>
                    </div>

                    {!damage && <img ref={blockRef} src={"https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/Wondle_portrait_app/block.svg"} alt="" className="rectangle_block" />}

                    {damage && <img ref={blockRef} src={"https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/Wondle_portrait_app/block_damage.svg"} alt="" className="rectangle_block_damage" />}

                    <canvas
                        ref={canvasRef}
                        id="ios-canvas"
                        style={
                            Object.keys(config).includes("aspectRatio")
                                ? {
                                    width: "100vw",
                                    height: "100vh",
                                    objectFit: "contain",
                                    backgroundColor: "black",
                                }
                                : {}
                        }
                    ></canvas>
                    <video
                        ref={videoRef}
                        id="videoWindow"
                        style={
                            Object.keys(config).includes("aspectRatio")
                                ? {
                                    width: "100vw",
                                    height: "100vh",
                                    objectFit: "contain",
                                    backgroundColor: "black",
                                }
                                : {}
                        }
                        autoPlay
                        playsInline
                    ></video>
                    <div
                        ref={startBtn}
                        style={{
                            backgroundImage: `url(${cameraImg})`,
                            backgroundSize: "37px 37px",
                            backgroundRepeat: "no-repeat",
                            objectFit: "cover",
                            backgroundPosition: "center"
                        }}
                        onClick={captureImg}
                        id="port-btn-vin-start"
                    ></div>
                    <div style={{ zIndex: "2", display: displayBackBtn ? "" : "none" }} className="back-btn">
                        <i className="fas fa-arrow-left fa-2x" onClick={back}></i>
                    </div>
                    {instructions && (
                        <div className="camerInst">
                            <p>{instructions}</p>
                        </div>
                    )}
                    <div
                        id="vin-image"
                        style={
                            Object.keys(config).includes("aspectRatio")
                                ? { backgroundColor: "black", objectFit: "contain" }
                                : {}
                        }
                        ref={imgRef}
                    >
                        <img
                            ref={divImgRef}
                            style={
                                Object.keys(config).includes("aspectRatio")
                                    ? { objectFit: "contain" }
                                    : { width: "100vw", height: "100vh" }
                            }
                        />
                        <i
                            className="fas fa-times fa-2x vin-btn-retake"
                            ref={noRef}
                            style={{ bottom: "20%", backgroundColor: config["colors"]["btn"] }}
                            onClick={imageRetake}
                        ></i>
                        <i
                            className="fas fa-check fa-2x vin-btn-next"
                            ref={yesRef}
                            style={{ bottom: "20%", backgroundColor: config["colors"]["btn"] }}
                            onClick={() => {
                                if (damage) {
                                    imageNext();
                                } else {
                                    damageRef.current.style.display = "flex";
                                    noRef.current.style.display = "none";
                                    yesRef.current.style.display = "none";
                                }
                            }}
                        ></i>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default MultipleImageSub;
