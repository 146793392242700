import React, { useEffect } from "react";

const JarvisTracking = ({ URL, clientId, customerHash }) => {
  useEffect(() => {
    if (customerHash) {
      localStorage.setItem("customerHash", customerHash);
    }
    const loadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    const meta = document.createElement("meta");
    meta.name = "tdl-sso-client_id";
    meta.content = clientId;
    document.head.appendChild(meta);

    loadScript(URL);
  }, [URL, clientId, customerHash]);

  return null;
};

export default JarvisTracking;
