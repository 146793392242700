import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../context";
import { addImageToList, setLog, userLogs, patLogs , } from "../context/utils";
import camera from "../img/camera.png";
import Button from "./Button";
import Rotate from "./Rotate";
// import ReactGA from "react-ga4";

let image = {};
let currentSubBlock = "";
let currentSubObject = {};
let currentTag = "";
let imageCaptured = false;
let mandatoryMissing = [];
let subMissing = [];
let cameraStarted = false;
let track = null;

const Zeroapp = () => {
    const {
    stepCovered,
    setStepCovered,
    showAlert,
    showZeroCamera,
    setShowZeroCamera,
    previewZeroImg,
    setPreviewZeroImg,
    currentBlock,
    setCondition,
    setScreen,
    mandatory,
    setMandatory,
    subCondition,
    subMandatory,
    isLandscape,
    setLandscape,
    setSubCompleted,
    setSubMandatoryCompleted,
    setCount1,
    count1,
    config,
    setSubCondition,
    setSubMandatory,
    subCompleted,
    subMandatoryCompleted,
    condition,
    inspectionId,
  } = useContext(AppContext);

  
  const constraints = {
    video: Object.keys(config).includes("aspectRatio") ?
    {
      width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
      height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
      facingMode: "environment",
      aspectRatio: 4 / 3,
      zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 }
   } : {
     width: Object.keys(config).includes("resolution") ? config["resolution"]["width"] : 1920,
     height: Object.keys(config).includes("resolution") ? config["resolution"]["height"] : 1080,
     facingMode: "environment",
     zoom: { ideal: Object.keys(config).includes("zoom") ? config["zoom"] ? 0.5 : 1 : 0.5 }
   },
   audio: false,
  };

  const videoRef = useRef();
  const canvasRef = useRef();
  const modalRef = useRef();
  const startBtnRef = useRef();
  // ReactGA.send({
  //   hitType: "pageview",
  //   page: "Zeroapp page",
  //   title: "Zeroapp page",
  // });
  userLogs({ position: 8, last_page: "zero module", inspectionId });
  patLogs("Entered to zeroapp module", inspectionId);
  const land = () => window.innerWidth > window.innerHeight * 1.2;

  window.onresize = () => {
    setLandscape(land());
    if (cameraStarted) startCamera(currentSubObject);
  };

  const addImage = () => {
    if (imageCaptured) return;
    cameraStarted = false;
    imageCaptured = true;
    let temp2 = subCondition;
    temp2[currentBlock.id][currentSubBlock] = "completed";
    setSubCondition(temp2);

    currentBlock.values.map((item) => {
      if (item.name === currentSubBlock && item.mandatory) {
        let temp3 = subMandatory;
        temp3[currentBlock.id][currentSubBlock] = "completed";
        setSubMandatory(temp3);
      }
    });
    setLog(`${currentTag} was captured`);
    track.stop();
    window.stream = null;
    addImageToList(image);
    checkAll();
    setCount1(count1 + 1);
    setShowZeroCamera(false);
    setPreviewZeroImg(null);
  };

  const startCamera = (v) => {
    cameraStarted = true;
    currentSubBlock = v.name;
    currentTag = v.tag;
    currentSubObject = v;
    imageCaptured = false;
    setShowZeroCamera(v.name);
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      window.stream = stream;
      track = stream.getTracks()[0];
      videoRef.current.srcObject = stream;
      setTimeout(() => {
        startBtnRef.current.style.display = "block";
      }, 500);
    });
  };

  const back = () => {
    currentSubBlock = "";
    currentSubObject = {};
    currentTag = "";
    cameraStarted = false;
    imageCaptured = true;
    track.stop();
    window.stream = null;
    setShowZeroCamera(false);
  };

  const goBack = () => {
    if (subCompleted) {
      modalRef.current.style.display = "flex";
      return;
    }
    cameraStarted = false;
    setSubCompleted(false);
    setSubMandatoryCompleted(false);
    currentSubBlock = "";
    currentSubObject = {};
    currentTag = "";
    setScreen("menu");
  };

  const removeModal = () => {
    modalRef.current.style.display = "none";
  };

  const modal = () => {
    if (subCompleted) {
      cameraStarted = false;
      finish();
      return;
    }
    if (!subMandatoryCompleted || !subCompleted) {
      modalRef.current.style.display = "flex";
      return;
    }
  };

  const finish = () => {
    if (!subMandatoryCompleted) return;
    cameraStarted = false;
    let temp = condition;
    if (currentBlock.mandatory) {
      let temp1 = mandatory;
      temp1[currentBlock.id] = "completed";
      setMandatory(temp1);
    }
    image = {};
    currentSubBlock = "";
    currentSubObject = {};
    currentTag = "";
    imageCaptured = false;
    temp[currentBlock.id] = "completed";
    setLog(`${currentBlock["id"]} was finished`);
    setSubCompleted(false);
    setSubMandatoryCompleted(false);
    setCondition(temp);
    setPreviewZeroImg(null);
    setScreen("menu");
  };

  const takePicture = () => {
    const video = videoRef.current;
    const cnv = canvasRef.current;
    cnv.width = video.videoWidth;
    cnv.height = video.videoHeight;
    cnv.getContext("2d").drawImage(video, 0, 0);
    const imgData = cnv.toDataURL("image/jpeg");
    let region = Object.keys(config["video-page"]).includes("clientRegion")
      ? config["video-page"]["clientRegion"]
      : "eu";
    image = {
      name: `${currentTag}.jpg`,
      imgData: cnv.toDataURL("image/jpeg"),
      tag: `${currentBlock.tag}: ${currentTag}`,
      web_region: null,
      angle: null,
      region: region,
      videoData: null,
      dialStatus: null,
    };
    setPreviewZeroImg({ id: showZeroCamera, imgData });
  };

  const checkAll = () => {
    let tempSubCheck = true;
    let tempMandCheck = true;
    mandatoryMissing = [];
    subMissing = [];
    currentBlock.values.map((item) => {
      if (subCondition[currentBlock.id][item.name] === "pending") {
        tempSubCheck = false;
        subMissing.push(item.name);
      }
      if (item.mandatory) {
        if (subMandatory[currentBlock.id][item.name] === "pending") {
          tempMandCheck = false;
          mandatoryMissing.push(item.name);
        }
      }
    });

    if (tempSubCheck) {
      setSubCompleted(true);
    }
    if (tempMandCheck) {
      setSubMandatoryCompleted(true);
    }
  };

  useEffect(() => {
    setLandscape(land());
    checkAll();
    setCount1(count1 + 1);
    imageCaptured = true;
  }, []);

  return (
    <div className="zero-container">
      {!isLandscape ? (
        <Rotate />
      ) : showZeroCamera ? (
        <>
          <video
            ref={videoRef}
            className="zero-video"
            style= {Object.keys(config).includes("aspectRatio") 
            ? {objectFit: "contain", backgroundColor: "black"} 
            : {}}
            autoPlay
            playsInline
          ></video>
          <div ref={startBtnRef} className="zero-button-click">
            <div
              onClick={takePicture}
              style={{
                backgroundImage: `url(${camera})`,
                backgroundSize: "37px 37px",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundPosition: "center",
                width: "50px",
                height: "50px",
              }}
            ></div>
          </div>
          <div className="back-btn">
            <i className="fas fa-arrow-left fa-2x" onClick={back}></i>
          </div>
          <canvas ref={canvasRef} className="zero-canvas"></canvas>
          {previewZeroImg && (
            <div className="zero-preview">
              <img
                className="zero-previewImg zero-img"
                src={previewZeroImg.imgData}
                style= {Object.keys(config).includes("aspectRatio") 
                ? {objectFit: "contain", backgroundColor: "black"} 
                : {}}
              />
              <div className="zero-previewBtn">
                <div
                  className="fas fa-times fa-2x vin-btn-retake"
                  onClick={() => setPreviewZeroImg(null)}
                ></div>
                <div
                  className="fas fa-check fa-2x vin-btn-next"
                  onClick={addImage}
                ></div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="zero-container-div" style={{ display: "flex" }}>
            {currentBlock.values.map((v, i) => {
              if (i <= currentBlock["values"].length / 2 - 1) {
                return (
                  <div key={i} className="zero-main-div">
                    {" "}
                    <div
                      className="zero-item"
                      style={
                        subCondition[currentBlock.id][v.name] === "completed"
                          ? {
                              borderColor: config["colors"]["complete-block"],
                              pointerEvents: "none",
                            }
                          : {
                              borderColor: config["colors"]["incomplete-block"],
                            }
                      }
                      onClick={() => startCamera(v)}
                    >
                      <img
                        className="zero-img"
                        src={v["image"]}
                        alt="image not found"
                      />
                    </div>
                    {v.mandatory ? (
                      <div
                        className="zero-div-btn"
                        onClick={() => startCamera(v)}
                        style={
                          subCondition[currentBlock.id][v.name] === "completed"
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        {v.name}&#42;
                      </div>
                    ) : (
                      <div
                        className="zero-div-btn"
                        onClick={() => startCamera(v)}
                        style={
                          subCondition[currentBlock.id][v.name] === "completed"
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        {v.name}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
          <div className="zero-container-div" style={{ display: "flex" }}>
            {currentBlock.values.map((v, i) => {
              if (i > currentBlock["values"].length / 2 - 1) {
                return (
                  <div className="zero-main-div" key={i}>
                    {v.mandatory ? (
                      <div
                        className="zero-div-btn"
                        onClick={() => startCamera(v)}
                        style={
                          subCondition[currentBlock.id][v.name] === "completed"
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        {v.name}&#42;
                      </div>
                    ) : (
                      <div
                        className="zero-div-btn"
                        onClick={() => startCamera(v)}
                        style={
                          subCondition[currentBlock.id][v.name] === "completed"
                            ? { pointerEvents: "none" }
                            : null
                        }
                      >
                        {v.name}
                      </div>
                    )}
                    <div
                      className="zero-item"
                      style={
                        subCondition[currentBlock.id][v.name] === "completed"
                          ? {
                              borderColor: config["colors"]["complete-block"],
                              pointerEvents: "none",
                            }
                          : {
                              borderColor: config["colors"]["incomplete-block"],
                            }
                      }
                      onClick={() => startCamera(v)}
                    >
                      <img
                        className="zero-img"
                        src={v["image"]}
                        alt="image not found"
                      />
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="InternetWarning">
            <p>{showAlert && showAlert}</p>
          </div>
          <div
            className="modal-btn-container"
            style={{ display: "flex", marginRight: "100px" }}
          >
            <Button
              text={config["multiple-image-page"]["back-btn-text"]}
              disabled={subCompleted}
              onclick={goBack}
            />
            <Button
              text={config["multiple-image-page"]["next-btn-text"]}
              disabled={!subMandatoryCompleted}
              onclick={modal}
            />
          </div>
          <div
            ref={modalRef}
            className="menu-modal-container"
            style={{ display: "none" }}
          >
            {!subMandatoryCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["mandatory-modal-text"]}</h4>
                <h4>{mandatoryMissing.join(", ")}</h4>
              </div>
            ) : subMandatoryCompleted && !subCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["optional-modal-text"]}</h4>
                <h4>{subMissing.join(", ")}</h4>
                <div className="modal-btn-container">
                  <Button
                    text={config["multiple-image-page"]["modal-btn-next"]}
                    onclick={finish}
                  />
                </div>
              </div>
            ) : subCompleted ? (
              <div className="menu-modal">
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["multiple-image-page"]["back-modal-text"]}</h4>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default Zeroapp;
