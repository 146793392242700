import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../context";
import Button from "./Button";
import Rotate from "./Rotate";
import { addImageToList } from "../context/utils";

let count = 0;

const ImageUpload = () => {
  const {
    setLandscape,
    setScreen,
    isLandscape,
    currentBlock,
    storedImage,
    setStoredImage,
    currentImage,
    setCurrentImage,
    setMandatory,
    setCondition,
    condition,
    mandatory
  } = useContext(AppContext);

  const fileInputRef = useRef(null);
  const popupRef = useRef(null);
  const waringpopuup = useRef(null)

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [errorMssg, setError] = useState("")
  window.onresize = () => {
    setLandscape(land());
    setStoredImage(storedImage);
    // console.log(storedImage);
  };

  const handleFiles = async (event) => {
    const files = event.target.files;
    const maxCount = currentBlock["count"];
    const remainingSlots = maxCount - storedImage.length;

    if (files.length > 0 && remainingSlots > 0) {
      const filesToUpload = Array.from(files).slice(0, remainingSlots);

      for (const file of filesToUpload) {
        if (!file.type.startsWith("image/")) {
          console.error("Invalid file type. Only images are allowed.");
          continue;
        }

        const imageFile = await convertToJpegBase64(file);
        if (imageFile) {
          setStoredImage((prevImages) => [
            ...prevImages,
            { imgData: imageFile, id: count },
          ]);
          count += 1;
          // console.log(imageFile);
        }
      }
    }
  };


  const convertToJpegBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          //if (img.width < 1920 || img.height < 1080) {
          // if (img.width < 2000 && img.height < 2000) {   //sanchit sir ask to enter 2000 as a raw
          //   setError("The image resolution is too high. The image must be in landscape orientation and its resolution must be under 2000p.")
          //   setTimeout(()=>{setError("")},5000)
          //   resolve(false); 
          //   return;
          // }

          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height);

          const jpegBase64 = canvas.toDataURL("image/jpeg", 1);
          resolve(jpegBase64);
        };

        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const openPopUp = (e) => {
    setCurrentImage(e);
    if (popupRef.current) {
      popupRef.current.style.display = "flex";
    }
  };

  const removeImage = () => {
    if (popupRef.current) {
      popupRef.current.style.display = "none";
    }
    setStoredImage((prevImages) =>
      prevImages.filter((item) => item.id !== currentImage.id)
    );
  };

  const finish = () => {
    storedImage.map((e) => {
      const image = {
        name: `${currentBlock["tag"]}.jpg`,
        imgData: e.imgData,
        tag: currentBlock["tag"],
        web_region: null,
        angle: null,
        region: "eu",
        videoData: null,
        dialStatus: null,
      };
      addImageToList(image);
    });

    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };
  

  return (
    <>
      <div className="uploadMainDiv">
        {!isLandscape && (
          <div style={{position: "absolute", top: "0px", left: "0px", width: "100dvw", height: "100dvh", backgroundColor: "white", zIndex: 1}}>
            <Rotate />
          </div>
        )}
        <div className="ImageDiv">
          {storedImage.map((e) => (
            <img
              key={e.id}
              src={e.imgData}
              alt={`Uploaded ${e.id}`}
              style={{
                height: "80px",
                width: "130px",
                margin: "10px",
                borderRadius: "5px",
                border: "1px solid black",
              }}
              onClick={() => openPopUp(e)}
            />
          ))}
        </div>
        <div className="errormssg">{errorMssg.length > 0 && <p style={{color: "red", fontWeight: "bold"}}>{errorMssg}</p>}</div>
        <div className="buttonDiv">
          <Button
            secondaryBtn="true"
            text={"Back"}
            disabled={false}
            onclick={() => {
              setScreen("menu");
            }}
            marginDefault="0px 0px 0px 0px"
          />
          <Button
            text={"Add Images"}
            disabled={storedImage.length >= currentBlock["count"]}
            onclick={openFilePicker}
            marginDefault="0px 0px 0px 0px"
          />
          <Button
            text={"Upload"}
            disabled={storedImage.length === 0}
            onclick={finish}
            marginDefault="0px 0px 0px 0px"
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFiles}
            accept="image/*"
            multiple
            style={{ display: "none" }}
            disabled={storedImage.length >= currentBlock["count"]}
          />
        </div>
        <div ref={popupRef} className="uploadPopup">
          {currentImage.imgData && (
            <img
              src={currentImage.imgData}
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
              }}
              alt="Preview"
            />
          )}
          <div className="popupDiv">
            <Button
              text={"Back"}
              disabled={false}
              onclick={() => {
                if (popupRef.current) popupRef.current.style.display = "none";
              }}
              marginDefault="0px 0px 0px 0px"
            />
            <Button
              text={"Remove"}
              disabled={false}
              onclick={removeImage}
              marginDefault="0px 0px 0px 0px"
            />
          </div>
        </div>
        <div ref={waringpopuup} className="waringpopuup">
          <div className="waringpopuupdiv">
            <p style={{fontWeight: "bold", textAlign: "center"}}>You have to upload the front and back of the RC.</p>
            <Button
            text={"Continue"}
            disabled={storedImage.length >= currentBlock["count"]}
            onclick={()=>{
              waringpopuup.current.style.display = "none"
            }}
            marginDefault="0px 0px 0px 0px"
          />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
