export function score(input) {
  var var0;
  if (input[9] <= 0.14580000191926956) {
    if (input[4] <= 0.14484314620494843) {
      if (input[19] <= 0.2919843718409538) {
        if (input[32] <= 0.08923611044883728) {
          var0 = [
            0.2717391304347826, 0.09782608695652174, 0.18478260869565216, 0.0,
            0.0, 0.0036231884057971015, 0.010869565217391304, 0.0, 0.0, 0.0,
            0.0036231884057971015, 0.0, 0.13768115942028986,
            0.17028985507246377, 0.07971014492753623, 0.014492753623188406,
            0.025362318840579712, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.006920415224913495, 0.0, 0.0,
            0.0034602076124567475, 0.006920415224913495, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.020761245674740483, 0.20761245674740483, 0.1384083044982699,
            0.08996539792387544, 0.1245674740484429, 0.2975778546712803,
            0.02422145328719723, 0.07958477508650519,
          ];
        }
      } else {
        if (input[20] <= 0.33887386322021484) {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.0, 0.25, 0.0, 0.5, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.23055554926395416) {
        if (input[31] <= 0.04472656175494194) {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07653061224489796,
            0.1836734693877551, 0.20408163265306123, 0.5255102040816326,
            0.01020408163265306, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.14285714285714285, 0.21428571428571427,
            0.2857142857142857, 0.09523809523809523, 0.2619047619047619, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[37] <= 0.3379059433937073) {
          var0 = [
            0.0, 0.0, 0.0, 0.40476190476190477, 0.16666666666666666,
            0.21428571428571427, 0.14285714285714285, 0.047619047619047616, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.023809523809523808,
            0.0, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.055900621118012424, 0.09937888198757763,
            0.15527950310559005, 0.5279503105590062, 0.12422360248447205, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.037267080745341616,
            0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[30] <= 0.04879999905824661) {
      if (input[22] <= 0.04473039135336876) {
        if (input[36] <= 0.03960000071674585) {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.16666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16666666666666666, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var0 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[36] <= 0.01334999967366457) {
        if (input[10] <= 0.19271627068519592) {
          var0 = [
            0.0, 0.0, 0.0, 0.6666666666666666, 0.3333333333333333, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.021739130434782608, 0.967391304347826,
            0.010869565217391304,
          ];
        }
      } else {
        if (input[10] <= 0.3345703035593033) {
          var0 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var0 = [
            0.0, 0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var1;
  if (input[9] <= 0.1306999996304512) {
    if (input[17] <= 0.01736111007630825) {
      if (input[19] <= 0.06464843451976776) {
        if (input[8] <= 0.41551388800144196) {
          var1 = [
            0.017241379310344827, 0.0, 0.1724137931034483, 0.08620689655172414,
            0.10344827586206896, 0.08620689655172414, 0.017241379310344827,
            0.05172413793103448, 0.0, 0.017241379310344827, 0.08620689655172414,
            0.0, 0.017241379310344827, 0.034482758620689655,
            0.017241379310344827, 0.0, 0.0, 0.0, 0.017241379310344827, 0.0,
            0.27586206896551724,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.05303030303030303, 0.0, 0.0, 0.015151515151515152,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007575757575757576,
            0.030303030303030304, 0.8333333333333334, 0.022727272727272728,
            0.03787878787878788,
          ];
        }
      } else {
        if (input[25] <= 0.07558593899011612) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.010101010101010102, 0.0,
            0.05555555555555555, 0.1717171717171717, 0.5, 0.26262626262626265,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.05472636815920398, 0.0845771144278607,
            0.1691542288557214, 0.3781094527363184, 0.14925373134328357,
            0.15920398009950248, 0.004975124378109453, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.014150000177323818) {
        if (input[41] <= 0.33472222089767456) {
          var1 = [
            0.3440366972477064, 0.15137614678899083, 0.1926605504587156, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.23394495412844038,
            0.05504587155963303, 0.01834862385321101, 0.0045871559633027525,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6875, 0.0625, 0.125, 0.125, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07909999787807465) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1588235294117647, 0.48823529411764705, 0.21176470588235294,
            0.08235294117647059, 0.058823529411764705, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09090909090909091, 0.3333333333333333,
            0.5757575757575758, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[38] <= 0.1614583283662796) {
      if (input[6] <= 0.10034999996423721) {
        if (input[41] <= 0.31041666865348816) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.6666666666666666, 0.0, 0.3333333333333333, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[41] <= 0.856007993221283) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.03, 0.97, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.42857142857142855, 0.5714285714285714,
            0.0,
          ];
        }
      }
    } else {
      if (input[10] <= 0.33085937798023224) {
        if (input[4] <= 0.6257812678813934) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[37] <= 0.2353515625) {
          var1 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var1 = [
            0.0, 0.0, 0.0, 0.2222222222222222, 0.2222222222222222,
            0.4444444444444444, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.1111111111111111, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var2;
  if (input[15] <= 0.01269999984651804) {
    if (input[21] <= 0.04574999958276749) {
      if (input[26] <= 0.10555555671453476) {
        if (input[30] <= 0.040300000458955765) {
          var2 = [
            0.014150943396226415, 0.0, 0.03773584905660377, 0.0, 0.0, 0.0,
            0.02830188679245283, 0.0047169811320754715, 0.04716981132075472,
            0.22169811320754718, 0.41509433962264153, 0.22641509433962265,
            0.0047169811320754715, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.7777777777777778,
            0.2222222222222222,
          ];
        }
      } else {
        if (input[6] <= 0.03595000132918358) {
          var2 = [
            0.0, 0.0, 0.0, 0.0625, 0.03365384615384615, 0.09134615384615384,
            0.46153846153846156, 0.1971153846153846, 0.14423076923076922,
            0.009615384615384616, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var2 = [
            0.0, 0.0, 0.0, 0.18269230769230768, 0.23076923076923078,
            0.33653846153846156, 0.0673076923076923, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.019230769230769232,
            0.16346153846153846, 0.0,
          ];
        }
      }
    } else {
      if (input[22] <= 0.10508035868406296) {
        var2 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
        ];
      } else {
        if (input[40] <= 0.1222224272787571) {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.11764705882352941, 0.058823529411764705,
            0.23529411764705882, 0.5294117647058824, 0.058823529411764705,
          ];
        } else {
          var2 = [
            0.0, 0.0, 0.0, 0.009259259259259259, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.018518518518518517, 0.009259259259259259, 0.0, 0.0,
            0.009259259259259259, 0.9074074074074074, 0.046296296296296294, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[21] <= 0.034300001338124275) {
      if (input[17] <= 0.32630544900894165) {
        if (input[15] <= 0.12894999980926514) {
          var2 = [
            0.55, 0.0, 0.21666666666666667, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.20833333333333334, 0.0, 0.025, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var2 = [
            0.06666666666666667, 0.0, 0.7333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        var2 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[15] <= 0.053549999371171) {
        if (input[10] <= 0.0341796875) {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.03636363636363636, 0.21818181818181817, 0.3090909090909091,
            0.43636363636363634, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[1] <= 0.5493106842041016) {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.2463768115942029, 0.3188405797101449, 0.08695652173913043,
            0.13043478260869565, 0.07246376811594203, 0.14492753623188406, 0.0,
            0.0, 0.0,
          ];
        } else {
          var2 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.047619047619047616, 0.14965986394557823, 0.4217687074829932,
            0.1836734693877551, 0.1360544217687075, 0.061224489795918366, 0.0,
            0.0, 0.0,
          ];
        }
      }
    }
  }
  var var3;
  if (input[29] <= 0.09409721940755844) {
    if (input[3] <= 0.06590000167489052) {
      if (input[22] <= 0.10508035868406296) {
        if (input[41] <= 0.28808334469795227) {
          var3 = [
            0.0, 0.19333333333333333, 0.0, 0.02, 0.02666666666666667, 0.0,
            0.006666666666666667, 0.0, 0.006666666666666667, 0.0,
            0.04666666666666667, 0.36, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.23333333333333334, 0.10666666666666667,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.021739130434782608, 0.021739130434782608, 0.0,
            0.06521739130434782, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.043478260869565216, 0.8478260869565217, 0.0,
          ];
        }
      } else {
        if (input[16] <= 0.1328125) {
          var3 = [
            0.0, 0.0, 0.0, 0.03937007874015748, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.023622047244094488,
            0.8346456692913385, 0.08661417322834646, 0.015748031496062992,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.1111111111111111, 0.8888888888888888, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.22812500596046448) {
        if (input[4] <= 0.3111328184604645) {
          var3 = [
            0.0, 0.0, 0.0, 0.0, 0.8888888888888888, 0.0, 0.1111111111111111,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.024390243902439025, 0.03414634146341464,
            0.03902439024390244, 0.014634146341463415, 0.09268292682926829,
            0.18048780487804877, 0.23414634146341465, 0.37073170731707317,
            0.00975609756097561, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.04884999990463257) {
          var3 = [
            0.0, 0.0, 0.0, 0.6, 0.2, 0.14, 0.06, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.030864197530864196, 0.08024691358024691,
            0.19135802469135801, 0.5246913580246914, 0.15432098765432098,
            0.018518518518518517, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.13958333432674408) {
      if (input[17] <= 0.18850000202655792) {
        if (input[27] <= 0.24610000103712082) {
          var3 = [
            0.12195121951219512, 0.0, 0.4634146341463415, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.4146341463414634, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var3 = [
            0.32, 0.0, 0.68, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[29] <= 0.5434561967849731) {
          var3 = [
            0.75, 0.0, 0.16176470588235295, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.058823529411764705, 0.0, 0.029411764705882353, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var3 = [
            0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.75,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.044600000604987144) {
        if (input[1] <= 0.5950314402580261) {
          var3 = [
            0.0, 0.0, 0.0423728813559322, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.2288135593220339, 0.2711864406779661,
            0.1016949152542373, 0.13559322033898305, 0.09322033898305085,
            0.11016949152542373, 0.01694915254237288, 0.0, 0.0,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05747126436781609, 0.1724137931034483, 0.6896551724137931,
            0.04597701149425287, 0.011494252873563218, 0.022988505747126436,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.677734375) {
          var3 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.015625, 0.140625, 0.296875, 0.359375, 0.1875, 0.0, 0.0, 0.0,
          ];
        } else {
          var3 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.25, 0.125, 0.625, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var4;
  if (input[16] <= 0.12558594346046448) {
    if (input[5] <= 0.49297480285167694) {
      if (input[6] <= 0.12164999917149544) {
        if (input[11] <= 0.36698611080646515) {
          var4 = [
            0.017857142857142856, 0.0, 0.017857142857142856,
            0.03571428571428571, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05357142857142857, 0.6071428571428571, 0.017857142857142856, 0.0,
            0.07142857142857142, 0.0, 0.0, 0.0, 0.03571428571428571, 0.0,
            0.14285714285714285,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.03, 0.06, 0.0, 0.02, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.01, 0.87, 0.01, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.30790000408887863) {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.017699115044247787, 0.0, 0.061946902654867256,
            0.9203539823008849, 0.0,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.031149999238550663) {
        if (input[36] <= 0.022400000132620335) {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.10144927536231885,
            0.10144927536231885, 0.10144927536231885, 0.6811594202898551,
            0.014492753623188406, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.016129032258064516, 0.0, 0.08064516129032258,
            0.14516129032258066, 0.1935483870967742, 0.3870967741935484,
            0.12903225806451613, 0.04838709677419355, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.06720000132918358) {
          var4 = [
            0.0, 0.0, 0.0, 0.37777777777777777, 0.25555555555555554,
            0.08888888888888889, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.17777777777777778, 0.0, 0.0,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.03773584905660377, 0.06289308176100629,
            0.13836477987421383, 0.6415094339622641, 0.09433962264150944,
            0.025157232704402517, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.02187499962747097) {
      if (input[17] <= 0.3370693325996399) {
        if (input[33] <= 0.025299999862909317) {
          var4 = [
            0.4701492537313433, 0.0, 0.23880597014925373, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11940298507462686, 0.06716417910447761,
            0.05223880597014925, 0.014925373134328358, 0.014925373134328358,
            0.022388059701492536, 0.0, 0.0, 0.0,
          ];
        } else {
          var4 = [
            0.036585365853658534, 0.0, 0.25609756097560976, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.35365853658536583, 0.14634146341463414,
            0.07317073170731707, 0.06097560975609756, 0.036585365853658534,
            0.036585365853658534, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var4 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[7] <= 0.34617967903614044) {
        if (input[6] <= 0.0722000002861023) {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.15853658536585366, 0.7682926829268293, 0.06097560975609756,
            0.012195121951219513, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.06896551724137931, 0.13793103448275862, 0.5172413793103449,
            0.27586206896551724, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.07819999754428864) {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.045454545454545456, 0.0, 0.20454545454545456, 0.25, 0.5, 0.0, 0.0,
            0.0,
          ];
        } else {
          var4 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07407407407407407, 0.25925925925925924, 0.35185185185185186,
            0.2777777777777778, 0.037037037037037035, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var5;
  if (input[9] <= 0.1339999958872795) {
    if (input[4] <= 0.14484314620494843) {
      if (input[19] <= 0.29221754521131516) {
        if (input[17] <= 0.02187499962747097) {
          var5 = [
            0.014388489208633094, 0.0, 0.02877697841726619, 0.03597122302158273,
            0.0, 0.0, 0.02158273381294964, 0.0, 0.0, 0.0, 0.007194244604316547,
            0.0, 0.0, 0.007194244604316547, 0.007194244604316547, 0.0,
            0.02158273381294964, 0.02877697841726619, 0.6906474820143885,
            0.03597122302158273, 0.10071942446043165,
          ];
        } else {
          var5 = [
            0.12944162436548223, 0.06091370558375635, 0.10913705583756345, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.09644670050761421,
            0.11421319796954314, 0.2131979695431472, 0.10152284263959391,
            0.1065989847715736, 0.06852791878172589, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[19] <= 0.5957341194152832) {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[25] <= 0.07558593899011612) {
        if (input[19] <= 0.4009765535593033) {
          var5 = [
            0.0, 0.0, 0.0, 0.05, 0.0, 0.0, 0.05, 0.0, 0.15, 0.6, 0.1, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.05, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007246376811594203, 0.0,
            0.028985507246376812, 0.26811594202898553, 0.6811594202898551,
            0.014492753623188406, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.05253906175494194) {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.06896551724137931, 0.1206896551724138,
            0.3275862068965517, 0.4482758620689655, 0.034482758620689655, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.13983050847457626, 0.0847457627118644,
            0.19491525423728814, 0.4915254237288136, 0.05084745762711865,
            0.012711864406779662, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.025423728813559324, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[4] <= 0.10976562649011612) {
      if (input[24] <= 0.1542000025510788) {
        if (input[30] <= 0.049949999898672104) {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.8571428571428571, 0.14285714285714285,
            0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.008, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.992, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.3439542353153229) {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[36] <= 0.028599999845027924) {
        if (input[5] <= 0.7454861104488373) {
          var5 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.20468749850988388) {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var5 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var6;
  if (input[27] <= 0.02215000055730343) {
    if (input[6] <= 0.11694999784231186) {
      if (input[4] <= 0.14484314620494843) {
        if (input[18] <= 0.10040000081062317) {
          var6 = [
            0.0, 0.18072289156626506, 0.0, 0.024096385542168676,
            0.030120481927710843, 0.012048192771084338, 0.018072289156626505,
            0.018072289156626505, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.006024096385542169, 0.018072289156626505, 0.6265060240963856, 0.0,
            0.06626506024096386,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.056338028169014086, 0.0,
            0.014084507042253521, 0.9295774647887324, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.014700000174343586) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04487179487179487, 0.0,
            0.0641025641025641, 0.1987179487179487, 0.6538461538461539,
            0.03205128205128205, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00641025641025641, 0.0, 0.0,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.0, 0.11870503597122302, 0.1079136690647482,
            0.1474820143884892, 0.381294964028777, 0.1079136690647482,
            0.10431654676258993, 0.01079136690647482, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.02158273381294964, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.872906506061554) {
        if (input[11] <= 0.3932499885559082) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.0, 0.75,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.009345794392523364, 0.04672897196261682,
            0.9439252336448598, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.632600873708725) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[21] <= 0.02710000053048134) {
      if (input[22] <= 0.115234375) {
        if (input[35] <= 0.4919999986886978) {
          var6 = [
            0.275, 0.05, 0.575, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var6 = [
            0.6506024096385542, 0.0, 0.2289156626506024, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.08433734939759036, 0.0,
            0.03614457831325301, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.4556640684604645) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var6 = [
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.037550000473856926) {
        if (input[17] <= 0.21159187704324722) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.13725490196078433, 0.4215686274509804, 0.16666666666666666,
            0.13725490196078433, 0.08823529411764706, 0.049019607843137254, 0.0,
            0.0, 0.0,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.013888888888888888, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09722222222222222, 0.1388888888888889,
            0.7222222222222222, 0.0, 0.013888888888888888, 0.013888888888888888,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[15] <= 0.0648999996483326) {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.03125, 0.140625, 0.265625, 0.53125, 0.03125, 0.0, 0.0,
          ];
        } else {
          var6 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.16216216216216217, 0.6486486486486487, 0.16216216216216217,
            0.02702702702702703, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var7;
  if (input[12] <= 0.04340000078082085) {
    if (input[6] <= 0.1148499995470047) {
      if (input[24] <= 0.037049999460577965) {
        if (input[32] <= 0.0520833320915699) {
          var7 = [
            0.22, 0.11333333333333333, 0.18, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01, 0.08666666666666667, 0.12666666666666668, 0.15,
            0.07333333333333333, 0.02666666666666667, 0.013333333333333334, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0111731843575419, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.03910614525139665, 0.3575418994413408,
            0.18435754189944134, 0.15083798882681565, 0.0782122905027933,
            0.055865921787709494, 0.0, 0.12290502793296089,
          ];
        }
      } else {
        if (input[3] <= 0.07035000249743462) {
          var7 = [
            0.0, 0.0, 0.0, 0.025423728813559324, 0.025423728813559324,
            0.00847457627118644, 0.00847457627118644, 0.01694915254237288, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05084745762711865,
            0.0423728813559322, 0.07627118644067797, 0.7457627118644068, 0.0,
            0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.5, 0.3888888888888889, 0.08333333333333333,
            0.027777777777777776, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[28] <= 0.06894531100988388) {
        if (input[10] <= 0.7612968683242798) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.08, 0.92, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0,
          ];
        }
      } else {
        if (input[33] <= 0.005049999803304672) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.631578947368421, 0.3684210526315789, 0.0, 0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.030749999918043613) {
      if (input[24] <= 0.014700000174343586) {
        if (input[18] <= 0.19579999893903732) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0136986301369863, 0.0,
            0.0821917808219178, 0.1917808219178082, 0.6986301369863014,
            0.0136986301369863, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.9, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[41] <= 0.3559027910232544) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.37777777777777777, 0.6,
            0.022222222222222223, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.1111111111111111, 0.3333333333333333,
            0.1111111111111111, 0.4444444444444444, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.025849999859929085) {
        if (input[12] <= 0.17170000076293945) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.012345679012345678, 0.08641975308641975,
            0.8271604938271605, 0.07407407407407407, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.26666666666666666,
            0.6666666666666666, 0.06666666666666667, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.02710000053048134) {
          var7 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var7 = [
            0.0, 0.0, 0.0, 0.10112359550561797, 0.16853932584269662,
            0.2696629213483146, 0.43820224719101125, 0.02247191011235955, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var8;
  if (input[12] <= 0.04494999907910824) {
    if (input[9] <= 0.1339999958872795) {
      if (input[15] <= 0.01269999984651804) {
        if (input[7] <= 0.3340444713830948) {
          var8 = [
            0.022222222222222223, 0.0, 0.05555555555555555, 0.12222222222222222,
            0.06666666666666667, 0.03333333333333333, 0.022222222222222223,
            0.011111111111111112, 0.0, 0.0, 0.022222222222222223,
            0.43333333333333335, 0.0, 0.03333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.03333333333333333, 0.022222222222222223, 0.12222222222222222,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.05042016806722689, 0.03361344537815126,
            0.008403361344537815, 0.04201680672268908, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.008403361344537815, 0.0, 0.01680672268907563,
            0.025210084033613446, 0.773109243697479, 0.01680672268907563,
            0.025210084033613446,
          ];
        }
      } else {
        if (input[17] <= 0.38944604992866516) {
          var8 = [
            0.17233009708737865, 0.0, 0.10679611650485436, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1262135922330097, 0.1262135922330097,
            0.18932038834951456, 0.08980582524271845, 0.06796116504854369,
            0.12135922330097088, 0.0, 0.0, 0.0,
          ];
        } else {
          var8 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.250961534678936) {
        if (input[7] <= 0.14902343973517418) {
          var8 = [
            0.0, 0.0, 0.0, 0.42857142857142855, 0.42857142857142855, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.14285714285714285,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[37] <= 0.09648437798023224) {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.06060606060606061, 0.9393939393939394,
            0.0,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.6666666666666666, 0.3333333333333333, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[39] <= 0.01924999989569187) {
      if (input[26] <= 0.11944444477558136) {
        if (input[38] <= 0.665544867515564) {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.061452513966480445,
            0.24581005586592178, 0.5865921787709497, 0.10614525139664804, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.14082030951976776) {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.019230769230769232, 0.038461538461538464,
            0.5192307692307693, 0.4230769230769231, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.16666666666666666,
            0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.038850000128149986) {
        if (input[32] <= 0.06145833432674408) {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.17857142857142858, 0.2857142857142857,
            0.5357142857142857, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.01694915254237288, 0.1271186440677966,
            0.7711864406779662, 0.0847457627118644, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.17274999618530273) {
          var8 = [
            0.0, 0.0, 0.0, 0.23529411764705882, 0.5294117647058824,
            0.17647058823529413, 0.058823529411764705, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var8 = [
            0.0, 0.0, 0.0, 0.0, 0.1111111111111111, 0.8888888888888888, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var9;
  if (input[3] <= 0.06500000134110451) {
    if (input[9] <= 0.12425000220537186) {
      if (input[30] <= 0.0034000000450760126) {
        if (input[0] <= 0.06145000085234642) {
          var9 = [
            0.0, 0.3424657534246575, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.6438356164383562, 0.0136986301369863, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var9 = [
            0.25882352941176473, 0.0, 0.2196078431372549, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.19607843137254902, 0.19215686274509805,
            0.10196078431372549, 0.011764705882352941, 0.0196078431372549, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.04270833358168602) {
          var9 = [
            0.0, 0.0, 0.0, 0.0078125, 0.015625, 0.0, 0.0234375, 0.015625,
            0.015625, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03125,
            0.7578125, 0.0078125, 0.125,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04, 0.385, 0.165, 0.185, 0.225, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[25] <= 0.1395408660173416) {
        if (input[8] <= 0.23401163518428802) {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.023809523809523808, 0.9761904761904762,
            0.0,
          ];
        }
      } else {
        if (input[30] <= 0.04894999973475933) {
          var9 = [
            0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.0,
            0.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.041666666666666664, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.125, 0.8333333333333334,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.11145833134651184) {
      if (input[13] <= 0.6390624940395355) {
        if (input[40] <= 0.2706632614135742) {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07228915662650602,
            0.1144578313253012, 0.1686746987951807, 0.6144578313253012,
            0.030120481927710843, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.125, 0.75, 0.125, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.3580078035593033) {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.045454545454545456, 0.0,
            0.22727272727272727, 0.6818181818181818, 0.045454545454545456, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333,
            0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[38] <= 0.3861111104488373) {
        if (input[40] <= 0.06757812201976776) {
          var9 = [
            0.0, 0.0, 0.0, 0.06666666666666667, 0.2, 0.0, 0.06666666666666667,
            0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.5178571428571429, 0.16071428571428573,
            0.21428571428571427, 0.08928571428571429, 0.017857142857142856, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[14] <= 0.3211805522441864) {
          var9 = [
            0.0, 0.0, 0.0, 0.3888888888888889, 0.5, 0.05555555555555555,
            0.05555555555555555, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var9 = [
            0.0, 0.0, 0.0, 0.0078125, 0.0859375, 0.15625, 0.59375, 0.1484375,
            0.0078125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var10;
  if (input[12] <= 0.04334999993443489) {
    if (input[9] <= 0.14695000648498535) {
      if (input[27] <= 0.04230000078678131) {
        if (input[21] <= 0.04574999958276749) {
          var10 = [
            0.0, 0.2706766917293233, 0.0, 0.22556390977443608,
            0.06015037593984962, 0.03007518796992481, 0.03759398496240601, 0.0,
            0.0, 0.0, 0.0, 0.22556390977443608, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.007518796992481203, 0.015037593984962405, 0.12781954887218044,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.007352941176470588, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007352941176470588,
            0.04411764705882353, 0.8676470588235294, 0.0661764705882353,
            0.007352941176470588,
          ];
        }
      } else {
        if (input[34] <= 0.3759612441062927) {
          var10 = [
            0.2721518987341772, 0.0, 0.16455696202531644, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10759493670886076, 0.03164556962025317,
            0.0379746835443038, 0.10759493670886076, 0.14556962025316456,
            0.12025316455696203, 0.012658227848101266, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.1076923076923077, 0.0, 0.08461538461538462, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.09615384615384616, 0.16538461538461538,
            0.3423076923076923, 0.08846153846153847, 0.05384615384615385,
            0.06153846153846154, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[24] <= 0.21389999985694885) {
        if (input[37] <= 0.10761718451976776) {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.021739130434782608, 0.9782608695652174,
            0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.6058593690395355) {
          var10 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.05976562574505806) {
      if (input[26] <= 0.18368054926395416) {
        if (input[12] <= 0.3041500002145767) {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.020833333333333332, 0.0,
            0.05555555555555555, 0.2222222222222222, 0.6805555555555556,
            0.020833333333333332, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.10526315789473684,
            0.13157894736842105, 0.7631578947368421, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.772265613079071) {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.037037037037037035,
            0.4444444444444444, 0.48148148148148145, 0.037037037037037035, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.5833333333333334,
            0.08333333333333333, 0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[37] <= 0.4369218796491623) {
        if (input[25] <= 0.6330077946186066) {
          var10 = [
            0.0, 0.0, 0.0, 0.08695652173913043, 0.10869565217391304,
            0.5434782608695652, 0.2391304347826087, 0.021739130434782608, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.125, 0.75, 0.0, 0.0, 0.0, 0.125, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.507485955953598) {
          var10 = [
            0.0, 0.0, 0.0, 0.042105263157894736, 0.1368421052631579,
            0.10526315789473684, 0.5157894736842106, 0.14736842105263157,
            0.05263157894736842, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var10 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var11;
  if (input[18] <= 0.016950000077486038) {
    if (input[29] <= 0.08819444477558136) {
      if (input[22] <= 0.1002630814909935) {
        if (input[30] <= 0.052149999886751175) {
          var11 = [
            0.0, 0.33783783783783783, 0.0, 0.16216216216216217,
            0.12162162162162163, 0.08108108108108109, 0.0945945945945946,
            0.02702702702702703, 0.013513513513513514, 0.013513513513513514,
            0.06756756756756757, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.02702702702702703, 0.05405405405405406, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.09278350515463918, 0.05154639175257732,
            0.020618556701030927, 0.0, 0.010309278350515464, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.6804123711340206,
            0.14432989690721648,
          ];
        }
      } else {
        if (input[9] <= 0.17035000026226044) {
          var11 = [
            0.0, 0.0, 0.0, 0.08064516129032258, 0.008064516129032258, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.024193548387096774,
            0.024193548387096774, 0.8306451612903226, 0.03225806451612903, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.004399999976158142) {
        if (input[32] <= 0.13611111044883728) {
          var11 = [
            0.366120218579235, 0.01639344262295082, 0.31693989071038253, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.20218579234972678,
            0.0546448087431694, 0.01639344262295082, 0.01092896174863388,
            0.01639344262295082, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.17647058823529413, 0.23529411764705882, 0.0, 0.0,
            0.5882352941176471, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.1026500016450882) {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.01818181818181818, 0.03636363636363636, 0.2909090909090909,
            0.5272727272727272, 0.12727272727272726, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.20270270270270271, 0.47297297297297297, 0.23648648648648649,
            0.08783783783783784, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[39] <= 0.00989999994635582) {
      if (input[4] <= 0.11503906548023224) {
        if (input[31] <= 0.18027344346046448) {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03571428571428571, 0.9642857142857143, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.0,
            0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.0711805522441864) {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11165048543689321,
            0.1407766990291262, 0.22815533980582525, 0.49029126213592233,
            0.02912621359223301, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.08, 0.08, 0.04, 0.24, 0.24, 0.32, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[4] <= 0.7582031190395355) {
        if (input[26] <= 0.509784072637558) {
          var11 = [
            0.0, 0.0, 0.0, 0.037383177570093455, 0.11214953271028037,
            0.19626168224299065, 0.5514018691588785, 0.08411214953271028,
            0.018691588785046728, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.028985507246376812, 0.014492753623188406,
            0.028985507246376812, 0.8405797101449275, 0.08695652173913043, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.11060000211000443) {
          var11 = [
            0.0, 0.0, 0.0, 0.0, 0.5, 0.25, 0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var11 = [
            0.0, 0.0, 0.0, 0.25, 0.0, 0.6666666666666666, 0.0,
            0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var12;
  if (input[6] <= 0.1193000003695488) {
    if (input[23] <= 0.13958333432674408) {
      if (input[25] <= 0.07558593899011612) {
        if (input[4] <= 0.35175780951976776) {
          var12 = [
            0.2629310344827586, 0.14224137931034483, 0.17672413793103448, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.017241379310344827, 0.008620689655172414,
            0.01293103448275862, 0.25, 0.07758620689655173, 0.0,
            0.008620689655172414, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04310344827586207,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.0, 0.026490066225165563, 0.0, 0.0, 0.019867549668874173,
            0.006622516556291391, 0.039735099337748346, 0.2251655629139073,
            0.6423841059602649, 0.039735099337748346, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.09005000069737434) {
          var12 = [
            0.0, 0.0, 0.0, 0.009478672985781991, 0.04739336492890995,
            0.15639810426540285, 0.46445497630331756, 0.1943127962085308,
            0.12322274881516587, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.004739336492890996, 0.0, 0.0,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.0, 0.2711864406779661, 0.4406779661016949,
            0.22033898305084745, 0.06779661016949153, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[17] <= 0.01736111007630825) {
        if (input[13] <= 0.40800780057907104) {
          var12 = [
            0.0, 0.0, 0.0, 0.06837606837606838, 0.008547008547008548, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02564102564102564,
            0.008547008547008548, 0.0, 0.0, 0.02564102564102564,
            0.8547008547008547, 0.008547008547008548, 0.0,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.0, 0.4, 0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.10315000265836716) {
          var12 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06,
            0.0, 0.04, 0.02, 0.34, 0.54, 0.0, 0.0, 0.0,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.008968609865470852, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.14349775784753363, 0.18834080717488788,
            0.3542600896860987, 0.19282511210762332, 0.1031390134529148,
            0.008968609865470852, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[30] <= 0.025500000454485416) {
      var12 = [
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
      ];
    } else {
      if (input[21] <= 0.13379999622702599) {
        if (input[11] <= 0.6606540977954865) {
          var12 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.009523809523809525, 0.9809523809523809,
            0.009523809523809525,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.6666666666666666,
            0.0,
          ];
        }
      } else {
        if (input[11] <= 0.5826388895511627) {
          var12 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.06666666666666667, 0.0, 0.8666666666666667, 0.0, 0.0,
            0.06666666666666667,
          ];
        } else {
          var12 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var13;
  if (input[9] <= 0.14639999717473984) {
    if (input[23] <= 0.13958333432674408) {
      if (input[9] <= 0.031149999238550663) {
        if (input[34] <= 0.012890624813735485) {
          var13 = [
            0.0, 0.09935897435897435, 0.0, 0.0, 0.0, 0.0, 0.022435897435897436,
            0.07051282051282051, 0.1346153846153846, 0.125, 0.3269230769230769,
            0.14423076923076922, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07692307692307693,
          ];
        } else {
          var13 = [
            0.4774774774774775, 0.0, 0.36036036036036034, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16216216216216217, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.22502563893795013) {
          var13 = [
            0.0, 0.0, 0.0, 0.1297709923664122, 0.17557251908396945,
            0.22137404580152673, 0.2900763358778626, 0.1297709923664122,
            0.022900763358778626, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015267175572519083,
            0.0, 0.0, 0.0, 0.007633587786259542, 0.007633587786259542, 0.0,
          ];
        } else {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 0.05952380952380952, 0.08333333333333333,
            0.8214285714285714, 0.03571428571428571, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[24] <= 0.03060000017285347) {
        if (input[32] <= 0.13479166477918625) {
          var13 = [
            0.0, 0.0, 0.008333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.24166666666666667, 0.38333333333333336,
            0.15833333333333333, 0.06666666666666667, 0.10833333333333334,
            0.03333333333333333, 0.0, 0.0, 0.0,
          ];
        } else {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.045454545454545456, 0.5056818181818182, 0.1590909090909091,
            0.09659090909090909, 0.11931818181818182, 0.03409090909090909,
            0.022727272727272728, 0.017045454545454544,
          ];
        }
      } else {
        if (input[21] <= 0.09555000066757202) {
          var13 = [
            0.0, 0.0, 0.0, 0.12280701754385964, 0.03508771929824561, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.008771929824561403,
            0.0, 0.8157894736842105, 0.017543859649122806, 0.0,
          ];
        } else {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.08333333333333333, 0.4166666666666667, 0.375,
            0.041666666666666664, 0.08333333333333333, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[36] <= 0.01334999967366457) {
      if (input[5] <= 0.3791666626930237) {
        if (input[30] <= 0.05014999955892563) {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.4, 0.6, 0.0,
          ];
        } else {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      } else {
        var13 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[4] <= 0.4619140699505806) {
        if (input[8] <= 0.25) {
          var13 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var13 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var13 = [
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var14;
  if (input[29] <= 0.09409721940755844) {
    if (input[20] <= 0.04236111044883728) {
      if (input[8] <= 0.42770451307296753) {
        if (input[17] <= 0.18854166567325592) {
          var14 = [
            0.0, 0.0, 0.0, 0.2054794520547945, 0.2191780821917808,
            0.0684931506849315, 0.0684931506849315, 0.0136986301369863,
            0.0136986301369863, 0.0136986301369863, 0.0410958904109589, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0410958904109589,
            0.0410958904109589, 0.273972602739726,
          ];
        } else {
          var14 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.10413805022835732) {
          var14 = [
            0.0, 0.0, 0.0, 0.13592233009708737, 0.009708737864077669,
            0.019417475728155338, 0.019417475728155338, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.019417475728155338,
            0.7766990291262136, 0.019417475728155338,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.03787878787878788, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015151515151515152,
            0.045454545454545456, 0.7196969696969697, 0.18181818181818182, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.06652644276618958) {
        if (input[4] <= 0.11503906548023224) {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03225806451612903, 0.0,
            0.04838709677419355, 0.0, 0.016129032258064516, 0.9032258064516129,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.015, 0.015, 0.015, 0.045, 0.115, 0.185, 0.215,
            0.375, 0.02, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.0466499999165535) {
          var14 = [
            0.0, 0.0, 0.0, 0.36, 0.44, 0.08, 0.12, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.021739130434782608, 0.043478260869565216,
            0.2391304347826087, 0.5942028985507246, 0.09420289855072464,
            0.007246376811594203, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[8] <= 0.13819444179534912) {
      if (input[39] <= 0.009449999779462814) {
        if (input[17] <= 0.1682114452123642) {
          var14 = [
            0.014492753623188406, 0.0, 0.5217391304347826, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.30434782608695654, 0.043478260869565216,
            0.057971014492753624, 0.057971014492753624, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.49056603773584906, 0.05660377358490566, 0.1509433962264151, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.24528301886792453,
            0.04716981132075472, 0.009433962264150943, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[2] <= 0.6572916805744171) {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.46153846153846156, 0.23076923076923078, 0.3076923076923077, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.9, 0.0, 0.0, 0.1, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.05819999985396862) {
        if (input[10] <= 0.2816406190395355) {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.2391304347826087, 0.7282608695652174, 0.03260869565217391, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.23529411764705882, 0.23529411764705882, 0.47058823529411764,
            0.058823529411764705, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.11830000206828117) {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.014084507042253521, 0.07042253521126761, 0.4507042253521127,
            0.43661971830985913, 0.028169014084507043, 0.0, 0.0,
          ];
        } else {
          var14 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.2549019607843137, 0.47058823529411764, 0.23529411764705882,
            0.0392156862745098, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var15;
  if (input[30] <= 0.06940000131726265) {
    if (input[28] <= 0.06894531100988388) {
      if (input[9] <= 0.01510000042617321) {
        if (input[4] <= 0.14707031846046448) {
          var15 = [
            0.0, 0.24324324324324326, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04054054054054054, 0.0, 0.013513513513513514, 0.6891891891891891,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.013513513513513514, 0.0, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.014084507042253521, 0.018779342723004695,
            0.09859154929577464, 0.2112676056338028, 0.14553990610328638,
            0.47417840375586856, 0.03755868544600939, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[21] <= 0.04609999991953373) {
          var15 = [
            0.0, 0.0, 0.0, 0.11857707509881422, 0.14624505928853754,
            0.16600790513833993, 0.44664031620553357, 0.06719367588932806,
            0.015810276679841896, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.015810276679841896, 0.023715415019762844, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.009708737864077669, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.009708737864077669,
            0.06796116504854369, 0.8543689320388349, 0.05825242718446602, 0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.05312500149011612) {
        if (input[41] <= 0.3031249940395355) {
          var15 = [
            0.27058823529411763, 0.023529411764705882, 0.2823529411764706, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3058823529411765,
            0.08235294117647059, 0.011764705882352941, 0.0058823529411764705,
            0.01764705882352941, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6, 0.35555555555555557, 0.0, 0.044444444444444446, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[33] <= 0.016699999570846558) {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.1282051282051282, 0.19230769230769232, 0.24358974358974358,
            0.41025641025641024, 0.02564102564102564, 0.0, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.08849557522123894, 0.5309734513274337, 0.24778761061946902,
            0.05309734513274336, 0.07964601769911504, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.1339999958872795) {
      if (input[10] <= 0.15117187798023224) {
        if (input[21] <= 0.23764999210834503) {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.1444687470793724) {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.0, 0.3333333333333333,
            0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.2222222222222222, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1111111111111111, 0.0,
            0.6666666666666666, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[37] <= 0.1357421875) {
        if (input[10] <= 0.1835365891456604) {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.010101010101010102, 0.9696969696969697,
            0.020202020202020204,
          ];
        }
      } else {
        if (input[38] <= 0.5166666507720947) {
          var15 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var15 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var16;
  if (input[20] <= 0.09618055447936058) {
    if (input[16] <= 0.1279296875) {
      if (input[6] <= 0.12164999917149544) {
        if (input[21] <= 0.03969999961555004) {
          var16 = [
            0.0, 0.0, 0.03571428571428571, 0.20535714285714285,
            0.21428571428571427, 0.14285714285714285, 0.0625,
            0.017857142857142856, 0.0, 0.008928571428571428,
            0.07142857142857142, 0.0, 0.008928571428571428, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.03571428571428571, 0.008928571428571428, 0.1875,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00980392156862745, 0.00980392156862745, 0.0, 0.0,
            0.0196078431372549, 0.9509803921568627, 0.00980392156862745, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.029249999672174454) {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.07272727272727272, 0.8818181818181818,
            0.045454545454545456,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.6666666666666666, 0.3333333333333333, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[15] <= 0.2816000059247017) {
        if (input[8] <= 0.1180555522441864) {
          var16 = [
            0.38311688311688313, 0.0, 0.2012987012987013, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2922077922077922, 0.07792207792207792,
            0.025974025974025976, 0.01948051948051948, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.12162162162162163, 0.35585585585585583, 0.1891891891891892,
            0.16216216216216217, 0.17117117117117117, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var16 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[26] <= 0.171875) {
      if (input[18] <= 0.19584999978542328) {
        if (input[4] <= 0.553440511226654) {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02830188679245283, 0.0,
            0.018867924528301886, 0.1509433962264151, 0.7924528301886793,
            0.009433962264150943, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0975609756097561, 0.0,
            0.17073170731707318, 0.5365853658536586, 0.1951219512195122, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.24660000205039978) {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.017241379310344827, 0.9827586206896551, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.005450000055134296) {
        if (input[8] <= 0.21319444477558136) {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05555555555555555,
            0.2777777777777778, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.25, 0.5833333333333334,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[6] <= 0.0381499994546175) {
          var16 = [
            0.0, 0.0, 0.0, 0.0, 0.07142857142857142, 0.1357142857142857, 0.65,
            0.12857142857142856, 0.014285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var16 = [
            0.0, 0.0, 0.0, 0.14285714285714285, 0.2857142857142857,
            0.42857142857142855, 0.14285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var17;
  if (input[4] <= 0.16417907923460007) {
    if (input[29] <= 0.08819444477558136) {
      if (input[9] <= 0.13980000466108322) {
        if (input[22] <= 0.09783339127898216) {
          var17 = [
            0.0, 0.2605042016806723, 0.0, 0.008403361344537815, 0.0,
            0.008403361344537815, 0.01680672268907563, 0.0,
            0.025210084033613446, 0.0, 0.025210084033613446, 0.453781512605042,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.025210084033613446,
            0.025210084033613446, 0.15126050420168066,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.041666666666666664, 0.9270833333333334,
            0.03125, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.19472655653953552) {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.00847457627118644, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.09322033898305085,
            0.8983050847457628, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.03242187574505806) {
        if (input[22] <= 0.06894531100988388) {
          var17 = [
            0.5, 0.017857142857142856, 0.4017857142857143, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.08035714285714286, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0410958904109589, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.5068493150684932, 0.3150684931506849,
            0.0547945205479452, 0.0684931506849315, 0.0136986301369863, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.0802999995648861) {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.023809523809523808, 0.023809523809523808, 0.09523809523809523,
            0.23809523809523808, 0.5476190476190477, 0.07142857142857142, 0.0,
            0.0,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16022099447513813, 0.4419889502762431, 0.16574585635359115,
            0.16022099447513813, 0.0718232044198895, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[20] <= 0.0972222238779068) {
      if (input[35] <= 0.240327388048172) {
        if (input[36] <= 0.04230000078678131) {
          var17 = [
            0.0, 0.0, 0.0, 0.546875, 0.140625, 0.03125, 0.15625, 0.0, 0.0625,
            0.015625, 0.03125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015625, 0.0,
            0.0,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.125, 0.25, 0.625, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var17 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[30] <= 0.010149999987334013) {
        if (input[40] <= 0.25214843451976776) {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.005649717514124294, 0.0,
            0.12429378531073447, 0.1751412429378531, 0.22598870056497175,
            0.4519774011299435, 0.01694915254237288, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.038461538461538464, 0.6538461538461539,
            0.2692307692307692, 0.038461538461538464, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.03879999928176403) {
          var17 = [
            0.0, 0.0, 0.0, 0.0, 0.05309734513274336, 0.09734513274336283,
            0.6814159292035398, 0.12389380530973451, 0.04424778761061947, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var17 = [
            0.0, 0.0, 0.0, 0.275, 0.325, 0.25, 0.15, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var18;
  if (input[20] <= 0.09618055447936058) {
    if (input[16] <= 0.12558594346046448) {
      if (input[40] <= 0.12456971406936646) {
        if (input[9] <= 0.13665000349283218) {
          var18 = [
            0.061224489795918366, 0.0, 0.16326530612244897, 0.0, 0.0,
            0.08163265306122448, 0.04081632653061224, 0.02040816326530612,
            0.12244897959183673, 0.0, 0.061224489795918366, 0.0, 0.0,
            0.04081632653061224, 0.0, 0.0, 0.04081632653061224, 0.0,
            0.04081632653061224, 0.0, 0.32653061224489793,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.07142857142857142, 0.08571428571428572, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.014285714285714285, 0.8285714285714286, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.20264732837677002) {
          var18 = [
            0.0, 0.0, 0.0, 0.36538461538461536, 0.19230769230769232,
            0.15384615384615385, 0.057692307692307696, 0.019230769230769232,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.038461538461538464, 0.0, 0.0, 0.0, 0.0,
            0.07692307692307693, 0.09615384615384616, 0.0,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.028368794326241134, 0.0070921985815602835,
            0.0070921985815602835, 0.0070921985815602835, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0070921985815602835,
            0.7730496453900709, 0.1702127659574468, 0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.0520833320915699) {
        if (input[27] <= 0.022549999877810478) {
          var18 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var18 = [
            0.29017857142857145, 0.013392857142857142, 0.14285714285714285, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2544642857142857,
            0.18303571428571427, 0.06696428571428571, 0.022321428571428572,
            0.026785714285714284, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07854999974370003) {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05185185185185185, 0.5185185185185185, 0.24444444444444444,
            0.14814814814814814, 0.037037037037037035, 0.0, 0.0, 0.0,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.04225352112676056, 0.11267605633802817, 0.28169014084507044,
            0.5633802816901409, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[20] <= 0.37162965536117554) {
      if (input[11] <= 0.3690972179174423) {
        if (input[26] <= 0.34236110746860504) {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.008064516129032258, 0.0,
            0.04838709677419355, 0.27419354838709675, 0.6370967741935484,
            0.03225806451612903, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.03278688524590164, 0.0, 0.11475409836065574,
            0.32786885245901637, 0.5245901639344263, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.06520000100135803) {
          var18 = [
            0.0, 0.0, 0.0, 0.22727272727272727, 0.6363636363636364,
            0.045454545454545456, 0.09090909090909091, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.07051282051282051, 0.14743589743589744,
            0.6025641025641025, 0.1282051282051282, 0.05128205128205128, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[4] <= 0.4992110878229141) {
        if (input[5] <= 0.31807002425193787) {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01639344262295082, 0.9836065573770492, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var18 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0,
            0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        var18 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var19;
  if (input[4] <= 0.14484314620494843) {
    if (input[6] <= 0.13475000113248825) {
      if (input[15] <= 0.01269999984651804) {
        if (input[23] <= 0.421750009059906) {
          var19 = [
            0.009708737864077669, 0.0, 0.04854368932038835, 0.04854368932038835,
            0.038834951456310676, 0.0, 0.038834951456310676, 0.0, 0.0, 0.0,
            0.038834951456310676, 0.5145631067961165, 0.019417475728155338,
            0.009708737864077669, 0.009708737864077669, 0.0, 0.0, 0.0,
            0.02912621359223301, 0.009708737864077669, 0.18446601941747573,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.01098901098901099, 0.9560439560439561,
            0.03296703296703297, 0.0,
          ];
        }
      } else {
        if (input[23] <= 0.13958333432674408) {
          var19 = [
            0.41975308641975306, 0.24691358024691357, 0.21604938271604937, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11728395061728394, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.003484320557491289, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.11498257839721254, 0.21254355400696864,
            0.27177700348432055, 0.16376306620209058, 0.13937282229965156,
            0.09407665505226481, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.025500000454485416) {
        if (input[11] <= 0.545481726527214) {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.0693359375) {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.009433962264150943, 0.009433962264150943,
            0.9528301886792453, 0.02830188679245283,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.1111111111111111, 0.0, 0.8888888888888888, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.11145833134651184) {
      if (input[36] <= 0.026449999772012234) {
        if (input[4] <= 0.5489332973957062) {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.00847457627118644,
            0.0423728813559322, 0.059322033898305086, 0.0847457627118644,
            0.7796610169491526, 0.025423728813559324, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.02631578947368421, 0.02631578947368421,
            0.07894736842105263, 0.18421052631578946, 0.21052631578947367,
            0.3157894736842105, 0.15789473684210525, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.1797500029206276) {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14285714285714285,
            0.5714285714285714, 0.0, 0.0, 0.2857142857142857, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03333333333333333, 0.1,
            0.5666666666666667, 0.26666666666666666, 0.03333333333333333, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[3] <= 0.09895000234246254) {
        if (input[35] <= 0.2226250022649765) {
          var19 = [
            0.0, 0.0, 0.0, 0.5882352941176471, 0.3235294117647059, 0.0,
            0.08823529411764706, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.04065000079572201) {
          var19 = [
            0.0, 0.0, 0.0, 0.040983606557377046, 0.05737704918032787,
            0.09836065573770492, 0.639344262295082, 0.12295081967213115,
            0.040983606557377046, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var19 = [
            0.0, 0.0, 0.0, 0.1388888888888889, 0.20833333333333334,
            0.3333333333333333, 0.20833333333333334, 0.1111111111111111, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var20;
  if (input[6] <= 0.13854999840259552) {
    if (input[20] <= 0.09618055447936058) {
      if (input[15] <= 0.013299999758601189) {
        if (input[4] <= 0.14454033970832825) {
          var20 = [
            0.0, 0.0, 0.037267080745341616, 0.043478260869565216,
            0.012422360248447204, 0.0, 0.012422360248447204,
            0.012422360248447204, 0.0, 0.0, 0.012422360248447204, 0.0,
            0.012422360248447204, 0.012422360248447204, 0.0, 0.0, 0.0,
            0.012422360248447204, 0.6459627329192547, 0.043478260869565216,
            0.14285714285714285,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.36363636363636365, 0.2727272727272727,
            0.15584415584415584, 0.07792207792207792, 0.0, 0.012987012987012988,
            0.0, 0.05194805194805195, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06493506493506493, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.10347222536802292) {
          var20 = [
            0.24731182795698925, 0.08602150537634409, 0.16129032258064516, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.17921146953405018,
            0.15412186379928317, 0.1003584229390681, 0.035842293906810034,
            0.03225806451612903, 0.0035842293906810036, 0.0, 0.0, 0.0,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.022222222222222223, 0.32592592592592595, 0.22962962962962963,
            0.2074074074074074, 0.21481481481481482, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.5318910479545593) {
        if (input[18] <= 0.19579999893903732) {
          var20 = [
            0.0, 0.0, 0.0, 0.02666666666666667, 0.02666666666666667,
            0.017777777777777778, 0.04, 0.08888888888888889,
            0.14666666666666667, 0.16444444444444445, 0.48444444444444446,
            0.0044444444444444444, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04081632653061224, 0.9591836734693877, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.09989999979734421) {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.06818181818181818, 0.09090909090909091,
            0.8181818181818182, 0.022727272727272728, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.015384615384615385, 0.13846153846153847,
            0.2923076923076923, 0.4, 0.1076923076923077, 0.046153846153846156,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[29] <= 0.12048611044883728) {
      if (input[23] <= 0.7467882037162781) {
        if (input[32] <= 0.2575823962688446) {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.047619047619047616, 0.9523809523809523,
            0.0,
          ];
        }
      } else {
        var20 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
        ];
      }
    } else {
      if (input[29] <= 0.30763888359069824) {
        var20 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[25] <= 0.8802734315395355) {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var20 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var21;
  if (input[28] <= 0.06894531100988388) {
    if (input[4] <= 0.1776556447148323) {
      if (input[18] <= 0.0989999994635582) {
        if (input[34] <= 0.019471153616905212) {
          var21 = [
            0.0, 0.11059907834101383, 0.0, 0.03225806451612903,
            0.013824884792626729, 0.0, 0.013824884792626729,
            0.013824884792626729, 0.0, 0.0, 0.009216589861751152, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.004608294930875576, 0.013824884792626729,
            0.1935483870967742, 0.4838709677419355, 0.11059907834101383,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.598931074142456) {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.01933593675494194) {
        if (input[11] <= 0.3975694477558136) {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.01020408163265306, 0.00510204081632653,
            0.01020408163265306, 0.08673469387755102, 0.16326530612244897,
            0.1989795918367347, 0.5, 0.025510204081632654, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.0, 0.04, 0.26, 0.08, 0.48, 0.14, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.06520000100135803) {
          var21 = [
            0.0, 0.0, 0.0, 0.4727272727272727, 0.10909090909090909,
            0.14545454545454545, 0.12727272727272726, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14545454545454545, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.0, 0.025, 0.13333333333333333, 0.225,
            0.5583333333333333, 0.058333333333333334, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[22] <= 0.07187499850988388) {
      if (input[33] <= 0.025299999862909317) {
        if (input[1] <= 0.4260678142309189) {
          var21 = [
            0.0, 0.07142857142857142, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.21428571428571427, 0.0, 0.21428571428571427, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.7831325301204819, 0.0, 0.20481927710843373, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.012048192771084338, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[34] <= 0.36699219048023224) {
          var21 = [
            0.3, 0.0, 0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.1111111111111111, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.5555555555555556, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.04277343675494194) {
        if (input[0] <= 0.2073500007390976) {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9,
            0.05, 0.05, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.3541666666666667, 0.3958333333333333, 0.041666666666666664,
            0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[33] <= 0.017549999989569187) {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11, 0.15, 0.07, 0.31, 0.32, 0.04, 0.0, 0.0,
          ];
        } else {
          var21 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10666666666666667, 0.5333333333333333, 0.18666666666666668, 0.12,
            0.05333333333333334, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var22;
  if (input[12] <= 0.05285000056028366) {
    if (input[30] <= 0.06094999983906746) {
      if (input[9] <= 0.05324999988079071) {
        if (input[21] <= 0.011950000189244747) {
          var22 = [
            0.33163265306122447, 0.14285714285714285, 0.2857142857142857, 0.0,
            0.00510204081632653, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015306122448979591,
            0.1377551020408163, 0.0663265306122449, 0.0, 0.015306122448979591,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09090909090909091, 0.2648221343873518, 0.3557312252964427,
            0.11857707509881422, 0.14624505928853754, 0.015810276679841896,
            0.007905138339920948, 0.0, 0.0,
          ];
        }
      } else {
        if (input[5] <= 0.2465277761220932) {
          var22 = [
            0.0, 0.0, 0.0, 0.007936507936507936, 0.015873015873015872, 0.0,
            0.007936507936507936, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.015873015873015872, 0.023809523809523808, 0.07936507936507936,
            0.14285714285714285, 0.6746031746031746, 0.031746031746031744, 0.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.3880597014925373, 0.23880597014925373,
            0.16417910447761194, 0.029850746268656716, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1791044776119403, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.1205499991774559) {
        if (input[11] <= 0.2029999941587448) {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.05042016806722689, 0.025210084033613446, 0.0, 0.0,
            0.008403361344537815, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.06722689075630252, 0.8487394957983193, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.42988281697034836) {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.8666666666666667, 0.0, 0.0,
            0.13333333333333333,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[26] <= 0.11944444477558136) {
      if (input[14] <= 0.6362541019916534) {
        if (input[14] <= 0.4615079313516617) {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015625, 0.140625, 0.375,
            0.46875, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04807692307692308, 0.0,
            0.009615384615384616, 0.125, 0.8173076923076923, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var22 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[32] <= 0.18472222238779068) {
        if (input[9] <= 0.040049999952316284) {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.023809523809523808, 0.0,
            0.5238095238095238, 0.4523809523809524, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.057692307692307696, 0.09615384615384616,
            0.40384615384615385, 0.28846153846153844, 0.1346153846153846,
            0.019230769230769232, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.210916668176651) {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.2222222222222222, 0.0, 0.1111111111111111,
            0.1111111111111111, 0.5555555555555556, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var22 = [
            0.0, 0.0, 0.0, 0.0, 0.058823529411764705, 0.12745098039215685,
            0.7450980392156863, 0.06862745098039216, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var23;
  if (input[12] <= 0.04325000010430813) {
    if (input[16] <= 0.12558594346046448) {
      if (input[21] <= 0.04880000092089176) {
        if (input[30] <= 0.07039999961853027) {
          var23 = [
            0.009900990099009901, 0.0, 0.06930693069306931, 0.25742574257425743,
            0.19801980198019803, 0.0594059405940594, 0.04950495049504951, 0.0,
            0.0, 0.0, 0.009900990099009901, 0.18811881188118812, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.09900990099009901, 0.0594059405940594, 0.0,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.0449438202247191, 0.033707865168539325, 0.0, 0.0,
            0.011235955056179775, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.6741573033707865, 0.23595505617977527,
          ];
        }
      } else {
        if (input[10] <= 0.25084252655506134) {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.05263157894736842, 0.0, 0.10526315789473684,
            0.10526315789473684, 0.15789473684210525, 0.5263157894736842,
            0.05263157894736842,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.023255813953488372, 0.8294573643410853,
            0.14728682170542637, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.0035500000230968) {
        if (input[27] <= 0.022549999877810478) {
          var23 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var23 = [
            0.27232142857142855, 0.0, 0.17410714285714285, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.23660714285714285, 0.17410714285714285,
            0.10267857142857142, 0.022321428571428572, 0.017857142857142856,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[15] <= 0.0690000019967556) {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00980392156862745, 0.19607843137254902, 0.19607843137254902,
            0.27450980392156865, 0.3235294117647059, 0.0, 0.0, 0.0,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0975609756097561, 0.6463414634146342, 0.0975609756097561,
            0.07317073170731707, 0.08536585365853659, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[26] <= 0.11944444477558136) {
      if (input[14] <= 0.6706503331661224) {
        if (input[13] <= 0.4513203203678131) {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07407407407407407, 0.0,
            0.07407407407407407, 0.6296296296296297, 0.2222222222222222, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02830188679245283, 0.0,
            0.02830188679245283, 0.14150943396226415, 0.8018867924528302, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var23 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[11] <= 0.4654615968465805) {
        if (input[40] <= 0.11582031100988388) {
          var23 = [
            0.0, 0.0, 0.0, 0.0, 0.014705882352941176, 0.0, 0.029411764705882353,
            0.39705882352941174, 0.5441176470588235, 0.014705882352941176, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.06451612903225806, 0.20967741935483872,
            0.16129032258064516, 0.3387096774193548, 0.20967741935483872,
            0.016129032258064516, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.04075000062584877) {
          var23 = [
            0.0, 0.0, 0.0, 0.010869565217391304, 0.03260869565217391,
            0.05434782608695652, 0.8695652173913043, 0.03260869565217391, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var23 = [
            0.0, 0.0, 0.0, 0.18181818181818182, 0.13636363636363635, 0.5,
            0.18181818181818182, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var24;
  if (input[27] <= 0.04270000010728836) {
    if (input[9] <= 0.1334500014781952) {
      if (input[10] <= 0.05253906175494194) {
        if (input[4] <= 0.15195313096046448) {
          var24 = [
            0.0, 0.24561403508771928, 0.0, 0.0, 0.0, 0.0, 0.02631578947368421,
            0.0, 0.02631578947368421, 0.0, 0.017543859649122806,
            0.47368421052631576, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.21052631578947367,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.014925373134328358, 0.03980099502487562,
            0.0845771144278607, 0.16417910447761194, 0.20398009950248755,
            0.48258706467661694, 0.009950248756218905, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.04628906399011612) {
          var24 = [
            0.0, 0.0, 0.0, 0.12751677852348994, 0.08053691275167785,
            0.020134228187919462, 0.013422818791946308, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.013422818791946308, 0.03355704697986577,
            0.6912751677852349, 0.020134228187919462, 0.0,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.04371584699453552, 0.1092896174863388,
            0.2185792349726776, 0.47540983606557374, 0.12021857923497267,
            0.03278688524590164, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[36] <= 0.006599999964237213) {
        if (input[3] <= 0.03815000131726265) {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0380952380952381, 0.9428571428571428,
            0.01904761904761905,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.5555555555555556, 0.2222222222222222,
            0.2222222222222222, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.09634999930858612) {
          var24 = [
            0.0, 0.0, 0.0, 0.9090909090909091, 0.0, 0.09090909090909091, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.6666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[8] <= 0.1215277761220932) {
      if (input[1] <= 0.4066406190395355) {
        if (input[22] <= 0.23164062201976776) {
          var24 = [
            0.0, 0.05555555555555555, 0.7222222222222222, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2222222222222222, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.047619047619047616, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.6190476190476191, 0.3333333333333333, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.2142999991774559) {
          var24 = [
            0.5, 0.0, 0.21551724137931033, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.15517241379310345, 0.09482758620689655,
            0.008620689655172414, 0.0, 0.02586206896551724, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var24 = [
            0.06666666666666667, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.36666666666666664, 0.13333333333333333,
            0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[27] <= 0.0856499969959259) {
        if (input[2] <= 0.5857638716697693) {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.034482758620689655, 0.034482758620689655, 0.06896551724137931,
            0.5862068965517241, 0.1724137931034483, 0.10344827586206896, 0.0,
            0.0,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0425531914893617, 0.14893617021276595, 0.0425531914893617,
            0.2127659574468085, 0.5531914893617021, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.19978197664022446) {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.17777777777777778, 0.3, 0.2222222222222222, 0.2222222222222222,
            0.07777777777777778, 0.0, 0.0, 0.0,
          ];
        } else {
          var24 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.015873015873015872, 0.7619047619047619, 0.14285714285714285,
            0.031746031746031744, 0.047619047619047616, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var25;
  if (input[4] <= 0.1732872575521469) {
    if (input[29] <= 0.08819444477558136) {
      if (input[32] <= 0.03327546268701553) {
        if (input[16] <= 0.2369791716337204) {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.014492753623188406, 0.0, 0.0, 0.0,
            0.08695652173913043, 0.8985507246376812, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.11694999784231186) {
          var25 = [
            0.0, 0.0, 0.0, 0.042735042735042736, 0.02564102564102564,
            0.008547008547008548, 0.0, 0.008547008547008548,
            0.02564102564102564, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.008547008547008548, 0.017094017094017096, 0.7863247863247863, 0.0,
            0.07692307692307693,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.008695652173913044, 0.017391304347826087,
            0.017391304347826087, 0.9130434782608695, 0.043478260869565216,
          ];
        }
      }
    } else {
      if (input[22] <= 0.06894531100988388) {
        if (input[16] <= 0.4619140625) {
          var25 = [
            0.15217391304347827, 0.0, 0.6739130434782609, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10869565217391304, 0.0,
            0.06521739130434782, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.5945945945945946, 0.04054054054054054, 0.20270270270270271, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14864864864864866, 0.0,
            0.013513513513513514, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.0722000002861023) {
          var25 = [
            0.0, 0.0, 0.004901960784313725, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.17647058823529413, 0.2647058823529412,
            0.39705882352941174, 0.08823529411764706, 0.0392156862745098,
            0.00980392156862745, 0.0196078431372549, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.05434782608695652, 0.1956521739130435, 0.31521739130434784,
            0.42391304347826086, 0.010869565217391304, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[25] <= 0.07558593899011612) {
      if (input[39] <= 0.01209999993443489) {
        if (input[14] <= 0.45429421961307526) {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.17391304347826086,
            0.41304347826086957, 0.41304347826086957, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.009900990099009901, 0.0, 0.0,
            0.13861386138613863, 0.8415841584158416, 0.009900990099009901, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[14] <= 0.49470314383506775) {
          var25 = [
            0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[13] <= 0.19707030802965164) {
        if (input[22] <= 0.10821814835071564) {
          var25 = [
            0.0, 0.0, 0.0, 0.28888888888888886, 0.4444444444444444,
            0.1111111111111111, 0.15555555555555556, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8333333333333334, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[41] <= 0.5079327076673508) {
          var25 = [
            0.0, 0.0, 0.0, 0.0, 0.034482758620689655, 0.10344827586206896,
            0.06896551724137931, 0.3448275862068966, 0.41379310344827586,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var25 = [
            0.0, 0.0, 0.0, 0.046511627906976744, 0.06395348837209303,
            0.13372093023255813, 0.6162790697674418, 0.13953488372093023, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var26;
  if (input[14] <= 0.32951389253139496) {
    if (input[9] <= 0.14999999850988388) {
      if (input[10] <= 0.24877634644508362) {
        if (input[6] <= 0.030949999578297138) {
          var26 = [
            0.23430962343096234, 0.1087866108786611, 0.13807531380753138, 0.0,
            0.0041841004184100415, 0.0041841004184100415, 0.0, 0.0, 0.0, 0.0,
            0.008368200836820083, 0.12133891213389121, 0.21338912133891214,
            0.09623430962343096, 0.02092050209205021, 0.008368200836820083, 0.0,
            0.0, 0.0, 0.0, 0.04184100418410042,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1276595744680851, 0.3900709219858156, 0.10638297872340426,
            0.1276595744680851, 0.2198581560283688, 0.0070921985815602835,
            0.0070921985815602835, 0.014184397163120567,
          ];
        }
      } else {
        if (input[16] <= 0.1328125) {
          var26 = [
            0.0, 0.0, 0.0, 0.19101123595505617, 0.056179775280898875,
            0.056179775280898875, 0.011235955056179775, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0056179775280898875, 0.6292134831460674,
            0.05056179775280899, 0.0,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.056338028169014086, 0.04225352112676056, 0.2676056338028169,
            0.352112676056338, 0.28169014084507044, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.2498856708407402) {
        if (input[8] <= 0.2541666701436043) {
          var26 = [
            0.0, 0.0, 0.0, 0.4, 0.1, 0.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.8333333333333334, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.16666666666666666,
            0.0,
          ];
        }
      } else {
        if (input[38] <= 0.18298611044883728) {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.042105263157894736, 0.9578947368421052,
            0.0,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[30] <= 0.008649999741464853) {
      if (input[14] <= 0.6706503331661224) {
        if (input[24] <= 0.014700000174343586) {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007246376811594203,
            0.06521739130434782, 0.26811594202898553, 0.6594202898550725, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.013333333333333334, 0.18666666666666668,
            0.38666666666666666, 0.4, 0.013333333333333334, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var26 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[6] <= 0.03930000029504299) {
        if (input[9] <= 0.01510000042617321) {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0625, 0.0, 0.0625, 0.1875, 0.6875, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.021897810218978103, 0.15328467153284672,
            0.7153284671532847, 0.10948905109489052, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[14] <= 0.3656249940395355) {
          var26 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var26 = [
            0.0, 0.0, 0.0, 0.11363636363636363, 0.36363636363636365,
            0.3181818181818182, 0.20454545454545456, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var27;
  if (input[4] <= 0.16417907923460007) {
    if (input[7] <= 0.03242187574505806) {
      if (input[0] <= 0.06194999814033508) {
        if (input[19] <= 0.2251874953508377) {
          var27 = [
            0.017543859649122806, 0.5964912280701754, 0.0, 0.017543859649122806,
            0.017543859649122806, 0.0, 0.0, 0.0, 0.0, 0.0, 0.017543859649122806,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.017543859649122806, 0.0,
            0.3157894736842105,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015625, 0.0, 0.0, 0.0, 0.046875,
            0.9375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.06894531100988388) {
          var27 = [
            0.5409836065573771, 0.0, 0.28688524590163933, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1721311475409836, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.4642857142857143, 0.44642857142857145, 0.03571428571428571,
            0.05357142857142857, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[23] <= 0.13958333432674408) {
        if (input[6] <= 0.11134999990463257) {
          var27 = [
            0.0, 0.0, 0.0, 0.125, 0.3125, 0.3125, 0.125, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9818181818181818,
            0.01818181818181818,
          ];
        }
      } else {
        if (input[29] <= 0.09409721940755844) {
          var27 = [
            0.0, 0.0, 0.0, 0.006024096385542169, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04819277108433735,
            0.6927710843373494, 0.25301204819277107, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.12735849056603774, 0.33490566037735847, 0.16037735849056603,
            0.16981132075471697, 0.18867924528301888, 0.018867924528301886, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[41] <= 0.22847221791744232) {
      if (input[20] <= 0.2383333295583725) {
        if (input[6] <= 0.027449999935925007) {
          var27 = [
            0.0, 0.0, 0.0, 0.013071895424836602, 0.006535947712418301,
            0.0196078431372549, 0.013071895424836602, 0.13725490196078433,
            0.27450980392156865, 0.20915032679738563, 0.32679738562091504, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.06666666666666667, 0.4666666666666667,
            0.13333333333333333, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.39671093225479126) {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.23076923076923078, 0.0,
            0.5384615384615384, 0.07692307692307693, 0.15384615384615385, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.014285714285714285, 0.0, 0.0, 0.0,
            0.08571428571428572, 0.8142857142857143, 0.08571428571428572, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.04645000025629997) {
        if (input[6] <= 0.058549998328089714) {
          var27 = [
            0.0, 0.0, 0.0, 0.04032258064516129, 0.024193548387096774,
            0.0967741935483871, 0.6854838709677419, 0.13709677419354838,
            0.016129032258064516, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.4722656160593033) {
          var27 = [
            0.0, 0.0, 0.0, 0.7, 0.3, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var27 = [
            0.0, 0.0, 0.0, 0.18421052631578946, 0.2894736842105263, 0.5,
            0.02631578947368421, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var28;
  if (input[12] <= 0.04374999925494194) {
    if (input[2] <= 0.23993055522441864) {
      if (input[9] <= 0.1310499981045723) {
        if (input[7] <= 0.3420981615781784) {
          var28 = [
            0.008928571428571428, 0.22321428571428573, 0.026785714285714284,
            0.17857142857142858, 0.09821428571428571, 0.044642857142857144,
            0.026785714285714284, 0.008928571428571428, 0.0, 0.0,
            0.044642857142857144, 0.21428571428571427, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.008928571428571428, 0.008928571428571428, 0.0,
            0.10714285714285714,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.01652892561983471, 0.008264462809917356, 0.0,
            0.008264462809917356, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01652892561983471, 0.0, 0.01652892561983471, 0.09917355371900827,
            0.03305785123966942, 0.7768595041322314, 0.008264462809917356,
            0.01652892561983471,
          ];
        }
      } else {
        if (input[30] <= 0.05195000022649765) {
          var28 = [
            0.0, 0.0, 0.0, 0.2631578947368421, 0.21052631578947367,
            0.15789473684210525, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.21052631578947367, 0.15789473684210525, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.02912621359223301, 0.0, 0.009708737864077669, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.009708737864077669, 0.9320388349514563, 0.019417475728155338,
          ];
        }
      }
    } else {
      if (input[7] <= 0.04277343675494194) {
        if (input[23] <= 0.15173611044883728) {
          var28 = [
            0.5037593984962406, 0.0, 0.3458646616541353, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.14285714285714285, 0.0,
            0.007518796992481203, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0821917808219178, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.4794520547945205, 0.3287671232876712,
            0.0547945205479452, 0.0410958904109589, 0.0136986301369863, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07854999974370003) {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.14285714285714285, 0.5476190476190477, 0.16071428571428573,
            0.047619047619047616, 0.03571428571428571, 0.06547619047619048, 0.0,
            0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.01818181818181818, 0.0, 0.2545454545454545,
            0.6181818181818182, 0.10909090909090909, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[30] <= 0.004449999891221523) {
      if (input[39] <= 0.010950000025331974) {
        if (input[19] <= 0.4393046796321869) {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07142857142857142,
            0.35714285714285715, 0.23809523809523808, 0.3333333333333333, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07975460122699386,
            0.1165644171779141, 0.07975460122699386, 0.5828220858895705,
            0.1411042944785276, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[41] <= 0.7763889133930206) {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2777777777777778, 0.5555555555555556,
            0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.75, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.07115000113844872) {
        if (input[5] <= 0.5805555582046509) {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.6, 0.2, 0.0, 0.2, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.03361344537815126, 0.058823529411764705,
            0.7815126050420168, 0.058823529411764705, 0.06722689075630252, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.07670000195503235) {
          var28 = [
            0.0, 0.0, 0.0, 0.125, 0.34375, 0.4375, 0.09375, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var28 = [
            0.0, 0.0, 0.0, 0.0, 0.05555555555555555, 0.16666666666666666,
            0.7222222222222222, 0.05555555555555555, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var29;
  if (input[12] <= 0.04340000078082085) {
    if (input[29] <= 0.0954861119389534) {
      if (input[22] <= 0.10508035868406296) {
        if (input[9] <= 0.13900000602006912) {
          var29 = [
            0.0, 0.25, 0.0, 0.11206896551724138, 0.07758620689655173,
            0.05172413793103448, 0.04310344827586207, 0.017241379310344827, 0.0,
            0.0, 0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.017241379310344827,
            0.017241379310344827, 0.16379310344827586,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.030612244897959183, 0.05102040816326531,
            0.02040816326530612, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.05102040816326531, 0.8469387755102041, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.9014409482479095) {
          var29 = [
            0.0, 0.0, 0.0, 0.05128205128205128, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02564102564102564,
            0.06837606837606838, 0.8461538461538461, 0.008547008547008548, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.05, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.85, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.029249999672174454) {
        if (input[29] <= 0.38159723579883575) {
          var29 = [
            0.12244897959183673, 0.0, 0.7142857142857143, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16326530612244897, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.6764705882352942, 0.00980392156862745, 0.16666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.13725490196078433, 0.0,
            0.00980392156862745, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.056640625) {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5,
            0.375, 0.027777777777777776, 0.06944444444444445,
            0.027777777777777776, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11764705882352941, 0.4253393665158371, 0.19004524886877827,
            0.14479638009049775, 0.1085972850678733, 0.013574660633484163, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.01510000042617321) {
      if (input[5] <= 0.2681490480899811) {
        if (input[19] <= 0.2251874953508377) {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.045454545454545456, 0.0,
            0.09090909090909091, 0.8636363636363636, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.65234375) {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.006944444444444444, 0.034722222222222224,
            0.10416666666666667, 0.11805555555555555, 0.18055555555555555,
            0.5416666666666666, 0.013888888888888888, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.125, 0.375, 0.4, 0.1, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[38] <= 0.49872222542762756) {
        if (input[18] <= 0.0694500021636486) {
          var29 = [
            0.0, 0.0, 0.0, 0.0379746835443038, 0.11392405063291139,
            0.379746835443038, 0.43037974683544306, 0.0379746835443038, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.022727272727272728, 0.0, 0.045454545454545456,
            0.6818181818181818, 0.20454545454545456, 0.045454545454545456, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.3514973223209381) {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.03278688524590164, 0.11475409836065574,
            0.8360655737704918, 0.01639344262295082, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var29 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var30;
  if (input[6] <= 0.13475000113248825) {
    if (input[0] <= 0.08980000019073486) {
      if (input[11] <= 0.11701388657093048) {
        if (input[4] <= 0.14707031846046448) {
          var30 = [
            0.0, 0.36082474226804123, 0.0, 0.0, 0.0, 0.0, 0.010309278350515464,
            0.0, 0.0, 0.0, 0.041237113402061855, 0.4845360824742268,
            0.020618556701030927, 0.010309278350515464, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.07216494845360824,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.015706806282722512, 0.0, 0.015706806282722512,
            0.13612565445026178, 0.15706806282722513, 0.19895287958115182,
            0.46596858638743455, 0.010471204188481676, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.09618055447936058) {
          var30 = [
            0.0, 0.0, 0.0, 0.1625615763546798, 0.059113300492610835,
            0.09359605911330049, 0.024630541871921183, 0.0049261083743842365,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.019704433497536946,
            0.04926108374384237, 0.10837438423645321, 0.45320197044334976,
            0.014778325123152709, 0.009852216748768473,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.06341463414634146, 0.12195121951219512,
            0.15609756097560976, 0.526829268292683, 0.11707317073170732,
            0.014634146341463415, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[23] <= 0.15312500298023224) {
        if (input[15] <= 0.0625) {
          var30 = [
            0.25, 0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var30 = [
            0.6122448979591837, 0.0, 0.29591836734693877, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.09183673469387756, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.10381944477558136) {
          var30 = [
            0.0, 0.0, 0.02631578947368421, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.32456140350877194, 0.38596491228070173,
            0.16666666666666666, 0.05263157894736842, 0.03508771929824561,
            0.008771929824561403, 0.0, 0.0, 0.0,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.050359712230215826, 0.48201438848920863, 0.2733812949640288,
            0.18705035971223022, 0.007194244604316547, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.11634999886155128) {
      if (input[22] <= 0.29023437201976776) {
        if (input[40] <= 0.10898437350988388) {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3333333333333333, 0.3333333333333333,
            0.3333333333333333, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var30 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[41] <= 0.8554480671882629) {
        if (input[30] <= 0.03370000049471855) {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.011904761904761904, 0.9880952380952381,
            0.0,
          ];
        }
      } else {
        if (input[21] <= 0.07579999975860119) {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var30 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.9, 0.1, 0.0,
          ];
        }
      }
    }
  }
  var var31;
  if (input[18] <= 0.01145000010728836) {
    if (input[9] <= 0.1310499981045723) {
      if (input[9] <= 0.05295000039041042) {
        if (input[32] <= 0.1267361119389534) {
          var31 = [
            0.2915129151291513, 0.1033210332103321, 0.14391143911439114, 0.0,
            0.0, 0.0, 0.01107011070110701, 0.0, 0.0, 0.0, 0.025830258302583026,
            0.0, 0.16974169741697417, 0.11808118081180811, 0.07011070110701106,
            0.02214022140221402, 0.04428044280442804, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.00684931506849315, 0.0, 0.00684931506849315,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06164383561643835,
            0.4315068493150685, 0.136986301369863, 0.1095890410958904,
            0.10273972602739725, 0.00684931506849315, 0.0, 0.136986301369863,
          ];
        }
      } else {
        if (input[16] <= 0.1328125) {
          var31 = [
            0.0, 0.0, 0.0, 0.13380281690140844, 0.02112676056338028,
            0.056338028169014086, 0.007042253521126761, 0.014084507042253521,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007042253521126761,
            0.7535211267605634, 0.007042253521126761, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.07407407407407407, 0.3888888888888889,
            0.5370370370370371, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.058150000870227814) {
        if (input[5] <= 0.2725694477558136) {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.09090909090909091, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5454545454545454,
            0.36363636363636365, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.5714285714285714, 0.14285714285714285,
            0.2857142857142857, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.11249999701976776) {
          var31 = [
            0.0, 0.0, 0.0, 0.75, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.030612244897959183, 0.9693877551020408,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[26] <= 0.11944444477558136) {
      if (input[13] <= 0.1474609375) {
        if (input[4] <= 0.24062499403953552) {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.44015926122665405) {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.0, 0.05, 0.7, 0.15, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.013513513513513514, 0.0,
            0.0472972972972973, 0.18243243243243243, 0.6013513513513513,
            0.1554054054054054, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.038850000128149986) {
        if (input[9] <= 0.02845000009983778) {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.022222222222222223, 0.06666666666666667,
            0.08888888888888889, 0.3333333333333333, 0.4888888888888889, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.018867924528301886, 0.10062893081761007,
            0.7232704402515723, 0.1509433962264151, 0.006289308176100629, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.058150000870227814) {
          var31 = [
            0.0, 0.0, 0.0, 0.46153846153846156, 0.3333333333333333,
            0.1794871794871795, 0.02564102564102564, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var31 = [
            0.0, 0.0, 0.0, 0.0, 0.125, 0.75, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var32;
  if (input[5] <= 0.5634722113609314) {
    if (input[16] <= 0.12949219346046448) {
      if (input[6] <= 0.1143999993801117) {
        if (input[10] <= 0.25041744112968445) {
          var32 = [
            0.010752688172043012, 0.0, 0.07526881720430108, 0.0,
            0.021505376344086023, 0.0, 0.010752688172043012, 0.0,
            0.021505376344086023, 0.0, 0.03225806451612903, 0.6236559139784946,
            0.0, 0.021505376344086023, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1827956989247312,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.06299212598425197, 0.031496062992125984,
            0.03937007874015748, 0.015748031496062992, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.015748031496062992, 0.8267716535433071,
            0.007874015748031496, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.1376953125) {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.08088235294117647, 0.8897058823529411,
            0.029411764705882353,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[0] <= 0.1176999993622303) {
        if (input[6] <= 0.01549999974668026) {
          var32 = [
            0.0, 0.9285714285714286, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.07142857142857142, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.09333333333333334, 0.06666666666666667, 0.3466666666666667,
            0.49333333333333335, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.014150000177323818) {
          var32 = [
            0.3352272727272727, 0.0, 0.21022727272727273, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2727272727272727, 0.13068181818181818,
            0.045454545454545456, 0.005681818181818182, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16806722689075632, 0.5210084033613446, 0.25210084033613445,
            0.05042016806722689, 0.008403361344537815, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.029500000178813934) {
      if (input[19] <= 0.669921875) {
        if (input[13] <= 0.4565449506044388) {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02, 0.06, 0.38, 0.38, 0.16, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.00847457627118644, 0.0, 0.0,
            0.0847457627118644, 0.11016949152542373, 0.1016949152542373,
            0.6440677966101694, 0.05084745762711865, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.4305555522441864) {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.10526315789473684,
            0.7894736842105263, 0.10526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10526315789473684, 0.05263157894736842,
            0.05263157894736842, 0.21052631578947367, 0.5789473684210527, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[12] <= 0.06689999997615814) {
        if (input[32] <= 0.34254807233810425) {
          var32 = [
            0.0, 0.0, 0.0, 0.5084745762711864, 0.288135593220339,
            0.1016949152542373, 0.0847457627118644, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01694915254237288, 0.0, 0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[19] <= 0.1868463009595871) {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.6153846153846154, 0.3076923076923077,
            0.07692307692307693, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var32 = [
            0.0, 0.0, 0.0, 0.0, 0.041666666666666664, 0.18055555555555555,
            0.5972222222222222, 0.1388888888888889, 0.041666666666666664, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var33;
  if (input[28] <= 0.21682751178741455) {
    if (input[22] <= 0.12211650609970093) {
      if (input[6] <= 0.11624999716877937) {
        if (input[9] <= 0.033399999141693115) {
          var33 = [
            0.0, 0.0711864406779661, 0.0, 0.0, 0.006779661016949152, 0.0,
            0.020338983050847456, 0.07796610169491526, 0.1152542372881356,
            0.14915254237288136, 0.3389830508474576, 0.17966101694915254, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04067796610169491,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.1484375, 0.11328125, 0.1953125, 0.41015625, 0.0625,
            0.02734375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02734375,
            0.015625, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.1173115074634552) {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.02631578947368421, 0.9736842105263158,
            0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.09610000252723694) {
        if (input[22] <= 0.9151957333087921) {
          var33 = [
            0.0, 0.0, 0.0, 0.05405405405405406, 0.018018018018018018, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.9279279279279279, 0.0, 0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.14285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8571428571428571,
            0.0,
          ];
        }
      } else {
        if (input[11] <= 0.5528194308280945) {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.3157894736842105, 0.6842105263157895, 0.0, 0.0,
            0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.38461538461538464, 0.0, 0.23076923076923078,
            0.38461538461538464, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.13958333432674408) {
      if (input[17] <= 0.1682114452123642) {
        if (input[35] <= 0.4572916775941849) {
          var33 = [
            0.09090909090909091, 0.0, 0.2727272727272727, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.6363636363636364, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.8387096774193549, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.16129032258064516, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[1] <= 0.5785156190395355) {
          var33 = [
            0.7564102564102564, 0.01282051282051282, 0.20512820512820512, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01282051282051282, 0.0,
            0.01282051282051282, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.4444444444444444, 0.0, 0.2222222222222222, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[28] <= 0.5804687440395355) {
        if (input[30] <= 0.0038499999791383743) {
          var33 = [
            0.0, 0.0, 0.022727272727272728, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3068181818181818, 0.48863636363636365,
            0.11363636363636363, 0.045454545454545456, 0.022727272727272728,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01694915254237288, 0.2711864406779661, 0.4067796610169492,
            0.13559322033898305, 0.1694915254237288, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[21] <= 0.12019999697804451) {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07446808510638298, 0.1702127659574468, 0.6382978723404256,
            0.0851063829787234, 0.031914893617021274, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var33 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.07317073170731707, 0.17073170731707318, 0.34146341463414637,
            0.4146341463414634, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var34;
  if (input[29] <= 0.08819444477558136) {
    if (input[6] <= 0.11425000056624413) {
      if (input[40] <= 0.04179687425494194) {
        if (input[14] <= 0.6706503331661224) {
          var34 = [
            0.0, 0.0945945945945946, 0.0, 0.010135135135135136,
            0.033783783783783786, 0.02027027027027027, 0.05067567567567568,
            0.04391891891891892, 0.13513513513513514, 0.12837837837837837,
            0.34459459459459457, 0.08445945945945946, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.006756756756756757, 0.0, 0.0472972972972973,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[21] <= 0.04515000060200691) {
          var34 = [
            0.0, 0.0, 0.0, 0.11764705882352941, 0.15384615384615385,
            0.1493212669683258, 0.46153846153846156, 0.049773755656108594,
            0.00904977375565611, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.049773755656108594, 0.00904977375565611, 0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.010526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.021052631578947368,
            0.06315789473684211, 0.9052631578947369, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[17] <= 0.14305555820465088) {
        if (input[32] <= 0.4468149095773697) {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.046511627906976744, 0.9534883720930233,
            0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.5,
          ];
        }
      } else {
        var34 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[32] <= 0.0520833320915699) {
      if (input[28] <= 0.40312500298023224) {
        if (input[8] <= 0.16944444179534912) {
          var34 = [
            0.02564102564102564, 0.0, 0.20512820512820512, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.10256410256410256,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.7058823529411765, 0.058823529411764705, 0.0, 0.23529411764705882,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.06894531100988388) {
          var34 = [
            0.5344827586206896, 0.008620689655172414, 0.3103448275862069, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.12931034482758622, 0.0,
            0.017241379310344827, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1875, 0.3375, 0.275, 0.0875, 0.075, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.34617967903614044) {
        if (input[6] <= 0.0722000002861023) {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.046153846153846156, 0.8923076923076924, 0.06153846153846154, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.04, 0.2, 0.36, 0.4, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.05763888731598854) {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0,
          ];
        } else {
          var34 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07368421052631578, 0.17894736842105263, 0.3157894736842105,
            0.24210526315789474, 0.18947368421052632, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var35;
  if (input[3] <= 0.06500000134110451) {
    if (input[15] <= 0.01269999984651804) {
      if (input[18] <= 0.10040000081062317) {
        if (input[9] <= 0.1339999958872795) {
          var35 = [
            0.014388489208633094, 0.0, 0.02877697841726619,
            0.007194244604316547, 0.02877697841726619, 0.014388489208633094,
            0.014388489208633094, 0.014388489208633094, 0.0, 0.0,
            0.03597122302158273, 0.0, 0.0, 0.0, 0.014388489208633094, 0.0,
            0.014388489208633094, 0.03597122302158273, 0.6762589928057554,
            0.014388489208633094, 0.08633093525179857,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.009615384615384616, 0.009615384615384616, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04807692307692308, 0.9326923076923077, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.598931074142456) {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[23] <= 0.13958333432674408) {
        if (input[16] <= 0.5904296934604645) {
          var35 = [
            0.39655172413793105, 0.19827586206896552, 0.27586206896551724, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11206896551724138, 0.0,
            0.017241379310344827, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.05069444328546524) {
          var35 = [
            0.0, 0.0, 0.03361344537815126, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.2605042016806723, 0.4369747899159664,
            0.15126050420168066, 0.058823529411764705, 0.058823529411764705,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01639344262295082, 0.3551912568306011, 0.22404371584699453,
            0.23497267759562843, 0.16939890710382513, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[6] <= 0.009650000371038914) {
      if (input[10] <= 0.05253906175494194) {
        if (input[25] <= 0.08124999701976776) {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.006993006993006993, 0.0,
            0.027972027972027972, 0.25874125874125875, 0.6923076923076923,
            0.013986013986013986, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.020833333333333332, 0.0, 0.020833333333333332,
            0.3958333333333333, 0.5208333333333334, 0.041666666666666664, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.04755000025033951) {
          var35 = [
            0.0, 0.0, 0.0, 0.3333333333333333, 0.30303030303030304,
            0.21212121212121213, 0.09090909090909091, 0.06060606060606061, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0851063829787234, 0.3404255319148936,
            0.44680851063829785, 0.1276595744680851, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.04635000042617321) {
        if (input[12] <= 0.048649998381733894) {
          var35 = [
            0.0, 0.0, 0.0, 0.3684210526315789, 0.3684210526315789,
            0.15789473684210525, 0.10526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.008771929824561403, 0.07017543859649122,
            0.15789473684210525, 0.7192982456140351, 0.043859649122807015, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.46736110746860504) {
          var35 = [
            0.0, 0.0, 0.0, 0.08, 0.4, 0.44, 0.08, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var35 = [
            0.0, 0.0, 0.0, 0.5384615384615384, 0.34615384615384615,
            0.07692307692307693, 0.038461538461538464, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var36;
  if (input[28] <= 0.0693359375) {
    if (input[4] <= 0.17630838602781296) {
      if (input[6] <= 0.12164999917149544) {
        if (input[21] <= 0.03894999995827675) {
          var36 = [
            0.0, 0.2542372881355932, 0.0, 0.025423728813559324,
            0.0423728813559322, 0.00847457627118644, 0.00847457627118644,
            0.00847457627118644, 0.00847457627118644, 0.0, 0.09322033898305085,
            0.3898305084745763, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00847457627118644, 0.15254237288135594,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.010526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05263157894736842,
            0.9157894736842105, 0.021052631578947368, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.02570000011473894) {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.017391304347826087, 0.017391304347826087,
            0.08695652173913043, 0.8695652173913043, 0.008695652173913044,
          ];
        }
      }
    } else {
      if (input[9] <= 0.029200000688433647) {
        if (input[39] <= 0.03085000067949295) {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.009523809523809525, 0.1,
            0.15714285714285714, 0.19523809523809524, 0.5095238095238095,
            0.02857142857142857, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.11562500149011612) {
          var36 = [
            0.0, 0.0, 0.0, 0.3230769230769231, 0.23076923076923078, 0.2,
            0.09230769230769231, 0.015384615384615385, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13846153846153847, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.03954802259887006, 0.0903954802259887,
            0.12429378531073447, 0.6045197740112994, 0.11299435028248588,
            0.02824858757062147, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.02187499962747097) {
      if (input[30] <= 0.006049999967217445) {
        if (input[8] <= 0.16944444179534912) {
          var36 = [
            0.37333333333333335, 0.02, 0.29333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.22666666666666666, 0.05333333333333334,
            0.02, 0.013333333333333334, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6470588235294118, 0.058823529411764705, 0.0, 0.29411764705882354,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.06324999779462814) {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.18181818181818182, 0.45454545454545453, 0.18181818181818182,
            0.18181818181818182, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.09319999814033508) {
        if (input[8] <= 0.3758814036846161) {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.9090909090909091, 0.09090909090909091, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.125, 0.6136363636363636, 0.07954545454545454, 0.11363636363636363,
            0.0, 0.06818181818181818, 0.0, 0.0,
          ];
        }
      } else {
        if (input[33] <= 0.017299999482929707) {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.020833333333333332, 0.020833333333333332, 0.25,
            0.20833333333333334, 0.5, 0.0, 0.0, 0.0,
          ];
        } else {
          var36 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.2857142857142857, 0.3492063492063492, 0.2698412698412698,
            0.09523809523809523, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var37;
  if (input[29] <= 0.08819444477558136) {
    if (input[18] <= 0.03739999979734421) {
      if (input[3] <= 0.06640000268816948) {
        if (input[9] <= 0.12450000271201134) {
          var37 = [
            0.0, 0.13529411764705881, 0.0, 0.01764705882352941, 0.0, 0.0,
            0.0058823529411764705, 0.011764705882352941, 0.0, 0.0,
            0.0058823529411764705, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.023529411764705882, 0.052941176470588235, 0.6470588235294118,
            0.011764705882352941, 0.08823529411764706,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.018691588785046728, 0.028037383177570093, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.08411214953271028, 0.8504672897196262, 0.018691588785046728,
          ];
        }
      } else {
        if (input[12] <= 0.04884999990463257) {
          var37 = [
            0.0, 0.0, 0.0, 0.5918367346938775, 0.2653061224489796,
            0.12244897959183673, 0.02040816326530612, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.2, 0.25, 0.3, 0.05, 0.1, 0.0, 0.1, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[26] <= 0.3305555582046509) {
        if (input[19] <= 0.5063867568969727) {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.035398230088495575, 0.0,
            0.017699115044247787, 0.168141592920354, 0.30973451327433627,
            0.4690265486725664, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.011904761904761904, 0.0,
            0.10714285714285714, 0.23809523809523808, 0.5833333333333334,
            0.05952380952380952, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.05332031100988388) {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.03773584905660377, 0.05660377358490566,
            0.03773584905660377, 0.3018867924528302, 0.5660377358490566, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.012269938650306749, 0.11042944785276074,
            0.17791411042944785, 0.5460122699386503, 0.11042944785276074,
            0.04294478527607362, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[22] <= 0.07695312798023224) {
      if (input[33] <= 0.02560000028461218) {
        if (input[34] <= 0.24348192662000656) {
          var37 = [
            0.05555555555555555, 0.1111111111111111, 0.5, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.7142857142857143, 0.0, 0.20238095238095238, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.08333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.19513888657093048) {
          var37 = [
            0.041666666666666664, 0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2916666666666667, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.061250001192092896) {
        if (input[31] <= 0.13144531473517418) {
          var37 = [
            0.0, 0.0, 0.037037037037037035, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.2222222222222222, 0.4351851851851852,
            0.21296296296296297, 0.027777777777777776, 0.06481481481481481, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04, 0.4866666666666667, 0.22, 0.16, 0.08, 0.013333333333333334,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[40] <= 0.8589843511581421) {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.07894736842105263, 0.868421052631579,
            0.05263157894736842, 0.0, 0.0,
          ];
        } else {
          var37 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var38;
  if (input[18] <= 0.032749999314546585) {
    if (input[28] <= 0.06894531100988388) {
      if (input[22] <= 0.09900526702404022) {
        if (input[17] <= 0.18854166567325592) {
          var38 = [
            0.0, 0.0, 0.0, 0.11805555555555555, 0.09027777777777778, 0.0625,
            0.041666666666666664, 0.020833333333333332, 0.006944444444444444,
            0.013888888888888888, 0.013888888888888888, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.041666666666666664, 0.4652777777777778, 0.125,
          ];
        } else {
          var38 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[33] <= 0.0007999999797903001) {
          var38 = [
            0.0, 0.0, 0.0, 0.09090909090909091, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015151515151515152,
            0.030303030303030304, 0.6666666666666666, 0.18181818181818182,
            0.015151515151515152,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.005100000184029341) {
        if (input[22] <= 0.06894531100988388) {
          var38 = [
            0.544, 0.008, 0.392, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.056, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.024390243902439025, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.35365853658536583, 0.3048780487804878,
            0.12195121951219512, 0.0, 0.08536585365853659, 0.10975609756097561,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.04910000041127205) {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.15476190476190477, 0.49404761904761907, 0.23214285714285715,
            0.08333333333333333, 0.02976190476190476, 0.005952380952380952, 0.0,
            0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.15873015873015872, 0.25396825396825395,
            0.5873015873015873, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[39] <= 0.005150000099092722) {
      if (input[3] <= 0.044349998235702515) {
        if (input[18] <= 0.18155000358819962) {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.0, 0.2, 0.0, 0.4, 0.3, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.022727272727272728, 0.9772727272727273, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.5733935832977295) {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.007352941176470588, 0.0, 0.029411764705882353,
            0.03676470588235294, 0.10294117647058823, 0.19852941176470587,
            0.6176470588235294, 0.007352941176470588, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.015873015873015872, 0.0, 0.047619047619047616,
            0.031746031746031744, 0.19047619047619047, 0.15873015873015872,
            0.36507936507936506, 0.19047619047619047, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[37] <= 0.43632812798023224) {
        if (input[26] <= 0.4948984980583191) {
          var38 = [
            0.0, 0.0, 0.0, 0.08823529411764706, 0.20588235294117646,
            0.6176470588235294, 0.08823529411764706, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.07692307692307693, 0.038461538461538464,
            0.23076923076923078, 0.5384615384615384, 0.11538461538461539, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.07175000011920929) {
          var38 = [
            0.0, 0.0, 0.0, 0.0, 0.058333333333333334, 0.1, 0.725, 0.1,
            0.016666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var38 = [
            0.0, 0.0, 0.0, 0.041666666666666664, 0.16666666666666666,
            0.4166666666666667, 0.375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var39;
  if (input[9] <= 0.14999999850988388) {
    if (input[22] <= 0.09900526702404022) {
      if (input[1] <= 0.30195312201976776) {
        if (input[7] <= 0.02617187425494194) {
          var39 = [
            0.002702702702702703, 0.07837837837837838, 0.0, 0.01891891891891892,
            0.01891891891891892, 0.024324324324324326, 0.0918918918918919,
            0.08648648648648649, 0.12702702702702703, 0.11351351351351352,
            0.25405405405405407, 0.14054054054054055, 0.005405405405405406, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.002702702702702703, 0.0, 0.03513513513513514,
          ];
        } else {
          var39 = [
            0.0, 0.0, 0.0, 0.12087912087912088, 0.08241758241758242,
            0.17582417582417584, 0.4945054945054945, 0.06593406593406594, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.005494505494505495, 0.0, 0.0, 0.0,
            0.005494505494505495, 0.04395604395604396, 0.005494505494505495,
          ];
        }
      } else {
        if (input[1] <= 0.45643578469753265) {
          var39 = [
            0.16666666666666666, 0.0, 0.7857142857142857, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.047619047619047616, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var39 = [
            0.6404494382022472, 0.0, 0.19101123595505617, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.15730337078651685, 0.0,
            0.011235955056179775, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[26] <= 0.438773438334465) {
        if (input[39] <= 0.004399999976158142) {
          var39 = [
            0.0, 0.0, 0.0547945205479452, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.5068493150684932, 0.2465753424657534,
            0.0547945205479452, 0.0410958904109589, 0.0410958904109589,
            0.0547945205479452, 0.0, 0.0, 0.0,
          ];
        } else {
          var39 = [
            0.0, 0.0, 0.0, 0.02654867256637168, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.12831858407079647, 0.3407079646017699,
            0.18584070796460178, 0.11061946902654868, 0.13274336283185842,
            0.07079646017699115, 0.004424778761061947, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.7490084171295166) {
          var39 = [
            0.0, 0.0, 0.0, 0.044444444444444446, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03333333333333333,
            0.9222222222222223, 0.0, 0.0,
          ];
        } else {
          var39 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.8333333333333334, 0.16666666666666666, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[7] <= 0.15078124776482582) {
      if (input[24] <= 0.24204999208450317) {
        if (input[10] <= 0.7734335362911224) {
          var39 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var39 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      } else {
        var39 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[36] <= 0.015250000171363354) {
        if (input[21] <= 0.08889999985694885) {
          var39 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var39 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.75, 0.0,
          ];
        }
      } else {
        var39 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var40;
  if (input[28] <= 0.06894531100988388) {
    if (input[6] <= 0.1143999993801117) {
      if (input[11] <= 0.15243054926395416) {
        if (input[20] <= 0.3645084947347641) {
          var40 = [
            0.0, 0.13777777777777778, 0.0, 0.0, 0.0044444444444444444, 0.0,
            0.017777777777777778, 0.06666666666666667, 0.12,
            0.14222222222222222, 0.4311111111111111, 0.0044444444444444444, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0044444444444444444, 0.0,
            0.07111111111111111,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09230769230769231, 0.9076923076923077, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.09783339127898216) {
          var40 = [
            0.0, 0.0, 0.0, 0.13596491228070176, 0.09649122807017543,
            0.2236842105263158, 0.4473684210526316, 0.06578947368421052,
            0.017543859649122806, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0043859649122807015, 0.0043859649122807015, 0.0043859649122807015,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.05555555555555555, 0.047619047619047616, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007936507936507936,
            0.015873015873015872, 0.873015873015873, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.11425000056624413) {
        if (input[16] <= 0.1328125) {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[23] <= 0.7427662014961243) {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.018867924528301886, 0.9811320754716981,
            0.0,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.13958333432674408) {
      if (input[28] <= 0.5464843809604645) {
        if (input[16] <= 0.46171875298023224) {
          var40 = [
            0.21621621621621623, 0.0, 0.5945945945945946, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13513513513513514, 0.0,
            0.05405405405405406, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var40 = [
            0.7936507936507936, 0.07936507936507936, 0.09523809523809523, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.031746031746031744, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[16] <= 0.5824218690395355) {
          var40 = [
            0.2, 0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.55,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.9375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0625, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.04454999975860119) {
        if (input[8] <= 0.359375) {
          var40 = [
            0.0, 0.0, 0.02631578947368421, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3684210526315789, 0.40789473684210525,
            0.07894736842105263, 0.07894736842105263, 0.039473684210526314, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.08074534161490683, 0.6211180124223602, 0.16149068322981366,
            0.06832298136645963, 0.055900621118012424, 0.012422360248447204,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.12128906324505806) {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09090909090909091, 0.09090909090909091,
            0.8181818181818182, 0.0, 0.0, 0.0,
          ];
        } else {
          var40 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.07692307692307693, 0.28205128205128205, 0.4358974358974359,
            0.15384615384615385, 0.05128205128205128, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var41;
  if (input[14] <= 0.32604166865348816) {
    if (input[8] <= 0.3916666656732559) {
      if (input[29] <= 0.08819444477558136) {
        if (input[16] <= 0.22031250596046448) {
          var41 = [
            0.0, 0.0, 0.0, 0.15789473684210525, 0.18421052631578946,
            0.09210526315789473, 0.013157894736842105, 0.0, 0.0, 0.0,
            0.039473684210526314, 0.39473684210526316, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.02631578947368421, 0.0, 0.09210526315789473,
          ];
        } else {
          var41 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[41] <= 0.3031249940395355) {
          var41 = [
            0.31016042780748665, 0.0106951871657754, 0.2887700534759358, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.27807486631016043,
            0.058823529411764705, 0.016042780748663103, 0.03208556149732621,
            0.0053475935828877, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.7, 0.15, 0.1, 0.05, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[17] <= 0.04618055373430252) {
        if (input[30] <= 0.06185000017285347) {
          var41 = [
            0.0, 0.0, 0.0, 0.09333333333333334, 0.05333333333333334, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.006666666666666667,
            0.02666666666666667, 0.7333333333333333, 0.08666666666666667, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.020202020202020204, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.010101010101010102,
            0.09090909090909091, 0.8282828282828283, 0.050505050505050504,
          ];
        }
      } else {
        if (input[29] <= 0.38620801270008087) {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0761904761904762, 0.10476190476190476, 0.26666666666666666,
            0.21904761904761905, 0.3333333333333333, 0.0, 0.0, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06896551724137931, 0.6781609195402298, 0.12643678160919541,
            0.08045977011494253, 0.04597701149425287, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.05332031100988388) {
      if (input[31] <= 0.265625) {
        if (input[20] <= 0.3649647384881973) {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.01015228426395939, 0.015228426395939087,
            0.06598984771573604, 0.17766497461928935, 0.2182741116751269,
            0.5025380710659898, 0.01015228426395939, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.23684210526315788, 0.7631578947368421, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.20543981343507767) {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.2222222222222222, 0.0, 0.3333333333333333,
            0.0, 0.4444444444444444, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[12] <= 0.07330000028014183) {
        if (input[9] <= 0.08514999970793724) {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.9090909090909091, 0.09090909090909091, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.875, 0.0, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.24310000240802765) {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.05625, 0.2, 0.66875, 0.0625, 0.0125, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var41 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var42;
  if (input[13] <= 0.14691884070634842) {
    if (input[6] <= 0.1148499995470047) {
      if (input[9] <= 0.053449999541044235) {
        if (input[8] <= 0.2562499940395355) {
          var42 = [
            0.242914979757085, 0.10526315789473684, 0.19433198380566802, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.004048582995951417,
            0.12955465587044535, 0.1417004048582996, 0.11336032388663968,
            0.008097165991902834, 0.012145748987854251, 0.008097165991902834,
            0.0, 0.0, 0.0, 0.04048582995951417,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.005952380952380952, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.13095238095238096, 0.5059523809523809,
            0.17857142857142858, 0.1130952380952381, 0.05357142857142857,
            0.011904761904761904, 0.0, 0.0,
          ];
        }
      } else {
        if (input[23] <= 0.4523755609989166) {
          var42 = [
            0.0, 0.0, 0.0, 0.5217391304347826, 0.2608695652173913,
            0.043478260869565216, 0.043478260869565216, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.028985507246376812, 0.07246376811594203,
            0.014492753623188406, 0.0, 0.014492753623188406,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.016260162601626018, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.008130081300813009, 0.008130081300813009,
            0.10569105691056911, 0.12195121951219512, 0.7235772357723578,
            0.016260162601626018, 0.0,
          ];
        }
      }
    } else {
      if (input[29] <= 0.13472221791744232) {
        if (input[41] <= 0.8631444871425629) {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.00980392156862745, 0.00980392156862745,
            0.029411764705882353, 0.9313725490196079, 0.0196078431372549,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.75, 0.25, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.5005859434604645) {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3333333333333333, 0.6666666666666666, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.15243054926395416) {
      if (input[5] <= 0.2681490480899811) {
        if (input[37] <= 0.07662585563957691) {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04, 0.96, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.75, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[26] <= 0.11944444477558136) {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.012195121951219513, 0.0,
            0.018292682926829267, 0.29878048780487804, 0.6524390243902439,
            0.018292682926829267, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.02040816326530612, 0.04081632653061224,
            0.061224489795918366, 0.30612244897959184, 0.5102040816326531,
            0.061224489795918366, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[18] <= 0.06420000270009041) {
        if (input[25] <= 0.6301640570163727) {
          var42 = [
            0.0, 0.0, 0.0, 0.1111111111111111, 0.13580246913580246,
            0.4444444444444444, 0.2345679012345679, 0.07407407407407407, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.2975499927997589) {
          var42 = [
            0.0, 0.0, 0.0, 0.011627906976744186, 0.023255813953488372,
            0.10465116279069768, 0.7441860465116279, 0.10465116279069768,
            0.011627906976744186, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var42 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11764705882352941, 0.35294117647058826,
            0.5294117647058824, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var43;
  if (input[15] <= 0.0142000000923872) {
    if (input[22] <= 0.09932077303528786) {
      if (input[10] <= 0.05253906175494194) {
        if (input[20] <= 0.365945041179657) {
          var43 = [
            0.009302325581395349, 0.0, 0.027906976744186046, 0.0,
            0.004651162790697674, 0.009302325581395349, 0.03255813953488372,
            0.05581395348837209, 0.14883720930232558, 0.2, 0.4325581395348837,
            0.009302325581395349, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06976744186046512,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10714285714285714, 0.8928571428571429, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.1456499993801117) {
          var43 = [
            0.0, 0.0, 0.0, 0.10683760683760683, 0.1581196581196581,
            0.21367521367521367, 0.3888888888888889, 0.08547008547008547,
            0.02564102564102564, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.004273504273504274, 0.017094017094017096, 0.0,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.043010752688172046, 0.08602150537634409,
            0.010752688172043012, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.010752688172043012, 0.8494623655913979, 0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.1869020015001297) {
        if (input[5] <= 0.5944444537162781) {
          var43 = [
            0.0, 0.0, 0.0, 0.2857142857142857, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.21428571428571427, 0.07142857142857142, 0.0,
            0.35714285714285715, 0.0, 0.0, 0.07142857142857142, 0.0,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.64377760887146) {
          var43 = [
            0.0, 0.0, 0.0, 0.00847457627118644, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01694915254237288,
            0.940677966101695, 0.03389830508474576, 0.0,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.08333333333333333, 0.75,
            0.08333333333333333,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.15763889253139496) {
      if (input[27] <= 0.027149999514222145) {
        var43 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[29] <= 0.38159723579883575) {
          var43 = [
            0.2, 0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.13333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var43 = [
            0.6836734693877551, 0.02040816326530612, 0.1836734693877551, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.08163265306122448, 0.0,
            0.030612244897959183, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[0] <= 0.1026500016450882) {
        if (input[39] <= 0.08100000023841858) {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03508771929824561, 0.0, 0.017543859649122806, 0.05263157894736842,
            0.5087719298245614, 0.38596491228070173, 0.0, 0.0, 0.0,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.02565000019967556) {
          var43 = [
            0.0, 0.0, 0.01694915254237288, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.4067796610169492, 0.423728813559322,
            0.05084745762711865, 0.06779661016949153, 0.03389830508474576, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var43 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.13836477987421383, 0.5849056603773585, 0.1761006289308176,
            0.08176100628930817, 0.018867924528301886, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var44;
  if (input[9] <= 0.15775000303983688) {
    if (input[21] <= 0.042249999940395355) {
      if (input[25] <= 0.07558593899011612) {
        if (input[34] <= 0.012890624813735485) {
          var44 = [
            0.0, 0.1214574898785425, 0.0, 0.004048582995951417, 0.0, 0.0,
            0.016194331983805668, 0.008097165991902834, 0.02834008097165992,
            0.21052631578947367, 0.3481781376518219, 0.20647773279352227, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05668016194331984,
          ];
        } else {
          var44 = [
            0.45864661654135336, 0.0, 0.3533834586466165, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.15037593984962405, 0.015037593984962405,
            0.022556390977443608, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.05839843675494194) {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.03571428571428571, 0.0, 0.14285714285714285,
            0.3392857142857143, 0.44642857142857145, 0.03571428571428571, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var44 = [
            0.0, 0.0, 0.0, 0.1125, 0.15833333333333333, 0.18333333333333332,
            0.425, 0.0875, 0.0125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0125, 0.008333333333333333, 0.0,
          ];
        }
      }
    } else {
      if (input[29] <= 0.09409721940755844) {
        if (input[21] <= 0.0970499999821186) {
          var44 = [
            0.0, 0.0, 0.0, 0.03508771929824561, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9035087719298246,
            0.06140350877192982, 0.0,
          ];
        } else {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.8888888888888888, 0.1111111111111111, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[32] <= 0.22737609595060349) {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07766990291262135, 0.2087378640776699, 0.2087378640776699,
            0.18932038834951456, 0.19902912621359223, 0.11650485436893204, 0.0,
            0.0, 0.0,
          ];
        } else {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06172839506172839, 0.6666666666666666, 0.04938271604938271,
            0.06172839506172839, 0.1111111111111111, 0.04938271604938271, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[36] <= 0.01334999967366457) {
      if (input[10] <= 0.25219763815402985) {
        if (input[32] <= 0.19445833563804626) {
          var44 = [
            0.0, 0.0, 0.0, 0.2, 0.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.6666666666666666,
            0.0,
          ];
        }
      } else {
        if (input[31] <= 0.8211446106433868) {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.013157894736842105, 0.9868421052631579,
            0.0,
          ];
        } else {
          var44 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.6666666666666666,
            0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.1631944477558136) {
        var44 = [
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        var44 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var45;
  if (input[28] <= 0.06894531100988388) {
    if (input[9] <= 0.13900000602006912) {
      if (input[34] <= 0.017875000834465027) {
        if (input[32] <= 0.03854166716337204) {
          var45 = [
            0.0, 0.07473309608540925, 0.0, 0.0, 0.0, 0.02491103202846975,
            0.042704626334519574, 0.08540925266903915, 0.12099644128113879,
            0.11387900355871886, 0.31316725978647686, 0.22419928825622776, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.13157894736842105, 0.13909774436090225,
            0.14285714285714285, 0.2781954887218045, 0.03383458646616541,
            0.041353383458646614, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0037593984962406013, 0.018796992481203006, 0.14285714285714285,
            0.018796992481203006, 0.04887218045112782,
          ];
        }
      } else {
        if (input[10] <= 0.7419771552085876) {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.07465000078082085) {
        if (input[37] <= 0.07539062201976776) {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.009433962264150943, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04716981132075472,
            0.9433962264150944, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.6, 0.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.1781499981880188) {
          var45 = [
            0.0, 0.0, 0.0, 0.4, 0.0, 0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[31] <= 0.04179687425494194) {
      if (input[23] <= 0.13958333432674408) {
        if (input[1] <= 0.5785156190395355) {
          var45 = [
            0.7079646017699115, 0.017699115044247787, 0.25663716814159293, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.017699115044247787, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.07407407407407407, 0.0, 0.4074074074074074, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.48148148148148145, 0.0,
            0.037037037037037035, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.10347222536802292) {
          var45 = [
            0.0, 0.0, 0.03636363636363636, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.2545454545454545, 0.4909090909090909,
            0.10909090909090909, 0.01818181818181818, 0.09090909090909091, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1, 0.4, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.35466326773166656) {
        if (input[0] <= 0.10190000012516975) {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.3, 0.0, 0.0, 0.7, 0.0, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.025974025974025976, 0.7402597402597403, 0.09090909090909091,
            0.11688311688311688, 0.025974025974025976, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.11434999853372574) {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.03333333333333333, 0.43333333333333335,
            0.4666666666666667, 0.06666666666666667, 0.0, 0.0,
          ];
        } else {
          var45 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06976744186046512, 0.37209302325581395, 0.4186046511627907,
            0.09302325581395349, 0.046511627906976744, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var46;
  if (input[3] <= 0.054500000551342964) {
    if (input[16] <= 0.12558594346046448) {
      if (input[40] <= 0.12697242945432663) {
        if (input[18] <= 0.18155000358819962) {
          var46 = [
            0.01652892561983471, 0.0, 0.05785123966942149, 0.024793388429752067,
            0.03305785123966942, 0.0, 0.01652892561983471, 0.01652892561983471,
            0.008264462809917356, 0.0, 0.03305785123966942, 0.0,
            0.008264462809917356, 0.008264462809917356, 0.0, 0.0,
            0.008264462809917356, 0.01652892561983471, 0.01652892561983471,
            0.6115702479338843, 0.12396694214876033,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.1327499970793724) {
          var46 = [
            0.0, 0.0, 0.0, 0.017543859649122806, 0.0, 0.0, 0.017543859649122806,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.008771929824561403, 0.0, 0.0,
            0.017543859649122806, 0.9298245614035088, 0.008771929824561403, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13333333333333333, 0.8666666666666667,
            0.0,
          ];
        }
      }
    } else {
      if (input[31] <= 0.04335937649011612) {
        if (input[34] <= 0.01738281175494194) {
          var46 = [
            0.07142857142857142, 0.8571428571428571, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.07142857142857142, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var46 = [
            0.3055555555555556, 0.0, 0.18518518518518517, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.14351851851851852, 0.2037037037037037,
            0.10648148148148148, 0.032407407407407406, 0.023148148148148147,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.0929499976336956) {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.058823529411764705, 0.3137254901960784,
            0.6274509803921569, 0.0, 0.0, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03418803418803419, 0.5299145299145299, 0.24786324786324787,
            0.1623931623931624, 0.02564102564102564, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[12] <= 0.12424999848008156) {
      if (input[39] <= 0.04619999974966049) {
        if (input[41] <= 0.7076388895511627) {
          var46 = [
            0.0, 0.0, 0.0, 0.109375, 0.140625, 0.234375, 0.234375, 0.0625,
            0.015625, 0.0, 0.203125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13924050632911392, 0.8607594936708861,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[31] <= 0.5667968988418579) {
          var46 = [
            0.0, 0.0, 0.0, 0.59375, 0.3125, 0.0625, 0.03125, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.23809523809523808, 0.47619047619047616,
            0.2857142857142857, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[10] <= 0.06093750149011612) {
        if (input[3] <= 0.18940000236034393) {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1320754716981132,
            0.11320754716981132, 0.018867924528301886, 0.7169811320754716,
            0.018867924528301886, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.01680672268907563, 0.03361344537815126,
            0.06722689075630252, 0.2689075630252101, 0.24369747899159663,
            0.35294117647058826, 0.01680672268907563, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.11666666716337204) {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.14285714285714285, 0.14285714285714285,
            0.47619047619047616, 0.23809523809523808, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var46 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0851063829787234, 0.6595744680851063,
            0.23404255319148937, 0.02127659574468085, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var47;
  if (input[18] <= 0.19579999893903732) {
    if (input[9] <= 0.13900000602006912) {
      if (input[3] <= 0.06500000134110451) {
        if (input[24] <= 0.0364999994635582) {
          var47 = [
            0.12606837606837606, 0.053418803418803416, 0.10897435897435898, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.010683760683760684, 0.0,
            0.11538461538461539, 0.0876068376068376, 0.17307692307692307,
            0.0811965811965812, 0.08974358974358974, 0.0876068376068376,
            0.02564102564102564, 0.00641025641025641, 0.03418803418803419,
          ];
        } else {
          var47 = [
            0.0, 0.0, 0.0, 0.02857142857142857, 0.0, 0.014285714285714285,
            0.03571428571428571, 0.007142857142857143, 0.02142857142857143, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.02142857142857143, 0.02142857142857143,
            0.07142857142857142, 0.7571428571428571, 0.02142857142857143, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.08124999701976776) {
          var47 = [
            0.0, 0.0, 0.0, 0.007692307692307693, 0.0, 0.0, 0.05384615384615385,
            0.007692307692307693, 0.06153846153846154, 0.19230769230769232,
            0.676923076923077, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var47 = [
            0.0, 0.0, 0.0, 0.08582089552238806, 0.1417910447761194,
            0.13059701492537312, 0.3805970149253731, 0.1455223880597015,
            0.1044776119402985, 0.011194029850746268, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.04469999857246876) {
        if (input[26] <= 0.3048611059784889) {
          var47 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var47 = [
            0.0, 0.0, 0.0, 0.15384615384615385, 0.5384615384615384, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.15384615384615385, 0.15384615384615385, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.1530500054359436) {
          var47 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.011363636363636364, 0.9886363636363636,
            0.0,
          ];
        } else {
          var47 = [
            0.0, 0.0, 0.0, 0.4, 0.1, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.3, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[4] <= 0.514447808265686) {
      if (input[36] <= 0.04580000042915344) {
        if (input[18] <= 0.2824000120162964) {
          var47 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.14285714285714285, 0.8571428571428571, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var47 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var47 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      var47 = [
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      ];
    }
  }
  var var48;
  if (input[9] <= 0.13625000417232513) {
    if (input[12] <= 0.05145000107586384) {
      if (input[22] <= 0.09900526702404022) {
        if (input[28] <= 0.14921875298023224) {
          var48 = [
            0.0, 0.21487603305785125, 0.0, 0.19008264462809918,
            0.09917355371900827, 0.049586776859504134, 0.049586776859504134,
            0.0, 0.0, 0.0, 0.03305785123966942, 0.21487603305785125, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.01652892561983471, 0.024793388429752067,
            0.10743801652892562,
          ];
        } else {
          var48 = [
            0.49640287769784175, 0.007194244604316547, 0.4028776978417266, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.09352517985611511, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.0364999994635582) {
          var48 = [
            0.0, 0.0, 0.007042253521126761, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09859154929577464, 0.1936619718309859,
            0.3028169014084507, 0.1056338028169014, 0.1443661971830986,
            0.1056338028169014, 0.03169014084507042, 0.01056338028169014, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.06611570247933884, 0.01652892561983471, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04132231404958678,
            0.03305785123966942, 0.09917355371900827, 0.7355371900826446,
            0.008264462809917356, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.3635416775941849) {
        if (input[18] <= 0.2767999991774559) {
          var48 = [
            0.0, 0.0, 0.0, 0.011538461538461539, 0.0038461538461538464, 0.05,
            0.1, 0.15, 0.16538461538461538, 0.12307692307692308,
            0.3923076923076923, 0.0038461538461538464, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.9117187261581421) {
          var48 = [
            0.0, 0.0, 0.0, 0.00909090909090909, 0.1, 0.17272727272727273,
            0.7090909090909091, 0.00909090909090909, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 0.09090909090909091, 0.7272727272727273, 0.0,
            0.18181818181818182, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[6] <= 0.10924999788403511) {
      if (input[30] <= 0.03894999995827675) {
        if (input[39] <= 0.11139999702572823) {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.19003906100988388) {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.8888888888888888, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1111111111111111,
          ];
        }
      }
    } else {
      if (input[30] <= 0.03330000024288893) {
        var48 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
        ];
      } else {
        if (input[26] <= 0.5988913774490356) {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var48 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.21428571428571427, 0.7857142857142857,
            0.0,
          ];
        }
      }
    }
  }
  var var49;
  if (input[28] <= 0.078125) {
    if (input[5] <= 0.49297480285167694) {
      if (input[9] <= 0.1339999958872795) {
        if (input[32] <= 0.19131945073604584) {
          var49 = [
            0.0, 0.3333333333333333, 0.0, 0.028985507246376812,
            0.014492753623188406, 0.0, 0.028985507246376812,
            0.028985507246376812, 0.0, 0.0, 0.014492753623188406,
            0.5507246376811594, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.02727272727272727, 0.0, 0.0, 0.0, 0.0,
            0.00909090909090909, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00909090909090909, 0.05454545454545454, 0.6818181818181818,
            0.01818181818181818, 0.2,
          ];
        }
      } else {
        if (input[7] <= 0.20975059270858765) {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.00980392156862745, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0392156862745098,
            0.9509803921568627, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.004449999891221523) {
        if (input[20] <= 0.257383793592453) {
          var49 = [
            0.0, 0.0, 0.0, 0.016853932584269662, 0.056179775280898875,
            0.0449438202247191, 0.016853932584269662, 0.15168539325842698,
            0.23595505617977527, 0.19662921348314608, 0.2808988764044944, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.03278688524590164, 0.0, 0.0,
            0.01639344262295082, 0.09836065573770492, 0.8360655737704918,
            0.01639344262295082, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.21950781345367432) {
          var49 = [
            0.0, 0.0, 0.0, 0.3829787234042553, 0.2127659574468085,
            0.0425531914893617, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.3617021276595745, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.0718232044198895, 0.08839779005524862,
            0.15469613259668508, 0.5414364640883977, 0.13259668508287292,
            0.011049723756906077, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[21] <= 0.028800000436604023) {
      if (input[16] <= 0.44224466383457184) {
        if (input[29] <= 0.5211805701255798) {
          var49 = [
            0.11428571428571428, 0.0, 0.7714285714285715, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.05714285714285714, 0.0,
            0.05714285714285714, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.1682114452123642) {
          var49 = [
            0.12903225806451613, 0.0, 0.6451612903225806, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.22580645161290322, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.8148148148148148, 0.0, 0.12345679012345678, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.037037037037037035, 0.0,
            0.024691358024691357, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.08685000240802765) {
        if (input[1] <= 0.5524356663227081) {
          var49 = [
            0.0, 0.0, 0.016129032258064516, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.2903225806451613, 0.5161290322580645,
            0.04838709677419355, 0.08064516129032258, 0.0, 0.016129032258064516,
            0.03225806451612903, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.08450704225352113, 0.28169014084507044, 0.6197183098591549,
            0.014084507042253521, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.1215277761220932) {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.5294117647058824, 0.11764705882352941, 0.0, 0.29411764705882354,
            0.058823529411764705, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var49 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07142857142857142, 0.19285714285714287, 0.2357142857142857,
            0.24285714285714285, 0.2571428571428571, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var50;
  if (input[20] <= 0.09687500074505806) {
    if (input[16] <= 0.12558594346046448) {
      if (input[6] <= 0.13935000449419022) {
        if (input[32] <= 0.22873611003160477) {
          var50 = [
            0.011904761904761904, 0.0, 0.047619047619047616, 0.2976190476190476,
            0.21428571428571427, 0.16666666666666666, 0.05952380952380952,
            0.023809523809523808, 0.023809523809523808, 0.011904761904761904,
            0.023809523809523808, 0.0, 0.011904761904761904,
            0.023809523809523808, 0.011904761904761904, 0.0, 0.0, 0.0,
            0.07142857142857142, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.017543859649122806, 0.008771929824561403,
            0.008771929824561403, 0.008771929824561403, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.008771929824561403, 0.7982456140350878,
            0.03508771929824561, 0.11403508771929824,
          ];
        }
      } else {
        if (input[9] <= 0.1246500015258789) {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.4, 0.2, 0.0, 0.0, 0.4,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.044444444444444446, 0.9555555555555556,
            0.0,
          ];
        }
      }
    } else {
      if (input[31] <= 0.04374999925494194) {
        if (input[23] <= 0.13958333432674408) {
          var50 = [
            0.4074074074074074, 0.1728395061728395, 0.30864197530864196, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1111111111111111, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.019801980198019802, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3465346534653465, 0.40594059405940597,
            0.1188118811881188, 0.06930693069306931, 0.039603960396039604, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.0783500000834465) {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06451612903225806, 0.45161290322580644, 0.29838709677419356,
            0.10483870967741936, 0.08064516129032258, 0.0, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09803921568627451, 0.29411764705882354,
            0.6078431372549019, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.38333334028720856) {
      if (input[38] <= 0.1614583283662796) {
        if (input[4] <= 0.171875) {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.023255813953488372, 0.9767441860465116, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.045454545454545456,
            0.13636363636363635, 0.0, 0.045454545454545456, 0.7727272727272727,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[14] <= 0.68390092253685) {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.012195121951219513, 0.018292682926829267,
            0.12195121951219512, 0.17073170731707318, 0.24390243902439024,
            0.4329268292682927, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.0657999999821186) {
        if (input[7] <= 0.12311197817325592) {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.045454545454545456, 0.11363636363636363,
            0.5454545454545454, 0.20454545454545456, 0.09090909090909091, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.0, 0.011111111111111112, 0.03333333333333333,
            0.8666666666666667, 0.08888888888888889, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[4] <= 0.8128455579280853) {
          var50 = [
            0.0, 0.0, 0.0, 0.04054054054054054, 0.25675675675675674,
            0.2972972972972973, 0.3783783783783784, 0.02702702702702703, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var50 = [
            0.0, 0.0, 0.0, 0.8571428571428571, 0.14285714285714285, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var51;
  if (input[20] <= 0.09687500074505806) {
    if (input[0] <= 0.051100000739097595) {
      if (input[9] <= 0.1339999958872795) {
        if (input[21] <= 0.03894999995827675) {
          var51 = [
            0.010526315789473684, 0.2631578947368421, 0.0, 0.2736842105263158,
            0.08421052631578947, 0.07368421052631578, 0.07368421052631578,
            0.021052631578947368, 0.031578947368421054, 0.021052631578947368,
            0.010526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.031578947368421054, 0.042105263157894736, 0.06315789473684211,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.006622516556291391, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.006622516556291391, 0.006622516556291391, 0.0,
            0.006622516556291391, 0.046357615894039736, 0.09933774834437085,
            0.8013245033112583, 0.013245033112582781, 0.013245033112582781,
          ];
        }
      } else {
        if (input[8] <= 0.20033332705497742) {
          var51 = [
            0.0, 0.0, 0.0, 0.2857142857142857, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.21428571428571427,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.02727272727272727, 0.01818181818181818, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03636363636363636, 0.9181818181818182, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.23715278506278992) {
        if (input[17] <= 0.22172018885612488) {
          var51 = [
            0.15178571428571427, 0.0, 0.32142857142857145, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.10714285714285714, 0.0625,
            0.017857142857142856, 0.044642857142857144, 0.044642857142857144,
            0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.6164383561643836, 0.0, 0.0821917808219178, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1506849315068493, 0.0958904109589041,
            0.0547945205479452, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[33] <= 0.015050000045448542) {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.22916666666666666, 0.08333333333333333, 0.20833333333333334,
            0.3125, 0.16666666666666666, 0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11643835616438356, 0.5753424657534246, 0.15753424657534246,
            0.0958904109589041, 0.0547945205479452, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.06652644276618958) {
      if (input[20] <= 0.40093955397605896) {
        if (input[37] <= 0.38007812201976776) {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.04597701149425287, 0.022988505747126436,
            0.06896551724137931, 0.04597701149425287, 0.04597701149425287,
            0.12643678160919541, 0.5977011494252874, 0.04597701149425287, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.008547008547008548, 0.02564102564102564,
            0.13675213675213677, 0.28205128205128205, 0.23931623931623933,
            0.3076923076923077, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[13] <= 0.5114434361457825) {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.5714285714285714, 0.42857142857142855, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.0657999999821186) {
        if (input[13] <= 0.7383042275905609) {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.030927835051546393, 0.08247422680412371,
            0.8350515463917526, 0.05154639175257732, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.35714285714285715, 0.21428571428571427,
            0.42857142857142855, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[41] <= 0.7944444417953491) {
          var51 = [
            0.0, 0.0, 0.0, 0.125, 0.35, 0.4, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var51 = [
            0.0, 0.0, 0.0, 0.0, 0.08823529411764706, 0.2647058823529412,
            0.6176470588235294, 0.029411764705882353, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var52;
  if (input[14] <= 0.32604166865348816) {
    if (input[22] <= 0.09932077303528786) {
      if (input[11] <= 0.4184606522321701) {
        if (input[20] <= 0.3137510269880295) {
          var52 = [
            0.2716049382716049, 0.19753086419753085, 0.2777777777777778,
            0.006172839506172839, 0.018518518518518517, 0.037037037037037035,
            0.006172839506172839, 0.0, 0.0, 0.0, 0.006172839506172839, 0.0,
            0.06790123456790123, 0.0, 0.006172839506172839, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.10493827160493827,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.1232638880610466) {
          var52 = [
            0.0, 0.0, 0.0, 0.1326530612244898, 0.04081632653061224, 0.0,
            0.02040816326530612, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.01020408163265306, 0.0, 0.0, 0.0, 0.030612244897959183,
            0.7551020408163265, 0.01020408163265306,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.42857142857142855, 0.39285714285714285,
            0.10714285714285714, 0.03571428571428571, 0.03571428571428571, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.05299999937415123) {
        if (input[6] <= 0.0324499998241663) {
          var52 = [
            0.0, 0.0, 0.012345679012345678, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.43209876543209874, 0.4444444444444444,
            0.07407407407407407, 0.024691358024691357, 0.012345679012345678,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.13829787234042554, 0.4521276595744681, 0.1595744680851064,
            0.12234042553191489, 0.09042553191489362, 0.031914893617021274, 0.0,
            0.005319148936170213,
          ];
        }
      } else {
        if (input[17] <= 0.04270833358168602) {
          var52 = [
            0.0, 0.0, 0.0, 0.049586776859504134, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01652892561983471,
            0.7933884297520661, 0.14049586776859505, 0.0,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.01818181818181818, 0.03636363636363636, 0.23636363636363636,
            0.7090909090909091, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[30] <= 0.008649999741464853) {
      if (input[14] <= 0.6803962588310242) {
        if (input[25] <= 0.07558593899011612) {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.00641025641025641,
            0.07692307692307693, 0.19230769230769232, 0.7243589743589743, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.06060606060606061, 0.21212121212121213,
            0.2878787878787879, 0.36363636363636365, 0.07575757575757576, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var52 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[39] <= 0.046400001272559166) {
        if (input[9] <= 0.015399999916553497) {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.38461538461538464,
            0.15384615384615385, 0.46153846153846156, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.017391304347826087, 0.05217391304347826,
            0.17391304347826086, 0.7391304347826086, 0.017391304347826087, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.05914999917149544) {
          var52 = [
            0.0, 0.0, 0.0, 0.0, 0.6086956521739131, 0.34782608695652173, 0.0,
            0.043478260869565216, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var52 = [
            0.0, 0.0, 0.0, 0.5, 0.14285714285714285, 0.35714285714285715, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var53;
  if (input[9] <= 0.13529999554157257) {
    if (input[12] <= 0.04374999925494194) {
      if (input[26] <= 0.43101388216018677) {
        if (input[21] <= 0.0345000009983778) {
          var53 = [
            0.28346456692913385, 0.11811023622047244, 0.2047244094488189,
            0.05511811023622047, 0.015748031496062992, 0.0,
            0.003937007874015748, 0.0, 0.0, 0.0, 0.015748031496062992,
            0.1220472440944882, 0.1062992125984252, 0.0, 0.003937007874015748,
            0.0, 0.0, 0.0, 0.0, 0.003937007874015748, 0.06692913385826772,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.0034602076124567475, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0726643598615917, 0.17993079584775087,
            0.30103806228373703, 0.1314878892733564, 0.14186851211072665,
            0.12110726643598616, 0.04498269896193772, 0.0034602076124567475,
            0.0,
          ];
        }
      } else {
        if (input[19] <= 0.06679687649011612) {
          var53 = [
            0.0, 0.0, 0.0, 0.08333333333333333, 0.020833333333333332,
            0.020833333333333332, 0.0, 0.010416666666666666, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.010416666666666666, 0.0, 0.041666666666666664,
            0.78125, 0.03125, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.4, 0.26666666666666666, 0.13333333333333333, 0.2,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[26] <= 0.11944444477558136) {
        if (input[14] <= 0.6645994484424591) {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03355704697986577,
            0.006711409395973154, 0.04697986577181208, 0.19463087248322147,
            0.7181208053691275, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[40] <= 0.06652644276618958) {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.05405405405405406, 0.12162162162162163,
            0.0945945945945946, 0.2972972972972973, 0.3918918918918919,
            0.04054054054054054, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.024539877300613498, 0.11042944785276074,
            0.18404907975460122, 0.5337423312883436, 0.147239263803681, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[6] <= 0.10924999788403511) {
      if (input[3] <= 0.1046999990940094) {
        if (input[25] <= 0.38593749701976776) {
          var53 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.16666666666666666, 0.8333333333333334, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[38] <= 0.3913194388151169) {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.25, 0.5, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.08990000188350677) {
        if (input[23] <= 0.24025000631809235) {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.029411764705882353, 0.9705882352941176,
            0.0,
          ];
        }
      } else {
        if (input[9] <= 0.19264999777078629) {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var53 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      }
    }
  }
  var var54;
  if (input[3] <= 0.06590000167489052) {
    if (input[29] <= 0.08819444477558136) {
      if (input[6] <= 0.1127999983727932) {
        if (input[6] <= 0.0215000007301569) {
          var54 = [
            0.0, 0.24242424242424243, 0.0, 0.020202020202020204,
            0.010101010101010102, 0.0, 0.010101010101010102,
            0.010101010101010102, 0.0, 0.0, 0.020202020202020204,
            0.5151515151515151, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.010101010101010102, 0.0, 0.16161616161616163,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.030612244897959183, 0.02040816326530612,
            0.01020408163265306, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.01020408163265306, 0.02040816326530612, 0.9081632653061225,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.10659722238779068) {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.028037383177570093, 0.9532710280373832,
            0.018691588785046728,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.02187499962747097) {
        if (input[0] <= 0.1790500059723854) {
          var54 = [
            0.65, 0.025, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.025, 0.05, 0.0, 0.1, 0.15, 0.0, 0.0, 0.0,
          ];
        } else {
          var54 = [
            0.33121019108280253, 0.0, 0.2611464968152866, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.19745222929936307, 0.12738853503184713,
            0.06369426751592357, 0.01910828025477707, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.1026500016450882) {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.05555555555555555, 0.018518518518518517, 0.4074074074074074,
            0.46296296296296297, 0.05555555555555555, 0.0, 0.0,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.20606060606060606, 0.45454545454545453, 0.23636363636363636,
            0.07272727272727272, 0.030303030303030304, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[24] <= 0.01720000058412552) {
      if (input[32] <= 0.12083332985639572) {
        if (input[20] <= 0.2337072715163231) {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.024096385542168676,
            0.13253012048192772, 0.37349397590361444, 0.46987951807228917, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.012658227848101266,
            0.05063291139240506, 0.8987341772151899, 0.0379746835443038, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.04635000042617321) {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[11] <= 0.38402777910232544) {
        if (input[12] <= 0.25644999742507935) {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0196078431372549, 0.0784313725490196,
            0.09803921568627451, 0.5490196078431373, 0.23529411764705882,
            0.0196078431372549, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.3861111104488373) {
          var54 = [
            0.0, 0.0, 0.0, 0.2857142857142857, 0.2714285714285714,
            0.22857142857142856, 0.12857142857142856, 0.02857142857142857,
            0.05714285714285714, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var54 = [
            0.0, 0.0, 0.0, 0.058823529411764705, 0.11176470588235295,
            0.17647058823529413, 0.5588235294117647, 0.09411764705882353, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var55;
  if (input[4] <= 0.16417907923460007) {
    if (input[15] <= 0.01269999984651804) {
      if (input[32] <= 0.03327546268701553) {
        if (input[7] <= 0.03828125074505806) {
          var55 = [
            0.013888888888888888, 0.0, 0.09722222222222222, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.041666666666666664, 0.7777777777777778,
            0.027777777777777776, 0.041666666666666664, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[33] <= 0.0010999999940395355) {
          var55 = [
            0.0, 0.0, 0.0, 0.005555555555555556, 0.011111111111111112,
            0.011111111111111112, 0.027777777777777776, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.027777777777777776, 0.2388888888888889,
            0.5833333333333334, 0.09444444444444444,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.031746031746031744, 0.9682539682539683, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.03242187574505806) {
        if (input[17] <= 0.32777777314186096) {
          var55 = [
            0.3181818181818182, 0.0, 0.17613636363636365, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3068181818181818, 0.14772727272727273,
            0.022727272727272728, 0.022727272727272728, 0.005681818181818182,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.3424128592014313) {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04395604395604396, 0.6483516483516484, 0.08791208791208792,
            0.15384615384615385, 0.06593406593406594, 0.0, 0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.20952380952380953, 0.11428571428571428, 0.3142857142857143,
            0.19047619047619047, 0.17142857142857143, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.06652644276618958) {
      if (input[20] <= 0.2337072715163231) {
        if (input[26] <= 0.32256944477558136) {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.12857142857142856,
            0.37142857142857144, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.1375, 0.1, 0.0875, 0.0625, 0.2375, 0.35, 0.025,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.021050000563263893) {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.012048192771084338, 0.0,
            0.024096385542168676, 0.08433734939759036, 0.8433734939759037,
            0.03614457831325301, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.09090909090909091, 0.2727272727272727,
            0.18181818181818182, 0.45454545454545453, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.09109999984502792) {
        if (input[25] <= 0.6361328065395355) {
          var55 = [
            0.0, 0.0, 0.0, 0.01910828025477707, 0.06369426751592357,
            0.12738853503184713, 0.7197452229299363, 0.05732484076433121,
            0.012738853503184714, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.17391304347826086, 0.2608695652173913,
            0.13043478260869565, 0.08695652173913043, 0.13043478260869565, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.21739130434782608,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[4] <= 0.29375000298023224) {
          var55 = [
            0.0, 0.0, 0.0, 0.8333333333333334, 0.0, 0.16666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var55 = [
            0.0, 0.0, 0.0, 0.3333333333333333, 0.2, 0.36666666666666664,
            0.06666666666666667, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.03333333333333333, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var56;
  if (input[9] <= 0.12450000271201134) {
    if (input[12] <= 0.04884999990463257) {
      if (input[15] <= 0.01269999984651804) {
        if (input[41] <= 0.5169677138328552) {
          var56 = [
            0.023529411764705882, 0.0, 0.047058823529411764,
            0.08235294117647059, 0.023529411764705882, 0.0, 0.0,
            0.011764705882352941, 0.0, 0.0, 0.023529411764705882,
            0.38823529411764707, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.023529411764705882, 0.0, 0.3764705882352941,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 0.1032258064516129, 0.03870967741935484,
            0.04516129032258064, 0.03225806451612903, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.012903225806451613, 0.0, 0.0, 0.0, 0.01935483870967742,
            0.7354838709677419, 0.012903225806451613, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.10445000231266022) {
          var56 = [
            0.0, 0.23595505617977527, 0.011235955056179775, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.011235955056179775, 0.02247191011235955,
            0.033707865168539325, 0.056179775280898875, 0.2247191011235955,
            0.4044943820224719, 0.0, 0.0, 0.0,
          ];
        } else {
          var56 = [
            0.1757188498402556, 0.0, 0.12460063897763578, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13738019169329074, 0.13099041533546327,
            0.25878594249201275, 0.11501597444089456, 0.051118210862619806,
            0.006389776357827476, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[31] <= 0.09531249850988388) {
        if (input[18] <= 0.19235000014305115) {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.009009009009009009, 0.07207207207207207,
            0.11261261261261261, 0.15315315315315314, 0.2072072072072072,
            0.44144144144144143, 0.0045045045045045045, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11538461538461539, 0.8846153846153846, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.04645000025629997) {
          var56 = [
            0.0, 0.0, 0.0, 0.006896551724137931, 0.027586206896551724,
            0.15172413793103448, 0.7172413793103448, 0.06206896551724138,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 0.07692307692307693, 0.6538461538461539,
            0.07692307692307693, 0.11538461538461539, 0.07692307692307693, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[24] <= 0.1618499979376793) {
      if (input[3] <= 0.05934999883174896) {
        if (input[37] <= 0.4542067348957062) {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.037383177570093455, 0.9626168224299065,
            0.0,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.1315999999642372) {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.5554687678813934) {
        if (input[24] <= 0.1749499961733818) {
          var56 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var56 = [
            0.0, 0.0, 0.0, 0.7857142857142857, 0.07142857142857142,
            0.07142857142857142, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.07142857142857142, 0.0,
          ];
        }
      } else {
        var56 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var57;
  if (input[30] <= 0.0698000006377697) {
    if (input[18] <= 0.037049999460577965) {
      if (input[32] <= 0.2809368073940277) {
        if (input[41] <= 0.23402777314186096) {
          var57 = [
            0.2629310344827586, 0.15086206896551724, 0.19827586206896552,
            0.017241379310344827, 0.017241379310344827, 0.017241379310344827,
            0.004310344827586207, 0.0, 0.004310344827586207,
            0.01293103448275862, 0.03017241379310345, 0.0, 0.16379310344827586,
            0.05603448275862069, 0.017241379310344827, 0.017241379310344827,
            0.01293103448275862, 0.017241379310344827, 0.0, 0.0, 0.0,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.08904109589041095, 0.03424657534246575,
            0.03424657534246575, 0.0136986301369863, 0.003424657534246575, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.13356164383561644, 0.2534246575342466,
            0.1095890410958904, 0.11301369863013698, 0.13013698630136986,
            0.05136986301369863, 0.03424657534246575, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.22620251029729843) {
          var57 = [
            0.0, 0.0, 0.0, 0.023529411764705882, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.011764705882352941, 0.0,
            0.011764705882352941, 0.9058823529411765, 0.047058823529411764, 0.0,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.6842105263157895, 0.0, 0.05263157894736842,
            0.2631578947368421, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[24] <= 0.01889999955892563) {
        if (input[18] <= 0.19770000129938126) {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05555555555555555, 0.0,
            0.10416666666666667, 0.22916666666666666, 0.5972222222222222,
            0.013888888888888888, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05263157894736842, 0.9473684210526315, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.021100000478327274) {
          var57 = [
            0.0, 0.0, 0.0, 0.012048192771084338, 0.08433734939759036,
            0.012048192771084338, 0.1686746987951807, 0.30120481927710846,
            0.42168674698795183, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.006711409395973154, 0.10738255033557047,
            0.18120805369127516, 0.6040268456375839, 0.08053691275167785,
            0.020134228187919462, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.1310499981045723) {
      if (input[8] <= 0.20104166865348816) {
        if (input[26] <= 0.29548612236976624) {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[16] <= 0.36054687201976776) {
          var57 = [
            0.0, 0.0, 0.0, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.1, 0.0, 0.5, 0.0, 0.2,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[10] <= 0.1835365891456604) {
        var57 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[6] <= 0.05999999865889549) {
          var57 = [
            0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6666666666666666,
          ];
        } else {
          var57 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.031578947368421054, 0.968421052631579,
            0.0,
          ];
        }
      }
    }
  }
  var var58;
  if (input[20] <= 0.09618055447936058) {
    if (input[2] <= 0.24687500298023224) {
      if (input[22] <= 0.1002630814909935) {
        if (input[32] <= 0.22938033938407898) {
          var58 = [
            0.009433962264150943, 0.27358490566037735, 0.0, 0.24528301886792453,
            0.09433962264150944, 0.1320754716981132, 0.05660377358490566,
            0.03773584905660377, 0.009433962264150943, 0.018867924528301886,
            0.02830188679245283, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09433962264150944, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.022727272727272728, 0.011363636363636364,
            0.011363636363636364, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.06818181818181818, 0.7159090909090909,
            0.17045454545454544,
          ];
        }
      } else {
        if (input[6] <= 0.11104999855160713) {
          var58 = [
            0.0, 0.0, 0.0, 0.061224489795918366, 0.02040816326530612, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01020408163265306, 0.0,
            0.04081632653061224, 0.04081632653061224, 0.01020408163265306,
            0.8163265306122449, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.024390243902439025, 0.0975609756097561,
            0.24390243902439024, 0.07317073170731707, 0.5365853658536586,
            0.024390243902439025,
          ];
        }
      }
    } else {
      if (input[8] <= 0.1180555522441864) {
        if (input[22] <= 0.06894531100988388) {
          var58 = [
            0.5206611570247934, 0.0, 0.36363636363636365, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10743801652892562, 0.0,
            0.008264462809917356, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.031746031746031744, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.5396825396825397, 0.3333333333333333,
            0.06349206349206349, 0.0, 0.031746031746031744, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.0802999995648861) {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0547945205479452, 0.1506849315068493, 0.0958904109589041,
            0.4657534246575342, 0.2328767123287671, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16167664670658682, 0.49700598802395207, 0.16167664670658682,
            0.11976047904191617, 0.059880239520958084, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[39] <= 0.00989999994635582) {
      if (input[3] <= 0.03280000016093254) {
        if (input[36] <= 0.04580000042915344) {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.022727272727272728, 0.9772727272727273, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6666666666666666, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.00419999985024333) {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.10106382978723404,
            0.14361702127659576, 0.21808510638297873, 0.5, 0.03723404255319149,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.058823529411764705, 0.08823529411764706,
            0.058823529411764705, 0.3235294117647059, 0.08823529411764706,
            0.38235294117647056, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[12] <= 0.06689999997615814) {
        if (input[19] <= 0.4095703214406967) {
          var58 = [
            0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.2727272727272727, 0.5454545454545454,
            0.13636363636363635, 0.045454545454545456, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.12187500298023224) {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.0425531914893617, 0.3191489361702128,
            0.40425531914893614, 0.23404255319148937, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var58 = [
            0.0, 0.0, 0.0, 0.0, 0.045454545454545456, 0.07954545454545454,
            0.7954545454545454, 0.07954545454545454, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var59;
  if (input[9] <= 0.13735000044107437) {
    if (input[16] <= 0.12558594346046448) {
      if (input[9] <= 0.03370000049471855) {
        if (input[20] <= 0.3422499895095825) {
          var59 = [
            0.0, 0.0, 0.02654867256637168, 0.0, 0.01327433628318584,
            0.004424778761061947, 0.03982300884955752, 0.14601769911504425,
            0.1415929203539823, 0.14601769911504425, 0.3805309734513274, 0.0,
            0.008849557522123894, 0.004424778761061947, 0.0, 0.0,
            0.008849557522123894, 0.0, 0.008849557522123894, 0.0,
            0.07079646017699115,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16049382716049382, 0.8395061728395061, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[34] <= 0.017875000834465027) {
          var59 = [
            0.0, 0.0, 0.0, 0.09923664122137404, 0.12213740458015267,
            0.17938931297709923, 0.32061068702290074, 0.08396946564885496,
            0.019083969465648856, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.011450381679389313, 0.13358778625954199, 0.030534351145038167,
            0.0,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.028169014084507043, 0.971830985915493, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.04648437350988388) {
        if (input[28] <= 0.12910155951976776) {
          var59 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var59 = [
            0.3563218390804598, 0.011494252873563218, 0.2413793103448276, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2413793103448276,
            0.09770114942528736, 0.011494252873563218, 0.022988505747126436,
            0.017241379310344827, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07899999991059303) {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16149068322981366, 0.4968944099378882, 0.2111801242236025,
            0.07453416149068323, 0.055900621118012424, 0.0, 0.0, 0.0,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.05555555555555555, 0.1527777777777778, 0.2916666666666667,
            0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[38] <= 0.18298611044883728) {
      if (input[23] <= 0.6317120790481567) {
        if (input[5] <= 0.2725694477558136) {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.01098901098901099, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02197802197802198,
            0.9560439560439561, 0.01098901098901099,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[41] <= 0.8721320331096649) {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2631578947368421, 0.7368421052631579,
            0.0,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.03355000168085098) {
        if (input[11] <= 0.4861111044883728) {
          var59 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.0494999997317791) {
          var59 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var59 = [
            0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var60;
  if (input[6] <= 0.1400500014424324) {
    if (input[20] <= 0.09618055447936058) {
      if (input[28] <= 0.21682751178741455) {
        if (input[8] <= 0.4148194491863251) {
          var60 = [
            0.0, 0.3333333333333333, 0.0, 0.1111111111111111,
            0.10101010101010101, 0.06060606060606061, 0.0707070707070707,
            0.0707070707070707, 0.030303030303030304, 0.020202020202020204,
            0.020202020202020204, 0.0, 0.0, 0.0, 0.0, 0.010101010101010102, 0.0,
            0.0, 0.010101010101010102, 0.0, 0.16161616161616163,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.0, 0.12080536912751678, 0.013422818791946308,
            0.013422818791946308, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.03355704697986577, 0.06711409395973154, 0.7248322147651006,
            0.026845637583892617, 0.0,
          ];
        }
      } else {
        if (input[23] <= 0.15173611044883728) {
          var60 = [
            0.49264705882352944, 0.007352941176470588, 0.38235294117647056, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07352941176470588, 0.0,
            0.04411764705882353, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.003937007874015748, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0984251968503937, 0.2125984251968504,
            0.3110236220472441, 0.15354330708661418, 0.1141732283464567,
            0.1062992125984252, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.005450000055134296) {
        if (input[3] <= 0.047200001776218414) {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03571428571428571, 0.0,
            0.017857142857142856, 0.0, 0.07142857142857142, 0.875, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.019138755980861243, 0.004784688995215311,
            0.07177033492822966, 0.0861244019138756, 0.15311004784688995,
            0.215311004784689, 0.4258373205741627, 0.023923444976076555, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.22502563893795013) {
          var60 = [
            0.0, 0.0, 0.0, 0.0375, 0.15, 0.3625, 0.3375, 0.0875, 0.025, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.0, 0.044444444444444446, 0.06666666666666667,
            0.07777777777777778, 0.7666666666666667, 0.044444444444444446, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[35] <= 0.24270834028720856) {
      if (input[16] <= 0.15214844048023224) {
        if (input[21] <= 0.09795000031590462) {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9680851063829787,
            0.031914893617021274,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.1, 0.1, 0.3, 0.4, 0.1,
          ];
        }
      } else {
        if (input[41] <= 0.30069443583488464) {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var60 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.25, 0.25, 0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      var60 = [
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
      ];
    }
  }
  var var61;
  if (input[24] <= 0.0364999994635582) {
    if (input[4] <= 0.166015625) {
      if (input[27] <= 0.026149999350309372) {
        if (input[30] <= 0.03374999947845936) {
          var61 = [
            0.0, 0.2916666666666667, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06944444444444445, 0.5555555555555556, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.013888888888888888, 0.06944444444444445, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.02247191011235955, 0.011235955056179775,
            0.02247191011235955, 0.7303370786516854, 0.21348314606741572,
          ];
        }
      } else {
        if (input[23] <= 0.13958333432674408) {
          var61 = [
            0.5294117647058824, 0.0, 0.3382352941176471, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11764705882352941, 0.0,
            0.014705882352941176, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07258064516129033, 0.18951612903225806, 0.3024193548387097,
            0.17338709677419356, 0.1532258064516129, 0.10887096774193548, 0.0,
            0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[19] <= 0.6674426198005676) {
        if (input[30] <= 0.007249999791383743) {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04032258064516129,
            0.23387096774193547, 0.6854838709677419, 0.04032258064516129, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.25, 0.0, 0.0, 0.75, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.194444440305233) {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.3076923076923077,
            0.6153846153846154, 0.0, 0.07692307692307693, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.15384615384615385, 0.0,
            0.15384615384615385, 0.6923076923076923, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[36] <= 0.005950000137090683) {
      if (input[6] <= 0.04474999941885471) {
        if (input[18] <= 0.07715000212192535) {
          var61 = [
            0.0, 0.0, 0.0, 0.2, 0.16, 0.2, 0.44, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.04554999992251396) {
          var61 = [
            0.0, 0.0, 0.0, 0.014492753623188406, 0.007246376811594203, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007246376811594203,
            0.057971014492753624, 0.10144927536231885, 0.7608695652173914,
            0.050724637681159424, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.2413793103448276, 0.10344827586206896,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06896551724137931, 0.034482758620689655, 0.0, 0.0,
            0.5172413793103449, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.01933593675494194) {
        if (input[20] <= 0.04930555447936058) {
          var61 = [
            0.0, 0.0, 0.0, 0.36, 0.28, 0.28, 0.0, 0.08, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.0, 0.034482758620689655, 0.05747126436781609,
            0.25287356321839083, 0.3793103448275862, 0.27586206896551724, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.03685000166296959) {
          var61 = [
            0.0, 0.0, 0.0, 0.08823529411764706, 0.1568627450980392,
            0.0784313725490196, 0.6666666666666666, 0.00980392156862745, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var61 = [
            0.0, 0.0, 0.0, 0.11688311688311688, 0.19480519480519481,
            0.44155844155844154, 0.16883116883116883, 0.03896103896103896, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03896103896103896,
            0.0, 0.0,
          ];
        }
      }
    }
  }
  var var62;
  if (input[9] <= 0.14800000190734863) {
    if (input[29] <= 0.0954861119389534) {
      if (input[34] <= 0.019471153616905212) {
        if (input[40] <= 0.09317219629883766) {
          var62 = [
            0.0, 0.06993006993006994, 0.0, 0.013986013986013986,
            0.01048951048951049, 0.02097902097902098, 0.03146853146853147,
            0.07692307692307693, 0.10139860139860139, 0.1048951048951049,
            0.3321678321678322, 0.17482517482517482, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.006993006993006993, 0.006993006993006993,
            0.04895104895104895,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.0, 0.12406015037593984, 0.10526315789473684,
            0.11654135338345864, 0.39473684210526316, 0.045112781954887216,
            0.011278195488721804, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.011278195488721804, 0.022556390977443608, 0.15037593984962405,
            0.018796992481203006, 0.0,
          ];
        }
      } else {
        if (input[15] <= 0.013299999758601189) {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.011950000189244747) {
        if (input[33] <= 0.047450000420212746) {
          var62 = [
            0.5982905982905983, 0.008547008547008548, 0.28205128205128205, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.09401709401709402, 0.0,
            0.017094017094017096, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.8333333333333334, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[21] <= 0.12154999747872353) {
          var62 = [
            0.0, 0.0, 0.0043859649122807015, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.13596491228070176, 0.21929824561403508,
            0.4166666666666667, 0.11842105263157894, 0.06578947368421052,
            0.021929824561403508, 0.017543859649122806, 0.0, 0.0,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03488372093023256, 0.03488372093023256, 0.05813953488372093,
            0.27906976744186046, 0.3488372093023256, 0.2441860465116279, 0.0,
            0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[7] <= 0.15078124776482582) {
      if (input[4] <= 0.29960936307907104) {
        if (input[24] <= 0.25600001215934753) {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var62 = [
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[36] <= 0.015250000171363354) {
        if (input[31] <= 0.10358231514692307) {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var62 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.03361344537815126, 0.9663865546218487,
            0.0,
          ];
        }
      } else {
        var62 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var63;
  if (input[6] <= 0.13940000534057617) {
    if (input[21] <= 0.042249999940395355) {
      if (input[33] <= 0.002749999985098839) {
        if (input[9] <= 0.01334999967366457) {
          var63 = [
            0.003367003367003367, 0.10101010101010101, 0.0, 0.0,
            0.006734006734006734, 0.003367003367003367, 0.010101010101010102,
            0.07407407407407407, 0.12121212121212122, 0.10101010101010101,
            0.3333333333333333, 0.18518518518518517, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.06060606060606061,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.11553784860557768, 0.17928286852589642,
            0.16334661354581673, 0.398406374501992, 0.11155378486055777,
            0.01593625498007968, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.00796812749003984, 0.0, 0.00796812749003984,
          ];
        }
      } else {
        if (input[35] <= 0.4902638792991638) {
          var63 = [
            0.1568627450980392, 0.0, 0.7058823529411765, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11764705882352941, 0.0196078431372549,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var63 = [
            0.5604395604395604, 0.0, 0.2087912087912088, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1978021978021978, 0.0,
            0.03296703296703297, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[2] <= 0.24444444477558136) {
        if (input[17] <= 0.0850694477558136) {
          var63 = [
            0.0, 0.0, 0.0, 0.01020408163265306, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8979591836734694,
            0.09183673469387756, 0.0,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1,
            0.0, 0.0, 0.2, 0.3, 0.4, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.0034000000450760126) {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.24468085106382978, 0.48936170212765956, 0.13829787234042554,
            0.06382978723404255, 0.06382978723404255, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.023391812865497075, 0.3742690058479532, 0.19298245614035087,
            0.1695906432748538, 0.14035087719298245, 0.09941520467836257, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[21] <= 0.13934999704360962) {
      if (input[21] <= 0.08889999985694885) {
        if (input[40] <= 0.03581250086426735) {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9795918367346939,
            0.02040816326530612,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.20314999669790268) {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.3333333333333333,
            0.0,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.8333333333333334,
            0.0,
          ];
        }
      }
    } else {
      if (input[23] <= 0.5208333134651184) {
        if (input[32] <= 0.07847221940755844) {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.29023437201976776) {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3333333333333333, 0.0, 0.6666666666666666, 0.0, 0.0,
            0.0,
          ];
        } else {
          var63 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var64;
  if (input[6] <= 0.14030000567436218) {
    if (input[1] <= 0.1339344158768654) {
      if (input[41] <= 0.23055554926395416) {
        if (input[13] <= 0.22285155951976776) {
          var64 = [
            0.008333333333333333, 0.25, 0.016666666666666666, 0.05, 0.05,
            0.041666666666666664, 0.058333333333333334, 0.008333333333333333,
            0.0, 0.0, 0.008333333333333333, 0.26666666666666666, 0.025,
            0.008333333333333333, 0.0, 0.0, 0.03333333333333333, 0.0, 0.025,
            0.0, 0.15,
          ];
        } else {
          var64 = [
            0.0, 0.0, 0.0, 0.00904977375565611, 0.0, 0.01809954751131222,
            0.02262443438914027, 0.07692307692307693, 0.16289592760180996,
            0.20361990950226244, 0.36199095022624433, 0.14479638009049775, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[4] <= 0.17600557953119278) {
          var64 = [
            0.0, 0.0, 0.0, 0.036036036036036036, 0.0, 0.0, 0.018018018018018018,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06306306306306306,
            0.036036036036036036, 0.7927927927927928, 0.05405405405405406, 0.0,
          ];
        } else {
          var64 = [
            0.0, 0.0, 0.0, 0.15789473684210525, 0.10964912280701754,
            0.17982456140350878, 0.47368421052631576, 0.05701754385964912, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.021929824561403508,
            0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[22] <= 0.07695312798023224) {
        if (input[15] <= 0.1273999959230423) {
          var64 = [
            0.5565217391304348, 0.0, 0.33043478260869563, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11304347826086956, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var64 = [
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.05069444328546524) {
          var64 = [
            0.0, 0.0, 0.018518518518518517, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3611111111111111, 0.42592592592592593,
            0.09259259259259259, 0.05555555555555555, 0.046296296296296294, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var64 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03296703296703297, 0.3516483516483517, 0.1813186813186813,
            0.2032967032967033, 0.14285714285714285, 0.08791208791208792, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[17] <= 0.07222222536802292) {
      if (input[22] <= 0.08885538950562477) {
        if (input[11] <= 0.2147955298423767) {
          var64 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var64 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      } else {
        if (input[10] <= 0.15970846265554428) {
          var64 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.0, 0.0,
            0.3333333333333333,
          ];
        } else {
          var64 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2, 0.8, 0.0,
          ];
        }
      }
    } else {
      if (input[16] <= 0.3212890625) {
        var64 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        var64 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var65;
  if (input[27] <= 0.04230000078678131) {
    if (input[22] <= 0.09783339127898216) {
      if (input[5] <= 0.26076388359069824) {
        if (input[10] <= 0.1173115074634552) {
          var65 = [
            0.0, 0.27358490566037735, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.02830188679245283, 0.0, 0.07547169811320754, 0.4528301886792453,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.16981132075471697,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.04950495049504951, 0.019801980198019802,
            0.019801980198019802, 0.039603960396039604, 0.009900990099009901,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06930693069306931, 0.7821782178217822, 0.009900990099009901,
          ];
        }
      } else {
        if (input[41] <= 0.22812500596046448) {
          var65 = [
            0.0, 0.0, 0.0, 0.021645021645021644, 0.030303030303030304,
            0.012987012987012988, 0.03896103896103896, 0.12554112554112554,
            0.15151515151515152, 0.21212121212121213, 0.3939393939393939,
            0.012987012987012988, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.14516129032258066, 0.0967741935483871,
            0.22580645161290322, 0.45698924731182794, 0.07526881720430108, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.16759999841451645) {
        if (input[8] <= 0.46793055534362793) {
          var65 = [
            0.0, 0.0, 0.0, 0.3333333333333333, 0.38095238095238093, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.2857142857142857, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.009345794392523364, 0.028037383177570093,
            0.9065420560747663, 0.037383177570093455, 0.018691588785046728,
          ];
        }
      } else {
        if (input[11] <= 0.6484947502613068) {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.6666666666666666, 0.3333333333333333,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.010900000110268593) {
      if (input[17] <= 0.1664753407239914) {
        if (input[6] <= 0.07169999927282333) {
          var65 = [
            0.0449438202247191, 0.0, 0.2808988764044944, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.23595505617977527, 0.33707865168539325,
            0.033707865168539325, 0.0449438202247191, 0.02247191011235955, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.07142857142857142, 0.0, 0.8571428571428571,
            0.07142857142857142, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[21] <= 0.013650000095367432) {
          var65 = [
            0.7, 0.0375, 0.1375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.24358974358974358, 0.32051282051282054, 0.358974358974359,
            0.02564102564102564, 0.0, 0.05128205128205128, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.0722000002861023) {
        if (input[34] <= 0.4984375089406967) {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1388888888888889, 0.0, 0.3888888888888889, 0.3333333333333333,
            0.1388888888888889, 0.0, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.017241379310344827, 0.8275862068965517, 0.15517241379310345, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[0] <= 0.12249999865889549) {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.03571428571428571, 0.08928571428571429, 0.23214285714285715,
            0.6428571428571429, 0.0, 0.0, 0.0,
          ];
        } else {
          var65 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.07692307692307693, 0.46153846153846156, 0.46153846153846156,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var66;
  if (input[13] <= 0.15390624850988388) {
    if (input[9] <= 0.1339999958872795) {
      if (input[28] <= 0.06894531100988388) {
        if (input[9] <= 0.04059999994933605) {
          var66 = [
            0.0, 0.3375, 0.0, 0.0, 0.0, 0.0, 0.025, 0.0, 0.0, 0.0, 0.0125,
            0.2625, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0375, 0.0125, 0.0, 0.3125,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.12080536912751678, 0.10738255033557047,
            0.040268456375838924, 0.006711409395973154, 0.006711409395973154,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.013422818791946308,
            0.03355704697986577, 0.6442953020134228, 0.026845637583892617, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.1215277761220932) {
          var66 = [
            0.34574468085106386, 0.015957446808510637, 0.2127659574468085, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2872340425531915,
            0.11170212765957446, 0.015957446808510637, 0.010638297872340425,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09865470852017937, 0.4125560538116592, 0.13452914798206278,
            0.18385650224215247, 0.15695067264573992, 0.013452914798206279, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[5] <= 0.30763888359069824) {
        if (input[11] <= 0.44364503026008606) {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.6, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.008928571428571428, 0.017857142857142856, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03571428571428571, 0.9285714285714286, 0.008928571428571428,
          ];
        }
      } else {
        if (input[41] <= 0.37118056416511536) {
          var66 = [
            0.0, 0.0, 0.0, 0.125, 0.75, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.8333333333333334, 0.0, 0.16666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.11269531399011612) {
      if (input[20] <= 0.35414113104343414) {
        if (input[26] <= 0.16388888657093048) {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.028368794326241134, 0.0,
            0.04964539007092199, 0.2553191489361702, 0.6666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.05, 0.03333333333333333,
            0.4166666666666667, 0.4666666666666667, 0.03333333333333333, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[4] <= 0.5006954669952393) {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8, 0.2, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.06309999898076057) {
        if (input[40] <= 0.940625011920929) {
          var66 = [
            0.0, 0.0, 0.0, 0.01, 0.02, 0.08, 0.79, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.03910000063478947) {
          var66 = [
            0.0, 0.0, 0.0, 0.0, 0.10256410256410256, 0.20512820512820512,
            0.6410256410256411, 0.05128205128205128, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var66 = [
            0.0, 0.0, 0.0, 0.17073170731707318, 0.17073170731707318,
            0.5365853658536586, 0.12195121951219512, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var67;
  if (input[15] <= 0.01269999984651804) {
    if (input[19] <= 0.06464843451976776) {
      if (input[23] <= 0.4445277899503708) {
        if (input[30] <= 0.30790000408887863) {
          var67 = [
            0.014184397163120567, 0.0, 0.028368794326241134, 0.1347517730496454,
            0.09219858156028368, 0.09219858156028368, 0.06382978723404255,
            0.014184397163120567, 0.014184397163120567, 0.0,
            0.028368794326241134, 0.0, 0.0070921985815602835, 0.0,
            0.014184397163120567, 0.0, 0.0, 0.0, 0.014184397163120567,
            0.475177304964539, 0.0070921985815602835,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      } else {
        if (input[23] <= 0.5880506634712219) {
          var67 = [
            0.0, 0.0, 0.0, 0.009259259259259259, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.018518518518518517,
            0.037037037037037035, 0.8425925925925926, 0.09259259259259259, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3389830508474576, 0.5932203389830508,
            0.06779661016949153,
          ];
        }
      }
    } else {
      if (input[41] <= 0.22847221791744232) {
        if (input[18] <= 0.19120000302791595) {
          var67 = [
            0.0, 0.0, 0.0, 0.004830917874396135, 0.01932367149758454,
            0.004830917874396135, 0.04830917874396135, 0.10628019323671498,
            0.18840579710144928, 0.15942028985507245, 0.463768115942029,
            0.004830917874396135, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03278688524590164, 0.9672131147540983, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.5295138955116272) {
          var67 = [
            0.0, 0.0, 0.0, 0.07142857142857142, 0.15714285714285714,
            0.15714285714285714, 0.5285714285714286, 0.08571428571428572, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.2727272727272727, 0.0, 0.7272727272727273, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[32] <= 0.05069444328546524) {
      if (input[41] <= 0.30088141560554504) {
        if (input[2] <= 0.23993055522441864) {
          var67 = [
            0.038461538461538464, 0.9230769230769231, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.038461538461538464, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.41954022988505746, 0.0, 0.2413793103448276, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.26436781609195403, 0.04597701149425287,
            0.011494252873563218, 0.011494252873563218, 0.005747126436781609,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.03305000066757202) {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.5740740740740741, 0.3333333333333333, 0.0, 0.09259259259259259,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[0] <= 0.10190000012516975) {
        if (input[30] <= 0.013650000095367432) {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.03773584905660377, 0.22641509433962265,
            0.7358490566037735, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.2288789600133896) {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0641025641025641, 0.2948717948717949, 0.44871794871794873,
            0.14102564102564102, 0.05128205128205128, 0.0, 0.0, 0.0,
          ];
        } else {
          var67 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.06976744186046512, 0.7674418604651163, 0.046511627906976744,
            0.09302325581395349, 0.023255813953488372, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var68;
  if (input[29] <= 0.08819444477558136) {
    if (input[6] <= 0.13780000060796738) {
      if (input[25] <= 0.040062498301267624) {
        if (input[3] <= 0.04130000062286854) {
          var68 = [
            0.0, 0.22549019607843138, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0196078431372549, 0.4803921568627451, 0.0, 0.0, 0.0, 0.0,
            0.0196078431372549, 0.0, 0.12745098039215685, 0.0,
            0.12745098039215685,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.047619047619047616,
            0.005952380952380952, 0.05357142857142857, 0.25, 0.6369047619047619,
            0.005952380952380952, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[36] <= 0.007250000024214387) {
          var68 = [
            0.0, 0.0, 0.0, 0.0967741935483871, 0.056451612903225805,
            0.04032258064516129, 0.056451612903225805, 0.03225806451612903,
            0.024193548387096774, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03225806451612903, 0.6370967741935484, 0.024193548387096774, 0.0,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.08296943231441048, 0.13973799126637554,
            0.18777292576419213, 0.38427947598253276, 0.11353711790393013,
            0.08733624454148471, 0.004366812227074236, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[10] <= 0.10853658616542816) {
        var68 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
        ];
      } else {
        if (input[7] <= 0.6395964622497559) {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.21568627450980393, 0.7843137254901961,
            0.0,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[22] <= 0.06894531100988388) {
      if (input[2] <= 0.6454861164093018) {
        if (input[35] <= 0.09270833432674408) {
          var68 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var68 = [
            0.20689655172413793, 0.0, 0.5862068965517241, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.20689655172413793, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.015699999406933784) {
          var68 = [
            0.6055045871559633, 0.0, 0.28440366972477066, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11009174311926606, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.02187499962747097) {
        if (input[11] <= 0.14861111342906952) {
          var68 = [
            0.0, 0.0, 0.010638297872340425, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.46808510638297873, 0.26595744680851063,
            0.09574468085106383, 0.031914893617021274, 0.0425531914893617,
            0.0851063829787234, 0.0, 0.0, 0.0,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.29411764705882354, 0.17647058823529413, 0.35294117647058826,
            0.17647058823529413, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.05069444328546524) {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.6792452830188679, 0.22641509433962265, 0.07547169811320754,
            0.018867924528301886, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var68 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.019736842105263157, 0.28289473684210525, 0.3092105263157895,
            0.19736842105263158, 0.15789473684210525, 0.03289473684210526, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var69;
  if (input[3] <= 0.06500000134110451) {
    if (input[28] <= 0.06894531100988388) {
      if (input[22] <= 0.08419353514909744) {
        if (input[11] <= 0.38006944954395294) {
          var69 = [
            0.0, 0.25742574257425743, 0.0, 0.009900990099009901, 0.0, 0.0, 0.0,
            0.0, 0.009900990099009901, 0.0, 0.0594059405940594,
            0.46534653465346537, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.19801980198019803,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.013513513513513514, 0.04054054054054054, 0.0, 0.0,
            0.04054054054054054, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.02702702702702703, 0.8513513513513513, 0.02702702702702703,
          ];
        }
      } else {
        if (input[25] <= 0.13500100374221802) {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.045454545454545456, 0.4772727272727273,
            0.45454545454545453, 0.022727272727272728,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.022727272727272728, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03409090909090909,
            0.8863636363636364, 0.056818181818181816, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.02187499962747097) {
        if (input[6] <= 0.011500000022351742) {
          var69 = [
            0.34269662921348315, 0.0, 0.2696629213483146, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2752808988764045, 0.06179775280898876,
            0.028089887640449437, 0.02247191011235955, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.22857142857142856, 0.2, 0.11428571428571428, 0.14285714285714285,
            0.3142857142857143, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[34] <= 0.4912109375) {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.046511627906976744, 0.11627906976744186, 0.3023255813953488,
            0.3023255813953488, 0.22093023255813954, 0.011627906976744186, 0.0,
            0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16083916083916083, 0.5104895104895105, 0.13286713286713286,
            0.09090909090909091, 0.1048951048951049, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.05253906175494194) {
      if (input[31] <= 0.04472656175494194) {
        if (input[36] <= 0.021650000475347042) {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.046153846153846156,
            0.08461538461538462, 0.13076923076923078, 0.7384615384615385, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.01639344262295082, 0.04918032786885246,
            0.16393442622950818, 0.2459016393442623, 0.32786885245901637,
            0.13114754098360656, 0.06557377049180328, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.16180555522441864) {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.07142857142857142, 0.0, 0.21428571428571427,
            0.42857142857142855, 0.2857142857142857, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.06674999743700027) {
        if (input[32] <= 0.1701388880610466) {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.02702702702702703, 0.16216216216216217,
            0.32432432432432434, 0.32432432432432434, 0.16216216216216217, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0273972602739726, 0.0136986301369863,
            0.0684931506849315, 0.863013698630137, 0.0273972602739726, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.08179999887943268) {
          var69 = [
            0.0, 0.0, 0.0, 0.26, 0.36, 0.31, 0.07, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var69 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.1, 0.9, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var70;
  if (input[13] <= 0.15390624850988388) {
    if (input[9] <= 0.13665000349283218) {
      if (input[27] <= 0.03460000082850456) {
        if (input[34] <= 0.019471153616905212) {
          var70 = [
            0.0, 0.14917127071823205, 0.0, 0.11602209944751381,
            0.06077348066298342, 0.022099447513812154, 0.03314917127071823,
            0.011049723756906077, 0.0, 0.0, 0.011049723756906077,
            0.17679558011049723, 0.0, 0.0, 0.0, 0.0, 0.022099447513812154,
            0.022099447513812154, 0.26519337016574585, 0.016574585635359115,
            0.09392265193370165,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.13660000264644623) {
          var70 = [
            0.08225108225108226, 0.0, 0.05627705627705628, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.07792207792207792, 0.10822510822510822,
            0.329004329004329, 0.12987012987012986, 0.10822510822510822,
            0.10822510822510822, 0.0, 0.0, 0.0,
          ];
        } else {
          var70 = [
            0.2611464968152866, 0.01910828025477707, 0.1910828025477707, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.21019108280254778,
            0.12738853503184713, 0.09554140127388536, 0.08280254777070063,
            0.012738853503184714, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.11784999817609787) {
        if (input[10] <= 0.711718738079071) {
          var70 = [
            0.0, 0.0, 0.0, 0.1, 0.7, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2, 0.0, 0.0,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.6, 0.0, 0.4, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.6611422002315521) {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.02564102564102564, 0.9743589743589743,
            0.0,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.2222222222222222, 0.7777777777777778,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[24] <= 0.01720000058412552) {
      if (input[8] <= 0.2437363862991333) {
        if (input[18] <= 0.2767999991774559) {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.006622516556291391, 0.0,
            0.052980132450331126, 0.23178807947019867, 0.695364238410596,
            0.013245033112582781, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var70 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[8] <= 0.375) {
        if (input[20] <= 0.15590278059244156) {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.024390243902439025, 0.21951219512195122,
            0.12195121951219512, 0.21951219512195122, 0.3902439024390244,
            0.024390243902439025, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.08196721311475409, 0.01639344262295082,
            0.29508196721311475, 0.39344262295081966, 0.21311475409836064, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.038349999114871025) {
          var70 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.11956521739130435, 0.8043478260869565,
            0.07608695652173914, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var70 = [
            0.0, 0.0, 0.0, 0.25, 0.25, 0.3076923076923077, 0.19230769230769232,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var71;
  if (input[6] <= 0.1193000003695488) {
    if (input[3] <= 0.06500000134110451) {
      if (input[24] <= 0.030899999663233757) {
        if (input[20] <= 0.26421399414539337) {
          var71 = [
            0.15035799522673032, 0.081145584725537, 0.09307875894988067, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.00477326968973747, 0.0,
            0.12410501193317422, 0.1288782816229117, 0.21957040572792363,
            0.057279236276849645, 0.04295942720763723, 0.0405727923627685,
            0.016706443914081145, 0.002386634844868735, 0.03818615751789976,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[16] <= 0.17890624701976776) {
          var71 = [
            0.0, 0.0, 0.0, 0.08333333333333333, 0.041666666666666664, 0.025,
            0.03333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.008333333333333333, 0.8083333333333333, 0.0, 0.0,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.2857142857142857, 0.7142857142857143, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[24] <= 0.038999998942017555) {
        if (input[7] <= 0.2644684463739395) {
          var71 = [
            0.0, 0.0, 0.0, 0.01282051282051282, 0.0, 0.0, 0.0,
            0.01282051282051282, 0.07692307692307693, 0.24358974358974358,
            0.6089743589743589, 0.04487179487179487, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.37812499701976776) {
          var71 = [
            0.0, 0.0, 0.0, 0.06206896551724138, 0.08275862068965517,
            0.16551724137931034, 0.2206896551724138, 0.25517241379310346,
            0.20689655172413793, 0.006896551724137931, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.14788732394366197, 0.1267605633802817,
            0.1267605633802817, 0.5845070422535211, 0.014084507042253521, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[16] <= 0.1328125) {
      if (input[21] <= 0.13734999671578407) {
        if (input[8] <= 0.7217844128608704) {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.019417475728155338, 0.9805825242718447,
            0.0,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.5,
          ];
        }
      } else {
        if (input[29] <= 0.3187499940395355) {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.8333333333333334, 0.0, 0.0, 0.0,
            0.16666666666666666,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[7] <= 0.5408203303813934) {
        var71 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[6] <= 0.13825000077486038) {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var71 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3333333333333333, 0.0, 0.6666666666666666, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var72;
  if (input[9] <= 0.14580000191926956) {
    if (input[4] <= 0.14484314620494843) {
      if (input[20] <= 0.300672322511673) {
        if (input[26] <= 0.44050954282283783) {
          var72 = [
            0.14316239316239315, 0.04700854700854701, 0.12393162393162394, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.010683760683760684, 0.0,
            0.09829059829059829, 0.1047008547008547, 0.19017094017094016,
            0.09188034188034189, 0.0811965811965812, 0.06837606837606838,
            0.021367521367521368, 0.004273504273504274, 0.014957264957264958,
          ];
        } else {
          var72 = [
            0.0, 0.0, 0.0, 0.01818181818181818, 0.0, 0.0, 0.00909090909090909,
            0.00909090909090909, 0.00909090909090909, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.00909090909090909, 0.0, 0.03636363636363636,
            0.8636363636363636, 0.045454545454545456, 0.0,
          ];
        }
      } else {
        var72 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[11] <= 0.3690972179174423) {
        if (input[4] <= 0.553440511226654) {
          var72 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.014084507042253521,
            0.09859154929577464, 0.15492957746478872, 0.14788732394366197,
            0.5774647887323944, 0.007042253521126761, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var72 = [
            0.0, 0.0, 0.0, 0.0, 0.014084507042253521, 0.028169014084507043,
            0.014084507042253521, 0.14084507042253522, 0.30985915492957744,
            0.323943661971831, 0.16901408450704225, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.06464999914169312) {
          var72 = [
            0.0, 0.0, 0.0, 0.0, 0.008403361344537815, 0.11764705882352941,
            0.7058823529411765, 0.10084033613445378, 0.04201680672268908, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.025210084033613446, 0.0,
            0.0,
          ];
        } else {
          var72 = [
            0.0, 0.0, 0.0, 0.3090909090909091, 0.21818181818181817, 0.2,
            0.18181818181818182, 0.01818181818181818, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.07272727272727272, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[7] <= 0.19472655653953552) {
      if (input[25] <= 0.6058593690395355) {
        var72 = [
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        var72 = [
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[8] <= 0.2867916598916054) {
        var72 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[3] <= 0.04774999991059303) {
          var72 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.04716981132075472, 0.9528301886792453,
            0.0,
          ];
        } else {
          var72 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var73;
  if (input[20] <= 0.0972222201526165) {
    if (input[9] <= 0.13665000349283218) {
      if (input[17] <= 0.01736111007630825) {
        if (input[32] <= 0.2033417746424675) {
          var73 = [
            0.014492753623188406, 0.0, 0.0, 0.3188405797101449,
            0.17391304347826086, 0.11594202898550725, 0.028985507246376812,
            0.057971014492753624, 0.028985507246376812, 0.0,
            0.057971014492753624, 0.0, 0.014492753623188406,
            0.043478260869565216, 0.0, 0.0, 0.043478260869565216,
            0.043478260869565216, 0.057971014492753624, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.03676470588235294, 0.022058823529411766,
            0.007352941176470588, 0.022058823529411766, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.007352941176470588, 0.7794117647058824,
            0.007352941176470588, 0.11764705882352941,
          ];
        }
      } else {
        if (input[30] <= 0.0034000000450760126) {
          var73 = [
            0.22388059701492538, 0.10820895522388059, 0.17537313432835822, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.17537313432835822,
            0.1791044776119403, 0.0708955223880597, 0.04477611940298507,
            0.022388059701492536, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05142857142857143, 0.34285714285714286, 0.19428571428571428,
            0.21142857142857144, 0.2, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.2867916598916054) {
        if (input[6] <= 0.03294999897480011) {
          var73 = [
            0.0, 0.0, 0.0, 0.375, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.125,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[37] <= 0.09648437798023224) {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.020833333333333332, 0.9791666666666666,
            0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.5, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[26] <= 0.33298611640930176) {
      if (input[5] <= 0.2926281988620758) {
        if (input[13] <= 0.598931074142456) {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.22534722089767456) {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015151515151515152, 0.0,
            0.12121212121212122, 0.4696969696969697, 0.3939393939393939, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03225806451612903, 0.0,
            0.043010752688172046, 0.08602150537634409, 0.7634408602150538,
            0.07526881720430108, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.004449999891221523) {
        if (input[12] <= 0.12390000000596046) {
          var73 = [
            0.0, 0.0, 0.0, 0.1111111111111111, 0.1111111111111111,
            0.2222222222222222, 0.5555555555555556, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.0, 0.02040816326530612, 0.0, 0.12244897959183673,
            0.4489795918367347, 0.3877551020408163, 0.02040816326530612, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.04050000011920929) {
          var73 = [
            0.0, 0.0, 0.0, 0.04950495049504951, 0.0297029702970297,
            0.04950495049504951, 0.7425742574257426, 0.1188118811881188,
            0.009900990099009901, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var73 = [
            0.0, 0.0, 0.0, 0.14545454545454545, 0.2909090909090909,
            0.34545454545454546, 0.2, 0.01818181818181818, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var74;
  if (input[12] <= 0.05125000141561031) {
    if (input[16] <= 0.12558594346046448) {
      if (input[22] <= 0.10382254421710968) {
        if (input[19] <= 0.13462500274181366) {
          var74 = [
            0.006578947368421052, 0.0, 0.03289473684210526, 0.17105263157894737,
            0.08552631578947369, 0.02631578947368421, 0.013157894736842105,
            0.019736842105263157, 0.0, 0.0, 0.0, 0.0, 0.006578947368421052, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.019736842105263157, 0.4934210526315789, 0.125,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.125, 0.2, 0.05, 0.0, 0.0, 0.0, 0.0, 0.025, 0.6,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.06875000149011612) {
          var74 = [
            0.0, 0.0, 0.0, 0.057692307692307696, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.009615384615384616, 0.019230769230769232, 0.0,
            0.019230769230769232, 0.038461538461538464, 0.8269230769230769,
            0.028846153846153848, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.6666666666666666,
            0.08333333333333333,
          ];
        }
      }
    } else {
      if (input[40] <= 0.0224609375) {
        if (input[23] <= 0.15763889253139496) {
          var74 = [
            0.41935483870967744, 0.2064516129032258, 0.2645161290322581, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.10967741935483871, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.025, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.3625, 0.2375, 0.2, 0.05, 0.0625, 0.0625, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.5189453065395355) {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.21428571428571427, 0.08333333333333333, 0.20238095238095238,
            0.30952380952380953, 0.19047619047619047, 0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.125, 0.5666666666666667, 0.1, 0.13333333333333333, 0.075, 0.0,
            0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.3975694477558136) {
      if (input[13] <= 0.3714843690395355) {
        if (input[25] <= 0.556640625) {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.14285714285714285, 0.2857142857142857,
            0.14285714285714285, 0.14285714285714285, 0.2857142857142857, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07407407407407407,
            0.1111111111111111, 0.7777777777777778, 0.037037037037037035, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.011749999597668648) {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11650485436893204,
            0.10679611650485436, 0.15048543689320387, 0.49029126213592233,
            0.13592233009708737, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3, 0.1, 0.5, 0.1, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[18] <= 0.06565000116825104) {
        if (input[32] <= 0.18645833432674408) {
          var74 = [
            0.0, 0.0, 0.0, 0.027777777777777776, 0.05555555555555555,
            0.5277777777777778, 0.2222222222222222, 0.16666666666666666, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.15789473684210525, 0.13157894736842105,
            0.6052631578947368, 0.10526315789473684, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.2975499927997589) {
          var74 = [
            0.0, 0.0, 0.0, 0.010752688172043012, 0.03225806451612903,
            0.08602150537634409, 0.7419354838709677, 0.10752688172043011,
            0.021505376344086023, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var74 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.16666666666666666,
            0.6666666666666666, 0.16666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var75;
  if (input[27] <= 0.022199999541044235) {
    if (input[23] <= 0.4223194420337677) {
      if (input[30] <= 0.008750000037252903) {
        if (input[14] <= 0.34756945073604584) {
          var75 = [
            0.0, 0.36363636363636365, 0.0, 0.0, 0.0, 0.15584415584415584,
            0.05194805194805195, 0.0, 0.0, 0.0, 0.06493506493506493,
            0.36363636363636365, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.004629629629629629, 0.06481481481481481,
            0.12962962962962962, 0.1111111111111111, 0.14351851851851852,
            0.4398148148148148, 0.10648148148148148, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[5] <= 0.2465277761220932) {
          var75 = [
            0.0, 0.0, 0.0, 0.025, 0.0125, 0.0, 0.0125, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.025, 0.725, 0.2,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.11160714285714286, 0.14285714285714285,
            0.20982142857142858, 0.44642857142857145, 0.05803571428571429,
            0.03125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[34] <= 0.017875000834465027) {
        if (input[26] <= 0.4560000002384186) {
          var75 = [
            0.0, 0.0, 0.0, 0.06, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.02, 0.04, 0.12, 0.72, 0.04,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.037037037037037035, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.07407407407407407,
            0.6666666666666666, 0.2222222222222222, 0.0,
          ];
        }
      } else {
        var75 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[6] <= 0.011500000022351742) {
      if (input[21] <= 0.02710000053048134) {
        if (input[1] <= 0.45643578469753265) {
          var75 = [
            0.08333333333333333, 0.05555555555555555, 0.7222222222222222, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1388888888888889, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var75 = [
            0.6195652173913043, 0.0, 0.2391304347826087, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.13043478260869565, 0.0,
            0.010869565217391304, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[21] <= 0.17384999990463257) {
          var75 = [
            0.0, 0.0, 0.058823529411764705, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.6274509803921569, 0.29411764705882354,
            0.0196078431372549, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.3, 0.4, 0.3, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.12139999866485596) {
        if (input[30] <= 0.0035500000230968) {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.5476190476190477, 0.4523809523809524, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.044642857142857144, 0.5892857142857143, 0.22321428571428573,
            0.07142857142857142, 0.0625, 0.008928571428571428, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.675585925579071) {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.044444444444444446, 0.1111111111111111, 0.17777777777777778,
            0.5111111111111111, 0.15555555555555556, 0.0, 0.0, 0.0,
          ];
        } else {
          var75 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09090909090909091, 0.045454545454545456,
            0.8636363636363636, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var76;
  if (input[5] <= 0.5684027671813965) {
    if (input[9] <= 0.14639999717473984) {
      if (input[22] <= 0.09783339127898216) {
        if (input[33] <= 0.0025500000920146704) {
          var76 = [
            0.0, 0.272, 0.0, 0.104, 0.0, 0.008, 0.0, 0.016, 0.016, 0.0, 0.04,
            0.368, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.016, 0.04, 0.12,
          ];
        } else {
          var76 = [
            0.49206349206349204, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.0,
            0.007936507936507936, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.0225694440305233) {
          var76 = [
            0.0, 0.0, 0.0, 0.01639344262295082, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.01639344262295082, 0.00819672131147541, 0.0,
            0.04918032786885246, 0.05737704918032787, 0.8278688524590164,
            0.02459016393442623, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.003436426116838488, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.11683848797250859, 0.18213058419243985,
            0.32646048109965636, 0.09965635738831616, 0.15120274914089346,
            0.12027491408934708, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.025299999862909317) {
        if (input[6] <= 0.13234999775886536) {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.10924999788403511) {
          var76 = [
            0.0, 0.0, 0.0, 0.4, 0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.041666666666666664, 0.9583333333333334,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.3739583343267441) {
      if (input[20] <= 0.2571180611848831) {
        if (input[24] <= 0.01720000058412552) {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11538461538461539,
            0.358974358974359, 0.5256410256410257, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.021739130434782608, 0.0, 0.08695652173913043,
            0.30434782608695654, 0.5869565217391305, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.32795000076293945) {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.014492753623188406,
            0.028985507246376812, 0.014492753623188406, 0.057971014492753624,
            0.8840579710144928, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.0482999999076128) {
        if (input[6] <= 0.023950000293552876) {
          var76 = [
            0.0, 0.0, 0.0, 0.014084507042253521, 0.04225352112676056,
            0.056338028169014086, 0.352112676056338, 0.4788732394366197,
            0.056338028169014086, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.009433962264150943, 0.03773584905660377,
            0.1509433962264151, 0.7264150943396226, 0.018867924528301886, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05660377358490566,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.1018499992787838) {
          var76 = [
            0.0, 0.0, 0.0, 0.0, 0.5217391304347826, 0.391304347826087,
            0.043478260869565216, 0.043478260869565216, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var76 = [
            0.0, 0.0, 0.0, 0.5909090909090909, 0.18181818181818182,
            0.22727272727272727, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var77;
  if (input[15] <= 0.013299999758601189) {
    if (input[3] <= 0.06500000134110451) {
      if (input[9] <= 0.1339999958872795) {
        if (input[10] <= 0.1304253488779068) {
          var77 = [
            0.011235955056179775, 0.0, 0.02247191011235955, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.011235955056179775, 0.0, 0.056179775280898875,
            0.6629213483146067, 0.0, 0.0449438202247191, 0.0, 0.0, 0.0, 0.0,
            0.011235955056179775, 0.0, 0.1797752808988764,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.04132231404958678, 0.01652892561983471, 0.0,
            0.008264462809917356, 0.008264462809917356, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.008264462809917356, 0.8842975206611571,
            0.03305785123966942, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.140583336353302) {
          var77 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.42857142857142855, 0.5714285714285714,
            0.0,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.017699115044247787, 0.017699115044247787, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05309734513274336, 0.911504424778761, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.22812500596046448) {
        if (input[25] <= 0.07558593899011612) {
          var77 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.013245033112582781, 0.0,
            0.08609271523178808, 0.2582781456953642, 0.5960264900662252,
            0.046357615894039736, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.06315789473684211, 0.12631578947368421,
            0.08421052631578947, 0.06315789473684211, 0.29473684210526313,
            0.3368421052631579, 0.031578947368421054, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.06520000100135803) {
          var77 = [
            0.0, 0.0, 0.0, 0.48148148148148145, 0.3148148148148148,
            0.09259259259259259, 0.1111111111111111, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.012987012987012988, 0.045454545454545456,
            0.2012987012987013, 0.6103896103896104, 0.12987012987012986, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.15312500298023224) {
      if (input[15] <= 0.29189999401569366) {
        if (input[1] <= 0.45643578469753265) {
          var77 = [
            0.1724137931034483, 0.0, 0.6896551724137931, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10344827586206896, 0.0,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var77 = [
            0.6071428571428571, 0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.14285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        var77 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[21] <= 0.10624999925494194) {
        if (input[30] <= 0.0070500001311302185) {
          var77 = [
            0.0, 0.0, 0.023255813953488372, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3023255813953488, 0.46511627906976744,
            0.19767441860465115, 0.011627906976744186, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.022222222222222223, 0.7444444444444445, 0.07777777777777778,
            0.07777777777777778, 0.07777777777777778, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.3722222149372101) {
          var77 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.4666666666666667, 0.5333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var77 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.012987012987012988, 0.05194805194805195, 0.36363636363636365,
            0.33766233766233766, 0.23376623376623376, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var78;
  if (input[3] <= 0.06640000268816948) {
    if (input[30] <= 0.06805000081658363) {
      if (input[25] <= 0.040062498301267624) {
        if (input[22] <= 0.04412499815225601) {
          var78 = [
            0.29694323144104806, 0.1572052401746725, 0.2183406113537118, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.021834061135371178,
            0.24017467248908297, 0.048034934497816595, 0.0,
            0.004366812227074236, 0.0, 0.0, 0.0, 0.0, 0.013100436681222707, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.004149377593360996, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0912863070539419, 0.1908713692946058,
            0.2572614107883817, 0.15352697095435686, 0.14937759336099585,
            0.0995850622406639, 0.04149377593360996, 0.012448132780082987, 0.0,
          ];
        }
      } else {
        if (input[27] <= 0.04230000078678131) {
          var78 = [
            0.0, 0.0, 0.0, 0.017094017094017096, 0.03418803418803419, 0.0, 0.0,
            0.0, 0.017094017094017096, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.017094017094017096, 0.017094017094017096, 0.8717948717948718,
            0.02564102564102564, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.13636363636363635, 0.3181818181818182, 0.5,
            0.045454545454545456, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[31] <= 0.6861478388309479) {
        if (input[30] <= 0.2970000058412552) {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.034482758620689655, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.15517241379310345,
            0.05172413793103448, 0.7586206896551724, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      } else {
        if (input[9] <= 0.06729999929666519) {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9821428571428571,
            0.017857142857142856,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.06652644276618958) {
      if (input[26] <= 0.11944444477558136) {
        if (input[20] <= 0.20165598392486572) {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14545454545454545,
            0.4727272727272727, 0.38181818181818183, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.019417475728155338, 0.0,
            0.019417475728155338, 0.13592233009708737, 0.7766990291262136,
            0.04854368932038835, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.3232421875) {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8461538461538461,
            0.15384615384615385, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.08333333333333333, 0.13333333333333333,
            0.06666666666666667, 0.08333333333333333, 0.18333333333333332,
            0.43333333333333335, 0.016666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[41] <= 0.7064357399940491) {
        if (input[30] <= 0.016150000505149364) {
          var78 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.40625, 0.40625, 0.1875, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.43333333333333335, 0.43333333333333335, 0.1,
            0.03333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.15114999562501907) {
          var78 = [
            0.0, 0.0, 0.0, 0.041666666666666664, 0.025, 0.06666666666666667,
            0.8, 0.06666666666666667, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var78 = [
            0.0, 0.0, 0.0, 0.30303030303030304, 0.2727272727272727,
            0.2727272727272727, 0.09090909090909091, 0.06060606060606061, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var79;
  if (input[3] <= 0.048700001090765) {
    if (input[17] <= 0.0225694440305233) {
      if (input[40] <= 0.13328973203897476) {
        if (input[10] <= 0.16381002217531204) {
          var79 = [
            0.03225806451612903, 0.0, 0.0967741935483871, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.021505376344086023, 0.0, 0.053763440860215055,
            0.5268817204301075, 0.0, 0.010752688172043012, 0.0, 0.0,
            0.010752688172043012, 0.03225806451612903, 0.0, 0.0,
            0.21505376344086022,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.036585365853658534, 0.012195121951219513,
            0.012195121951219513, 0.0, 0.024390243902439025, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.04878048780487805,
            0.8536585365853658, 0.012195121951219513,
          ];
        }
      } else {
        if (input[25] <= 0.047870710492134094) {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.025, 0.0, 0.0, 0.0, 0.0, 0.4, 0.575, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.02702702702702703, 0.009009009009009009, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.02702702702702703, 0.8648648648648649, 0.07207207207207207, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.0224609375) {
        if (input[22] <= 0.07187499850988388) {
          var79 = [
            0.4966442953020134, 0.1610738255033557, 0.2550335570469799, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.087248322147651, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.047619047619047616, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.38095238095238093, 0.21428571428571427,
            0.11904761904761904, 0.047619047619047616, 0.05952380952380952,
            0.13095238095238096, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.5189453065395355) {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.32608695652173914, 0.10869565217391304, 0.22826086956521738,
            0.20652173913043478, 0.13043478260869565, 0.0, 0.0, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11016949152542373, 0.5084745762711864, 0.1016949152542373,
            0.17796610169491525, 0.1016949152542373, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[7] <= 0.02617187425494194) {
      if (input[9] <= 0.01510000042617321) {
        if (input[20] <= 0.2487446591258049) {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.008264462809917356, 0.01652892561983471, 0.0,
            0.1652892561983471, 0.30578512396694213, 0.256198347107438,
            0.24793388429752067, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.016666666666666666,
            0.06666666666666667, 0.05, 0.85, 0.016666666666666666, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[24] <= 0.08935000002384186) {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8148148148148148,
            0.18518518518518517, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.13157894736842105, 0.3157894736842105,
            0.07894736842105263, 0.15789473684210525, 0.18421052631578946,
            0.13157894736842105, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.045100001618266106) {
        if (input[20] <= 0.2562568038702011) {
          var79 = [
            0.0, 0.0, 0.0, 0.028169014084507043, 0.1267605633802817,
            0.19718309859154928, 0.5915492957746479, 0.056338028169014086, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.17695312201976776) {
          var79 = [
            0.0, 0.0, 0.0, 0.47540983606557374, 0.2459016393442623,
            0.21311475409836064, 0.06557377049180328, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var79 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var80;
  if (input[5] <= 0.5741844177246094) {
    if (input[28] <= 0.0693359375) {
      if (input[22] <= 0.12211650609970093) {
        if (input[6] <= 0.09075000137090683) {
          var80 = [
            0.0, 0.25225225225225223, 0.0, 0.07207207207207207,
            0.06306306306306306, 0.02702702702702703, 0.02702702702702703,
            0.009009009009009009, 0.018018018018018018, 0.0,
            0.02702702702702703, 0.36936936936936937, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.02702702702702703, 0.0, 0.10810810810810811,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.012048192771084338, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03614457831325301,
            0.9518072289156626, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.12159999832510948) {
          var80 = [
            0.0, 0.0, 0.0, 0.029411764705882353, 0.00980392156862745, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0196078431372549,
            0.00980392156862745, 0.9215686274509803, 0.00980392156862745, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.07142857142857142, 0.25, 0.6071428571428571,
            0.07142857142857142,
          ];
        }
      }
    } else {
      if (input[41] <= 0.23402777314186096) {
        if (input[23] <= 0.15173611044883728) {
          var80 = [
            0.5116279069767442, 0.023255813953488372, 0.32558139534883723, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.13953488372093023, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.024691358024691357, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.4074074074074074, 0.19753086419753085,
            0.08641975308641975, 0.07407407407407407, 0.13580246913580246,
            0.07407407407407407, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.037699999287724495) {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.26865671641791045, 0.4925373134328358, 0.08208955223880597,
            0.11940298507462686, 0.03731343283582089, 0.0, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0891089108910891, 0.3465346534653465, 0.1782178217821782,
            0.31683168316831684, 0.06930693069306931, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[41] <= 0.22847221791744232) {
      if (input[24] <= 0.014700000174343586) {
        if (input[19] <= 0.5494489371776581) {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01, 0.0, 0.02, 0.13, 0.82, 0.02,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.16216216216216217,
            0.4864864864864865, 0.35135135135135137, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[13] <= 0.23046875) {
          var80 = [
            0.0, 0.0, 0.0, 0.29411764705882354, 0.35294117647058826,
            0.23529411764705882, 0.11764705882352941, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.046875, 0.078125, 0.34375, 0.515625,
            0.015625, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.06644999980926514) {
        if (input[13] <= 0.7183443605899811) {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.06451612903225806, 0.12903225806451613,
            0.7096774193548387, 0.043010752688172046, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.053763440860215055, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.058823529411764705, 0.0, 0.11764705882352941,
            0.23529411764705882, 0.5882352941176471, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[23] <= 0.47959403693675995) {
          var80 = [
            0.0, 0.0, 0.0, 0.27586206896551724, 0.2988505747126437,
            0.20689655172413793, 0.20689655172413793, 0.011494252873563218, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var80 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var81;
  if (input[3] <= 0.06500000134110451) {
    if (input[16] <= 0.12558594346046448) {
      if (input[19] <= 0.13462500274181366) {
        if (input[22] <= 0.1002630814909935) {
          var81 = [
            0.04477611940298507, 0.0, 0.022388059701492536, 0.03731343283582089,
            0.029850746268656716, 0.007462686567164179, 0.007462686567164179,
            0.022388059701492536, 0.0, 0.0, 0.007462686567164179, 0.0,
            0.007462686567164179, 0.0, 0.0, 0.0, 0.0, 0.0, 0.03731343283582089,
            0.6268656716417911, 0.14925373134328357,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.007407407407407408, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.014814814814814815, 0.0, 0.0, 0.022222222222222223,
            0.037037037037037035, 0.8074074074074075, 0.1037037037037037,
            0.007407407407407408,
          ];
        }
      } else {
        if (input[10] <= 0.12343750149011612) {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.015625, 0.0, 0.0,
            0.984375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.014150000177323818) {
        if (input[17] <= 0.32977767288684845) {
          var81 = [
            0.35714285714285715, 0.0, 0.2087912087912088, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.26373626373626374, 0.0989010989010989,
            0.038461538461538464, 0.016483516483516484, 0.016483516483516484,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.03519999980926514) {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.21951219512195122, 0.5691056910569106, 0.07317073170731707,
            0.04878048780487805, 0.08943089430894309, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.056818181818181816, 0.2727272727272727, 0.25,
            0.42045454545454547, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[40] <= 0.06652644276618958) {
      if (input[9] <= 0.04464999958872795) {
        if (input[24] <= 0.014700000174343586) {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06206896551724138,
            0.2827586206896552, 0.6344827586206897, 0.020689655172413793, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.325, 0.625, 0.05, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[37] <= 0.36152343451976776) {
          var81 = [
            0.0, 0.0, 0.0, 0.08333333333333333, 0.8333333333333334, 0.0,
            0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.23076923076923078, 0.0, 0.23076923076923078,
            0.23076923076923078, 0.3076923076923077, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[20] <= 0.18159722536802292) {
        if (input[14] <= 0.3319396823644638) {
          var81 = [
            0.0, 0.0, 0.0, 0.6206896551724138, 0.3448275862068966,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.05263157894736842, 0.10526315789473684,
            0.3508771929824561, 0.3333333333333333, 0.15789473684210525, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[4] <= 0.2852289229631424) {
          var81 = [
            0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.8333333333333334, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var81 = [
            0.0, 0.0, 0.0, 0.0234375, 0.09375, 0.125, 0.6953125, 0.0625, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var82;
  if (input[29] <= 0.09409721940755844) {
    if (input[3] <= 0.06640000268816948) {
      if (input[20] <= 0.33887386322021484) {
        if (input[9] <= 0.13385000079870224) {
          var82 = [
            0.0, 0.13989637305699482, 0.0, 0.05699481865284974,
            0.0051813471502590676, 0.015544041450777202, 0.0051813471502590676,
            0.025906735751295335, 0.0, 0.0, 0.025906735751295335, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.02072538860103627, 0.03626943005181347,
            0.5595854922279793, 0.02072538860103627, 0.08808290155440414,
          ];
        } else {
          var82 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0594059405940594, 0.9405940594059405,
            0.0,
          ];
        }
      } else {
        var82 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[39] <= 0.004449999891221523) {
        if (input[10] <= 0.05839843675494194) {
          var82 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.01639344262295082,
            0.060109289617486336, 0.15846994535519127, 0.2185792349726776,
            0.5081967213114754, 0.03825136612021858, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var82 = [
            0.0, 0.0, 0.0, 0.24444444444444444, 0.2, 0.17777777777777778,
            0.08888888888888889, 0.17777777777777778, 0.1111111111111111, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.3850845396518707) {
          var82 = [
            0.0, 0.0, 0.0, 0.4067796610169492, 0.15254237288135594,
            0.2033898305084746, 0.13559322033898305, 0.1016949152542373, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var82 = [
            0.0, 0.0, 0.0, 0.03571428571428571, 0.08571428571428572,
            0.16428571428571428, 0.5857142857142857, 0.12857142857142856, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[23] <= 0.15173611044883728) {
      if (input[15] <= 0.3774000108242035) {
        if (input[29] <= 0.37049999833106995) {
          var82 = [
            0.18181818181818182, 0.0, 0.7878787878787878, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.030303030303030304, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var82 = [
            0.6627906976744186, 0.0, 0.20930232558139536, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.10465116279069768, 0.0,
            0.023255813953488372, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var82 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[7] <= 0.03242187574505806) {
        if (input[21] <= 0.04580000042915344) {
          var82 = [
            0.0, 0.0, 0.04, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.92,
            0.04, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var82 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.15,
            0.675, 0.075, 0.1, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[15] <= 0.06890000030398369) {
          var82 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07518796992481203, 0.21804511278195488, 0.22556390977443608,
            0.20300751879699247, 0.22556390977443608, 0.05263157894736842, 0.0,
            0.0,
          ];
        } else {
          var82 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.13333333333333333, 0.6111111111111112, 0.08888888888888889, 0.1,
            0.06666666666666667, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var83;
  if (input[6] <= 0.1148499995470047) {
    if (input[27] <= 0.03460000082850456) {
      if (input[23] <= 0.44326774775981903) {
        if (input[24] <= 0.014700000174343586) {
          var83 = [
            0.0, 0.10887096774193548, 0.0, 0.0, 0.0, 0.0, 0.024193548387096774,
            0.004032258064516129, 0.04435483870967742, 0.13709677419354838,
            0.4274193548387097, 0.1975806451612903, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.056451612903225805,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.09523809523809523, 0.1391941391941392,
            0.1391941391941392, 0.4175824175824176, 0.10256410256410256,
            0.08791208791208792, 0.007326007326007326, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.01098901098901099, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.10704627260565758) {
          var83 = [
            0.0, 0.0, 0.0, 0.5714285714285714, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.42857142857142855,
            0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.009900990099009901, 0.0297029702970297,
            0.9603960396039604, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.2395833283662796) {
        if (input[23] <= 0.13958333432674408) {
          var83 = [
            0.45081967213114754, 0.00819672131147541, 0.4098360655737705, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11475409836065574, 0.0,
            0.01639344262295082, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0547945205479452, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.4794520547945205, 0.3698630136986301,
            0.0684931506849315, 0.0136986301369863, 0.0136986301369863, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.5087740421295166) {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09923664122137404, 0.5648854961832062, 0.16030534351145037,
            0.06870229007633588, 0.09923664122137404, 0.007633587786259542, 0.0,
            0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.18181818181818182, 0.20454545454545456, 0.2727272727272727,
            0.18181818181818182, 0.1590909090909091, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[28] <= 0.0693359375) {
      if (input[30] <= 0.05075000040233135) {
        if (input[7] <= 0.5609413385391235) {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.6666666666666666,
            0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.2029999941587448) {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.017857142857142856, 0.017857142857142856,
            0.9642857142857143, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.4697916656732559) {
        if (input[15] <= 0.07894999906420708) {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.19296874850988388) {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var83 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.058823529411764705, 0.9411764705882353, 0.0, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var84;
  if (input[6] <= 0.1193000003695488) {
    if (input[27] <= 0.04230000078678131) {
      if (input[22] <= 0.10382254421710968) {
        if (input[24] <= 0.014650000259280205) {
          var84 = [
            0.0, 0.13100436681222707, 0.0, 0.0, 0.0, 0.0, 0.03056768558951965,
            0.0, 0.021834061135371178, 0.1703056768558952, 0.39737991266375544,
            0.2052401746724891, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.043668122270742356,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0, 0.10561056105610561, 0.10891089108910891,
            0.17491749174917492, 0.33663366336633666, 0.1419141914191419,
            0.10891089108910891, 0.009900990099009901, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.009900990099009901, 0.0033003300330033004, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.06640000268816948) {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.037037037037037035, 0.9629629629629629, 0.0,
            0.0,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0, 0.4166666666666667, 0.5833333333333334, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.003800000064074993) {
        if (input[22] <= 0.07187499850988388) {
          var84 = [
            0.5166666666666667, 0.05, 0.35, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.08333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.45,
            0.3375, 0.1375, 0.0375, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[7] <= 0.3867638111114502) {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.22123893805309736, 0.5132743362831859, 0.061946902654867256,
            0.1504424778761062, 0.05309734513274336, 0.0, 0.0, 0.0,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10714285714285714, 0.16666666666666666, 0.2619047619047619,
            0.20238095238095238, 0.2261904761904762, 0.03571428571428571, 0.0,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.17954687774181366) {
      if (input[30] <= 0.013199999928474426) {
        var84 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[17] <= 0.06875000149011612) {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[1] <= 0.07402344048023224) {
        if (input[26] <= 0.7065557241439819) {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.008264462809917356, 0.01652892561983471,
            0.049586776859504134, 0.9256198347107438, 0.0,
          ];
        } else {
          var84 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      } else {
        var84 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  }
  var var85;
  if (input[9] <= 0.13439999520778656) {
    if (input[29] <= 0.09652777761220932) {
      if (input[26] <= 0.11944444477558136) {
        if (input[17] <= 0.33038193732500076) {
          var85 = [
            0.0, 0.0, 0.0, 0.013215859030837005, 0.0, 0.0, 0.004405286343612335,
            0.004405286343612335, 0.030837004405286344, 0.17180616740088106,
            0.4008810572687225, 0.24669603524229075, 0.0, 0.0, 0.0, 0.0,
            0.004405286343612335, 0.0, 0.03524229074889868,
            0.004405286343612335, 0.08370044052863436,
          ];
        } else {
          var85 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.06640000268816948) {
          var85 = [
            0.0, 0.0, 0.0, 0.010101010101010102, 0.020202020202020204, 0.0,
            0.06060606060606061, 0.0, 0.010101010101010102, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.030303030303030304, 0.8686868686868687, 0.0,
            0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 0.11235955056179775, 0.1198501872659176,
            0.16853932584269662, 0.3333333333333333, 0.12734082397003746,
            0.1348314606741573, 0.003745318352059925, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.2395833283662796) {
        if (input[22] <= 0.07187499850988388) {
          var85 = [
            0.4928571428571429, 0.02142857142857143, 0.34285714285714286, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.12857142857142856, 0.0,
            0.014285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.028985507246376812, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.42028985507246375, 0.43478260869565216,
            0.057971014492753624, 0.043478260869565216, 0.014492753623188406,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[1] <= 0.5574342608451843) {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.1568627450980392, 0.09803921568627451, 0.21568627450980393,
            0.21568627450980393, 0.24509803921568626, 0.06862745098039216, 0.0,
            0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.025, 0.5833333333333334, 0.15833333333333333, 0.125,
            0.10833333333333334, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[24] <= 0.17159999907016754) {
      if (input[7] <= 0.8207932710647583) {
        if (input[7] <= 0.11249999701976776) {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.04310344827586207, 0.9568965517241379,
            0.0,
          ];
        }
      } else {
        if (input[31] <= 0.675976574420929) {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.040300000458955765) {
        if (input[36] <= 0.0466499999165535) {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[25] <= 0.4173828065395355) {
          var85 = [
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var85 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var86;
  if (input[27] <= 0.04230000078678131) {
    if (input[22] <= 0.1002630814909935) {
      if (input[25] <= 0.14969951659440994) {
        if (input[4] <= 0.37478554248809814) {
          var86 = [
            0.0, 0.14204545454545456, 0.0, 0.0, 0.0, 0.0, 0.017045454545454544,
            0.0, 0.017045454545454544, 0.011363636363636364,
            0.028409090909090908, 0.26704545454545453, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.3977272727272727, 0.11931818181818182,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.0072992700729927005, 0.0, 0.0,
            0.021897810218978103, 0.0072992700729927005, 0.021897810218978103,
            0.26277372262773724, 0.6496350364963503, 0.029197080291970802, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.15084721893072128) {
          var86 = [
            0.0, 0.0, 0.0, 0.21818181818181817, 0.17272727272727273,
            0.16363636363636364, 0.07272727272727272, 0.02727272727272727,
            0.12727272727272726, 0.01818181818181818, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.05454545454545454, 0.14545454545454545, 0.0,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.01990049751243781, 0.0945273631840796,
            0.09950248756218906, 0.48756218905472637, 0.1791044776119403,
            0.11940298507462686, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.09780000150203705) {
        if (input[39] <= 0.04010000079870224) {
          var86 = [
            0.0, 0.0, 0.0, 0.0392156862745098, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9411764705882353,
            0.0196078431372549, 0.0,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.10526315789473684, 0.15789473684210525, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.2631578947368421, 0.47368421052631576, 0.0,
          ];
        }
      } else {
        if (input[31] <= 0.397430881857872) {
          var86 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.5714285714285714, 0.42857142857142855,
            0.0,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.08333333333333333, 0.75, 0.0, 0.08333333333333333,
            0.08333333333333333,
          ];
        }
      }
    }
  } else {
    if (input[21] <= 0.011950000189244747) {
      if (input[1] <= 0.45643578469753265) {
        if (input[33] <= 0.0025500000920146704) {
          var86 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var86 = [
            0.13953488372093023, 0.0, 0.6976744186046512, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16279069767441862, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[28] <= 0.5559345185756683) {
          var86 = [
            0.9452054794520548, 0.0, 0.0273972602739726, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0273972602739726, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var86 = [
            0.16, 0.0, 0.6, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.24,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.008349999785423279) {
        if (input[40] <= 0.09609375149011612) {
          var86 = [
            0.0, 0.0, 0.06896551724137931, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.5689655172413793, 0.1896551724137931,
            0.034482758620689655, 0.08620689655172414, 0.05172413793103448, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.7049180327868853, 0.2459016393442623, 0.03278688524590164,
            0.01639344262295082, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.06749999895691872) {
          var86 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.05333333333333334, 0.6933333333333334, 0.14666666666666667, 0.08,
            0.02666666666666667, 0.0, 0.0, 0.0,
          ];
        } else {
          var86 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.011904761904761904, 0.08333333333333333, 0.14285714285714285,
            0.27380952380952384, 0.4642857142857143, 0.023809523809523808, 0.0,
            0.0,
          ];
        }
      }
    }
  }
  var var87;
  if (input[5] <= 0.49297480285167694) {
    if (input[16] <= 0.12558594346046448) {
      if (input[9] <= 0.1310499981045723) {
        if (input[31] <= 0.04179687425494194) {
          var87 = [
            0.015384615384615385, 0.0, 0.06153846153846154, 0.0, 0.0, 0.0,
            0.015384615384615385, 0.0, 0.0, 0.0, 0.015384615384615385,
            0.7538461538461538, 0.03076923076923077, 0.046153846153846156,
            0.03076923076923077, 0.0, 0.03076923076923077, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.031496062992125984, 0.0, 0.0, 0.015748031496062992,
            0.031496062992125984, 0.007874015748031496, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.023622047244094488, 0.6692913385826772,
            0.031496062992125984, 0.1889763779527559,
          ];
        }
      } else {
        if (input[8] <= 0.20508334040641785) {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.8, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.01694915254237288, 0.00847457627118644, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03389830508474576, 0.940677966101695, 0.0,
          ];
        }
      }
    } else {
      if (input[35] <= 0.09270833432674408) {
        if (input[11] <= 0.19131945073604584) {
          var87 = [
            0.0, 0.8709677419354839, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.06451612903225806, 0.0, 0.0, 0.0, 0.0,
            0.06451612903225806, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.21428571428571427, 0.42857142857142855,
            0.35714285714285715, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.06894531100988388) {
          var87 = [
            0.4108527131782946, 0.0, 0.4496124031007752, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.12403100775193798, 0.0,
            0.015503875968992248, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.008658008658008658, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.09090909090909091, 0.17316017316017315,
            0.3203463203463203, 0.14285714285714285, 0.15151515151515152,
            0.11255411255411256, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[25] <= 0.08124999701976776) {
      if (input[20] <= 0.23300058394670486) {
        if (input[40] <= 0.1630859375) {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06741573033707865,
            0.449438202247191, 0.48314606741573035, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.2437363862991333) {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.014492753623188406, 0.0,
            0.028985507246376812, 0.043478260869565216, 0.8695652173913043,
            0.043478260869565216, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[3] <= 0.048700001090765) {
        var87 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
        ];
      } else {
        if (input[9] <= 0.07515000179409981) {
          var87 = [
            0.0, 0.0, 0.0, 0.0106951871657754, 0.06417112299465241,
            0.1443850267379679, 0.44385026737967914, 0.20320855614973263,
            0.12834224598930483, 0.0053475935828877, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var87 = [
            0.0, 0.0, 0.0, 0.3592233009708738, 0.23300970873786409,
            0.2621359223300971, 0.14563106796116504, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var88;
  if (input[18] <= 0.04619999974966049) {
    if (input[6] <= 0.1221500001847744) {
      if (input[40] <= 0.7436748743057251) {
        if (input[23] <= 0.13958333432674408) {
          var88 = [
            0.2578125, 0.11328125, 0.1640625, 0.1171875, 0.08984375, 0.046875,
            0.02734375, 0.01953125, 0.00390625, 0.00390625, 0.01171875, 0.0,
            0.0703125, 0.0, 0.0078125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06640625,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.003236245954692557, 0.03559870550161812,
            0.009708737864077669, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10679611650485436, 0.11650485436893204, 0.28802588996763756,
            0.13592233009708737, 0.10032362459546926, 0.07766990291262135,
            0.12297734627831715, 0.003236245954692557, 0.0,
          ];
        }
      } else {
        if (input[16] <= 0.14374999701976776) {
          var88 = [
            0.0, 0.0, 0.0, 0.029850746268656716, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9701492537313433, 0.0,
            0.0,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.07692307692307693, 0.46153846153846156,
            0.46153846153846156, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[25] <= 0.9158914685249329) {
        if (input[10] <= 0.17954687774181366) {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.06666666666666667, 0.7333333333333333, 0.0, 0.0,
            0.2,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.019801980198019802, 0.0297029702970297,
            0.019801980198019802, 0.039603960396039604, 0.8910891089108911, 0.0,
          ];
        }
      } else {
        var88 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[9] <= 0.01510000042617321) {
      if (input[20] <= 0.34138065576553345) {
        if (input[4] <= 0.5480468571186066) {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.034782608695652174,
            0.0782608695652174, 0.11304347826086956, 0.1565217391304348,
            0.6173913043478261, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.01694915254237288, 0.03389830508474576,
            0.2711864406779661, 0.2033898305084746, 0.288135593220339,
            0.1864406779661017, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[18] <= 0.16050000116229057) {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.9, 0.1, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.0373500008136034) {
        if (input[25] <= 0.6363437473773956) {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.018018018018018018, 0.05405405405405406,
            0.8288288288288288, 0.08108108108108109, 0.018018018018018018, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.42857142857142855, 0.0, 0.0, 0.14285714285714285,
            0.2857142857142857, 0.14285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[3] <= 0.163100004196167) {
          var88 = [
            0.0, 0.0, 0.0, 0.0, 0.041666666666666664, 0.9583333333333334, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var88 = [
            0.0, 0.0, 0.0, 0.03125, 0.0625, 0.28125, 0.53125, 0.09375, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var89;
  if (input[16] <= 0.12558594346046448) {
    if (input[6] <= 0.1143999993801117) {
      if (input[10] <= 0.05253906175494194) {
        if (input[5] <= 0.2681490480899811) {
          var89 = [
            0.020202020202020204, 0.0, 0.050505050505050504, 0.0, 0.0, 0.0,
            0.04040404040404041, 0.0, 0.020202020202020204, 0.0,
            0.030303030303030304, 0.6464646464646465, 0.0, 0.010101010101010102,
            0.030303030303030304, 0.0, 0.0, 0.0, 0.010101010101010102, 0.0,
            0.1414141414141414,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.009900990099009901, 0.019801980198019802,
            0.009900990099009901, 0.09405940594059406, 0.1782178217821782,
            0.18316831683168316, 0.5, 0.0049504950495049506, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[35] <= 0.19697627425193787) {
          var89 = [
            0.0, 0.0, 0.0, 0.14035087719298245, 0.10877192982456141,
            0.18596491228070175, 0.32280701754385965, 0.07368421052631578,
            0.02456140350877193, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.12280701754385964, 0.007017543859649123, 0.014035087719298246,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.06004999950528145) {
        var89 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
        ];
      } else {
        if (input[30] <= 0.05014999955892563) {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.42857142857142855, 0.5714285714285714,
            0.0,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.024390243902439025, 0.975609756097561,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[39] <= 0.005100000184029341) {
      if (input[28] <= 0.0791015625) {
        if (input[9] <= 0.04780000075697899) {
          var89 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.06894531100988388) {
          var89 = [
            0.5899280575539568, 0.02877697841726619, 0.2733812949640288, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1079136690647482, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.4444444444444444, 0.20987654320987653, 0.14814814814814814,
            0.04938271604938271, 0.09876543209876543, 0.04938271604938271, 0.0,
            0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[40] <= 0.5496093928813934) {
        if (input[6] <= 0.05745000019669533) {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10810810810810811, 0.7297297297297297, 0.13513513513513514,
            0.02702702702702703, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.13636363636363635, 0.3939393939393939, 0.25757575757575757,
            0.21212121212121213, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.00925000011920929) {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.9230769230769231, 0.0, 0.07692307692307693, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var89 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.04, 0.12, 0.12, 0.32, 0.4, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var90;
  if (input[9] <= 0.04154999926686287) {
    if (input[14] <= 0.388888880610466) {
      if (input[1] <= 0.6142728328704834) {
        if (input[21] <= 0.011950000189244747) {
          var90 = [
            0.3484848484848485, 0.15151515151515152, 0.21717171717171718, 0.0,
            0.0, 0.010101010101010102, 0.020202020202020204,
            0.020202020202020204, 0.010101010101010102, 0.005050505050505051,
            0.0, 0.10606060606060606, 0.045454545454545456, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.06565656565656566,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.017543859649122806, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.21052631578947367, 0.3157894736842105,
            0.14035087719298245, 0.10526315789473684, 0.13157894736842105,
            0.043859649122807015, 0.008771929824561403, 0.0,
            0.02631578947368421,
          ];
        }
      } else {
        if (input[7] <= 0.2158203125) {
          var90 = [
            0.0, 0.0, 0.17857142857142858, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.35714285714285715, 0.17857142857142858,
            0.07142857142857142, 0.03571428571428571, 0.17857142857142858, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07246376811594203, 0.7971014492753623, 0.11594202898550725, 0.0,
            0.014492753623188406, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[14] <= 0.6706503331661224) {
        if (input[30] <= 0.004050000105053186) {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0048543689320388345,
            0.014563106796116505, 0.0825242718446602, 0.11650485436893204,
            0.1941747572815534, 0.587378640776699, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.07407407407407407, 0.0, 0.4074074074074074,
            0.14814814814814814, 0.37037037037037035, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var90 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[12] <= 0.031950000673532486) {
      if (input[6] <= 0.12164999917149544) {
        if (input[17] <= 0.04270833358168602) {
          var90 = [
            0.0, 0.0, 0.0, 0.1978021978021978, 0.09340659340659341,
            0.027472527472527472, 0.016483516483516484, 0.005494505494505495,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.005494505494505495,
            0.6483516483516484, 0.005494505494505495, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.16363636363636364, 0.2, 0.23636363636363636, 0.4, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[9] <= 0.11425000056624413) {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.125, 0.875, 0.0, 0.0, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.12790697674418605, 0.872093023255814,
            0.0,
          ];
        }
      }
    } else {
      if (input[14] <= 0.41579224169254303) {
        if (input[41] <= 0.6381944417953491) {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.7272727272727273, 0.0, 0.0,
            0.2727272727272727, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.06976744186046512, 0.23255813953488372,
            0.3953488372093023, 0.3023255813953488, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.03799999877810478) {
          var90 = [
            0.0, 0.0, 0.0, 0.0, 0.030303030303030304, 0.12121212121212122,
            0.7878787878787878, 0.050505050505050504, 0.010101010101010102, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var90 = [
            0.0, 0.0, 0.0, 0.1111111111111111, 0.4074074074074074,
            0.2962962962962963, 0.18518518518518517, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var91;
  if (input[5] <= 0.49297480285167694) {
    if (input[6] <= 0.1148499995470047) {
      if (input[9] <= 0.053449999541044235) {
        if (input[18] <= 0.18155000358819962) {
          var91 = [
            0.141439205955335, 0.07444168734491315, 0.11910669975186104, 0.0,
            0.0, 0.0, 0.004962779156327543, 0.0, 0.0024813895781637717, 0.0,
            0.01240694789081886, 0.0, 0.0794044665012407, 0.12406947890818859,
            0.19851116625310175, 0.1141439205955335, 0.04466501240694789,
            0.03225806451612903, 0.01488833746898263, 0.0, 0.03722084367245657,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.018518518518518517, 0.9814814814814815, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[15] <= 0.016599999740719795) {
          var91 = [
            0.0, 0.0, 0.0, 0.043859649122807015, 0.02631578947368421,
            0.008771929824561403, 0.0, 0.008771929824561403, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.8947368421052632,
            0.008771929824561403, 0.008771929824561403,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.05660377358490566, 0.07547169811320754, 0.33962264150943394,
            0.5283018867924528, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.1664140820503235) {
        if (input[29] <= 0.3062499910593033) {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.5714285714285714, 0.0, 0.14285714285714285,
            0.2857142857142857, 0.0,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[17] <= 0.08645833283662796) {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.04950495049504951, 0.9108910891089109,
            0.039603960396039604,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.125, 0.5, 0.375, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.05253906175494194) {
      if (input[24] <= 0.014700000174343586) {
        if (input[3] <= 0.18255000561475754) {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.021739130434782608,
            0.021739130434782608, 0.9565217391304348, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.010416666666666666, 0.0,
            0.052083333333333336, 0.375, 0.5104166666666666,
            0.052083333333333336, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.010950000025331974) {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2631578947368421,
            0.7368421052631579, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.0, 0.2, 0.13333333333333333, 0.4,
            0.26666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[12] <= 0.06550000235438347) {
        if (input[9] <= 0.1006000004708767) {
          var91 = [
            0.0, 0.0, 0.0, 0.2222222222222222, 0.28888888888888886,
            0.08888888888888889, 0.13333333333333333, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.26666666666666666, 0.0, 0.0,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.6923076923076923, 0.15384615384615385,
            0.11538461538461539, 0.038461538461538464, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.4762499928474426) {
          var91 = [
            0.0, 0.0, 0.0, 0.08108108108108109, 0.12162162162162163,
            0.13513513513513514, 0.3918918918918919, 0.1891891891891892,
            0.08108108108108109, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var91 = [
            0.0, 0.0, 0.0, 0.010752688172043012, 0.021505376344086023,
            0.10752688172043011, 0.8279569892473119, 0.03225806451612903, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var92;
  if (input[6] <= 0.1148499995470047) {
    if (input[3] <= 0.06500000134110451) {
      if (input[16] <= 0.12558594346046448) {
        if (input[40] <= 0.09103156998753548) {
          var92 = [
            0.0, 0.0, 0.031914893617021274, 0.0425531914893617,
            0.05319148936170213, 0.031914893617021274, 0.02127659574468085, 0.0,
            0.010638297872340425, 0.0, 0.05319148936170213, 0.4787234042553192,
            0.0, 0.010638297872340425, 0.0, 0.0, 0.0, 0.0, 0.02127659574468085,
            0.0, 0.24468085106382978,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.009259259259259259, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.018518518518518517, 0.0, 0.0, 0.0,
            0.018518518518518517, 0.9444444444444444, 0.009259259259259259, 0.0,
          ];
        }
      } else {
        if (input[39] <= 0.005100000184029341) {
          var92 = [
            0.29955947136563876, 0.12334801762114538, 0.20704845814977973, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.23788546255506607,
            0.06167400881057269, 0.039647577092511016, 0.013215859030837005,
            0.01762114537444934, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.16, 0.405, 0.16, 0.115, 0.16, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.08298610895872116) {
        if (input[30] <= 0.00419999985024333) {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.00980392156862745, 0.024509803921568627,
            0.10784313725490197, 0.1568627450980392, 0.19607843137254902,
            0.4852941176470588, 0.0196078431372549, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.1896551724137931, 0.08620689655172414,
            0.08620689655172414, 0.3103448275862069, 0.1724137931034483,
            0.15517241379310345, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[20] <= 0.23874591290950775) {
          var92 = [
            0.0, 0.0, 0.0, 0.10909090909090909, 0.21818181818181817,
            0.23636363636363636, 0.37272727272727274, 0.06363636363636363, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.018867924528301886, 0.03773584905660377,
            0.03773584905660377, 0.8679245283018868, 0.03773584905660377, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[10] <= 0.1778583824634552) {
      if (input[21] <= 0.2722499966621399) {
        if (input[1] <= 0.1171875) {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.5703125) {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[25] <= 0.8770616352558136) {
        if (input[29] <= 0.19305555522441864) {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.027522935779816515, 0.05504587155963303,
            0.9174311926605505, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[29] <= 0.42673611640930176) {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var92 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.5, 0.0, 0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var93;
  if (input[14] <= 0.3170138895511627) {
    if (input[6] <= 0.14030000567436218) {
      if (input[25] <= 0.0777578093111515) {
        if (input[6] <= 0.014150000177323818) {
          var93 = [
            0.27049180327868855, 0.06967213114754098, 0.1721311475409836, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.004098360655737705,
            0.11885245901639344, 0.1762295081967213, 0.0778688524590164,
            0.02459016393442623, 0.004098360655737705, 0.00819672131147541, 0.0,
            0.0, 0.0, 0.07377049180327869,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.004761904761904762, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.11904761904761904, 0.3952380952380952,
            0.13333333333333333, 0.1619047619047619, 0.12857142857142856,
            0.047619047619047616, 0.009523809523809525, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.20438344031572342) {
          var93 = [
            0.0, 0.0, 0.0, 0.373134328358209, 0.11940298507462686,
            0.14925373134328357, 0.014925373134328358, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.05970149253731343, 0.11940298507462686,
            0.1044776119402985, 0.05970149253731343, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.05128205128205128, 0.02564102564102564, 0.0,
            0.008547008547008548, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.008547008547008548, 0.017094017094017096, 0.05982905982905983,
            0.7863247863247863, 0.042735042735042736, 0.0,
          ];
        }
      }
    } else {
      if (input[21] <= 0.13529999554157257) {
        if (input[8] <= 0.7723278999328613) {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.01834862385321101, 0.981651376146789,
            0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      } else {
        if (input[8] <= 0.7368055582046509) {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
          ];
        }
      }
    }
  } else {
    if (input[9] <= 0.029200000688433647) {
      if (input[5] <= 0.28629764914512634) {
        if (input[19] <= 0.22542066872119904) {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03571428571428571, 0.9642857142857143, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[32] <= 0.06354166567325592) {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.00546448087431694, 0.00546448087431694,
            0.0546448087431694, 0.12568306010928962, 0.2786885245901639,
            0.5245901639344263, 0.00546448087431694, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.23076923076923078,
            0.7692307692307693, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[11] <= 0.4720972329378128) {
        if (input[24] <= 0.1513499990105629) {
          var93 = [
            0.0, 0.0, 0.0, 0.10144927536231885, 0.13043478260869565,
            0.043478260869565216, 0.43478260869565216, 0.2608695652173913,
            0.028985507246376812, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.0, 0.18181818181818182, 0.6363636363636364,
            0.09090909090909091, 0.09090909090909091, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[12] <= 0.06520000100135803) {
          var93 = [
            0.0, 0.0, 0.0, 0.25, 0.625, 0.125, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var93 = [
            0.0, 0.0, 0.0, 0.00847457627118644, 0.03389830508474576,
            0.15254237288135594, 0.788135593220339, 0.01694915254237288, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var94;
  if (input[20] <= 0.09618055447936058) {
    if (input[15] <= 0.01269999984651804) {
      if (input[22] <= 0.09932077303528786) {
        if (input[8] <= 0.5348110496997833) {
          var94 = [
            0.023529411764705882, 0.0, 0.023529411764705882,
            0.18823529411764706, 0.16470588235294117, 0.16470588235294117,
            0.09411764705882353, 0.011764705882352941, 0.011764705882352941,
            0.011764705882352941, 0.047058823529411764, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.03529411764705882, 0.047058823529411764,
            0.17647058823529413,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.020833333333333332, 0.041666666666666664, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0625,
            0.84375, 0.03125,
          ];
        }
      } else {
        if (input[32] <= 0.20264732837677002) {
          var94 = [
            0.0, 0.0, 0.0, 0.4117647058823529, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.17647058823529413, 0.11764705882352941, 0.0,
            0.11764705882352941, 0.0, 0.17647058823529413, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.016, 0.904, 0.072, 0.008,
          ];
        }
      }
    } else {
      if (input[32] <= 0.05069444328546524) {
        if (input[15] <= 0.238149993121624) {
          var94 = [
            0.32710280373831774, 0.0, 0.1822429906542056, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.19626168224299065, 0.1822429906542056,
            0.06542056074766354, 0.037383177570093455, 0.009345794392523364,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07849999889731407) {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.058333333333333334, 0.4666666666666667, 0.26666666666666666,
            0.15833333333333333, 0.05, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.014705882352941176, 0.11764705882352941, 0.36764705882352944,
            0.5, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[11] <= 0.38402777910232544) {
      if (input[20] <= 0.3443065732717514) {
        if (input[18] <= 0.12605000287294388) {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.014492753623188406, 0.007246376811594203,
            0.036231884057971016, 0.10144927536231885, 0.2318840579710145,
            0.2608695652173913, 0.34782608695652173, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06382978723404255,
            0.0851063829787234, 0.0425531914893617, 0.8085106382978723, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[18] <= 0.16050000116229057) {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[32] <= 0.20069444924592972) {
        if (input[6] <= 0.038200000301003456) {
          var94 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.16666666666666666, 0.5151515151515151,
            0.19696969696969696, 0.12121212121212122, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.09523809523809523, 0.47619047619047616,
            0.3333333333333333, 0.09523809523809523, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.5486111342906952) {
          var94 = [
            0.0, 0.0, 0.0, 0.010869565217391304, 0.07608695652173914,
            0.10869565217391304, 0.8043478260869565, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var94 = [
            0.0, 0.0, 0.0, 0.8, 0.0, 0.2, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var95;
  if (input[3] <= 0.048700001090765) {
    if (input[30] <= 0.06940000131726265) {
      if (input[16] <= 0.12949219346046448) {
        if (input[22] <= 0.1002630814909935) {
          var95 = [
            0.0, 0.0, 0.08333333333333333, 0.03571428571428571,
            0.023809523809523808, 0.011904761904761904, 0.03571428571428571,
            0.0, 0.011904761904761904, 0.0, 0.05952380952380952,
            0.6547619047619048, 0.011904761904761904, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.011904761904761904, 0.05952380952380952, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.007575757575757576, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.022727272727272728, 0.0, 0.0, 0.015151515151515152,
            0.07575757575757576, 0.8484848484848485, 0.030303030303030304, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.011500000022351742) {
          var95 = [
            0.3333333333333333, 0.11616161616161616, 0.20707070707070707, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.24242424242424243,
            0.06565656565656566, 0.020202020202020204, 0.015151515151515152,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.09004739336492891, 0.38388625592417064, 0.1848341232227488,
            0.1990521327014218, 0.14218009478672985, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[30] <= 0.3320999965071678) {
        if (input[10] <= 0.10853658616542816) {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.009259259259259259, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.009259259259259259,
            0.037037037037037035, 0.9351851851851852, 0.009259259259259259,
          ];
        }
      } else {
        var95 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0,
        ];
      }
    }
  } else {
    if (input[39] <= 0.004449999891221523) {
      if (input[20] <= 0.26579292118549347) {
        if (input[31] <= 0.09531249850988388) {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14685314685314685,
            0.18181818181818182, 0.3356643356643357, 0.3356643356643357, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.10810810810810811, 0.16216216216216217,
            0.1891891891891892, 0.13513513513513514, 0.05405405405405406,
            0.35135135135135137, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.5996705889701843) {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.018518518518518517, 0.0,
            0.037037037037037035, 0.9444444444444444, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3333333333333333, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.6666666666666666, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      }
    } else {
      if (input[39] <= 0.04645000025629997) {
        if (input[4] <= 0.779296875) {
          var95 = [
            0.0, 0.0, 0.0, 0.0, 0.017241379310344827, 0.13793103448275862,
            0.7586206896551724, 0.08620689655172414, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.16666666666666666, 0.5, 0.2777777777777778, 0.0,
            0.05555555555555555, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[36] <= 0.013650000095367432) {
          var95 = [
            0.0, 0.0, 0.0, 0.6428571428571429, 0.21428571428571427,
            0.14285714285714285, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var95 = [
            0.0, 0.0, 0.0, 0.13333333333333333, 0.4222222222222222,
            0.3111111111111111, 0.08888888888888889, 0.044444444444444446, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var96;
  if (input[19] <= 0.06679687649011612) {
    if (input[16] <= 0.12558594346046448) {
      if (input[9] <= 0.13665000349283218) {
        if (input[36] <= 0.007250000024214387) {
          var96 = [
            0.024691358024691357, 0.0, 0.043209876543209874,
            0.05555555555555555, 0.030864197530864196, 0.006172839506172839,
            0.0, 0.0, 0.0, 0.006172839506172839, 0.0, 0.0, 0.0,
            0.024691358024691357, 0.006172839506172839, 0.0,
            0.006172839506172839, 0.024691358024691357, 0.6419753086419753,
            0.024691358024691357, 0.10493827160493827,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.24, 0.24, 0.14, 0.16, 0.06, 0.0, 0.0, 0.08, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.08, 0.0, 0.0,
          ];
        }
      } else {
        if (input[8] <= 0.36804166436195374) {
          var96 = [
            0.0, 0.0, 0.0, 0.5, 0.35714285714285715, 0.07142857142857142, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.07142857142857142,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.03571428571428571, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.044642857142857144,
            0.9196428571428571, 0.0,
          ];
        }
      }
    } else {
      if (input[17] <= 0.38944604992866516) {
        if (input[40] <= 0.0224609375) {
          var96 = [
            0.24858757062146894, 0.005649717514124294, 0.2824858757062147, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.24293785310734464,
            0.12429378531073447, 0.05649717514124294, 0.005649717514124294,
            0.005649717514124294, 0.02824858757062147, 0.0, 0.0, 0.0,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.11467889908256881, 0.3211009174311927, 0.19724770642201836,
            0.2018348623853211, 0.1651376146788991, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        var96 = [
          0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    }
  } else {
    if (input[26] <= 0.3375000059604645) {
      if (input[12] <= 0.023099999874830246) {
        if (input[3] <= 0.12655000388622284) {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[5] <= 0.26875001192092896) {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.10526315789473684, 0.8947368421052632, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.012578616352201259,
            0.025157232704402517, 0.06918238993710692, 0.27672955974842767,
            0.5911949685534591, 0.025157232704402517, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.375) {
        if (input[41] <= 0.30173611640930176) {
          var96 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.043478260869565216,
            0.41304347826086957, 0.5217391304347826, 0.021739130434782608, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.02040816326530612, 0.16326530612244897,
            0.1836734693877551, 0.30612244897959184, 0.2653061224489796,
            0.061224489795918366, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[30] <= 0.025249999947845936) {
          var96 = [
            0.0, 0.0, 0.0, 0.022727272727272728, 0.0, 0.045454545454545456,
            0.9090909090909091, 0.022727272727272728, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var96 = [
            0.0, 0.0, 0.0, 0.09090909090909091, 0.22727272727272727,
            0.20454545454545456, 0.4659090909090909, 0.011363636363636364, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var97;
  if (input[12] <= 0.05145000107586384) {
    if (input[29] <= 0.08819444477558136) {
      if (input[20] <= 0.32046398520469666) {
        if (input[26] <= 0.10555555671453476) {
          var97 = [
            0.0, 0.176056338028169, 0.0, 0.014084507042253521, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.007042253521126761, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.007042253521126761, 0.007042253521126761, 0.07746478873239436,
            0.6056338028169014, 0.1056338028169014,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.0, 0.135678391959799, 0.11055276381909548,
            0.07035175879396985, 0.04020100502512563, 0.005025125628140704, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.020100502512562814,
            0.4824120603015075, 0.135678391959799, 0.0,
          ];
        }
      } else {
        var97 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[39] <= 0.003800000064074993) {
        if (input[33] <= 0.028350000269711018) {
          var97 = [
            0.40540540540540543, 0.013513513513513514, 0.20945945945945946, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.18243243243243243,
            0.0472972972972973, 0.060810810810810814, 0.013513513513513514,
            0.02027027027027027, 0.0472972972972973, 0.0, 0.0, 0.0,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.17647058823529413, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.45098039215686275, 0.23529411764705882,
            0.0784313725490196, 0.0392156862745098, 0.0, 0.0196078431372549,
            0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[6] <= 0.07889999821782112) {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.24242424242424243, 0.40606060606060607, 0.23636363636363636,
            0.06666666666666667, 0.030303030303030304, 0.01818181818181818, 0.0,
            0.0,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.04918032786885246, 0.04918032786885246, 0.3442622950819672,
            0.5245901639344263, 0.03278688524590164, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[24] <= 0.014700000174343586) {
      if (input[20] <= 0.3746827691793442) {
        if (input[13] <= 0.5400991439819336) {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.02, 0.0, 0.04, 0.11, 0.81, 0.02,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.05660377358490566,
            0.05660377358490566, 0.20754716981132076, 0.32075471698113206,
            0.3584905660377358, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        var97 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[39] <= 0.010600000154227018) {
        if (input[36] <= 0.03215000033378601) {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.02702702702702703, 0.0, 0.02702702702702703,
            0.21621621621621623, 0.7027027027027027, 0.02702702702702703, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.0, 0.03571428571428571, 0.07142857142857142, 0.0,
            0.14285714285714285, 0.4642857142857143, 0.2857142857142857, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[38] <= 0.4399526119232178) {
          var97 = [
            0.0, 0.0, 0.0, 0.017241379310344827, 0.10344827586206896,
            0.27586206896551724, 0.46551724137931033, 0.10344827586206896,
            0.034482758620689655, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        } else {
          var97 = [
            0.0, 0.0, 0.0, 0.0, 0.02197802197802198, 0.15384615384615385,
            0.7912087912087912, 0.03296703296703297, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var98;
  if (input[4] <= 0.16417907923460007) {
    if (input[29] <= 0.0954861119389534) {
      if (input[20] <= 0.33887386322021484) {
        if (input[22] <= 0.1002630814909935) {
          var98 = [
            0.0, 0.25, 0.0, 0.013157894736842105, 0.02631578947368421, 0.0,
            0.019736842105263157, 0.013157894736842105, 0.0, 0.0,
            0.013157894736842105, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.03289473684210526, 0.46710526315789475, 0.16447368421052633,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.008547008547008548, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.042735042735042736,
            0.06837606837606838, 0.7777777777777778, 0.10256410256410256, 0.0,
          ];
        }
      } else {
        var98 = [
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        ];
      }
    } else {
      if (input[30] <= 0.0034000000450760126) {
        if (input[17] <= 0.1682114452123642) {
          var98 = [
            0.020833333333333332, 0.0, 0.375, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.21875, 0.21875, 0.08333333333333333,
            0.020833333333333332, 0.0625, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.5079365079365079, 0.03968253968253968, 0.07936507936507936, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.14285714285714285,
            0.16666666666666666, 0.05555555555555555, 0.007936507936507936, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[9] <= 0.04894999973475933) {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.08333333333333333, 0.5083333333333333, 0.15833333333333333, 0.125,
            0.1, 0.025, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.013513513513513514, 0.22972972972972974, 0.35135135135135137,
            0.36486486486486486, 0.04054054054054054, 0.0, 0.0,
          ];
        }
      }
    }
  } else {
    if (input[25] <= 0.07558593899011612) {
      if (input[31] <= 0.06718750298023224) {
        if (input[37] <= 0.7109375) {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.009174311926605505,
            0.08256880733944955, 0.3119266055045872, 0.5504587155963303,
            0.045871559633027525, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.11666666666666667,
            0.8833333333333333, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        }
      } else {
        if (input[6] <= 0.04635000042617321) {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[9] <= 0.04085000045597553) {
        if (input[4] <= 0.46660156548023224) {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.1724137931034483,
            0.1724137931034483, 0.6551724137931034, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.0, 0.041666666666666664, 0.08333333333333333,
            0.08333333333333333, 0.6041666666666666, 0.16666666666666666,
            0.020833333333333332, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        }
      } else {
        if (input[14] <= 0.32951389253139496) {
          var98 = [
            0.0, 0.0, 0.0, 0.4375, 0.390625, 0.09375, 0.015625, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0625, 0.0, 0.0,
          ];
        } else {
          var98 = [
            0.0, 0.0, 0.0, 0.05755395683453238, 0.10071942446043165,
            0.2517985611510791, 0.49640287769784175, 0.08633093525179857,
            0.007194244604316547, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0,
          ];
        }
      }
    }
  }
  var var99;
  if (input[36] <= 0.004799999995157123) {
    if (input[9] <= 0.1310499981045723) {
      if (input[25] <= 0.0777578093111515) {
        if (input[6] <= 0.030949999578297138) {
          var99 = [
            0.19148936170212766, 0.1276595744680851, 0.16312056737588654, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0070921985815602835, 0.010638297872340425,
            0.03546099290780142, 0.12056737588652482, 0.18439716312056736,
            0.07801418439716312, 0.02127659574468085, 0.0070921985815602835,
            0.0070921985815602835, 0.0, 0.0, 0.0, 0.04609929078014184,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.005235602094240838, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.07853403141361257, 0.4083769633507853,
            0.13612565445026178, 0.1518324607329843, 0.17277486910994763,
            0.02617801047120419, 0.005235602094240838, 0.015706806282722512,
          ];
        }
      } else {
        if (input[39] <= 0.04295000061392784) {
          var99 = [
            0.0, 0.0, 0.0, 0.008620689655172414, 0.0, 0.0, 0.04310344827586207,
            0.008620689655172414, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.008620689655172414, 0.08620689655172414, 0.1206896551724138,
            0.7155172413793104, 0.008620689655172414, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.25925925925925924, 0.2222222222222222,
            0.18518518518518517, 0.07407407407407407, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.1111111111111111, 0.1111111111111111, 0.0,
            0.037037037037037035, 0.0,
          ];
        }
      }
    } else {
      if (input[8] <= 0.20033332705497742) {
        if (input[32] <= 0.13263888657093048) {
          var99 = [
            0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.5, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.5,
          ];
        }
      } else {
        if (input[22] <= 0.08384714648127556) {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.3142857142857143, 0.6857142857142857,
            0.0,
          ];
        }
      }
    }
  } else {
    if (input[41] <= 0.5795138776302338) {
      if (input[25] <= 0.08124999701976776) {
        if (input[12] <= 0.30354999005794525) {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.007575757575757576, 0.0,
            0.05303030303030303, 0.19696969696969696, 0.7196969696969697,
            0.022727272727272728, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2727272727272727,
            0.09090909090909091, 0.6363636363636364, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[11] <= 0.4618055671453476) {
          var99 = [
            0.0, 0.0, 0.0, 0.01694915254237288, 0.05084745762711865,
            0.01694915254237288, 0.0, 0.4576271186440678, 0.4406779661016949,
            0.01694915254237288, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.39473684210526316, 0.23684210526315788,
            0.05263157894736842, 0.2894736842105263, 0.02631578947368421, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        }
      }
    } else {
      if (input[6] <= 0.04059999994933605) {
        if (input[20] <= 0.22502563893795013) {
          var99 = [
            0.0, 0.0, 0.0, 0.021052631578947368, 0.08421052631578947, 0.2,
            0.4842105263157895, 0.17894736842105263, 0.031578947368421054, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.0, 0.0273972602739726, 0.0, 0.8904109589041096,
            0.0821917808219178, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0,
          ];
        }
      } else {
        if (input[22] <= 0.05232558026909828) {
          var99 = [
            0.0, 0.0, 0.0, 0.3, 0.16666666666666666, 0.4, 0.13333333333333333,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0,
          ];
        } else {
          var99 = [
            0.0, 0.0, 0.0, 0.1111111111111111, 0.1111111111111111, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.7777777777777778, 0.0, 0.0,
          ];
        }
      }
    }
  }
  return mulVectorNumber(
    addVectors(
      addVectors(
        addVectors(
          addVectors(
            addVectors(
              addVectors(
                addVectors(
                  addVectors(
                    addVectors(
                      addVectors(
                        addVectors(
                          addVectors(
                            addVectors(
                              addVectors(
                                addVectors(
                                  addVectors(
                                    addVectors(
                                      addVectors(
                                        addVectors(
                                          addVectors(
                                            addVectors(
                                              addVectors(
                                                addVectors(
                                                  addVectors(
                                                    addVectors(
                                                      addVectors(
                                                        addVectors(
                                                          addVectors(
                                                            addVectors(
                                                              addVectors(
                                                                addVectors(
                                                                  addVectors(
                                                                    addVectors(
                                                                      addVectors(
                                                                        addVectors(
                                                                          addVectors(
                                                                            addVectors(
                                                                              addVectors(
                                                                                addVectors(
                                                                                  addVectors(
                                                                                    addVectors(
                                                                                      addVectors(
                                                                                        addVectors(
                                                                                          addVectors(
                                                                                            addVectors(
                                                                                              addVectors(
                                                                                                addVectors(
                                                                                                  addVectors(
                                                                                                    addVectors(
                                                                                                      addVectors(
                                                                                                        addVectors(
                                                                                                          addVectors(
                                                                                                            addVectors(
                                                                                                              addVectors(
                                                                                                                addVectors(
                                                                                                                  addVectors(
                                                                                                                    addVectors(
                                                                                                                      addVectors(
                                                                                                                        addVectors(
                                                                                                                          addVectors(
                                                                                                                            addVectors(
                                                                                                                              addVectors(
                                                                                                                                addVectors(
                                                                                                                                  addVectors(
                                                                                                                                    addVectors(
                                                                                                                                      addVectors(
                                                                                                                                        addVectors(
                                                                                                                                          addVectors(
                                                                                                                                            addVectors(
                                                                                                                                              addVectors(
                                                                                                                                                addVectors(
                                                                                                                                                  addVectors(
                                                                                                                                                    addVectors(
                                                                                                                                                      addVectors(
                                                                                                                                                        addVectors(
                                                                                                                                                          addVectors(
                                                                                                                                                            addVectors(
                                                                                                                                                              addVectors(
                                                                                                                                                                addVectors(
                                                                                                                                                                  addVectors(
                                                                                                                                                                    addVectors(
                                                                                                                                                                      addVectors(
                                                                                                                                                                        addVectors(
                                                                                                                                                                          addVectors(
                                                                                                                                                                            addVectors(
                                                                                                                                                                              addVectors(
                                                                                                                                                                                addVectors(
                                                                                                                                                                                  addVectors(
                                                                                                                                                                                    addVectors(
                                                                                                                                                                                      addVectors(
                                                                                                                                                                                        addVectors(
                                                                                                                                                                                          addVectors(
                                                                                                                                                                                            addVectors(
                                                                                                                                                                                              addVectors(
                                                                                                                                                                                                addVectors(
                                                                                                                                                                                                  addVectors(
                                                                                                                                                                                                    addVectors(
                                                                                                                                                                                                      addVectors(
                                                                                                                                                                                                        addVectors(
                                                                                                                                                                                                          var0,
                                                                                                                                                                                                          var1
                                                                                                                                                                                                        ),
                                                                                                                                                                                                        var2
                                                                                                                                                                                                      ),
                                                                                                                                                                                                      var3
                                                                                                                                                                                                    ),
                                                                                                                                                                                                    var4
                                                                                                                                                                                                  ),
                                                                                                                                                                                                  var5
                                                                                                                                                                                                ),
                                                                                                                                                                                                var6
                                                                                                                                                                                              ),
                                                                                                                                                                                              var7
                                                                                                                                                                                            ),
                                                                                                                                                                                            var8
                                                                                                                                                                                          ),
                                                                                                                                                                                          var9
                                                                                                                                                                                        ),
                                                                                                                                                                                        var10
                                                                                                                                                                                      ),
                                                                                                                                                                                      var11
                                                                                                                                                                                    ),
                                                                                                                                                                                    var12
                                                                                                                                                                                  ),
                                                                                                                                                                                  var13
                                                                                                                                                                                ),
                                                                                                                                                                                var14
                                                                                                                                                                              ),
                                                                                                                                                                              var15
                                                                                                                                                                            ),
                                                                                                                                                                            var16
                                                                                                                                                                          ),
                                                                                                                                                                          var17
                                                                                                                                                                        ),
                                                                                                                                                                        var18
                                                                                                                                                                      ),
                                                                                                                                                                      var19
                                                                                                                                                                    ),
                                                                                                                                                                    var20
                                                                                                                                                                  ),
                                                                                                                                                                  var21
                                                                                                                                                                ),
                                                                                                                                                                var22
                                                                                                                                                              ),
                                                                                                                                                              var23
                                                                                                                                                            ),
                                                                                                                                                            var24
                                                                                                                                                          ),
                                                                                                                                                          var25
                                                                                                                                                        ),
                                                                                                                                                        var26
                                                                                                                                                      ),
                                                                                                                                                      var27
                                                                                                                                                    ),
                                                                                                                                                    var28
                                                                                                                                                  ),
                                                                                                                                                  var29
                                                                                                                                                ),
                                                                                                                                                var30
                                                                                                                                              ),
                                                                                                                                              var31
                                                                                                                                            ),
                                                                                                                                            var32
                                                                                                                                          ),
                                                                                                                                          var33
                                                                                                                                        ),
                                                                                                                                        var34
                                                                                                                                      ),
                                                                                                                                      var35
                                                                                                                                    ),
                                                                                                                                    var36
                                                                                                                                  ),
                                                                                                                                  var37
                                                                                                                                ),
                                                                                                                                var38
                                                                                                                              ),
                                                                                                                              var39
                                                                                                                            ),
                                                                                                                            var40
                                                                                                                          ),
                                                                                                                          var41
                                                                                                                        ),
                                                                                                                        var42
                                                                                                                      ),
                                                                                                                      var43
                                                                                                                    ),
                                                                                                                    var44
                                                                                                                  ),
                                                                                                                  var45
                                                                                                                ),
                                                                                                                var46
                                                                                                              ),
                                                                                                              var47
                                                                                                            ),
                                                                                                            var48
                                                                                                          ),
                                                                                                          var49
                                                                                                        ),
                                                                                                        var50
                                                                                                      ),
                                                                                                      var51
                                                                                                    ),
                                                                                                    var52
                                                                                                  ),
                                                                                                  var53
                                                                                                ),
                                                                                                var54
                                                                                              ),
                                                                                              var55
                                                                                            ),
                                                                                            var56
                                                                                          ),
                                                                                          var57
                                                                                        ),
                                                                                        var58
                                                                                      ),
                                                                                      var59
                                                                                    ),
                                                                                    var60
                                                                                  ),
                                                                                  var61
                                                                                ),
                                                                                var62
                                                                              ),
                                                                              var63
                                                                            ),
                                                                            var64
                                                                          ),
                                                                          var65
                                                                        ),
                                                                        var66
                                                                      ),
                                                                      var67
                                                                    ),
                                                                    var68
                                                                  ),
                                                                  var69
                                                                ),
                                                                var70
                                                              ),
                                                              var71
                                                            ),
                                                            var72
                                                          ),
                                                          var73
                                                        ),
                                                        var74
                                                      ),
                                                      var75
                                                    ),
                                                    var76
                                                  ),
                                                  var77
                                                ),
                                                var78
                                              ),
                                              var79
                                            ),
                                            var80
                                          ),
                                          var81
                                        ),
                                        var82
                                      ),
                                      var83
                                    ),
                                    var84
                                  ),
                                  var85
                                ),
                                var86
                              ),
                              var87
                            ),
                            var88
                          ),
                          var89
                        ),
                        var90
                      ),
                      var91
                    ),
                    var92
                  ),
                  var93
                ),
                var94
              ),
              var95
            ),
            var96
          ),
          var97
        ),
        var98
      ),
      var99
    ),
    0.01
  );
}
function addVectors(v1, v2) {
  let result = new Array(v1.length);
  for (let i = 0; i < v1.length; i++) {
    result[i] = v1[i] + v2[i];
  }
  return result;
}
function mulVectorNumber(v1, num) {
  let result = new Array(v1.length);
  for (let i = 0; i < v1.length; i++) {
    result[i] = v1[i] * num;
  }
  return result;
}
