import * as constants from "../utils_new/Constants";

export class HighSpeedDetector {
  constructor() {
    this.timeThreshold = 5000;
    this.timeFront = -1;
    this.timeRear = -1;
    this.timeSideLeft = -1;
    this.timeSideRight = -1;
    this.speedCondFlag1 = 0;
    this.speedCondFlag2 = 0;
    this.speedCondFlag3 = 0;
    this.speedCondFlag4 = 0;
    this.speedCondFlag5 = 0;
    this.speedCondFlag6 = 0;
  }

  checkHighSpeed(regionsCoveredMap) {
    // Set an individual timer whenever one of the four regions [Front, Rear, Side-Left, Side-Right] get covered fully or partially.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.timeFront === -1
    )
      this.timeFront = performance.now();
    if (
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.timeSideLeft === -1
    )
      this.timeSideLeft = performance.now();
    if (
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.timeSideRight === -1
    )
      this.timeSideRight = performance.now();
    if (
      regionsCoveredMap[constants.REGIONS.REAR] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.timeRear === -1
    )
      this.timeRear = performance.now();

    // Check if user moved too fast between Front and Rear.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.REAR] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag1 === 0
    ) {
      if (Math.abs(this.timeRear - this.timeFront) < 2 * this.timeThreshold) {
        this.speedCondFlag1 = 1;
        return true;
      }
    }

    // Check if user moved too fast between Side-Left and Side-Right.
    if (
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag2 === 0
    ) {
      if (
        Math.abs(this.timeSideLeft - this.timeSideRight) <
        2 * this.timeThreshold
      ) {
        this.speedCondFlag2 = 1;
        return true;
      }
    }

    // Check if user moved too fast between Front and Side-Left.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.REAR] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag3 === 0
    ) {
      if (Math.abs(this.timeSideLeft - this.timeFront) < this.timeThreshold) {
        this.speedCondFlag3 = 1;
        return true;
      }
    }

    // Check if user moved too fast between Front and Side-Right.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.REAR] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag4 === 0
    ) {
      if (Math.abs(this.timeSideRight - this.timeFront) < this.timeThreshold) {
        this.speedCondFlag4 = 1;
        return true;
      }
    }

    // Check if user moved too fast between Rear and Side-Left.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.REAR] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag5 === 0
    ) {
      if (Math.abs(this.timeSideLeft - this.timeRear) < this.timeThreshold) {
        this.speedCondFlag5 = 1;
        return true;
      }
    }

    // Check if user moved too fast between Rear and Side-Right.
    if (
      regionsCoveredMap[constants.REGIONS.FRONT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_LEFT] ===
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.REAR] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      regionsCoveredMap[constants.REGIONS.SIDE_RIGHT] !==
        constants.REGION_COVERAGE_STATUS.NOT_COVERED &&
      this.speedCondFlag6 === 0
    ) {
      if (Math.abs(this.timeSideRight - this.timeRear) < this.timeThreshold) {
        this.speedCondFlag6 = 1;
        return true;
      }
    }

    return false;
  }
}
