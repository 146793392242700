// Estimation of Zoom based on size of Bounding Boxes of Parts detected.
export class DistanceEstimator {
  constructor() {
    // Initializing constants
    this.neitherZoomedNorFarAway = 0;
    this.isZoomed = 1;
    this.isFarAway = 2;

    // Labels for different categories of Car Parts
    this.upperParts = ["front_glass", "back_glass", "window_glass"];
    this.middleParts = [
      "hood",
      "dicky",
      "side_view_mirror",
      "headlight",
      "taillight",
    ];
    this.lowerParts = [
      "front_door",
      "back_door",
      "front_bumper",
      "back_bumper",
      "wheel",
      "running_board",
    ];

    // Different Edge Thresholds for Zoom
    this.topEdgeThresholdUpperZoom = 0.2;
    this.bottomEdgeThresholdUpperZoom = 0.7;

    this.topEdgeThresholdLowerZoom = 0.3;
    this.bottomEdgeThresholdLowerZoom = 0.8;

    // Different Edge Thresholds for FarAway
    this.topEdgeThresholdUpperFarAway = 0.35;
    this.bottomEdgeThresholdUpperFarAway = 0.75;

    this.topEdgeThresholdLowerFarAway = 0.25;
    this.bottomEdgeThresholdLowerFarAway = 0.65;
  }

  // Function that determines whether the Part is close to the edges of the Frame or not, depending on its type.
  getPartEdgeStatus(singleDetectionObj, partType, distanceType) {
    let topEdgeThreshold, bottomEdgeThreshold, topEdgeStatus, bottomEdgeStatus;

    // The Y and Height of the detection's Bbox
    let y = singleDetectionObj["bbox"][1];
    let height = singleDetectionObj["bbox"][3];

    // Set Thresholds for checking if Zoomed
    if (distanceType === "zoom") {
      // Thresholds for Upper parts
      if (partType === "upper") {
        topEdgeThreshold = this.topEdgeThresholdUpperZoom;
        bottomEdgeThreshold = this.bottomEdgeThresholdUpperZoom;
      }
      // Thresholds for Lower parts
      else {
        topEdgeThreshold = this.topEdgeThresholdLowerZoom;
        bottomEdgeThreshold = this.bottomEdgeThresholdLowerZoom;
      }
    }

    // Set Thresholds for checking if Far Away
    else {
      // Thresholds for Upper parts
      if (partType === "upper") {
        topEdgeThreshold = this.topEdgeThresholdUpperFarAway;
        bottomEdgeThreshold = this.bottomEdgeThresholdUpperFarAway;
      }
      // Thresholds for Lower parts
      else {
        topEdgeThreshold = this.topEdgeThresholdLowerFarAway;
        bottomEdgeThreshold = this.bottomEdgeThresholdLowerFarAway;
      }
    }

    // Compare the upper and bottom edges of the bbox with the threshold
    // For Zoom, the edges should lie outside the thresholds
    if (distanceType === "zoom") {
      topEdgeStatus = y <= topEdgeThreshold;
      bottomEdgeStatus = y + height >= bottomEdgeThreshold;
    }
    // For Far Away, the edges should lie within the thresholds
    else {
      topEdgeStatus = y >= topEdgeThreshold;
      bottomEdgeStatus = y + height <= bottomEdgeThreshold;
    }

    // Return true iff both the statuses are true
    if (topEdgeStatus && bottomEdgeStatus) return true;
    else return false;
  }

  // Function that performs the Zoom check
  checkIfZoomed(
    detectedUpperParts,
    detectedMiddleParts,
    detectedLowerParts,
    hasUpperParts,
    hasMiddleParts,
    hasLowerParts,
    hasAllPartCategories,
    detectionCount
  ) {
    // check if a window_glass is present and if it is close to the edges of the frame, then it is zoomed in.
    for (let i = 0; i < detectedUpperParts.length; i++) {
      if (detectedUpperParts[i]["label"] === "window_glass")
        if (this.getPartEdgeStatus(detectedUpperParts[i], "upper", "zoom"))
          return true;
    }

    // check if front_door or back_door is present and if they are close to the edges of the frame, then it is zoomed in.
    for (let i = 0; i < detectedLowerParts.length; i++) {
      if (
        detectedLowerParts[i]["label"] === "front_door" ||
        detectedLowerParts[i]["label"] === "back_door"
      )
        if (this.getPartEdgeStatus(detectedLowerParts[i], "lower", "zoom"))
          return true;
    }

    /* If all categories of parts (upper, middle and lower) are present, then it is probably not zoomed in
            Or If a lot of parts (>= 6) are detected, then it is not zoomed in. */
    if (hasAllPartCategories || detectionCount >= 6) return false;

    /* If program control has reached here, this means that all three Parts Categories are not present in the frame. 
            Now, we test on the basis of various combination of Upper, Middle and Lower Parts */

    // if Middle Part is present
    if (hasMiddleParts) {
      // if both Upper and Middle Parts are present
      if (hasUpperParts) {
        // compare each Upper Part's bboxes with the top and bottom edges of the Image as 'upper'
        for (let i = 0; i < detectedUpperParts.length; i++) {
          if (this.getPartEdgeStatus(detectedUpperParts[i], "upper", "zoom"))
            return true;
        }
        // compare each Middle Part's bboxes with the top and bottom edges of the Image as 'lower'
        for (let i = 0; i < detectedMiddleParts.length; i++) {
          if (this.getPartEdgeStatus(detectedMiddleParts[i], "lower", "zoom"))
            return true;
        }
      }

      // if both Middle and Lower Parts are present
      else if (hasLowerParts) {
        // compare each Middle Part's bboxes with the top and bottom edges of the Image as 'upper'
        for (let i = 0; i < detectedMiddleParts.length; i++) {
          if (this.getPartEdgeStatus(detectedMiddleParts[i], "upper", "zoom"))
            return true;
        }
        // compare each Lower Part's bboxes with the top and bottom edges of the Image as 'lower'
        for (let i = 0; i < detectedLowerParts.length; i++) {
          if (this.getPartEdgeStatus(detectedLowerParts[i], "lower", "zoom"))
            return true;
        }
      }

      // if only Middle Parts is present
      else {
        // compare each Middle Part's bboxes with the top and bottom edges of the Image as 'upper'
        for (let i = 0; i < detectedMiddleParts.length; i++) {
          if (this.getPartEdgeStatus(detectedMiddleParts[i], "upper", "zoom"))
            return true;
        }
        // compare each Middle Part's bboxes with the top and bottom edges of the Image as 'lower'
        for (let i = 0; i < detectedMiddleParts.length; i++) {
          if (this.getPartEdgeStatus(detectedMiddleParts[i], "lower", "zoom"))
            return true;
        }
      }
    }

    // if No Middle Part is present
    else {
      // compare each Upper Part's bboxes with the top and bottom edges of the Image as 'upper'
      for (let i = 0; i < detectedUpperParts.length; i++) {
        if (this.getPartEdgeStatus(detectedUpperParts[i], "upper", "zoom"))
          return true;
      }
      // compare each Lower Part's bboxes with the top and bottom edges of the Image as 'lower'
      for (let i = 0; i < detectedLowerParts.length; i++) {
        if (this.getPartEdgeStatus(detectedLowerParts[i], "lower", "zoom"))
          return true;
      }
    }
    return false;
  }

  // Function that performs the Far Away check
  checkIfFarAway(
    detectedUpperParts,
    detectedMiddleParts,
    detectedLowerParts,
    hasUpperParts,
    hasMiddleParts,
    hasLowerParts,
    hasAllPartCategories,
    detectionCount
  ) {
    // If all categories of parts (upper, middle and lower) are present, then it is probably far away.
    if (hasUpperParts && hasLowerParts) {
      // compare each Upper Part's bboxes with the top and bottom edges of the Image as 'upper'
      for (let i = 0; i < detectedUpperParts.length; i++) {
        if (this.getPartEdgeStatus(detectedUpperParts[i], "upper", "faraway"))
          return true;
      }
      // compare each Lower Part's bboxes with the top and bottom edges of the Image as 'lower'
      for (let i = 0; i < detectedLowerParts.length; i++) {
        if (this.getPartEdgeStatus(detectedLowerParts[i], "lower", "faraway"))
          return true;
      }
    }
    return false;
  }

  // Function that is used to check for the Zoom or Far Away conditions.
  checkDistance(boundingBoxDetections) {
    // Initializing Arrays to categorize the Detected Car Parts
    let detectedUpperParts = [];
    let detectedMiddleParts = [];
    let detectedLowerParts = [];

    // Categorize the Detected Car Parts and check for Window Glass and Doors
    boundingBoxDetections.forEach((detection) => {
      let label = detection["label"];

      // Push the detections into different categories
      if (this.upperParts.includes(label)) detectedUpperParts.push(detection);
      else if (this.middleParts.includes(label))
        detectedMiddleParts.push(detection);
      else if (this.lowerParts.includes(label))
        detectedLowerParts.push(detection);
    });

    let hasUpperParts = detectedUpperParts.length > 0;
    let hasMiddleParts = detectedMiddleParts.length > 0;
    let hasLowerParts = detectedLowerParts.length > 0;
    let hasAllPartCategories = hasUpperParts && hasMiddleParts && hasLowerParts;
    let detectionCount = boundingBoxDetections.length;

    // Check for Zoom and FarAway and return corresponding values.
    if (
      this.checkIfZoomed(
        detectedUpperParts,
        detectedMiddleParts,
        detectedLowerParts,
        hasUpperParts,
        hasMiddleParts,
        hasLowerParts,
        hasAllPartCategories,
        detectionCount
      )
    )
      return this.isZoomed;
    else if (
      this.checkIfFarAway(
        detectedUpperParts,
        detectedMiddleParts,
        detectedLowerParts,
        hasUpperParts,
        hasMiddleParts,
        hasLowerParts,
        hasAllPartCategories,
        detectionCount
      )
    )
      return this.isFarAway;
    else return this.neitherZoomedNorFarAway;
  }
}
