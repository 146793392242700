import React, { useContext } from "react";
import { AppContext } from "../context";

const Header = () => {
  const { config } = useContext(AppContext);

  return (
    <div className="title">
      <h1>{config["intro-page"]["title"]}</h1>
    </div>
  );
};


export default Header;
