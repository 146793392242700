import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context";
import NavbarP from "./NavBarP";
import Rotate from "../Rotate";
import Button from "../Button";
import white_tick from "../../img/white_tick.png";
import car_inspection from "../../img/car_inspection.png";
import {
  userLogs,
  patLogs,
  feedBackStatus,
} from "../../context/utils";

let allMandatoryCompleted = true;
let allCompleted = true;
let alignment = false;
let mandatoryMissing = [];
let totalMissing = [];
let logsbugs = true;
let damageModuleExists = false;
let interval;
let totalModuleComplete = "";

const MenuP = () => {
  const {
    damageModuleCompleted,
    showAlert,
    config,
    setCurrentBlock,
    screen,
    setScreen,
    condition,
    isLandscape,
    setLandscape,
    mandatory,
    completed,
    setCompleted,
    mandatoryCompleted,
    setMandatoryCompleted,
    count1,
    setCount1,
    scrollX,
    setscrollX,
    setscrolEnd,
    setStopPing,
    inspectionId,
    showFeedBackWarning,
    setShowFeedBackWarning,
    imageFeedbackPopupRef,
    setImageFeedbackPopupRef
  } = useContext(AppContext);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const modalRef = useRef();
  let scrl = useRef();
  const menufeedBackPopUp = useRef();
  const imageFeebackPopup = useRef();
  const [reduce, setReduce] = useState(false);
  const [menuBlocks, setMenuBlocks] = useState(config["blocks"]);

  window.onresize = () => {
    patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
    userLogs({ position: 11, screen_orientation: "yes", inspectionId });
    setLandscape(land() ? "Landscape Mode" : "Portrait Mode");
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const success = () => {
    if (!allMandatoryCompleted || !allCompleted) {
      patLogs(
        `Submit button clicked and warning given to user that some module is not completed -> ${totalMissing.join(
          ", "
        )}`,
        inspectionId
      );
      if (imageFeedbackPopupRef) {
        imageFeebackPopup.current.style.display = "none";
        setImageFeedbackPopupRef(false);
      }
      modalRef.current.style.display = "flex";
      return;
    }

    if (feedBackStatus() === "checking") {
      menufeedBackPopUp.current.style.display = "flex";
    } else if (feedBackStatus() === "Feedback") {
      menufeedBackPopUp.current.style.display = "none";
      setShowFeedBackWarning(true);
      clearInterval(interval);
    } else if (feedBackStatus() === "noFeedback") {
      clearInterval(interval);
      setScreen("success");
    } else {
      clearInterval(interval);
      setScreen("success");
    }
    checkData();
  };

  const checkData = () => {
    interval = setInterval(() => {
      if (feedBackStatus() === "checking") {
        menufeedBackPopUp.current.style.display = "flex";
      } else if (feedBackStatus() === "Feedback") {
        clearInterval(interval);
        menufeedBackPopUp.current.style.display = "none";
        setShowFeedBackWarning(true);
      } else if (feedBackStatus() === "noFeedback") {
        clearInterval(interval);
        menufeedBackPopUp.current.style.display = "none";
        setScreen("success");
      } else {
        clearInterval(interval);
        setScreen("success");
      }
    }, 1000);
  };

  const modalSuccess = () => {
    patLogs(
      `Warning given to user,  this module ${totalMissing.join(
        ", "
      )}  is not completed, still clicked to submit button`,
      inspectionId
    );

    if (feedBackStatus() === "checking") {
      menufeedBackPopUp.current.style.display = "flex";
    } else if (feedBackStatus() === "Feedback") {
      menufeedBackPopUp.current.style.display = "none";
      setShowFeedBackWarning(true);
      clearInterval(interval);
    } else if (feedBackStatus() === "noFeedback") {
      clearInterval(interval);
      setScreen("success");
    } else {
      clearInterval(interval);
      setScreen("success");
    }
    checkData();
  };

  const removeModal = () => {
    patLogs(
      `user clicked cross button to close warning and move to menu page`,
      inspectionId
    );
    modalRef.current.style.display = "none";
  };


  useEffect(() => {
    config["blocks"].map((e) => {
      if (Object.values(e).includes("damage")) {
        damageModuleExists = true;
      }
    });
    if (logsbugs) {
      // ReactGA.send({
      //   hitType: "pageview",
      //   page: "Menu Page",
      //   title: "Menu Page",
      // });
      patLogs(land() ? "Landscape Mode" : "Portrait Mode", inspectionId);
      userLogs({ position: 8, last_page: "menu page", inspectionId });
      logsbugs = false;
    }
    setStopPing(true);
    allCompleted = true;
    allMandatoryCompleted = true;
    mandatoryMissing = [];
    totalMissing = [];
    setLandscape(land());
    if (config["blocks"].length <= 3) {
      alignment = true;
    }
    Object.keys(mandatory).map((item) => {
      if (mandatory[item] === "pending") {
        mandatoryMissing.push(item);
        allMandatoryCompleted = false;
      }
    });
    Object.keys(condition).map((item) => {
      if (condition[item] === "pending") {
        totalMissing.push(item);
        allCompleted = false;
      }
    });
    setCount1(count1 + 1);
    if (allCompleted) setCompleted(true);
    if (allMandatoryCompleted) setMandatoryCompleted(true);

    config["blocks"].map((e) => {
      if (e["id"].length > 14) {
        setReduce(true);
      }
    });
    totalModuleComplete = "";
    Object.keys(condition).map((e) => {
      if (condition[e] === "completed") {
        totalModuleComplete = e + "," + totalModuleComplete;
      }
    });
    userLogs({
      position: 13,
      completed_module: totalModuleComplete,
      inspectionId,
    });
    if (Object.keys(config).includes("TimeOut")) {
      setTimeout(() => {
        console.log(config["TimeOut"]["timeInMinutes"] * 1000 * 60);
        if (!["success"].includes(screen)) {
          setScreen("timeOut");
        }
      }, config["TimeOut"]["timeInMinutes"] * 1000 * 60);
    }

    if (imageFeedbackPopupRef) {
      imageFeebackPopup.current.style.display = "flex";
    }

    if (isLandscape) {
      if (Object.keys(config).includes("directModule") && config["directModule"]) {
        setTimeout(() => {
          clearInterval(interval);
          patLogs(`Switch to video module directly`, inspectionId);
          setCurrentBlock(config["blocks"][0]);
          setScreen("block");
        }, 30)
      }
    }

  }, [isLandscape]);

  useEffect(() => { }, [showFeedBackWarning]);

  return isLandscape ? (
    <Rotate />
  ) : (
    <div className="process" style={{ position: "relative", justifyContent: "normal" }}>
      {
        Object.keys(config).includes("allowPortraitApp") ? <NavbarP /> : ""
      }
      {
        Object.keys(config).includes("directModule") && config["directModule"] ? "" : <h2 style={{ margin: "120px 0px 6px", textAlign: "center" }}>{config["menu-page"]["title"]}</h2>
      }
      <div ref={imageFeebackPopup} className="imageFeedBackCompletePopup">
        <div className="imageFeedBackInnerDiv" style={{ zIndex: 5 }}>
          <p>
            {Object.keys(config).includes("imageFeedback") &&
              Object.keys(config["imageFeedback"]).includes("ImageAnalysisCompleteText")
              ? config["imageFeedback"]["ImageAnalysisCompleteText"]
              : "Analysis is completed please click on submit and upload button"
            }
          </p>
          <div className="modal-btn-container">
            <Button
              text={config["menu-page"]["btn-text"]}
              disabled={!mandatoryCompleted}
              onclick={success}
            />
          </div>
        </div>
      </div>

      <div ref={menufeedBackPopUp} className="imageFeedBackPopup">
        <div style={{ zIndex: 5 }}>
          <p>
            {Object.keys(config).includes("imageFeedback") &&
              Object.keys(config["imageFeedback"]).includes("ImageAnalysisMssg2")
              ? config["imageFeedback"]["ImageAnalysisMssg2"]
              : "Please wait for some time while we analyse the images"}
          </p>
          <img
            style={{ width: "100px", height: "100px" }}
            src={car_inspection}
          />
        </div>
      </div>

      <div
        className="process-main"
        style={{
          marginTop: "7dvh",
          height: "40%",
          width: "100%",
          flexWrap: "wrap",
          gap: window.innerWidth > 419 ? "15px" : "",
          justifyContent: window.innerWidth <= 389 ? "space-evenly" : window.innerWidth > 389 && window.innerWidth <= 419 ? "space-between" : "",
          padding: "0px 10px 0px 20px"
        }}>
        {menuBlocks.map((item, index) => {
          if (
            item["tag"] === "360 Image" &&
            feedBackStatus() === "Feedback" &&
            showFeedBackWarning === true
          ) {
            return (
              <div
                className="menu-optionsP"
                style={reduce ? { fontSize: "13px" } : null}
                key={index}
              >
                {item["mandatory"] ? (
                  <h4 style={{ color: "red", margin: "0px" }} key={index}>
                    {item["id"]}&#42;
                  </h4>
                ) : (
                  <h4 style={{ color: "red", margin: "0px" }} key={index}>
                    {item["id"]}
                  </h4>
                )}
                <div
                  className="process-vin"
                  style={{
                    border: "2px solid red",
                    width: "120px",
                    height: "100px",
                    margin: "0px"
                  }}
                  onClick={() => {
                    clearInterval(interval);
                    setCurrentBlock(item);
                    patLogs(`click to ${item.tag}`, inspectionId);
                    setScreen("block");
                  }}
                >
                  <img src={item["link"]} alt="123"></img>
                </div>
              </div>
            );
          }
          if (condition[item["id"]] === "completed") {
            return (
              <div
                className="menu-optionsP"
                style={reduce ? { fontSize: "13px" } : null}
                key={index}
              >
                {item["mandatory"] ? (
                  <h4
                    style={{ color: config["colors"]["complete-block"], margin: "0px" }}
                    key={index}
                  >
                    {item["id"]}&#42;
                  </h4>
                ) : (
                  <h4
                    style={{ color: config["colors"]["complete-block"], margin: "0px" }}
                    key={index}
                  >
                    {item["id"]}
                  </h4>
                )}
                <div
                  className="process-vin"
                  style={{
                    border: `2px solid ${config["colors"]["complete-block"]}`,
                    width: "120px",
                    height: "100px",
                    margin: "0px"
                  }}
                >
                  <div className="greenCircleTick">
                    <img src={white_tick} alt="1232"></img>
                  </div>
                  <img src={item["link"]} alt="123"></img>
                </div>
              </div>
            );
          }
          return Object.keys(config).includes("directModule") && config["directModule"] ? "" : (
            <div
              className="menu-optionsP"
              style={reduce ? { fontSize: "14px" } : null}
              key={index}
            >
              {item["mandatory"] ? (
                <h4 style={{ margin: "0px" }} key={index}>{item["id"]}&#42;</h4>
              ) : (
                <h4 style={{ margin: "0px" }} key={index}>{item["id"]}</h4>
              )}
              <div
                className="process-vin"
                style={{
                  border: `1px solid ${config["colors"]["incomplete-block"]}`,
                  width: "120px",
                  height: "100px",
                  margin: "0px"
                }}
                onClick={() => {
                  setCurrentBlock(item);
                  patLogs(`click to ${item.tag}`, inspectionId);
                  setScreen("block");
                }}
              >
                <img src={item["link"]} alt="123"></img>
              </div>
            </div>
          );
        })}
      </div>
      <div id="menuP-mixed-container">
        <div className="InternetWarning">
          <p>
            {feedBackStatus() === "Feedback" && showFeedBackWarning === true
              ? Object.keys(config).includes("imageFeedback") &&
                Object.keys(config["imageFeedback"]).includes("recaptureMssg")
                ? config["imageFeedback"]["recaptureMssg"]
                : "Few images captured incorrectly. Please click red sections to recapture"
              : showAlert && showAlert}
          </p>
        </div>
        <div className="modal-btn-container">
          <Button
            text={config["menu-page"]["btn-text"]}
            disabled={!mandatoryCompleted}
            onclick={success}
          />
        </div>
      </div>

      <div ref={modalRef} className="menu-modal-container">
        {!mandatoryCompleted ? (
          <div className="menu-modal">
            <div className="menu-back-btn">
              <i className="fas fa-times fa-lg" onClick={removeModal}></i>
            </div>
            <h4>{config["menu-page"]["mandatory-modal-text"]}</h4>
            <h4>{mandatoryMissing.join(", ")}</h4>
          </div>
        ) : mandatoryCompleted && !completed ? (
          <div className="menu-modal">
            {!damageModuleCompleted &&
              Object.keys(config["menu-page"]).includes(
                "damageConfirmationPopup"
              ) &&
              damageModuleExists ? (
              <>
                <h4>{config["menu-page"]["damageConfirmationPopup"]}</h4>
                <br></br>
                <br></br>
                <div className="damageConfirmationPopup">
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupYesBtn"]}
                    onclick={() => {
                      modalSuccess();
                      modalRef.current.style.display = "none";
                    }}
                  />
                  <Button
                    text={config["menu-page"]["damageConfirmationPopupNoBtn"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      removeModal();
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="menu-back-btn">
                  <i className="fas fa-times fa-lg" onClick={removeModal}></i>
                </div>
                <h4>{config["menu-page"]["optional-modal-text"]}</h4>
                <h4>{totalMissing.join(", ")}</h4>
                <div className="modal-btn-container">
                  <Button
                    text={config["menu-page"]["modal-btn-next"]}
                    onclick={() => {
                      modalRef.current.style.display = "none";
                      modalSuccess();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MenuP;
